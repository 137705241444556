import can from "can";

var promise;

export default {
    load: function initYoutubeAPI() {
        if (promise) return promise; // already loading

        var ytAPIDeferred = can.Deferred();

        can.$(function bodyReadyForYoutube() {
            var tag = document.createElement("script");

            tag.src = "https://www.youtube.com/iframe_api";
            tag.async = true;
            document.body.appendChild(tag);

            window.onYouTubeIframeAPIReady = function () {
                ytAPIDeferred.resolve(window.YT);
            };
        });
        promise = ytAPIDeferred.promise();
        return promise;
    },
};
