import can from "can";
import utils from "../lib/utils";
import configOverride from "./configs/platformConfig";

window.APP_VERSION = "/* @echo APP_VERSION */";
/* @if IS_LOCAL_DEVELOPMENT */
window.APP_VERSION = window.platform + ":development:" + Date.now() + ":local";
/* @endif */
console.log("loading app version", window.APP_VERSION);

var distBuild = process.env.NODE_ENV === "production";

var smplEnv = utils.getSMPLEnv(distBuild);

// use tag for versioning (semver comparisons should be possible on released versions)
// var version =
//     process.env.NODE_ENV === "production"
//         ? "/*@echo GIT_CURRENT_TAG*/"
//         : "develop" + (hash ? "-" + hash : "");
// var hash = "/*@echo GIT_CURRENT_COMMIT*/";
// /* @if IS_LOCAL_DEVELOPMENT */
// hash = undefined;
// /* @endif */

var params = {
    d: "www",
    l: "de-DE",
};
var forceLiveAPI = window.location.search.indexOf("capi=live") >= 0;
if (forceLiveAPI) {
    console.log("The app will use the production API!");
}

var pmdBaseURL = "https://pmd.bilder.netzkino.de";
var pmdNetzkinoSeiteBaseURL = "https://pmd.netzkino-seite.netzkino.de";

var config = /* @if PLATFORM!='netzkino' */ utils.mergeDeep(
    /* @endif */ {
        debug:
            false ||
            !distBuild ||
            utils.getParameterByName("debug", window.location.href) === "true",
        distBuild: distBuild,
        general: {
            name: "Netzkino",
            support: "support@netzkino.de",
            privacy: "datenschutz@netzkino.de",
            baseURL: "https://www.netzkino.de/",
            newsletter: "newsletter@netzkino.de",
            newsletterUnsubscribe: "abbestellen@netzkino.de",
            jugendschutzEmail: "jugendschutzbeauftragter-netzkino@usk.de",
            company: "Spotfilm Networx GmbH",
            companyShort: "Spotfilm",
            websiteShort: "Netzkino.de",
            isLanding: () =>
                typeof window.nklanding !== "undefined" && nklanding,
            addressShort: "Görzallee 299, 14167 Berlin",
            address:
                "Spotfilm Networx GmbH<br />" +
                "Görzallee 299<br />" +
                "14167 Berlin<br />" +
                "Telefon: +49 30 9599 915 35<br />" +
                'E-Mail: <a href="mailto:support@netzkino.de">support@Netzkino.de</a><br />',
            privacyContact:
                "Spotfilm Networx GmbH<br />" +
                "Hauk Markus<br />" +
                '<a href="mailto:datenschutz@netzkino.de">datenschutz@Netzkino.de</a><br />' +
                "Görzallee 299<br />" +
                "14167 Berlin",
        },
        paths: {
            images: {
                // base: distBuild?'http://cdn.netzkino.de/www/assets':'assets',
                base: pmdBaseURL + "/bilder/", // trailing slash!
                get: function (path) {
                    if (!path) return;
                    if (path.indexOf("://") > 0 || path.indexOf("//") === 0) {
                        return path; // do not alter absolute urls
                    }
                    return this.base + path;
                },
                genreToImage: (cat) =>
                    pmdBaseURL + "/bilder/webseite/Genre/" + cat.slug + ".jpg",
                loadingPlaceholder:
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
            },
            api: {
                // support the live version via ?capi=live
                base:
                    process.env.NODE_ENV === "production"
                        ? "//api.netzkino.de.simplecache.net/capi-2.0a"
                        : forceLiveAPI
                        ? "//api.netzkino.de.simplecache.net/capi-2.0a"
                        : "//netzkino-tapi.herokuapp.com/capi-2.0a",
                // base: '//10.0.1.2:1337/capi-2.0a',
                // base: '//localhost:1337/capi-2.0a',
                comments: "//dyn.netzkino.de/api/submit_comment",
                baseParams: params,
                paramOverride: function (params) {
                    params = params || {};
                    if (
                        window.platform === "netzkino" &&
                        utils.mightBePlusUser()
                    ) {
                        return utils.mergeDeep({}, params, {
                            d: "www-plus",
                        });
                    } else {
                        return params;
                    }
                },
                category: {
                    findOne: "/categories/{id}.json",
                    // findOneParams: params,
                    findAll: "/index.json", //+ '?' + params
                    search: "/search?q={search}", // &
                },
                movie: {
                    findOne: "/movies/{slug}.json",
                    countryCheck: "/country",
                },
                moviesList: {
                    get: "/moviesList",
                },
            },
        },
        categoryTree: {
            enabled: true,
            isRoot: function (cat) {
                var parentId =
                    cat.parentId !== undefined ? cat.parentId : cat.parent;

                if (cat instanceof can.Map) {
                    parentId = cat.attr("parent");
                }
                return parentId === 0;
            },
            categoryEnrichment: {
                // slug: { subtitle, displayedDescription, metadescription }
            },
        },
        my: {
            enabled: true,
            title: "MeinNetzkino",
            feedbackEmail: "support@netzkino.de",
            registerLabel: "Jetzt kostenlos registrieren!",
            recommendationCategory: "empfehlungen_woche",
            accountPageOnly: false,
            accountPage: {
                subscriptionRequired: false,
            },
            wishlist: {
                name: "Watchlist",
                sliderType: "sliderDoubleRow",
            },
            api: {
                getUrl: function (request) {
                    var mapping = {
                        login: "/auth/login",
                        logingoogle: "/auth/google",
                        loginfacebook: "/auth/facebook",
                        logout: "/auth/logout",
                        users: "/users/",
                        me: "/users/me",
                        createLoginToken: "/users/createLoginToken",
                        wishlist: "/wishlist/",
                        watchlist: "/watchlist/",
                        watchlistAdd: "/watchlist/add",
                        watchlistUpdate: "/watchlist/update",
                        base: "/",
                    };
                    if (mapping[request]) {
                        return (
                            "https://proxy.netzkino." +
                            smplEnv +
                            ".smpl.services" +
                            mapping[request]
                        ); //'//localhost:60099' + mapping[request]; ////login.netzkino.de' + mapping[request];
                    } else {
                        return null;
                    }
                },
                headerPlatformName: "Netzkino", // used for the X-{headerPlatformName}-Version headers set in api calls - needs to match backend deploy configuration
            },
            header: {
                loginLabel: "Login",
                buttonClass: "btn-light",
                isLoggedInButton: false,
            },
            smartLogin: {
                enabled: true,
                showHeaderButton: false,
                showMyButton: false,
            },
            forgotPasswordUrl:
                process.env.NODE_ENV === "production"
                    ? "//www.netzkino.de/forgot.html"
                    : "//www.netzkino.de/forgot-" + smplEnv + ".html",
            // redirects to mainpage where depending on user plus state redirection takes place
            // ATTENTION: Needs 'auth.php' script (see root of this project) deployed (manually) on target URL
            localLoginUrl: () =>
                "https://proxy.netzkino." +
                smplEnv +
                ".smpl.services/auth/login",
            // ATTENTION: DUPLICATED in my/plus! Needs 'auth.php' script (see root of this project) deployed (manually) on target URL
            localRegisterUrl: () =>
                "https://proxy.netzkino." + smplEnv + ".smpl.services/users/",
            socialLoginUrl: (provider) =>
                location.protocol +
                "//www.netzkino.de/auth?next=" +
                encodeURIComponent(
                    window.location.href.split("#")[0] + "#!/my"
                ),
            loginProviders: [
                // {
                //     name: 'Mit Facebook einloggen',
                //     registerName: 'Mit Facebook registrieren',
                //     key: 'facebook',
                //     image: 'webseite/social_f.png'
                // },
                // {
                //     name: 'Mit Google einloggen',
                //     registerName: 'Mit Google registrieren',
                //     key: 'google',
                //     image: 'webseite/social_g.png'
                // }
            ],
        },
        plus: {
            enabled: true,
            supportsPackages: true,
            title: "NetzkinoPlus",
            socialBar: {
                claim: "Mehr Filme für Alle!",
            },
            slogan: "Mehr Filme für Alle mit NetzkinoPlus",
            welcomeMessage:
                "Die Zahlung war erfolgreich! Willkommen bei NetzkinoPlus!",
            paymentDataChangedMessage:
                "Deine Zahlungsdaten wurden erfolgreich aktualisiert.",
            price: "ab 3,75€",
            headerLogoImagePath: "webseite/logo_header_small_plus.png",
            recommendationCategory:
                "unsere-empfehlungen-der-woche-netzkinoplus",
            packages: {
                supported: true,
                typeSelectionfootnoteHTML:
                    'NetzkinoPlus ist mit einem fortlaufenden Abonnement (monatliche Zahlung via Visa oder Mastercard) oder im Paketmodell (einmalige Zahlung via Visa, Mastercard oder PayPal) abschließbar. Die Zahlungsdaten lassen sich jederzeit unter den Benutzerkonto-Einstellungen ändern. Mehr Informationen findest du im <a href="https://inside.netzkino.de/faq" class="login-link" target="_blank">FAQ-Bereich</a>.',
                packagesFootnoteHTML:
                    "Bei der Option Paketkauf bestellst Du einmalig ein befristetes NetzkinoPlus Abonnement, welches sich nicht verlängert.<br />Du hast die Auswahlmöglichkeiten 3, 6 oder 12 Monate Laufzeit.",
            },
            paywall: {
                enabled: false,
                redirectTo: "plus",
                whitelist: [], // list of controls that are allowed to access
            },
            infoPage: {
                show: true,
            },
            api: {
                getUrl: function (request) {
                    switch (request) {
                        case "preparePayment":
                            return (
                                "https://payment.netzkino." +
                                smplEnv +
                                ".smpl.services/payment/prepare"
                            );
                        case "cancelPayment":
                            return (
                                "https://payment.netzkino." +
                                smplEnv +
                                ".smpl.services/payment/cancel"
                            );
                        case "getProducts":
                            return (
                                "https://payment.netzkino." +
                                smplEnv +
                                ".smpl.services/products"
                            );
                        case "postPaypalPayment":
                            return (
                                "https://payment.netzkino." +
                                smplEnv +
                                ".smpl.services" +
                                "/paypal/custom/notification"
                            );
                        case "stripeWalletPayment":
                            return (
                                "https://payment.netzkino." +
                                smplEnv +
                                ".smpl.services/payment/stripe/wallet"
                            );
                        case "postPaypalOrder":
                            return (
                                "https://payment.netzkino." +
                                smplEnv +
                                ".smpl.services" +
                                "/paypal/order/create"
                            );
                        case "createToken":
                            return (
                                "https://token.netzkino." +
                                smplEnv +
                                ".smpl.services/createToken"
                            );
                        default:
                            throw new Error("Invalid request type: " + request);
                    }
                },
            },
            socialLoginUrl: (provider) =>
                location.protocol +
                "//www.netzkino.de/auth?next=" +
                encodeURIComponent(
                    window.location.href.split("#")[0] + "#!/my/plus"
                ),
            plusCategorySlug: "netzkinoplus",
            plusCategoryId: 9681, // better have it defined together instead of relying on correct spelling / lookup - used in category.js
            isPlusCategory: (cat) =>
                cat.id === 9681 || cat.slug === "netzkinoplus",
            overridePlusCategoryInheritance: (cat) => false,
            showExtraCTA: true, //show extra payment/plus subscribe form on movie page
            plusCategoryToHide: (cat) => cat.slug === "netzkinoplus-highlights",
            genreCategories: {
                enabled: true,
                appearAfter: 9692,
                title: "Netzkino Plus Genre",
                linkTo: "netzkinoplus",
                categoryIds: [
                    /*10081,*/ 10131, 10151, 10171, 10191, 10211, 10231, 9811,
                ],
            },
            // This is the special erotik category handling for PrickelkinoPlus where this "PlusGenre" (only to be shown if the user is plus) has nearly the
            // same "power"/purpose as the Genre slider on the front page
            genreHighlightCategory: {
                enabled: true,
                appearAfter: 9721,
                title: "Genre Prickelkino",
                linkTo: "netzkinoplus-prickelkino",
                categoryId: 9811,
                categoryIds: [
                    9831, 9841, 9842, 9851, 9861 /*9961*/, 9881, 10001,
                    /*9891,*/ 9901, 9911, 9921, 9931, 9941, 9951,
                ],
            },
            getLandingPagePreviewURLs: function () {
                // working in the coffee-shop be like ... whups?
                if (["80", "443", ""].indexOf(window.location.port) > -1) {
                    return {
                        placeholderURL:
                            pmdBaseURL + "/bilder/webseite/plus/plus_image.jpg",
                        videoURL:
                            pmdBaseURL + "/bilder/webseite/plus/plus_movie.mp4",
                    };
                } else {
                    return {
                        placeholderURL:
                            "http://camendesign.com/code/video_for_everybody/poster.jpg",
                        videoURL:
                            "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4",
                    };
                }
            },
            getLandingPageMobileImageURL: () =>
                pmdBaseURL + "/bilder/webseite/plus/plus_mobile.jpg",
            registrationOptionHeader: {
                firstOptionTitle: "MeinNetzkino",
                firstOptionPrice: "kostenlos",
                secondOptionTitle: "NetzkinoPlus*",
                secondOptionPrice: "ab 3,75€ / Monat",
            },
            registrationOptions: [
                {
                    name: "Viele weitere Filme",
                    firstOption: false,
                    secondOption: true,
                },
                {
                    name: "2.000 Filme ohne Werbung**",
                    firstOption: false,
                    secondOption: true,
                },
                {
                    name: "Keine Bannerwerbung",
                    firstOption: false,
                    secondOption: true,
                },
                {
                    name: "Viele neue Erotik Highlights",
                    firstOption: false,
                    secondOption: true,
                },
                {
                    name: "Filme in HD***",
                    firstOption: false,
                    secondOption: true,
                },
                {
                    name: "Filme weiterschauen",
                    firstOption: true,
                    secondOption: true,
                },
                {
                    name: "Watchlist speichern",
                    firstOption: true,
                    secondOption: true,
                },
            ],
            registrationOptionFootnote: [
                "*NetzkinoPlus ist verfügbar unter netzkino.de. **Einige Filme enthalten Werbung.",
                "***Die High-Definition-Verfügbarkeit ist abhängig von deiner Internetgeschwindigkeit. Nicht alle Filme sind in HD verfügbar.",
            ],
            paymentFootnoteHTML:
                'Mit dem Kauf berechtigst du Netzkino deine Zahlungsmethode jeden Monat mit 4,99€/Monat (inkl. gesetzlicher MwSt) zu belasten. Das Abo verlängert sich jeden Monat automatisch und kann jederzeit gekündigt werden. Es gelten die Netzkino <a href="#!/agb" class="login-link" target="_blank">AGBs</a> inkl. der Widerrufsbelehrung.',
        },
        geoBlocking: {
            enabled: true,
        },
        // catArray: [39,61,/*81,*/1,4,32,18,6,51,31,3,10,5,33,35,/*91,*/71,111],
        header: {
            apps: {
                show: false,
                buttons: [
                    {
                        label: "iOS",
                        link: "https://itunes.apple.com/de/app/netzkino/id560901396?mt=8",
                        img: "https://s3-eu-west-1.amazonaws.com/uploads-eu.hipchat.com/62625/471677/TKZMCBPe9awJt2p/btn_appstore.png",
                    },
                    {
                        label: "Android",
                        link: "https://play.google.com/store/apps/details?id=de.netzkino.android.ics",
                        img: "https://s3-eu-west-1.amazonaws.com/uploads-eu.hipchat.com/62625/471677/tGAlTwDhnj88Oui/buttons_googlestore.png",
                    },
                    {
                        label: "Windows 8",
                        link: "http://apps.microsoft.com/windows/de-de/app/netzkino/fc8ac95f-b14e-44ef-a148-9a4c7bec7919",
                        img: "https://s3-eu-west-1.amazonaws.com/uploads-eu.hipchat.com/62625/471677/ZsElwKvxmAHH5cv/btn_windowsstore.png",
                    },
                    {
                        label: "Windows Phone 8",
                        link: "http://www.windowsphone.com/de-de/store/app/netzkino/3734d2e8-c646-472d-94cb-fc021505867c",
                        img: "https://s3-eu-west-1.amazonaws.com/uploads-eu.hipchat.com/62625/471677/00Iz0XMqa7Y9Vl3/btn_windowsphonestore.png",
                    },
                    {
                        label: "Amazon Kindle",
                        link: "http://www.amazon.de/Netzkino-Services-GmbH/dp/B00MR1YQM8",
                        img: "https://s3-eu-west-1.amazonaws.com/uploads-eu.hipchat.com/62625/471677/3UgUuk8Ukr0boRr/btn_amazonappstore.png",
                    },
                    {
                        label: "Amazon Fire TV",
                        link: "http://www.amazon.de/Netzkino-Filme-Movies-Videos-Spielfilme/dp/B00QRZUQQK/ref=sr_1_2?s=mobile-apps&ie=UTF8&qid=1419279264&sr=1-2&keywords=netzkino",
                        img: "https://s3-eu-west-1.amazonaws.com/uploads-eu.hipchat.com/62625/471677/AtoN0WvscbePHeb/btn_amazonfire.png",
                    },
                ],
            },
            info: {
                show: true,
                label: "Info",
                links: [
                    {
                        link: "https://inside.netzkino.de/ueber-uns",
                        label: "Über uns",
                        blank: true,
                    },
                    {
                        link: "https://inside.netzkino.de/so-gehts",
                        label: "So geht´s",
                        blank: true,
                    },
                    {
                        link: "https://inside.netzkino.de/newsletter",
                        label: "Newsletter",
                        blank: true,
                    },
                    {
                        link: "https://inside.netzkino.de",
                        label: "NetzkinoInside",
                        blank: true,
                    },
                    {
                        link: "https://inside.netzkino.de/blog",
                        label: "Blog",
                        blank: true,
                    },
                    {
                        link: "https://inside.netzkino.de/faq",
                        label: "Support & FAQ",
                        blank: true,
                    },
                    {
                        link: "https://inside.netzkino.de/presse",
                        label: "Presse",
                        blank: true,
                    },
                    {
                        link: "https://inside.netzkino.de/werbung",
                        label: "Werbung",
                        blank: true,
                    },
                    {
                        link: "https://www.netzkino.de/gutscheinfilm",
                        label: "Gutscheinfilm",
                        blank: false,
                    },
                ],
            },
            categories: {
                enabled: true,
                label: "Genre",
                icons: true,
                iconPath: "categories_trans/",
                forceVerticalNavigation: false,
                displayType: "dropdown",
            },
        },
        search: {
            displayType: "coverflow",
            menuLabel: "Suche",
        },
        socialBar: {
            show: true,
            claim: "Kostenlos und legal Filme schauen!",
            followMotivation: {
                show: true,
                img: pmdBaseURL + "/bilder/webseite/sml_btn_cta_white.png",
                label: "Folge uns auf",
            },
            extraLogo: {
                show: false,
                // label: 'Entertain Web',
                // img: pmdBaseURL + '/bilder/webseite/entertainweb_logo.png',
                // link: 'http://www.entertainweb.de'
            },
            // white images for footer display
            links: [
                {
                    // if you change this, update the media query in netzkino.scss (search for config.footer.social.images)
                    label: "Netzkino bei Facebook liken",
                    link: "https://www.facebook.com/netzkino",
                    image:
                        pmdBaseURL + "/bilder/webseite/smbutton_fb_small.png",
                    imageWhite:
                        pmdBaseURL + "/bilder/webseite/smbutton_fb_small_w.png",
                },
                {
                    label: "Netzkino bei Twitter folgen",
                    link: "https://twitter.com/netzkino",
                    image:
                        pmdBaseURL + "/bilder/webseite/smbutton_tw_small.png",
                    imageWhite:
                        pmdBaseURL + "/bilder/webseite/smbutton_tw_small_w.png",
                },
                {
                    label: "Netzkino bei Youtube abonnieren",
                    link: "https://www.youtube.com/netzkino",
                    image:
                        pmdBaseURL + "/bilder/webseite/smbutton_yt_small.png",
                    imageWhite:
                        pmdBaseURL + "/bilder/webseite/smbutton_yt_small_w.png",
                },
            ],
        },
        footer: {
            buttons: {
                show: true,
                buttons: [
                    {
                        link: "https://itunes.apple.com/de/app/netzkino/id560901396?mt=8",
                        img: "webseite/btn_appstore.png",
                    },
                    {
                        link: "https://play.google.com/store/apps/details?id=de.netzkino.android.ics",
                        img: "webseite/btn_googlestore.png",
                    },
                    {
                        link: "http://www.amazon.de/Netzkino-Services-GmbH/dp/B00MR1YQM8",
                        img: "webseite/btn_amazonappstore.png",
                    },
                    {
                        link: "http://www.amazon.de/Netzkino-Filme-Movies-Videos-Spielfilme/dp/B00QRZUQQK/ref=sr_1_2?s=mobile-apps&ie=UTF8&qid=1419279264&sr=1-2&keywords=netzkino",
                        img: "webseite/btn_amazonfire.png",
                    },
                    {
                        link: "https://appgallery1.huawei.com/#/app/C101518857",
                        img: "webseite/btn_huawei.png",
                    },
                ],
            },
            info: "Netzkino ist legal & kostenlos und finanziert sich über Werbeeinnahmen, um Filme schauen zu können deaktiviere bitte Deinen Adblocker.",
            links: [
                {
                    link: "#!/",
                    label: "Startseite",
                },
                {
                    link: "mailto:support@spotfilm-networx.com",
                    label: "Kontakt",
                },
                {
                    link: "https://inside.netzkino.de/jobs",
                    label: "Jobs",
                    blank: true,
                },
                {
                    link: "https://www.spotfilm-networx.com/jugendschutz",
                    label: "Jugendschutz",
                    blank: true,
                },
                {
                    link: "https://www.spotfilm-networx.com/agb",
                    label: "AGB",
                    blank: true,
                },
                {
                    link: "https://www.spotfilm-networx.com/datenschutz",
                    label: "Datenschutz",
                    blank: true,
                },
                {
                    link: "https://www.spotfilm-networx.com/impressum",
                    label: "Impressum",
                    blank: true,
                },
                {
                    link: "#!/",
                    label: "Cookie Policy",
                },
                {
                    link: "https://inside.netzkino.de/werbung",
                    label: "Werbung",
                    blank: true,
                },
            ],
            copyright: "Spotfilm Networx GmbH " + new Date().getFullYear(),
            copyrightLink: "https://www.spotfilm.de/",
            social: {
                show: true,
                images: [
                    // if you change this, update the media query in netzkino.scss (search for config.footer.social.images)
                    {
                        src:
                            pmdBaseURL +
                            "/bilder/webseite/footerImage-top100.png",
                        alt: "TOP 100",
                        link: "https://www.top100.de/die-top-innovatoren/2017/netzkino-services-gmbh.html",
                    },
                    {
                        src: "https://pci.usd.de/compliance/7157-3CE4-E5BA-2D54-F990-B591/seal_80.png",
                        link: "https://pci.usd.de/compliance/7157-3CE4-E5BA-2D54-F990-B591/details_de.html",
                        alt: "PCI compliance",
                        id: "pciCompliance",
                    },
                    {
                        src:
                            pmdBaseURL + "/bilder/webseite/footerImage-usk.png",
                        alt: "USK",
                        link: "http://www.usk.de",
                    },
                    {
                        src:
                            pmdBaseURL +
                            "/bilder/webseite/footerImage-top100yt.png",
                        alt: "YOUTUBER-RELEVANZINDEX",
                        link: "http://www.faktenkontor.de/youtuber-relevanzindex/",
                    },
                ],
            },
        },
        moviepage: {
            preferredAvoPlayer: "html5",
            facebookComments: {
                show: true,
            },
            sharing: {
                enabled: true,
                twitterVia: "netzkino",
                whatsApp: {
                    show: utils.isIOS() || utils.isAndroid(),
                    buttonText: "Bei WhatsApp teilen",
                    shareText: (movie) =>
                        "Schaue dir den Film bei Netzkino an: " +
                        movie.attr("title") +
                        " " +
                        movie._permalink(),
                },
            },
            akamaiOptionsForMovie: (movie) => ({
                // netzkino supports adaptive streaming
                streamingBitrates:
                    movie.attr("custom_fields.Adaptives_Streaming.0") === "HD"
                        ? "600000,1000000,1700000"
                        : "600000,1000000,2000000",
            }),
            audioSourceTemplate: () => pmdNetzkinoSeiteBaseURL + "/{}",
            videoSourceTemplate: function (isProtected, sourceReplacements) {
                var sourceTemplate = {
                    // nklanding for luigi
                    hds: "http://dzango_webseite-vh.akamaihd.net/z/Dzango-Filme/Der_einsame_Reiter_LowBitrate.mp4/manifest.f4m",
                    hls: "https://netzkino_ios2-vh.akamaihd.net/i/{}",
                    pmd: "https://pmd.netzkino-win8.netzkino.de/{}",
                };

                if (!window.nklanding) {
                    if (isProtected && sourceReplacements) {
                        sourceTemplate = {
                            hds:
                                "https://nk_plus_hds-vh.akamaihd.net/z/" +
                                sourceReplacements["hds"],
                            hls:
                                "https://nk_plus_hls-vh.akamaihd.net/i/" +
                                sourceReplacements["hls"],
                            pmd:
                                "https://pmd.netzkino-plus.netzkino.de/" +
                                sourceReplacements["pmd"],
                        };
                    } else {
                        sourceTemplate = {
                            hds: "https://netzkino_seite-vh.akamaihd.net/z/{}",
                            hls: "https://nk_seite_hls-vh.akamaihd.net/i/{}",
                            pmd: pmdNetzkinoSeiteBaseURL + "/{}",
                        };
                    }
                }

                return sourceTemplate;
            },
            scheduleURLForMovie: function (movie, userIsPlus) {
                // see AVO-137 - this should speedup loading the player
                if (location.hostname === "dev.netzkino.de") {
                    // FIXME dev only
                    return null;
                }
                var categories = movie.attr("categories") || [];
                var catIds = categories.join(",");
                var adconfURL = "//www.netzkino.de/adconf/avoplayer.php";
                if (
                    window.AVOCore.utils.featureDetector.has("flash") &&
                    window.AVOCore.utils.featureDetector.has("video.vpaid")
                ) {
                    adconfURL =
                        "//www.netzkino.de/adconf/avoplayer-with-vpaid.php";
                } else if (movie.hasMovieProperty("svod") && userIsPlus) {
                    adconfURL =
                        "//www.netzkino.de/adconf/avoplayer-plus-svod.php";
                } else if (userIsPlus) {
                    adconfURL =
                        "//www.netzkino.de/adconf/avoplayer-plus-avod.php";
                }
                return (
                    adconfURL +
                    "?flash=" +
                    window.AVOCore.utils.featureDetector.has("flash") +
                    "&movieId=" +
                    movie.attr("id") +
                    "&nklanding=" +
                    window.nklanding +
                    (catIds ? "&categoryIds=" + catIds : "") +
                    (userIsPlus ? "&plusUser=true" : "")
                );
            },
        },
        frontpage: {
            sliderTypeForCategory: function (cat) {
                switch (cat.slug) {
                    case "highlights": // fallthrough //has YT, needs large imgAll
                    case "filme_mit_auszeichnungen": //has YT, needs large imgAll
                    case "netzkino-exklusiv": //has YT, needs large imgAll
                    case "empfehlungen_woche":
                    default:
                        var availableSliderTypes = ["dynamicDoubleRowSlider"];
                        // ['sliderDefaultVertical', 'sliderDefaultHorizontalSmall', 'sliderDoubleRow',  'sliderDefaultHorizontal'];
                        return availableSliderTypes[
                            Math.floor(
                                Math.random() * availableSliderTypes.length
                            )
                        ];
                }
            },
            // position is not an ideal solution, as we need to ensure min array size, but we can use this to easily add some of the additional slider images
            // to the front (by ordering here and setting all to position:0)
            additionalSliderImages: function (category, userIsPlus) {
                // bei kategorie prickelkino nur prickelkino slider
                // wenn plusUser kein Themenkino und kein Werbeslider
                // wenn nicht plusUser dann Werbeslider normal
                var netflixSlider = {
                    actualSliderImageSmall:
                        pmdBaseURL + "/bilder/featuredSlider/Dogs-small.jpg",
                    actualSliderImageLarge:
                        pmdBaseURL + "/bilder/featuredSlider/Dogs.jpg",
                    actualSliderLink: "https://bit.ly/2ABhnPY",
                    actualSliderVideoLink:
                        pmdBaseURL + "/Ads/Dogs_trailer_2000000.mp4",
                    actualSliderTracker: "https://bit.ly/2FN9uwI",
                    specialType: "FeaturedSlot",
                    campaignName: "Netflix", // used for GA event identification
                    position: 0,
                    isActive: false,
                };
                var sky = {
                    actualSliderImageSmall: "https://bit.ly/3ktbuMZ",
                    actualSliderImageLarge: "https://bit.ly/3CgA2Pa", // tracker
                    // "https://ad.doubleclick.net/ddm/trackclk/N1111979.2621703WEISCHERONLINEGM/B26427045.313258660;dc_trk_aid=505828139;dc_trk_cid=156691421;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=",
                    actualSliderLink: utils.isMobile()
                        ? "https://bit.ly/3o7MqgX"
                        : "https://bit.ly/3lBMHWb",
                    actualSliderTracker: [
                        "https://m.exactag.com/ai.aspx?extProvId=8&extPu=sky-dcm&extLi=26427045&extPm=313258660&extCr=156691421&subid=ADGapID_026_800138_541275280-541275280&rnd=[Timestamp]&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_312}",
                        // img from End Date
                        "https://ad.doubleclick.net/ddm/trackimp/N1111979.2621703WEISCHERONLINEGM/B26427045.313258660;dc_trk_aid=505828139;dc_trk_cid=156691421;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=?",
                    ],
                    // "Ad Name" column
                    actualSliderScriptSrc: [
                        "https://ad.doubleclick.net/ddm/trackimpj/N1111979.2621703WEISCHERONLINEGM/B26427045.313258660;dc_trk_aid=505828139;dc_trk_cid=156691421;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=?",
                        "https://pixel.adsafeprotected.com/rjss/st/741547/56893508/skeleton.js",
                    ],
                    // not implemented
                    actualSliderIframeSrc: [
                        "https://ad.doubleclick.net/ddm/trackimpi/N1111979.2621703WEISCHERONLINEGM/B26427045.313258660;dc_trk_aid=505828139;dc_trk_cid=156691421;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=?",
                        "https://skydeutschland.demdex.net/event?d_event=imp&d_bu=9532313&d_site=5757667&d_src=158980&d_adsrc=&d_creative=156691421&d_placement=313258660&d_campaign=26427045&d_bust=[Timestamp]&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_565}",
                    ],
                    specialType: "FeaturedSlot",
                    campaignName: "SKY September 21", // used for GA event identification
                    position: 0,
                    isActive: false,
                };
                var rtlNowSlider = utils.isMobile()
                    ? {
                          actualSliderImageSmall:
                              "https://pmd.bilder.netzkino.de/bilder/featuredSlider/tvnow-small.jpg",
                          actualSliderLink: "https://bit.ly/2VpWRKH",
                          actualSliderTracker: [
                              "https://bit.ly/2JpBrMj",
                              "https://bit.ly/2vtYRqx",
                          ],
                          actualSliderScriptSrc: "",
                          specialType: "FeaturedSlot",
                          campaignName: "TVNOW", // used for GA event identification
                          position: 0,
                          isActive: false,
                      }
                    : {
                          actualSliderImageLarge:
                              "https://pmd.bilder.netzkino.de/bilder/featuredSlider/tvnow.jpg",
                          actualSliderLink: "https://bit.ly/2vUopNS",
                          actualSliderTracker: [
                              "https://bit.ly/2JmE3dt",
                              "https://bit.ly/2vtYRqx",
                          ],
                          actualSliderScriptSrc: "",
                          specialType: "FeaturedSlot",
                          campaignName: "TVNOW", // used for GA event identification
                          position: 0,
                          isActive: false,
                      };
                var denonSlider = utils.isMobile()
                    ? {
                          actualSliderImageSmall:
                              "https://pmd.bilder.netzkino.de/bilder/featuredSlider/10_Jahre_NK_YT_img_all_small.jpg",
                          actualSliderLink: "https://bit.ly/2y1zG36",
                          actualSliderTracker: "",
                          actualSliderScriptSrc: "",
                          specialType: "FeaturedSlot",
                          campaignName: "Netzkino10 MWeb", // used for GA event identification
                          position: 0,
                          isActive: false,
                      }
                    : {
                          actualSliderImageLarge:
                              "https://pmd.bilder.netzkino.de/bilder/featuredSlider/10_Jahre_NK_YT_slider.jpg",
                          actualSliderLink: "https://bit.ly/2y1zG36",
                          actualSliderTracker: "",
                          actualSliderScriptSrc: "",
                          specialType: "FeaturedSlot",
                          campaignName: "Netzkino10", // used for GA event identification
                          position: 0,
                          isActive: false,
                      };
                var smartAdSlider = utils.isMobile()
                    ? {
                          specialType: "SmartAdSlot",
                          identifier: "featured_slider_banner_mobile", // smart ad format id
                          pageId: 1047539, // => featured_slider_first_slot_mobile
                          position: 0,
                          isActive: false,
                      }
                    : {
                          specialType: "SmartAdSlot",
                          identifier: "featured_slider_banner", // smart ad format id
                          pageId: 1046542, // => featured_slider_first_slot
                          position: 0,
                          isActive: false,
                      };
                //var topBannerSlider = Math.random() > 0.49 ? denonSlider : rtlNowSlider,
                var topBannerSlider = sky,
                    liawSlider = {
                        actualSliderImageSmall:
                            pmdBaseURL +
                            "/bilder/featuredSlider/liaw-small.jpg",
                        actualSliderImageLarge:
                            pmdBaseURL + "/bilder/featuredSlider/liaw.jpg",
                        actualSliderLink:
                            "https://www.loveisawonder.de/?utm_source=slider&utm_medium=banner&utm_campaign=free&customer=netzkino",
                        position: 0,
                    },
                    themenkinoSlider = {
                        actualSliderImageSmall:
                            pmdBaseURL +
                            "/bilder/featuredSlider/Themenkino-small.jpg",
                        actualSliderImageLarge:
                            pmdBaseURL +
                            "/bilder/featuredSlider/Themenkino.jpg",
                        actualSliderLink: "#!/themenkino-frontpage",
                        position: 1, // works only because we only add themenkinoSlider when also adding a featuredSlot-Slider (see below)
                    },
                    plusAdSlider = {
                        actualSliderImageSmall:
                            pmdBaseURL +
                            "/bilder/featuredSlider/slider_netzkinoplus_netzkino_home-small.jpg",
                        actualSliderImageLarge:
                            pmdBaseURL +
                            "/bilder/featuredSlider/slider_netzkinoplus_netzkino_home.jpg",
                        actualSliderLink: "#!/plus",
                    },
                    erotikPlusAdSlider = {
                        actualSliderImageSmall:
                            pmdBaseURL +
                            "/bilder/featuredSlider/slider_netzkinoplus_netzkino_prickelkino-small.jpg",
                        actualSliderImageLarge:
                            pmdBaseURL +
                            "/bilder/featuredSlider/slider_netzkinoplus_netzkino_prickelkino.jpg",
                        actualSliderLink: "#!/plus",
                    };

                var sliderList = []; // return no additionalSlider if userIsPlus and not on mainpage
                if (userIsPlus) {
                    // add liaw slider on mainpage if plus
                    if (category.slug == "featured-netzkinoplus") {
                        sliderList.push(liawSlider);
                    }
                    return sliderList;
                }

                // if not plus, add erotikSlider on category erotik, other plusSlider on other categories
                if (!userIsPlus && category && category.parent === 111) {
                    sliderList.push(erotikPlusAdSlider);
                } else {
                    sliderList.push(plusAdSlider);
                }

                if (
                    !userIsPlus &&
                    category.slug.indexOf("featured") >= 0 &&
                    smartAdSlider
                ) {
                    sliderList.push(smartAdSlider);
                }

                // add themenkino slider on mainpage if not plus
                if (!userIsPlus && category.slug == "featured") {
                    if (topBannerSlider) {
                        sliderList.push(topBannerSlider);
                    }
                    sliderList.push(themenkinoSlider);
                }
                // add adBanner slider to every other page except mainpage if not plus
                if (
                    !userIsPlus &&
                    category.slug != "featured" &&
                    topBannerSlider
                ) {
                    sliderList.push(topBannerSlider);
                }

                return sliderList;
            },
            isFeaturedSliderCategory: (cat) =>
                cat.slug == "featured" || cat.slug == "featured-netzkinoplus",
            isFrontpageCategory: function (cat) {
                var slug = parent.slug;
                if (cat instanceof can.Map) {
                    slug = cat.attr("slug");
                }
                return slug === "frontpage";
            },
            categorySlider: {
                show: true,
                showMoreButton: false,
            },
        },
        youtube: {
            covers: {
                max: 6,
            },
            exclusiveForCategories: [
                8,
                9441,
                6621, //Highlights, Filme mit Auszeichnung, Netzkino Highlights
            ],
            timeDefaults: {
                start: 30,
                end: 45,
            },
        },
        fbconfig: {
            appId: "171141563020350", // App ID from the App Dashboard
            channelUrl: "//www.netzkino.de/channel.html", // Channel File for x-domain communication
            status: true, // check the login status upon init?
            cookie: true, // set sessions cookies to allow your server to access the session?
            xfbml: true, // parse XFBML tags on this page?
        },

        google_analytics: {
            id:
                process.env.NODE_ENV === "production"
                    ? "UA-30186775-1"
                    : "UA-7961157-7",
            avo_id:
                process.env.NODE_ENV === "production"
                    ? "UA-30186775-1"
                    : "UA-7961157-7",
        },

        ads: {
            isAllowed: (identifier, provider) =>
                window.platform !== "netzkino" ||
                provider === "smartAd" ||
                [
                    // 'na-content', // moviepage rectangle netletix ad
                    "googlePublisherTag-mainpage-skyscraper-right",
                    "googlePublisherTag-mainpage-skyscraper-left",
                    //'googlePublisherTag-moviepage-skyscraper-right',
                    //'googlePublisherTag-moviepage-skyscraper-left',
                    // 'googlePublisherTag-moviepage-medium-rectangle',
                    "googlePublisherTag-mainpage-billboard-4",
                    "googlePublisherTag-mainpage-billboard-3",
                    "googlePublisherTag-mainpage-billboard-2",
                    "googlePublisherTag-mainpage-billboard-1",
                    "teads",
                    "coverAdRow", // netflixCoverSlider wrapping single smartAd-ads
                    //'parallax', //RTL Campaign removed
                    "googlePublisherTag",
                    "googlePublisherTag-mainpage-mobile-billboard",
                    // 'layer', // netletix footer ad
                    // 'inread' // netletix ad within content rows
                ].indexOf(identifier) > -1,
            frontpagebanner: {
                show: false,
                everyNthSlider: 3, // used for extra netzkino ad behaviour
            },
            parallax: {
                clickURL: "https://bit.ly/2FCsydI",
                imageURL: "https://bit.ly/2V2hJrP",
                trackingPixels: [
                    "https://ad.doubleclick.net/ddm/trackimp/N936482.3336433NETZKINO.DE/B22453528.242368124;dc_trk_aid=439217729;dc_trk_cid=113586666;ord={timestamp};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=?",
                    "https://secure-gl.imrworldwide.com/cgi-bin/m?ca=nlsn154054&cr=crtve&ce=netzkino&pc=netzkino_plc0001&ci=nlsnci2019&am=3&at=view&rt=banner&st=image&r={timestamp}",
                ],
            },
            selfAdBanner: {
                //if title = ga tracking
                small: [
                    {
                        title: "Netzkino Youtube",
                        imageURL: pmdBaseURL + "/bilder/banner/358x90_yt_1.jpg",
                        clickURL: "http://bit.ly/NetzkinoAbo",
                    },
                    {
                        title: "Netzkino Youtube",
                        imageURL: pmdBaseURL + "/bilder/banner/358x90_yt_2.jpg",
                        clickURL: "http://bit.ly/NetzkinoAbo",
                    },
                    {
                        title: "Netzkino Youtube",
                        imageURL: pmdBaseURL + "/bilder/banner/358x90_yt_3.jpg",
                        clickURL: "http://bit.ly/NetzkinoAbo",
                    },
                    {
                        title: "Netzkino Facebook",
                        imageURL: pmdBaseURL + "/bilder/banner/358x90_fb_1.jpg",
                        clickURL: "http://bit.ly/NetzkinoFacebook",
                    },
                    {
                        title: "Netzkino Facebook",
                        imageURL: pmdBaseURL + "/bilder/banner/358x90_fb_2.jpg",
                        clickURL: "http://bit.ly/NetzkinoFacebook",
                    },
                    {
                        title: "Netzkino Facebook",
                        imageURL: pmdBaseURL + "/bilder/banner/358x90_fb_3.jpg",
                        clickURL: "http://bit.ly/NetzkinoFacebook",
                    },
                    {
                        title: "Netzkino Newsletter",
                        imageURL: pmdBaseURL + "/bilder/banner/358x90_nl_1.jpg",
                        clickURL: "https://inside.netzkino.de/newsletter",
                    },
                    {
                        title: "Netzkino Newsletter",
                        imageURL: pmdBaseURL + "/bilder/banner/358x90_nl_2.jpg",
                        clickURL: "https://inside.netzkino.de/newsletter",
                    },
                    {
                        title: "Netzkino Newsletter",
                        imageURL: pmdBaseURL + "/bilder/banner/358x90_nl_3.jpg",
                        clickURL: "https://inside.netzkino.de/newsletter",
                    },
                    // {
                    //     title: 'Filmundo - Die Filmauktion',
                    //     imageURL: pmdBaseURL + '/bilder/banner/filmundo_358x90_film01.jpg',
                    //     clickURL: 'http://bit.ly/2IXzm5v'
                    // },
                    // {
                    //     title: 'Filmundo - Die Filmauktion',
                    //     imageURL: pmdBaseURL + '/bilder/banner/filmundo_358x90_film01.jpg',
                    //     clickURL: 'http://bit.ly/2IXzm5v'
                    // },
                    // {
                    //     title: 'Filmundo - Die Filmauktion',
                    //     imageURL: pmdBaseURL + '/bilder/banner/filmundo_358x90_film01.jpg',
                    //     clickURL: 'http://bit.ly/2IXzm5v'
                    // },
                    // {
                    //     title: 'Filmundo - Die Filmauktion',
                    //     imageURL: pmdBaseURL + '/bilder/banner/filmundo_358x90_film01.jpg',
                    //     clickURL: 'http://bit.ly/2IXzm5v'
                    // }
                ],
                rectangle: [
                    {
                        imageURL: pmdBaseURL + "/bilder/banner/300x250_fb.jpg",
                    },
                    {
                        imageURL: pmdBaseURL + "/bilder/banner/300x250_nl.jpg",
                    },
                    {
                        imageURL: pmdBaseURL + "/bilder/banner/300x250_yt.jpg",
                    },
                ],
                banner: [
                    {
                        imageURL: pmdBaseURL + "/bilder/banner/728x90_app.jpg",
                    },
                ],
            },
            smartAd: {
                siteId: 242610,
                networkId: 2957,
                formatIdsByDisplayPosition: {
                    "slider-tile-big-genre": 69880,
                    "artikelbild-cover-1": 70023,
                    "artikelbild-cover-2": 70018,
                    "artikelbild-cover-3": 70019,
                    "artikelbild-cover-4": 70020,
                    "artikelbild-cover-5": 70021,
                    "artikelbild-cover-6": 70022,
                    "artikelbild-cover-7": 70910,
                    "artikelbild-cover-8": 70911,
                    featured_slider_banner: 75424,
                    featured_slider_banner_mobile: 75544,
                },
            },
        },

        // log: distBuild?function() {}:console.log.bind(console)
        //use this for ie:
        log(...args) {
            if (!this.debug) return;
            window?.console?.log(...args);
        },
        warn(...args) {
            if (!this.debug) return;
            window?.console.warn(...args);
        },
        error(...args) {
            window?.console.error(...args);
        },
        vouchers: {
            validFor: 48, // hours
        },
        // ,
        // warn: distBuild?function() {}:console.warn.bind(console)
    } /* @if PLATFORM!='netzkino' */,
    configOverride
); /* @endif */
export default config;
