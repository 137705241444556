<div class="hospital-landingpage">
  <img src="{{imagePath config.plus.headerLogoImagePath}}" alt="Kixi Krankenhaus Image" class="hospital-landingpage-image" />
  <h1 style="font-size: 2.5em; margin-bottom: 20px;">Mit Kixi gesund werden!</h1>
  <div style="max-width: 500px;">
    <p style="font-size: 1.2em;">Genieße Kixi kostenlos während deines Aufenthalts solange du dich im WLAN des Krankenhauses befindest. Dafür einfach den Knopf unten drücken, dann wird dir der Zugriff freigeschaltet.</p>
    <p style="font-size: 1.2em;">Wir wünschen gute Besserung!</p>
  <div>
  {{#if state.isHospital}}
    <button class="hospital-landingpage-button" can-click="initNetopsieLogin">Los geht's</button>
  {{/else}}
    <div class="hospital-landingpage-info-text-box">
        Der Zugriff ist nur aus dem Netzwerk des teilnehmenden Krankenhauses möglich. Bitte wähle dich in das WLAN ein, dann kannst du Kixi nutzen, wenn du diese Seite neu lädst.
    </div>
  {{/if}}
  <div id="hospitalLandingPageError" class="hospital-landingpage-error" >ERROR</div>
</div>

