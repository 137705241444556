import can from "can";
import Cookie from "../../../lib/cookie-handler";
import loader from "../../../lib/loader";
import API from "../../api";
import config from "../../config";
import ga from "../../modules/googleanalytics";
import viewHeader from "./view.stache";
can.Component.extend({
    tag: "my-user-register",
    template: viewHeader,
    scope: {
        config: config,
        registrationSelection: "@",
        disabledSocial: "true",
        disableButtons: can.compute(function () {
            var disabled = this.attr("disabledSocial");
            return disabled === "true" || disabled === true;
        }),
        register: function (context, $el, ev) {
            // ev.preventDefault(); // this is a form we want to submit!

            var self = this,
                email = $el.find("#registerEmail").val(),
                $button = $el.find("#registerSubmit"),
                registeredPlus = false;

            // if we are registering for plus, we want to set cookie to directly navigate to payment after first login
            if (context.attr("registerPlus") === true) {
                registeredPlus = true;
                var now = new Date();
                var time = now.getTime();
                time += 60 * 60 * 1000;
                now.setTime(time);
                Cookie.createCookie("registeredPlus", "1", now.toUTCString()); // valid for 1 hour
            } else {
                // we might have checked for plus option first and now only want to sign up
                Cookie.clearCookie("registeredPlus");
            }

            config.log("trying to submit register form", email);
            $button.attr("disabled", "disabled");
            loader(new can.Deferred());
        },
        switchToLogin: function () {
            can.$("my-user-register").trigger("switchLoginComponent", "login");
        },
        setupPlus: function () {
            ga.sendEvent("Account Action", "RegisterOption", "plus");
            this.attr("registerPlus", true);
            this.attr("showRegistrationOptions", false);
            this.attr("colWidthOuter", "col-xs-12 col-sm-6");
        },
        setupMy: function () {
            ga.sendEvent("Account Action", "RegisterOption", "user");
            this.attr("registerPlus", false);
            this.attr("showRegistrationOptions", false);
            this.attr("colWidthOuter", "col-xs-12");
        },
    },
    events: {
        init: function () {
            var self = this,
                registrationSelection = this.scope.attr(
                    "registrationSelection"
                );

            switch (registrationSelection) {
                case "plus":
                    this.scope.setupPlus();
                    break;
                case "my":
                    this.scope.setupMy();
                    break;
                default:
                    if (config.plus.enabled) {
                        this.scope.attr("showRegistrationOptions", true);
                        this.scope.attr(
                            "registrationOptionHeader",
                            config.plus.registrationOptionHeader
                        );
                        this.scope.attr(
                            "registrationOptions",
                            config.plus.registrationOptions
                        );
                    } else {
                        this.scope.attr("showRegistrationOptions", false);
                    }
                    break;
            }

            this.scope.attr("colWidth", "col-xs-12");
        },
        "#login click": function ($el, ev) {
            if (ev && ev.preventDefault) {
                ev.preventDefault();
            }
            this.scope.switchToLogin();
        },
        "#firstOption click": function ($el, ev) {
            if (ev && ev.preventDefault) {
                ev.preventDefault();
            }
            this.scope.setupMy();
        },
        "#secondOption click": function ($el, ev) {
            if (ev && ev.preventDefault) {
                ev.preventDefault();
            }
            this.scope.setupPlus();
        },
        "form submit": function (el, ev) {
            ev.preventDefault();

            API.addToStorage("loggedIn", true);
            ga.sendEvent("Account Action", "Register");
            var email = $("#registerEmail").val();
            var password = $("#registerPassword").val();
            var redirectSettings = {
                successRedirect:
                    this.scope.attr("registerPlus") === true
                        ? "#!/my/plus"
                        : undefined,
                errorRedirect: "#!/my/registrationerror",
            };

            API.register(email, password, redirectSettings);
        },
        "#confirm-agb change": function ($el, ev) {
            var confirmed = ev.target.checked;
            this.scope.attr("disabledSocial", !confirmed);
        },
    },
});
