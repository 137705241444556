<div class="{{#if useImageBackdrop}}withBackdrop{{/if}}">
    {{#if useImageBackdrop}}
        <div class="backdrop backdrop-left"></div>
        <div class="backdrop backdrop-right"></div>
    {{/if}}

    <div class="{{#if useImageBackdrop}}container{{/if}}">
        <div class="navPlaceholder"></div>

        {{#isEqual isLoggedIn 'pending'}}
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        {{/isEqual}}

        <generic-ad identifier="na-skyscraper" />

        {{#isEqual isLoggedIn 'true'}}
            {{#if showSocialBar}}
                <netzkino-title-social-bar cat="{{config.my.title}}" show-text="false"></netzkino-title-social-bar>
            {{/if}}

            <div class="main-background">
                <div class="container" style="margin-bottom: 30px;">
                    <div class="row">
                        <div class="col-xs-12">
                            <plus-payment directly-offer-payment="{{directlyOfferPayment}}" change-payment-data="{{changePaymentData}}" />
                        </div>
                    </div>
                </div>

                {{#if watchlistCategory}}
                    <netzkino-category-slider
                        cat="{watchlistCategory}"
                        id="watchlist"
                        type="watchlist"
                        function="remove"
                        title="Weiterschauen"
                        shuffle="false"
                        {{#if hidePayment}}class="hidden"{{/if}}
                    />
                {{else}}
                    <div class="spinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                {{/if}}

                {{#if wishlistCategory}}
                    <netzkino-category-slider
                        cat="{wishlistCategory}"
                        id="wishlist"
                        type="wishlist"
                        function="remove"
                        title="{{config.my.wishlist.name}}"
                        shuffle="false"
                        {{#if hidePayment}}class="hidden"{{/if}}
                    />
                {{else}}
                    <div class="spinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                {{/if}}

                {{#if hasCoupons}}
                    {{#if couponCategory}}
                        <netzkino-category-slider
                            cat="{couponCategory}"
                            id="couponList"
                            type="couponList"
                            function="remove"
                            title="Gutscheinfilme"
                            shuffle="false"
                            {{#if hidePayment}}class="hidden"{{/if}}
                        />
                    {{else}}
                        <div class="spinner">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                    {{/if}}
                {{/if}}

                {{#recommendationCategory}}
                    <netzkino-category-slider cat="{.}" type="recommendations" title="Empfehlungen" {{#hidePayment}}class="hidden"{{/hidePayment}} /></netzkino-category-slider>
                {{/recommendationCategory}}

                {{#unless state.isHospital}}
                    <div class="container payment-hide" style="margin-top: 75px;">
                        <div class="row">
                            {{#if config.my.smartLogin.showMyButton}}
                                <div class="col-xs-12 col-sm-3">
                                    <a href="#!/konto" class="btn btn-big btn-dark btn-my">Benutzerkonto</a>
                                </div>
                                <div class="col-xs-12 col-sm-3">
                                    <a href="#!/smartlogin" class="btn btn-big btn-dark btn-my">MagentaTV</a>
                                </div>
                                                    <div class="col-xs-12 col-sm-3">
                                    <a href="#!/smartlogin" class="btn btn-big btn-dark btn-my">SmartTV</a>
                                </div>
                                <div class="col-xs-12 col-sm-3">
                                    <a href="" id="logout" class="btn btn-big btn-dark btn-my">Abmelden</a>
                                </div>
                            {{else}}
                                <div class="col-xs-12 col-sm-6">
                                    <a href="#!/konto" class="btn btn-big btn-dark btn-my">Benutzerkonto</a>
                                </div>
                                <div class="col-xs-12 col-sm-6">
                                    <a href="" id="logout" class="btn btn-big btn-dark btn-my">Abmelden</a>
                                </div>
                            {{/if}}

                        </div>
                    </div>
                {{/unless}}
            </div>
        {{/if}}

        {{#isEqual isLoggedIn 'false'}}
            <div class="center-wrapper">
                {{#if redirectToSmartlogin}}
                    <div class="login-hint">
                        Du wirst automatisch auf die SmartTV Login Seite weitergeleitet nachdem du dich angemeldet hast!
                    </div>
                {{/if}}
                {{#register}}
                    <my-user-register registration-selection="{{registrationSelection}}"/>
                {{/register}}
                {{^register}}
                    <my-user-login />
                {{/register}}
            </div>
        {{/if}}
    </div>
</div>
