import can from "can";
import BaseControl from "../../../lib/can.basecontrol.js";
import loader from "../../../lib/loader";
import lazyrefresh from "../../components/img-lazy";
import config from "../../config";
import Category from "../../models/category";
import View from "./view.stache";

export default BaseControl.extend(
    "SearchPage",
    {
        defaults: {
            template: View,
        },
    },
    {
        init: function () {
            var state = this.options.state;
            var searchTerm = can.route.attr("id");
            var $el = this.element;
            var placeholderCategory = new Category({
                    slug: "suche",
                    title: "Suche",
                    id: "search",
                    search: searchTerm,
                    posts: [],
                }),
                catpostsPlaceholder = new can.List([]);

            loader(
                Category.findOne(placeholderCategory.serialize()).then(
                    function (cat) {
                        var moviesBySlug = state.data.moviesBySlug;

                        can.batch.start();
                        cat.attr("search", searchTerm); // stay compatible with the placeholder as .search is used in the template and search endpoint
                        // cat.attr(data.serialize());
                        cat.posts.forEach(function (movie) {
                            moviesBySlug[movie.slug] = movie;
                            moviesBySlug[movie.id] = movie;
                        });
                        var _catposts = cat.attr("posts");
                        _catposts.sort(function (a, b) {
                            return b.match.length - a.match.length;
                        });

                        var sliderCategories = [];
                        // catposts.push.apply(catposts, _catposts);
                        if (config.search.displayType === "typeSliders") {
                            var types = {};
                            _catposts.forEach(function (post) {
                                var type = post.type;
                                types[type] = types[type] || new can.List();
                                types[type].push(post);
                            });

                            sliderCategories = Object.keys(types).map(function (
                                type
                            ) {
                                var title;
                                var sliderType = "sliderDefaultVertical";
                                switch (type) {
                                    case "seriesContent":
                                        title = "Serien";
                                        break;
                                    case "audioContent":
                                        title = "Audioinhalte";
                                        sliderType = "audioContentSlider";
                                        break;
                                    default:
                                        title = "Filme";
                                        break;
                                }
                                return new Category({
                                    slug: "search",
                                    title: title,
                                    sliderType: sliderType,
                                    posts: types[type],
                                });
                            });
                        }

                        this.options.viewOptions.attr({
                            cat: cat,
                            catposts: _catposts,
                            loading: false,
                            sliderCategories: sliderCategories,
                        });
                        can.batch.stop();

                        can.defer(function () {
                            lazyrefresh($el);
                        });
                    }.bind(this)
                )
            );

            this.options.viewOptions.attr({
                config: config,
                cat: placeholderCategory,
                catposts: catpostsPlaceholder,
                loading: true,
                sliderCategories: new can.List([]),
            });

            return this._super.apply(this, arguments);
        },
    }
);
