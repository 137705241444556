// notification.js
import can from 'can'
import viewHeader from './notification.stache'
import state from '../../state'

can.Component.extend({
  tag: 'notification-component',
  template: viewHeader,
  scope: {
    visible: true,
    hideNotification: function () {
      console.log('Rivadebugger')
      this.attr('visible', false)
    },
    state: state,
  },
})
