{{#if isKixi}}
    {{#if state.currentUser.shouldWarnAboutWirecard}}
        <div class="payment-alert-container">
            <div class="payment-alert">
                <div class="payment-alert-img-container">
                    <img class="payment-alert-img" src="https://pmd.bilder.netzkino.de/bilder/paymentalert/alert.png">
                </div>
                <div class="payment-alert-infotext-container">
                    <div style="width: 100%;">
                        <div style="font-weight: bold; font-size: 25px;">BITTE AKTUALISIEREN SIE IHRE ZAHLUNGSMETHODE</div>
                        <div>WIR HABEN UNSEREN ZAHLUNGSANBIETER GWECHSELT. DAMIT SIE KIXI WIE GEWOHNT NUTZEN KÖNNEN, AKTUALISIEREN SIE BITTE IHRE ZAHLUNGSART</div>
                    </div>
                </div>
                <div class="payment-alert-button-container">
                        <div onclick="window.location='#!/my/changePaymentData';" class="payment-alert-button">
                            <div style="font-weight: bold; font-size: 15px;">JETZT AKTUALISIEREN</div>
                            <div style="font-weight: bold;">Dauert nur wenige Sekunden</div>
                        </div>
                </div>
            </div>
        </div>
        {{#if featuredSlider}}
            <netzkino-mainpage-slider cat="{featuredSlider}" use-background-image="{useBackgroundSlider}" />
        {{else}}
            <div class="navPlaceholder"></div>
        {{/if}}
    {{else}}
        {{#if state.currentUser.isPlus}}
            {{#if featuredSlider}}
                <netzkino-mainpage-slider cat="{featuredSlider}" use-background-image="{useBackgroundSlider}" />
            {{else}}
                <div class="navPlaceholder"></div>
            {{/if}}
        {{else}}
            {{!-- also on categorypage --}}
            <div class="kixiPromoVideoBanner" style="background-image: url('{{landingPreview.placeholderURL}}')">
                <video loop muted autoplay poster="{{landingPreview.placeholderURL}}" class="hidden-xs">
                    <source src="{{landingPreview.videoURL}}" type="video/mp4">
                </video>
                <div class="slider-register">
                    <a href="#!/my/payment" alt="Jetzt abonnieren" class="register-now highlight-shadow"></a>
                </div>
            </div>
        {{/if}}
    {{/if}}
{{else}}
    {{#if featuredSlider}}
        <!-- Payment Change Alert, since Wirecard failed big time -->
        {{#if state.currentUser.shouldWarnAboutWirecard}}
        <div class="payment-alert-container">
            <div class="payment-alert">
                <div class="payment-alert-img-container">
                    <img class="payment-alert-img" src="https://pmd.bilder.netzkino.de/bilder/paymentalert/alert.png">
                </div>
                <div class="payment-alert-infotext-container">
                    <div style="width: 100%;">
                        <div style="font-weight: bold; font-size: 25px;">BITTE AKTUALISIEREN SIE IHRE ZAHLUNGSMETHODE</div>
                        <div>WIR HABEN UNSEREN ZAHLUNGSANBIETER GWECHSELT. DAMIT SIE NETZKINO PLUS WIE GEWOHNT NUTZEN KÖNNEN, AKTUALISIEREN SIE BITTE IHRE ZAHLUNGSART</div>
                    </div>
                </div>
                <div class="payment-alert-button-container">
                        <div onclick="window.location='#!/my/changePaymentData';" class="payment-alert-button">
                            <div style="font-weight: bold; font-size: 15px;">JETZT AKTUALISIEREN</div>
                            <div style="font-weight: bold;">Dauert nur wenige Sekunden</div>
                        </div>
                </div>
            </div>
        </div>
        {{/if}}
        <netzkino-mainpage-slider cat="{featuredSlider}" use-background-image="{useBackgroundSlider}" />
    {{else}}
        <div class="navPlaceholder"></div>
    {{/if}}
{{/if}}
{{#unless state.currentUser.isPlus}}
    <!--<generic-ad identifier="na-skyscraper" />
    <generic-ad identifier="googlePublisherTag-mainpage-skyscraper-left"></generic-ad>
    <generic-ad identifier="googlePublisherTag-mainpage-billboard"></generic-ad>-->
{{/unless}}


{{#if showTitleBar}}
  <netzkino-title-social-bar cat="{{#if state.currentUser.isPlus}}{{config.plus.socialBar.claim}}{{else}}{{config.socialBar.claim}}{{/if}}" mainpage="true" show-text="false"></netzkino-title-social-bar>
{{/if}}

<div class="main-background">
    <div class="content-main">


        {{#if config.frontpage.categorySlider.show}}
            {{#if displayBanner}}
                {{#unless state.currentUser.isPlus}}
                    <generic-ad identifier="coverAdRow" />
                    {{! <div style="height: 45px;" class="cover-ad-row-show-on-delivery"></div> }}
                {{/unless}}
            {{/if displayBanner}}
        {{/if config.frontpage.categorySlider.show}}
        {{#each categories}}
            {{#unlessPlusCategory . state.currentUser}}
                <netzkino-category-slider cat="{.}" id="{{id}}" {{#if useLargeSlider}}type="large"{{/if}} slider-type-override="{sliderTypeOverride}"/>

                <!-- NP-657: Set up Ad-Billboards which will contain GooglePublisherTags -->
                {{#unless state.currentUser.isPlus}}
                    {{#isEqual @index 1}}
                            <generic-ad identifier="googlePublisherTag-mainpage-billboard-1"></generic-ad>
                    {{/isEqual}}
                    {{#isEqual @index 5}}
                            <generic-ad identifier="googlePublisherTag-mainpage-billboard-2"></generic-ad>
                    {{/isEqual}}
                    {{#isEqual @index 9}}
                            <generic-ad identifier="googlePublisherTag-mainpage-billboard-3"></generic-ad>-

                    {{/isEqual}}
                    {{#isEqual @index 13}}
                            <generic-ad identifier="googlePublisherTag-mainpage-billboard-4"></generic-ad>
                    {{/isEqual}}
                {{/unless}}

                {{#if config.ads.frontpagebanner.show}}
                    {{#if shouldDisplayAd}}
                        {{#unless state.currentUser.isPlus}}
                            <div class="container frontpage-adcontainer">
                                <a href="{{config.ads.frontpagebanner.target}}"><img class="img-responsive" src="{{config.ads.frontpagebanner.src}}"></a>
                            </div>
                        {{/unless}}
                    {{/if}}
                {{/if config.ads.frontpagebanner.show}}
                {{#if displayBanner}} {{!set for netzkino only}}
                    {{#if shouldDisplayAd}}
                        {{#unless state.currentUser.isPlus}}
                            <generic-ad identifier="na-superbanner" class="container category-slider-banner-container" />
                        {{/unless}}
                    {{/if}}
                {{/if}}
                <!-- {{#isEqual @index 10}}
                    <generic-ad identifier="vidoomy-2" style="display:block; margin-top: 10px"></generic-ad>
                {{/isEqual}} -->
            {{/unlessPlusCategory}}
        {{/each}}
        <generic-ad identifier="inread" container-class="nxInreadAd"></generic-ad>
    </div>
</div>
<generic-ad identifier="interstitialAd"></generic-ad>


