import can from "can";
import config from "../js/config";
import utils from "./utils";

var $head = $(document.head);
var baseTitle = $head.find("title").text();
var baseDescription = $head.find("meta[name=description]").attr("content");
var baseUrl = $head.find($("meta[name=og\\:url]")).attr("content");

function getOrCreateMetaHeader(selector, fallback) {
    var $found = $head.find(selector);
    if (!$found.length) {
        $found = $head.append(fallback);
    }
    return $found;
}

export default can.Control.extend(
    "Base",
    {
        defaults: {
            controlPath: "src/js/controls/",
            viewOptions: new can.Map(),
            template: null, // REQUIRED
        },
    },
    {
        init: function () {
            if (!this.options.template) {
                throw new Error(
                    'Please provide the "view" as an option for the control'
                );
            }
            // this.options.viewOptions.attr('state', this.options.state);
            config.log(
                "initializing",
                this.constructor.shortName,
                this.options.viewOptions
            );

            // eventhough can/view/stache sets the default extension, can/view/mustache will also get loaded
            // due to require.js this can happen concurrently, so race condition can lead to can.view() to load .mustache files => 404 in dev
            can.view.ext = ".stache"; // override default file extension

            // care for other platforms. In build we will register additional template files (named with the platform key). So if they are present
            // we do want to use them instead of the default ones.

            // FIXME does this still work after using parcel?
            // if(require.defined('stache!controls/' + this.constructor.shortName.toLowerCase() + '/' + config.general.stacheKey)) {
            //     stacheFileName = config.general.stacheKey;
            // }
            this.element.html(
                can.view(this.options.template, this.options.viewOptions)
            );
        },
        destroy: function () {
            config.log("destroying", this.constructor.shortName);
            // can.data(this.element, "controls", []);
            var ret = this._super.apply(this, arguments);
            if (this.element) {
                this.element.html("");
                this.element = null;
            }
            this.seo.reset();
            return ret;
        },
        seo: {
            setTitle: function (title, suffix) {
                if (
                    suffix &&
                    title.length + suffix.length <= 60 &&
                    title.indexOf(suffix) < 0
                ) {
                    document.title = title + suffix;
                } else {
                    document.title = title;
                }
            },
            setDescription: function (desc) {
                var description = utils.stripHTML(desc);
                var $selector = getOrCreateMetaHeader(
                    "meta[name=description]:first",
                    '<meta name="description" content="' + description + '" />'
                );
                $selector.attr("content", description);
            },
            setUrl: function (canonicalUrl) {
                var url = utils.stripHTML(canonicalUrl);
                var $selector = $head.find($("meta[name=og\\:url]"));
                $selector.attr("content", baseUrl + url);
                var $link = $head.find($("link[rel=canonical]"));
                $link.attr("href", baseUrl + url);
            },
            reset: function () {
                this.setTitle(baseTitle);
                this.setDescription(baseDescription);
                this.setUrl("/" + location.hash);
            },
        },
    }
);
