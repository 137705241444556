import can from "can";
// disallow the use of document.write once the app is loaded, this would lead to a blank page.

can.$(document).ready(function () {
    document.write = function () {
        try {
            // get the stack trace.
            throw new Error(
                "document write called after the page was already initialized"
            );
        } catch (e) {
            // this happened with SmartAd and calling window.sas.render(id) after the div with the corresponding id was removed.
            console.error(
                "document.write was called, skipping its execution as it would break the site. Please check the stacktrace.",
                e,
                arguments
            );
        }
    };
});
export default {};
