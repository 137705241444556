import can from "can";
import API from "../api";
import config from "../config";
import state from "../state";

var modelFactory;
function getModelFactory() {
    // avoid circular dependency
    if (!modelFactory) {
        modelFactory = require("./modelFactory").default;
    }
    return modelFactory;
}

export default can.Model.extend(
    "Category",
    {
        findAll: "GET " + API.getURLFromConfig("category.findAll"),
        findOne: function (params) {
            if (typeof params === "string") {
                return API.getCategoryById(params);
            } else if (params.search) {
                return API.searchCategory(params);
            } else {
                return API.getCategory(params);
            }
        },
        makeFindOne: API.makeFindOneProxy,

        attributes: {
            posts: "Movie.models",
        },

        //return correct data on find-all call
        models: function (data) {
            return getModelFactory().parseCategoryList(data.categories || data);
        },

        //return correct data on fine-one call, but preserve data of find-all call
        model: function (data) {
            var d = data;

            //this will happen when we do find-one, but not on find all (where no posts will be included...)
            if (data.category) {
                config.log("Category.model called! Fixing data ", data);
                d = data.category;
            }
            d.pages = data.pages;

            //this is a search.result, fake the id search for livebinding to work
            if (typeof data.count_total !== "undefined") {
                d.id = "search";
            }

            // internal model store (cache) is cleared as soon as categories are not assigned to any view
            // (as we always had all genre-categories in the header so the single category findOne calls always
            // had the oldModel present in store which led to the extension and not reinitialization of the category)
            // now that we do not show all "maincategories" in the header, we need to pass the oldModel manually to prevent
            // loss of information on findOne calls (which are in this case used to get the posts)
            var oldModel =
                state.data &&
                state.data.categoriesById &&
                state.data.categoriesById[d.id];
            var m = getModelFactory().parseCategory(oldModel ? oldModel : d);
            // var m = can.Model.model.call(this, oldModel ? oldModel : d);

            if (data.posts) {
                // m.attr('posts', data.posts);
                //debugger;
                m.attr("posts", getModelFactory().parsePostList(data.posts));
            }
            if (!m.attr("subcategories")) {
                m.attr("subcategories", []); // some categories could not appear on the index, but still get called directly, avoid exceptions
            }
            return m;
        },
    },
    {
        init: function (args) {
            this.attr({
                page: 0,
                loading: 0,
                ytAllowed:
                    config.youtube &&
                    (!config.youtube.exclusiveForCategories ||
                        (config.youtube.exclusiveForCategories &&
                            config.youtube.exclusiveForCategories.indexOf(
                                args.id
                            ) > -1)),
            });

            this.attr(args);
        },
        subCategoryCache: {},
        // sliderTypes: ["sliderDefaultVertical", "sliderDefaultHorizontalSmall", "sliderDoubleRow",  "sliderDefaultHorizontal"],
        sliderTypes: ["sliderDefaultVertical", "sliderDoubleRow"],
        cnt: 0,
        isRootCategory: can.compute(function () {
            return config.categoryTree.isRoot(this);
        }),
        parentCategory: can.compute(function () {
            return state.data.categoriesById[this.attr("parent")];
        }),

        hasParentWithCondition: function (condition) {
            var parent = this.attr("parentCategory");
            if (!parent || this.attr("isRootCategory")) {
                return false;
            } else if (condition(parent)) {
                return true;
            } else {
                return parent.hasParentWithCondition(condition);
            }
        },

        hasParent: function (parentId) {
            var parent = this.attr("parentCategory");
            return this.hasParentWithCondition(function (parent) {
                return parent.attr("id") === parentId;
            });
        },

        hasCategoryProperty: function (prop) {
            return (this.attr("properties") || []).indexOf(prop) >= 0;
        },

        getSubCategories: can.compute(function () {
            var self = this,
                slugList = [];
            this.subcategories.forEach(function (catId) {
                slugList.push(state.data.categoriesById[catId].slug);
            });
            self.cnt = 0;
            // remember, we have this category (and respectively all subcategories loaded)
            self.attr("hasLoaded", true);

            // hotfix - we do filter all undefined subcategories because they are not initalized
            // if there are no posts in it. Otherwise this leads to undefined property on category-page load.
            return new can.List(
                slugList
                    .map(function (catSlug) {
                        var subCat = self.getSubCategory(catSlug);
                        if (!subCat) {
                            return undefined;
                        }
                        if (catSlug.indexOf("featured") > -1) {
                            // this is the subcategory used to fill the slider and should not be displayed as a standrad category slider.
                            subCat.attr("isMainSliderCategory", true);
                        } else {
                            subCat.attr("isMainSliderCategory", false);
                        }

                        // this is a stupid workaround to solve the problem of subcategories from NetzkinoPlus duplicating their posts on Plus-Genre-Page loading
                        // originally caused by subcategory initialization in getSubCategory being called too often (as NetzkinoPlus category is getting refreshed if the
                        // respective genre-subCatgeory is called)
                        var ids = [];
                        subCat.attr(
                            "posts",
                            subCat.attr("posts").filter(function (movie) {
                                if (ids.indexOf(movie.attr("id")) > -1) {
                                    return false;
                                }
                                ids.push(movie.attr("id"));
                                return true;
                            })
                        );
                        return subCat;
                    })
                    .filter(function (cat) {
                        return cat.attr("posts.length");
                    })
            );
        }),

        allNestedSubcategories: can.compute(function () {
            var subCategories = this.attr("getSubCategories");
            var categories = [];
            subCategories.forEach(function (cat) {
                var children = cat.attr("allNestedSubcategories"); // recursively to support any number of levels
                if (children && children.length > 0) {
                    children.forEach(function (p) {
                        categories.push(p);
                    });
                } else {
                    categories.push(cat); // has no subcategories
                }
            });
            return categories;
        }),

        getSubCategory: function (categorySlug) {
            var self = this;
            if (categorySlug == self.slug + "-all") {
                var fakeSubCat = state.data.categoriesById[-this.attr("id")];
                fakeSubCat.attr("posts", this.attr("posts"));
                fakeSubCat.sliderType = "sliderDefaultVertical";
                this.subCategoryCache[fakeSubCat.slug] = fakeSubCat;
            } else if (!this.subCategoryCache[categorySlug]) {
                var moviesToFillCategoriesWith = this.attr("posts");
                var knownMovies = {};
                if (
                    !this.attr("isRootCategory") &&
                    this.attr("parentCategory")
                ) {
                    // For netzkino we assumed that movies must be in the lowest category and all its parents.
                    // The only assumption now is that every movie is in the root category, e.g. movies.
                    // The following lines verify that every movie is considered.
                    moviesToFillCategoriesWith = this.attr(
                        "parentCategory.posts"
                    ).filter(function (p) {
                        var isValid =
                            self.subcategories.filter(function (subId) {
                                return (
                                    p.categories.indexOf(parseInt(subId)) >= 0
                                );
                            }).length > 0;
                        if (isValid) {
                            knownMovies[p.id] = true;
                        }
                        return isValid;
                    });
                    // if there are any movies loaded for this category that are unknown to the parent, we add them
                    // never happened before, but be sure
                    this.attr("posts").forEach(function (p) {
                        if (!knownMovies[p.id]) {
                            moviesToFillCategoriesWith.push(p);
                        }
                    });
                }
                // this ensures that the direct load of a third-level category contains the movies of the top-most category as well
                if (
                    config.plus.genreCategories.categoryIds.indexOf(this.id) >
                        -1 ||
                    config.plus.genreHighlightCategory.categoryId === this.id ||
                    config.plus.genreHighlightCategory.categoryIds.indexOf(
                        this.id
                    ) > -1
                ) {
                    // TODO: also in highlighted?
                    moviesToFillCategoriesWith.push.apply(
                        moviesToFillCategoriesWith,
                        state.data.categoriesById[
                            config.plus.plusCategoryId
                        ].attr("posts")
                    ); // FIXME use isPlusCategory
                }
                moviesToFillCategoriesWith.forEach(function (movie) {
                    movie.attr("categories").forEach(function (catId) {
                        // only fill category cache for this very category
                        if (
                            self.subcategories.indexOf(catId + "") > -1 ||
                            catId == self.attr("id")
                        ) {
                            var catSlug = state.data.categoriesById[catId].slug;
                            var tmpCat = self.subCategoryCache[catSlug];
                            if (!tmpCat) {
                                // we load this subCategory for the first time
                                tmpCat = state.data.categoriesById[catId];
                                tmpCat.sliderType = config.frontpage.sliderTypeForCategory(
                                    tmpCat
                                );
                                self.cnt++;
                                self.subCategoryCache[catSlug] = tmpCat;
                                if (self.attr("id") == tmpCat.attr("parent")) {
                                    // we just fully loaded this subcategory, as all subcategory movies are included in parent category,
                                    // and the parent category load just triggered this load.
                                    // Distinction needed because there are videos which are in multiple 'parent'-categories and may have been loaded before.
                                    tmpCat.attr("hasLoaded", true);
                                }
                            }
                            if (tmpCat.attr("posts").indexOf(movie) < 0) {
                                tmpCat.attr("posts").push(movie);
                            }
                        }
                    });
                });
            }
            return (
                this.subCategoryCache[categorySlug] ||
                state.data.categoriesBySlug[categorySlug]
            ); // support empty categories
        },
        isPlusCategory: function () {
            // checks whether a category is a plus category or is child of one
            // var parentCategory = this.parent !== undefined && state.data.categoriesById[this.parent]
            // var isParentPlus = parentCategory && this.parent !== 0 && (config.plus.isPlusCategory(parentCategory));

            var category = this;
            var isPlus;
            while (!isPlus && category) {
                isPlus = config.plus.isPlusCategory(category);
                if (config.plus.overridePlusCategoryInheritance(category)) {
                    // allow subcategories to be non-plus
                    return false;
                }
                category = state.data.categoriesById[category.parent];
            }

            return isPlus;
        },
        // addAd: function () {
        //     var cat = this.attr('id'), c = '',
        //         ads = [
        //             '<a class="bannerImgs" href="http://www.kinderkino.de/kostenlos" target="_blank"><img src="http://cdn.netzkino.de/www/assets/imgs/banner/kinderkino-kostenlose-kinderfilme-und-serien.jpg" title="Kinderfilme und Serien gratis schauen auf kinderkino.de" /></a>',
        //             '<a class="bannerImgs" href="https://www.youtube.com/watch?v=24xTZn6w-C0" target="_blank"><img src="http://cdn.netzkino.de/www/assets/imgs/banner/boohtv.jpg" title="Kostenlose Horrorfilme" /></a>',
        //             '<a class="bannerImgs" href="https://www.youtube.com/watch?v=LGXUgneWy7o" target="_blank"><img src="http://cdn.netzkino.de/www/assets/imgs/banner/loveisawonder.jpg" title="Kinderfilme und Serien gratis schauen auf kinderkino.de" /></a>'
        //         ];

        //     if(cat === 6) {
        //         //scifi
        //         ads.push('<a class="bannerImgs" href="http://www.kinderkino.de/kostenlos" target="_blank"><img src="http://cdn.netzkino.de/www/assets/imgs/banner/kinderkino-kostenlose-kinderfilme-und-serien.jpg" title="Kinderfilme und Serien gratis schauen auf kinderkino.de" /></a>');
        //     }

        //     if(cat === 111 /* erotikkino */ || cat === 71 /* kino ab 18 */ || cat === 5 /* kino ab 18 */) {
        //         ads.length = 0;
        //         ads.push(
        //             '<a class="bannerImgs" href="https://www.youtube.com/watch?v=24xTZn6w-C0" target="_blank"><img src="http://cdn.netzkino.de/www/assets/imgs/banner/boohtv.jpg" title="Kostenlose Horrorfilme" /></a>',
        //             '<a class="bannerImgs" href="https://www.youtube.com/watch?v=LGXUgneWy7o" target="_blank"><img src="http://cdn.netzkino.de/www/assets/imgs/banner/loveisawonder.jpg" title="Kinderfilme und Serien gratis schauen auf kinderkino.de" /></a>'
        //         );
        //     }

        //     var randIdx = Math.round(Math.random()*(ads.length-1));
        //     config.log('Using idx ' + randIdx, ads);
        //     c = ads[randIdx];

        //     if(c) this.attr('posts').push({advertising: c});
        // },

        isLarge: function () {
            return this.attr("slug") == "highlights";
        },
    }
);
