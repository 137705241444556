import can from "can";
import Cookie from "../lib/cookie-handler";
import utils from "../lib/utils";
import config from "./config";

var storage; // default decided after the first read operation
var SID_KEY = "sid";

(function () {
    storage = window.localStorage;
    config.log("setting default storage to localStorage");
})();

var addToStorage = function (name, item) {
        if (!storage || !name || !item) return;
        config.log("first storage write");

        try {
            storage.setItem(name, item);
        } catch (e) {
            console.error("could not write to localstorage for", name);
        }
    },
    getFromStorage = function (name) {
        if (!storage || !name) return;
        try {
            return storage.getItem(name);
        } catch (e) {
            console.error("could not access localstorage for", name);
        }
    },
    removeFromStorage = function (name) {
        if (!storage || !name) return;
        try {
            window.localStorage.removeItem(name);
        } catch (e) {
            console.error("could not clear localstorage for", name);
        }
        try {
            window.sessionStorage.removeItem(name);
        } catch (e) {
            console.error("could not clear session for", name);
        }
    };

// This handles passing the session id via query params. This is necessary for social logins, as safari blocks all third-party cookies.
var qs = utils.parseQueryString();
if (qs.sid) {
    var sid = qs.sid;
    addToStorage(SID_KEY, qs.sid);
    utils.removeParamFromURL(SID_KEY, encodeURIComponent(sid));
}

function requestHelper(
    method,
    url,
    data,
    options,
    additionalQueryParameters,
    skipErrorHandler
) {
    var configParams = config.paths.api.paramOverride(
        config.paths.api.baseParams
    );
    var headers = {};
    headers["X-" + config.my.api.headerPlatformName + "-Version"] =
        configParams.v;
    headers["X-" + config.my.api.headerPlatformName + "-App-Device"] =
        configParams.d;
    headers["X-" + config.my.api.headerPlatformName + "-App-Language"] =
        configParams.l;

    var sid = getFromStorage(SID_KEY);
    if (sid) {
        headers.Authorization = "Bearer " + sid;
    }

    var request = {
        type: method,
        crossDomain: true,
        headers: headers,
        xhrFields: {
            withCredentials: true,
        },
    };
    if (data) {
        request.data = data;
        request.dataType = "json";
    }

    // extend would swap out headers entirely if not done this way ...
    if (options && options.headers) {
        Object.keys(options.headers).forEach(function (key) {
            request.headers[key] = options.headers[key];
        });
        delete options.headers;
    }

    can.extend(request, options);

    request.url =
        url +
        (!additionalQueryParameters ? "" : "?" + additionalQueryParameters);
    var ajaxRequest = can.$.ajax(request);

    // let's catch token expiration / 401 Unauthorized globally if not in first request ( checking whether we are still logged in )
    if (skipErrorHandler) {
        config.log("skipping error handler");
    } else {
        ajaxRequest.error(function (httpObj) {
            if (httpObj.status == 401) {
                config.log("Unauthorized, logging out ...");
                // do only reload if we are not currently on a moviepage
                if (can.$(".control-moviepage").length == 0) {
                    window.location.reload();
                }
            } else {
                config.error("http error", httpObj);
            }
        });
    }

    return ajaxRequest;
}

can.$.ajaxSetup({
    beforeSend: function (xhr) {
        xhr.setRequestHeader("Accept", "application/json");
    },
});

var API = {
    addToStorage: addToStorage,
    getFromStorage: getFromStorage,
    removeFromStorage: removeFromStorage,
    /**
     * only CAPI start
     */
    getURLFromConfig: function (target, params) {
        if (!target) {
            throw new Error("missing target!");
        }
        if (params && typeof params != "object") {
            throw new Error("request params have to be an object");
        }
        var url = config.paths.api.base,
            targetUrl = can.getObject(target, [config.paths.api]);

        if (!targetUrl) {
            throw new Error("invalid target!");
        }
        return (url += targetUrl);
    },
    register: function (username, password, settings) {
        var p = requestHelper("POST", config.my.localRegisterUrl(), {
            username: username,
            password: password,
        }).promise();

        // Note: this is a fix for safari no longer working with third party cookies!
        // we emulate the behaviour of a regular form submit by redirecting to another page after login
        p.then((loginData) => API.login(username, password, settings)).fail(
            function (err) {
                console.error('could not login', err)
                if (!settings?.disableRedirect) {
                  location.hash = '#!/my/loginerror'
                  location.reload() // also works if the page was already at loginerror
                }
            }
        );
        return p;
    },
    login: function (username, password, settings) {
        settings = settings || {};
        var successRedirect = settings.successRedirect;
        var errorRedirect = settings.errorRedirect;
        var disableRedirect = settings.disableRedirect

        var movieInfo = Cookie.readCookie("movieToWishlist");
        if (movieInfo) {
            var slug = movieInfo.split(/\|(.+)/)[1];
            successRedirect = successRedirect || "#!/filme/" + slug;
        }

        var p = requestHelper("POST", config.my.localLoginUrl(), {
            username: username,
            password: password,
        }).promise();

        // Note: this is a fix for safari no longer working with third party cookies!
        // we emulate the behaviour of a regular form submit by redirecting to another page after login
        p.then(function (loginData) {
            if (loginData && loginData.id) {
                addToStorage("sid", loginData.id);
                if (!disableRedirect) {
                  location.href = successRedirect || '#!/mainpage/loggedIn'
                  location.reload() // force reload
                }
            }
            console.log("loginData", loginData);
        }).fail(function (err) {
            console.error("could not login", err);
            if (!disableRedirect) {
              location.hash = errorRedirect || '#!/my/loginerror'
              location.reload() // also works if the page was already at loginerror
            }

        });
        return p;
    },
    /**
     * receive a the full url to request a resource that is configured in the config.
     * Uses the base url, the path and merges the base query params with the passed one.
     * URL can use templates like {something} which will replaced with data from the params.
     * Replacing a template will lead to the property being removed from the params.
     *
     * @method buildCAPIUrl
     * @param  {String} target config.js path based on paths.api (uses can.getObject) (Should not contain query parameters!)
     * @param  {Object} params query parameters that will get serialized
     * @return {String} the URL
     */
    buildCAPIUrl: function (target, params) {
        var url = API.getURLFromConfig(target);
        var allParams = API.buildParams(params);
        if (url.indexOf("?") < 0) {
            url += "?";
        }
        if (!/\&$/.test(url) && !/\?$/.test(url)) {
            url += "&";
        }
        var paramsBackup = can.extend(true, {}, allParams), // clone since allParams will get mutated
            enhancedURL = can.sub(url, allParams, true); // true = remove found properties from params

        if (!enhancedURL) {
            // can.sub returns null if a template was not fullfilled
            throw new Error(
                'Unfulfilled URL template "' +
                    url +
                    '" with params ' +
                    JSON.stringify(paramsBackup)
            );
        }

        return enhancedURL + can.$.param(allParams);
    },
    buildParams: (params) =>
        can.extend(
            {},
            config.paths.api.baseParams,
            config.paths.api.paramOverride(params)
        ),
    makeFindOneProxy: function () {
        // careful about "this" binding!
        var findOneFunc = can.Model.makeFindOne.apply(this, arguments);
        return function (params, success, error) {
            if (typeof params === "string") {
                params = { id: params };
            }
            return findOneFunc.call(
                this,
                API.buildParams(params),
                success,
                error
            );
        }.bind(this);
    },
    getCapi: (target, params) =>
        can
            .ajax({
                type: "GET",
                dataType: "json",
                url: API.buildCAPIUrl(target, params),
            })
            .then(function (response) {
                if (!response || response.error) {
                    var err = {
                        error: response,
                        source: { target: target, params: params },
                    };
                    console.error("[API] could not load json", err);
                    return can.Deferred().reject(err);
                } else {
                    return response;
                }
            }),
    getCategoryById: (id) =>
        API.getCapi("category.findOne", {
            id: id,
        }),
    getCategory: (params) => API.getCapi("category.findOne", params),
    searchCategory: function (params) {
        if (!params.search) throw new Error("cannot search without a query");
        return API.getCapi("category.search", params);
    },
    getAllCategories: (params) => API.getCapi("category.findAll", params),
    getMultipleMoviesByIdString: function (idString) {
        var target =
                API.getURLFromConfig("moviesList.get", {}) + "/" + idString,
            params = can.$.param(
                config.paths.api.paramOverride(config.paths.api.baseParams)
            );
        return can
            .ajax({
                type: "GET",
                dataType: "json",
                url: target + "?" + params,
            })
            .then(function (response) {
                if (!response || response.error) {
                    var err = {
                        error: response,
                        source: { target: target, params: params },
                    };
                    console.error("[API] could not load json", err);
                    return can.Deferred().reject(err);
                } else {
                    return response;
                }
            });
    },
    /**
     * only CAPI end
     */
    post: (url, data, options, additionalQueryParameters) =>
        requestHelper(
            "POST",
            url,
            data,
            options,
            additionalQueryParameters,
            false
        ),
    put: (url, data, options, additionalQueryParameters) =>
        requestHelper(
            "PUT",
            url,
            data,
            options,
            additionalQueryParameters,
            false
        ),
    delete: (url, data, options, additionalQueryParameters) =>
        requestHelper(
            "DELETE",
            url,
            data,
            options,
            additionalQueryParameters,
            false
        ),
    get: (url, options, additionalQueryParameters) =>
        requestHelper(
            "GET",
            url,
            null,
            options,
            additionalQueryParameters,
            false
        ),
    getUnauthorized: function (url, options, additionalQueryParameters) {
        config.log("GET unauthorized");
        return requestHelper(
            "GET",
            url,
            null,
            options,
            additionalQueryParameters,
            true
        );
    },
    useLocalStorage: function (localStorage) {
        if (localStorage) {
            storage = window.localStorage;
        } else {
            storage = window.sessionStorage;
        }
    },
    // using the curentUser as a local storage write to determine whether a user is still authorized
    // on user initialization it is checked whether a user is still loggedIn by calling users/me
    // the storage of the current user is just to prevent loading time / know the user state before
    // calling users/me
    storeCurrentUserInCache: function (currentUser) {
        if (!currentUser && !storage) return;
        try {
            addToStorage("currentUser", JSON.stringify(currentUser));
        } catch (e) {
            console.error("could not store currentUser in localStorage", e);
        }
    },
    getCurrentUserFromCache: function () {
        var user,
            userJson = getFromStorage("currentUser");
        if (userJson) {
            try {
                user = JSON.parse(userJson);
            } catch (e) {
                console.error("could not load user from localStorage", e);
            }
        }
        config.log("CurrentUserFromCache", user);
        return user;
    },
    removeCurrentUserFromCache: function () {
        removeFromStorage("currentUser");
        removeFromStorage(SID_KEY);
        removeFromStorage("loggedIn");
    },
};

export default API;
