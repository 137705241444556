<div class="{{#showRegistrationOptions}}login-container{{/showRegistrationOptions}}{{^showRegistrationOptions}} {{#registerPlus}}register-container{{/registerPlus}} {{^registerPlus}}login-container{{/registerPlus}} {{/showRegistrationOptions}}"> {{!me no liky}}
    {{#showRegistrationOptions}}
        {{#registrationOptionHeader}}
            <div class="row registration-option-header">
                <div class="col-xs-9 text-right">
                    <span class="option-title">{{firstOptionTitle}}</span><br/>
                    <span class="option-price">{{firstOptionPrice}}</span>
                </div>
                <div class="col-xs-3">
                    <span class="option-title">{{secondOptionTitle}}</span><br/>
                    <span class="option-price">{{secondOptionPrice}}</span>
                </div>
            </div>
        {{/registrationOptionHeader}}
        {{#each registrationOptions}}
            <div class="row registration-option">
                <div class="col-xs-6 option-name"><span>{{name}}</span></div>
                <div class="col-xs-3">{{#if firstOption}}<i class="fa fa-check"></i>{{/if}}</div>
                <div class="col-xs-3">{{#if secondOption}}<i class="fa fa-check"></i>{{/if}}</div>
            </div>
        {{/each}}
        <div class="row registration-option-footer">
            <div class="col-xs-6"></div>
            <div class="col-xs-3">
                <a href="" id="firstOption" class="btn btn-big btn-dark"><span class="hidden-xs">Auswählen</span><span class="visible-xs">Wähle</span></a>
            </div>
            <div class="col-xs-3">
                <a href="" id="secondOption" class="btn btn-big btn-dark"><span class="hidden-xs">Auswählen</span><span class="visible-xs">Wähle</span></a>
            </div>
        </div>
    {{/showRegistrationOptions}}
    {{^showRegistrationOptions}}
        {{#registerPlus}}
            <h3 class="login-headline">Für {{config.plus.title}} registrieren</h3>
        {{/registerPlus}}
        {{^registerPlus}}
            <h3 class="login-headline">Für {{config.my.title}} registrieren</h3>
        {{/registerPlus}}
        <div class="row">
            <div class="{{colWidthOuter}}" id="plus-teaser" {{^registerPlus}}style="display:none;"{{/registerPlus}}>
                <img src="{{imagePath 'webseite/plus/register_plus.jpg'}}" class="img-responsive" alt="{{config.plus.title}}" />
            </div>
            <div class="{{colWidthOuter}}">
                <form class="form-horizontal" id="registerForm" method="POST">
                    <div class="form-group">
                        <div class="{{colWidth}}">
                            <label for="registerEmail" class="login-label">Emailadresse</label>
                            <input type="text" class="form-control input-big login-input" id="registerEmail" name="username" placeholder="E-Mail" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="{{colWidth}}">
                            <label for="registerPassword" class="login-label">Passwort</label>
                            <input type="password" class="form-control input-big login-input" id="registerPassword" name="password" placeholder="Passwort" required>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="{{colWidth}}">
                            <label for="confirm-agb" class="login-label checkbox-container agb-label">
                                Mit meiner Registrierung stimme ich den <a href="#!/agb" class="login-link" target="_blank">AGB</a> und <a href="#!/datenschutz" class="login-link" target="_blank">Datenschutzbestimmungen</a> zu.
                                <input id="confirm-agb" class="form-control" type="checkbox" required style="width: 30px; float: right;" />
                                <span class="checkmark" style="top: 15px;"></span>
                            </label>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="{{colWidth}}">
                            <button type="submit" class="btn btn-primary btn-big login-btn">Jetzt registrieren</button>
                        </div>
                    </div>

                    {{#if config.my.loginProviders.length}}
                    <div class="form-group">
                        <div class="{{colWidth}}">
                            <span class="login-span">oder</a>
                        </div>
                    </div>
                    {{/if}}

                    <my-user-login-social is-register="true" is-register-plus="{{registerPlus}}" is-disabled="{{disabledSocial}}" />

                    <div class="form-group login-register-section">
                        <div class="{{colWidth}}">
                            <span class="login-span bold">Du hast schon einen Account?</span>
                            <a href="#!/my/login" id="login" class="login-link bold">Jetzt anmelden!</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    {{/showRegistrationOptions}}
</div>

{{#showRegistrationOptions}}
<div class="container text-center">
    <div class="row">
        <div class="col-xs-12">
            {{#each config.plus.registrationOptionFootnote}}
                <p class="register-footnote">
                    {{.}}
                </p>
            {{/each}}
        </div>
    </div>
</div>
{{/showRegistrationOptions}}