<div class="navPlaceholder"></div>

<div class="container content-page">
    <div class="row">
        <div class="col-xs-12">
            <h3 style="margin-bottom: 0px; margin-top: 60px;">ALLGEMEINE GESCHÄFTSBEDINGUNGEN</h3>
            (AGB’S) DER KIXI ENTERTAINMENT GmbH

            <h3>1. VERTRAGSGEGENSTAND, ANWENDUNGSBEREICH</h3>

            Der Video on Demand-Dienst "Kixi" (nachfolgend "VoD-Dienst") ist ein Angebot der Kixi Entertainment GmbH, August-Bebel-Str. 27, 14482 Potsdam (nachfolgend genannt "KIXI"). Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB‘s") gelten für alle Nutzer sowie die Registrierung von Kunden zu dem VoD-Dienst (nachfolgend "Rahmenvertrag") sowie für jede Vereinbarung zwischen dem Kunden und KIXI über den Abruf von Inhalten des VoD-Dienstes (nachfolgend jeweils ein "Nutzungsvertrag"). Abweichende Allgemeine Geschäftsbedingungen des Kunden gelten nicht, auch dann nicht wenn der Kunde sie KIXI übermittelt oder auf sie an anderer Stelle Bezug nimmt und KIXI ihnen nicht widerspricht.

            <h3>2. RAHMEN- UND NUTZUNGSVERTRAG</h3>

            2.1. Für die Nutzung des kostenpflichtigen KIXI VoD-Dienstes ist der Abschluss eines Rahmenvertrages die Voraussetzung. Für die Nutzung kostenloser Angebote kann er freiwillig zustande kommen. Der Rahmenvertrag kommt zustande indem der Kunde sich registriert. Erfolgt eine Freischaltung des VoD-Dienstes für den Kunden zu einem früheren Zeitpunkt, kommt der Rahmenvertrag bereits durch die Freischaltung zustande.

            <br/><br/>

            2.2. Der Kunde erhält von KIXI einen Newsletter wenn er in dessen Erhalt eingewilligt hat oder sofern eine andere Rechtsgrundlage besteht. Ein Anspruch des Kunden auf einen Newsletter besteht nicht. Der Kunde kann jederzeit der Zusendung des Newsletters per E-Mail oder Klick auf einen Abmeldelink im Newsletter widersprechen.

            <br/><br/>

            2.3. Zusätzlich zum Rahmenvertrag kommt zwischen KIXI und dem Kunden durch jeden Filmabruf und/oder jede Bestellung jeweils ein Nutzungsvertrag zustande, sofern KIXI den Filmabruf oder die Bestellung annimmt. Die Annahme erfolgt jeweils durch die Bereitstellung der Leistung durch KIXI.

            <br/><br/>

            2.4. KIXI behält sich vor, das Angebot des Kunden auf Abschluss eines Nutzungsvertrages im Einzelfall bei berechtigtem Interesse abzulehnen.

            <br/><br/>

            2.5 Die Nutzung des KiXi VoD-Dienst ist aus urheberrechtlichen Gründen ausschließlich Nutzern in der Bundesrepublik Deutschland gestattet. Der Abschluss des Rahmenvertrages aus Ländern außerhalb der Bundesrepublik Deutschland ist unzulässig. KiXi übernimmt keine Haftung für aus dem Ausland abgeschlossene Rahmenverträge für den VoD-Dienst, insbesondere in Hinblick auf die Abrufbarkeit der Inhalte und die Funktionalität der technischen Plattformen von KiXi.

            <h3>3. LEISTUNGSUMFANG</h3>


            3.1. Nach Zustandekommen des Nutzungsvertrages stellt KIXI dem Kunden den Inhalt im Wege des Streamings bereit. Unter "Streaming" versteht man die mit der Übermittlung unveränderte zeitgleiche Nutzung durch den Kunden, bei der keine dauerhafte Kopie auf Endgeräten des Kunden erstellt wird.

            <br/><br/>

            3.2. Bei Abschluss eines Abo-Pakets kann der Kunde die in dem jeweiligen Abo-Paket enthaltenen Inhalte während der jeweiligen Nutzungslaufzeit des Abos beliebig oft im Wege des Streamings abrufen. Nach Ablauf des Abos ist der Kunde verpflichtet, gespeicherte Inhalte endgültig von sämtlichen Speichermedien seiner Endgeräte zu löschen.

            <br/><br/>

            3.3. Weitergehende Nutzungsrechte erhält der Kunde nicht. Er ist insbesondere nicht berechtigt, die Inhalte in irgendeiner Weise zu bearbeiten oder zu verändern, außerhalb der Grenzen dieser AGB’s Kopien von den Inhalten anzufertigen, die Inhalte Dritten zur Verfügung zu stellen oder öffentlich zugänglich zu machen. Er darf Inhalte nicht auf physische Träger kopieren/brennen und/oder auf tragbare Abspielgeräte kopieren oder übertragen.

            <br/><br/>

            3.4. Der Kunde hat keinen Anspruch auf einen bestimmten Inhalt innerhalb des (abstrakt) beschriebenen Abo-Pakets, es sei denn KIXI hat dem Kunden einen bestimmten Inhalt konkret-individuell zugesagt. KIXI behält sich vor, das Inhalte-Paket, Preis- und Leistungsbeschreibungen sowie technische Rahmenbedingungen jederzeit zu ändern soweit dies erforderlich ist und der Kunde dadurch objektiv nicht wesentlich schlechter gestellt wird.

            <h3>4. BESONDERE PFLICHTEN UND OBLIEGENHEITEN DES KUNDEN</h3>


            4.1. Die Inanspruchnahme des VoD-Dienstes setzt eine breitbandige (mindestens 1 Mbits/s) Internetverbindung des Kunden bei einem beliebigen Dienstleister voraus. Wir weisen darauf hin, dass Bandbreiten unterhalb von 1 Mbit/Sek. die Nutzung des VoD-Dienstes nur mit erheblichen Qualitätsverlusten erlauben. Je nach benutzter Internet-Verbindung können abhängig von Dienstleister und Tarif erhebliche Mehrkosten entstehen, die regelmäßig vom Kunden zu tragen sind. Es obliegt dem Kunden, sich über etwaige Mehrkosten zu informieren und selbst dafür Sorge zu tragen, dass sein Endgerät die technischen Systemvoraussetzungen zur Nutzung unseres Angebots erfüllt.

            <br/><br/>

            4.2. Der Kunde muss die bei der Registrierung (Ziffer 2.1) abgefragten Angaben wahrheitsgemäß beantworten und ist für die richtige Eingabe seiner Daten verantwortlich.

            <br/><br/>

            4.3. Kennwörter/Passwörter dürfen vom Kunden nicht an Dritte weitergegeben werden und sind vor dem Zugriff durch Dritte geschützt aufzubewahren. Der Kunde muss sie nach erstmaliger Bereitstellung und alsdann in regelmäßigen Abständen ändern. Soweit für den Kunden Anlass zu der Vermutung besteht, dass unberechtigte Personen von dem Kennwort/Passwort Kenntnis erlangt haben, hat er das Kennwort/Passwort unverzüglich zu ändern. In digitalen Medien dürfen Kennwörter/Passwörter nur in verschlüsselter Form gespeichert werden. Die geschäftsmäßige Weiterveräußerung oder Vermietung der Leistungen von KIXI an Dritte ist nicht gestattet.

            <br/><br/>

            4.4. Der Kunde darf den VoD-Dienst nicht missbräuchlich nutzen. Er darf insbesondere

            <br/>
            a) abgerufene Inhalte nur unter Beachtung des nationalen und internationalen Urheberrechts im Rahmen der eingeräumten Nutzungsrechte nutzen und nicht vervielfältigen,

            <br/>
            b) abgerufene Inhalte nicht öffentlich vorführen, öffentlich zugänglich machen, permanent und/oder lokal speichern, senden, bearbeiten, verbreiten, vervielfältigen, vertreiben, bewerben, öffentlich wiedergeben, derartige Nutzungen unterstützen oder sie sonst außerhalb des vertraglich bestimmten Zwecks in irgendeiner Form nutzen,

            <br/>
            c) den abgerufenen Inhalt ausschließlich für Vorführungen im nicht öffentlichen Bereich und nur nichtgewerblich nutzen,

            <br/>
            d) Urheber- und Schutzrechtsvermerke für den Inhalt nicht entfernen oder ändern. Der Kunde hat den Inhalt vor jeglicher Nutzung durch Nichtberechtigte und vor sonstigem Missbrauch zu schützen,

            <br/>
            e) Kindern oder Jugendlichen nur solche Inhalte vorführen, vorführen lassen oder in anderer Weise zugänglich machen, die für die jeweilige Altersgruppe freigegeben sind.

            <br/>
            KIXI behält sich straf- und zivilrechtliche Verfolgungen von Missbrauchsfällen ausdrücklich vor.

            <br/><br/>

            4.5. Der Kunde haftet gegenüber KIXI für alle Schäden, Kosten und sonstige Aufwendungen, die durch schuldhafte Verstöße des Kunden gegen die sich aus dem Vertragsverhältnis zwischen KIXI und dem Kunden ergeben und stellt KIXI von hierdurch entstehenden Ansprüchen Dritter frei.

            <h3>5. ZAHLUNGSBEDINGUNGEN</h3>


            5.1. Der Kunde verpflichtet sich, das jeweilige Nutzungsentgelt für einen kostenpflichtigen Dienst nach Maßgabe der nachfolgenden Regelungen zu zahlen. Das Nutzungsentgelt wird mit Bereitstellung der Leistung sofort fällig, soweit nicht nachfolgend etwas Abweichendes geregelt ist.

            <br/><br/>

            5.2. Im Falle eines Abo-Pakets werden die Zahlungen monatlich jeweils zu Beginn eines Abrechnungsmonats in Rechnung gestellt und sind sofort fällig, soweit in der Rechnung nicht etwas Abweichendes geregelt ist. Der erste Abrechnungsmonat beginnt regelmäßig am Tag des Vertragsschlusses und endet am gleichen Tag des Kalender-Folgemonats (z.B. 15. Januar bis 15. Februar).

            <br/><br/>

            5.3. Ein Aufrechnungsrecht steht Kunden nur zu, soweit ihre Gegenforderung gegenüber KIXI rechtskräftig festgestellt, unbestritten oder anerkannt ist. Das Zurückbehaltungsrecht, insbesondere die Einrede des nicht erfüllten Vertrages, bleibt davon unberührt.

            <br/><br/>

            5.4. Die Zahlungspflicht besteht auch für Nutzungsentgelte, die dadurch entstanden sind, dass Dritte unbefugt oder befugt Inhalte über den Anschluss des Kunden oder unter Verwendung der Passwörter des Kunden abgerufen haben, solange der Kunde die Nutzung des Dritten zu vertreten hat.


            <h3>6. TECHNISCHER BETRIEB</h3>


            KIXI kann den Zugang und die Erreichbarkeit des Dienstes beschränken, sofern die Sicherheit des Netzbetriebes, die Vermeidung schwerwiegender Störungen des Netzes, der Software oder gespeicherter Daten, die Interoperabilität der Dienste, die Aufrechterhaltung der Netzintegrität oder Datenschutzaspekte dies erfordern.

            <h3>7. HAFTUNG</h3>


            Für Schäden die keine Personenschäden sind haftet KIXI nur dann, wenn KIXI oder einer ihrer Erfüllungsgehilfen wesentliche Vertragspflichten und/oder "Kardinalpflichten" verletzt oder wenn der Schaden auf grobe Fahrlässigkeit oder Vorsatz von KIXI oder einer ihrer Erfüllungsgehilfen zurückzuführen ist. Erfolgt eine schuldhafte Verletzung einer wesentlichen Vertragspflicht (Kardinalpflicht) nicht grob fahrlässig oder vorsätzlich, so ist die Haftung von KIXI auf solche typischen Schäden begrenzt, die zum Zeitpunkt des Vertragsschlusses vernünftigerweise vorhersehbar waren. Diese Haftungsbeschränkung gilt für vertragliche und außervertragliche Ansprüche. Die Haftung aufgrund ausdrücklich als solcher bezeichneten Garantien sowie zwingender gesetzlicher Vorschriften, insbesondere aufgrund des Produkthaftungsgesetzes, bleibt unberührt.

            <h3>8. LAUFZEIT UND BEENDIGUNG VON RAHMEN- UND NUTZUNGSVERTRAG</h3>


            8.1. Der Rahmenvertrag kann von beiden Seiten jederzeit zum Monatsende gekündigt werden. Für den Fall, dass ein Nutzungsvertrag über ein Abo-Paket geschlossen wurde, kann der Rahmenvertrag nur zusammen mit dem jeweiligen Nutzungsvertrag gekündigt werden.

            <br/><br/>

            8.2. Der Nutzungsvertrag über ein Abo-Paket hat die in der jeweiligen Leistungsbeschreibung bestimmte Laufzeit und verlängert sich automatisch um die in der Leistungsbeschreibung angegebene Laufzeit, sofern er nicht mit der in der Leistungsbeschreibung angegebenen Frist zum Vertragsende gekündigt wird.

            <br/><br/>

            8.3. Beiden Parteien bleibt das Kündigungsrecht aus wichtigem Grund vorbehalten.

            <h3>9. WIDERRUFSBELEHRUNG</h3>

            Die Widerrufsbelehrung ist aus juristischen Gründen Teil unserer AGB.
            Wenn Sie ihr Abo tatsächlich beenden wollen, klicken Sie bitte hier: <button class="btn btn-primary" id="cancel-subscription" style="font-size: 20px;vertical-align: baseline;">Abo kündigen</button>

            <br/><br/>

            Wenn Sie ihr Abonnement über die KiXi App abgeschlossen haben, können Sie dieses ausschließlich in ihren persönlichen Einstellungen auf Ihrem Smartphone oder Tablet verwalten oder beenden.
            
            <br/><br/>

            <a href="https://support.apple.com/de-de/HT202039">Weitere Informationen zum Kündigen eines Abonnements unter iOS</a>
            
            <br/><br/>
            <a href="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=de">Weitere Informationen zum Kündigen eines Abonnements unter Android</a>
            
            <br/><br/>

            Wenn Sie ihr Abonnement bei Amazon abgeschlossen haben, können Sie dieses ausschließlich in Ihrem persönlichen Amazon-Konto verwalten oder beenden.

            <br/><br/>

            <a href="https://www.amazon.de/gp/help/customer/display.html?language=de_DE&nodeId=202005090">Weitere Informationen zum Kündigen eines Abonnements unter Amazon</a>

            <br/><br/>


            9.1. Widerrufsrecht: Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt 14 Tage ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns,

            <br/>

            Kixi Entertainment GmbH<br>
            August-Bebel-Str. 27<br>
            14482 Potsdam<br>
            info@KIXI.de<br>

            mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.

            <br/><br/>

            9.2. Folgen des Widerrufs: Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.

            <br/><br/>

            9.3. Vorzeitiges Erlöschen des Widerrufsrechts: Bei einem Vertrag über die Lieferung von digitalen Inhalten, die sich nicht auf einem körperlichen Datenträger befinden (z.B. „Streaming“), erlischt das Widerrufsrecht vorzeitig, wenn Sie zustimmen, dass wir mit der Ausführung des Vertrages vor Ablauf der Widerrufsfrist beginnen (Start des Abrufs der digitalen Inhalte) und Sie ihre Kenntnis des Vorzeitigen Erlöschens bestätigt haben.

            <br/><br/>

            9.4. Muster-Widerrufsformular: Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück an:

            <br/><br/>
            Kixi Entertainment GmbH<br/>
            August-Bebel-Str. 27<br/>
            14482 Potsdam<br/>
            info@KIXI.de<br/>
            <br/>
            -Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)<br/>
            -Bestellt am (*)/erhalten am (*)<br/>
            -Name des/der Verbraucher(s)<br/>
            -Anschrift des/der Verbraucher(s)<br/>
            -Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)<br/>
            -Datum<br/>
            (*) Unzutreffendes streichen.

            <h3>10. ÄNDERUNG DER AGB</h3>

            Über Änderungen dieser AGB und/oder des Leistungsumfangs wird KIXI den Kunden schriftlich oder per E-Mail informieren. Soweit Änderungen nicht wesentliche Vertragsbestimmungen betreffen gilt die Zustimmung des Kunden zur Änderung dieser AGB’s als erteilt, sofern er der Änderung nicht binnen sechs Wochen nach Zugang der Änderungsmitteilung schriftlich widerspricht.

            <h3>11. SONSTIGES</h3>

            Für sämtliche Rechtsbeziehungen zwischen KIXI und dem Kunden gilt ausschließlich das für die Rechtsbeziehungen inländischer Parteien maßgebliche Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Sollte eine oder mehrere Klauseln dieser AGB ganz oder teilweise unwirksam sein, so wird hierdurch die Gültigkeit der übrigen Bestimmungen nicht berührt.

            <br/>
            <br/>
            Stand: 01.08.2017
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    </div>
</div>
