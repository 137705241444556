var Cookie = {
    createCookie: function (name, value, expire) {
        var expires = "";
        if (expire) {
            expires = "; expires=" + expire;
        }
        console.log("setting cookie", name + " - " + value + " - " + expires);
        document.cookie = name + "=" + value + expires + "; path=/";
    },
    readCookie: function (name) {
        console.log("read cookie", name);
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    clearCookie: function (name) {
        console.log("clear cookie", name);
        this.createCookie(name, "", -1);
    },
};

export default Cookie;
