import can from "can";
import swal from "sweetalert";
import Cookie from "../../../lib/cookie-handler";
import API from "../../api";
import config from "../../config";
import ga from "../../modules/googleanalytics";
import viewHeader from "./view.stache";

can.Component.extend({
    tag: "my-user-login-social",
    template: viewHeader,
    scope: {
        isRegister: "@",
        isRegisterPlus: "@",
        loginProviders: config.my.loginProviders,
        isDisabled: "false",
        socialLogin: function (key, $el) {
            config.log("submitted social login", arguments);

            $el.attr("disabled", "disabled");

            API.addToStorage("loggedIn", true);
            var redirectURL = config.my.socialLoginUrl(key);
            // if we are registering for plus, we want to set cookie to directly navigate to payment after first login
            if (this.attr("isRegisterPlus") === "true") {
                var now = new Date();
                var time = now.getTime();
                time += 60 * 60 * 1000;
                now.setTime(time);
                Cookie.createCookie("registeredPlus", "1", now.toUTCString()); // valid for 1 hour
                redirectURL = config.plus.socialLoginUrl(key);
            } else {
                // we might have checked for plus option first and now only want to sign up
                Cookie.clearCookie("registeredPlus");
            }

            var redirectURLEncoded = encodeURIComponent(redirectURL);
            config.log("RedirectURL for sociallogin:", redirectURLEncoded);
            window.location.href =
                config.my.api.getUrl("login" + key) +
                "?plainRedirect=false&redirectURL=" +
                redirectURLEncoded;
        },
    },
    events: {
        ".social-login-btn click": function ($el, ev) {
            ev.preventDefault();
            var key = $el.data("key");

            if (
                this.scope.isDisabled === "true" ||
                this.scope.isDisabled === true
            ) {
                swal({
                    title: "Hinweis",
                    text:
                        'Du musst erst unseren <a href="#!/agb" class="login-link" target="_blank">AGB</a> und <a href="#!/datenschutz" class="login-link" target="_blank">Datenschutzbestimmungen</a> zustimmen, um dich zu registrieren.',
                    type: "warning",
                    html: true,
                });
            } else {
                ga.sendEvent("Account Action", "Login", "social-" + key);

                if (config.plus.paywall.enabled) {
                    // if there is a paywall, redirect the user to the payment process! Use location to force full reload
                    // this is necessary as it resets the state of the plus-payment component.
                    window.location.href =
                        window.location.href.split("?")[0] + "#!/my/plus";
                }

                this.options.scope.socialLogin(key, $el);
            }
        },
    },
});
