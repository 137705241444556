<div class="sharing">
  {{#if config.moviepage.sharing.whatsApp.show}}
    <a class="whatsappButton" href="{{movie.whatsAppLink}}">{{config.moviepage.sharing.whatsApp.buttonText}}</a>
  {{/if}}

  <!-- FACEBOOK -->
  <div class="fb-like" data-href="{{permalink movie}}" data-layout="button_count" data-action="like" data-show-faces="true" data-share="true"></div>

  <!-- TWITTER -->
  <a class="twitter-share-button" href="https://twitter.com/share" data-url="{{permalink movie}}" data-via="{{config.moviepage.sharing.twitterVia}}">Tweet</a>


</div>