import can from "can";
import $ from "jquery";
import utils from "../../../lib/utils";
import config from "../../config";
import User from "../../models/user";
import ContentTracker from "../../modules/contentTracker";
import "../../modules/fuckadblock";
import watchlist from "../../modules/watchlist";
import state from "../../state";
import View from "./view.stache";

var DID_CLICK_ONCE = false;
var globalClickHandler = function () {
    DID_CLICK_ONCE = true;
    // run only once
    window.document.removeEventListener("click", globalClickHandler);
};
window.document.addEventListener("click", globalClickHandler);

var avourl = "//netzkino.clients.avoplayer.com.simplecache.net",
    internal = document.location.search.indexOf("avoplayer.internal") !== -1;
if (window.platform !== "netzkino" && window.platform !== "bronco") {
    // vpaid is only allowed on netzkino and bronco
    avourl = "//amogo.clients.avoplayer.com.simplecache.net";
}
if (internal) {
    avourl = "//internal.clients.avoplayer.com.simplecache.net";
}
avourl = avourl + "/website/avoplayer-web.js";

const avoPromise = $.getScript(avourl);

var adsBlocked = false;
// Function called if AdBlock is not detected
var adBlockCheck = new $.Deferred();

function adBlockNotDetected() {
    adsBlocked = false;
    adBlockCheck.resolve(false);
}

// Function called if AdBlock is detected
function adBlockDetected() {
    adsBlocked = true;
    adBlockCheck.resolve(true);
}

function cleanupPotentialOldPlayer(locationLog) {
    config.log(locationLog);

    window.avoCore.stop();
    window.avoCore.destroy();
    window.avoCore = null;
}

// Recommended audit because AdBlock lock the file 'fuckadblock.js'
// If the file is not called, the variable does not exist 'fuckAdBlock'
// This means that AdBlock is present
// if(typeof fuckAdBlock === 'undefined') {
//     adBlockDetected();
// } else {
//     fuckAdBlock.onDetected(adBlockDetected);
//     fuckAdBlock.onNotDetected(adBlockNotDetected);
// }
adBlockNotDetected(); // addefend will work its magic

// TODO: It has been mentioned in PR#58 that binding these tracking variables/functions to the component would be nicer.
//       Things that might be considered while doing so:
//        - Why is initAvoplayer / the avoplayer not bound to this component?
//        - If we bind the functions to the component, what happens if the component is destroyed, but the player has not finished (gracefully)?
//        - Also remember the doubled-component initialization problem where the component was initialized->destroyed->initialized which led to strange avoplayer
//          behaviour (among others: still playing in the background)

// as - through token creation - this became async, we defer and resolve if successfully created
// timing-wise this does only impact plus film initialization
function initAvoplayer(el, movie, isPlus, tracker) {
    config.log("avo - initAvoplayer");

    var avoplayerInitDef = new can.Deferred();

    if (window.avoCore) {
        cleanupPotentialOldPlayer(
            "avo - destroying avo on new instance initialization"
        );
    }

    function addBlockImage() {
        el.html(
            can.$("<div/>", {
                style: "width: 100%; height: 100%;",
                class: "fab",
            })
        );
    }

    if (adsBlocked) {
        // was already verified on front page
        addBlockImage();
        avoplayerInitDef.reject();
        return avoplayerInitDef;
    } else {
        // it could be that the movie page was loaded directly, in that case, the adsBlocked is not yet ready
        avoplayerInitDef.then(function () {
            return adBlockCheck.then(function (isBlocked) {
                if (isBlocked) {
                    window.avoCore && cleanupPotentialOldPlayer();
                    addBlockImage();
                }
            });
        });
    }

    tracker.contentDisplayed();

    el.html(
        can.$("<div/>", {
            style: "width: 100%; height: 100%;",
        })
    );
    el = can.$("div", el);

    if (
        !movie.attr("custom_fields.Streaming.0") &&
        movie.attr("custom_fields.Youtube.0")
    ) {
        // no streaming field set, fallback to youtube (if avaialable)
        el.html(movie.attr("custom_fields.Youtube.0"));
        can.$("iframe", el).css({
            height: "100%",
            width: "100%",
        });
        avoplayerInitDef.reject();
        return avoplayerInitDef;
    }

    var tokenDef = new can.Deferred();
    var aclsToProcess = [
        {
            key: "hls",
            tokenType: "hls",
            acl: "/i/" + movie.attr("custom_fields.Streaming.0") + ".mp4/*",
        },
        {
            key: "hds",
            tokenType: "hds",
            acl:
                "/z/" +
                movie.attr("custom_fields.Streaming.0") +
                ".mp4/manifest.f4m",
        },
        {
            key: "pmd",
            tokenType: "pmd",
            acl: "/" + movie.attr("custom_fields.Streaming.0") + ".mp4",
        },
    ];
    if (movie.isPlusMovie()) {
        User.createTokens(aclsToProcess).then(
            function (tokens) {
                var sourceReplacements = {};
                sourceReplacements["hls"] =
                    movie.attr("custom_fields.Streaming.0") +
                    ".mp4/master.m3u8?hdnts=" +
                    tokens["hls"];
                sourceReplacements["hds"] =
                    movie.attr("custom_fields.Streaming.0") +
                    ".mp4/manifest.f4m?hdcore=3.5.0&hdnts=" +
                    tokens["hds"];
                sourceReplacements["pmd"] =
                    movie.attr("custom_fields.Streaming.0") +
                    ".mp4?__token__=" +
                    tokens["pmd"];
                tokenDef.resolve(sourceReplacements);
            },
            function (err) {
                tokenDef.reject();
            }
        );
    } else {
        setTimeout(function () {
            tokenDef.resolve(null);
        }, 10); // apparently this timeout is needed for flowplayer instantiation (Flowplayer cannot access element)
        // Assuming that the createToken call takes more than 10ms we skip this timeout above.
    }
    const tokenPromise = Promise.resolve(tokenDef); // jQuery does not use real promises
    Promise.resolve(avoPromise)
        .then(() => {
            return tokenPromise;
        })
        .then(
            function (sourceReplacements) {
                try {
                    // HLS is no longer supported, since we do not use Akamai anymore
                    AVOCore.utils.featureDetector.features.video.html5.hls = false;
                } catch (e) {
                    console.error("could not disable HLS", e);
                }
                var Player = AVOCore.getPlayer(
                    config.moviepage.preferredAvoPlayer
                );
                var _player = new Player(el);

                // chrome adds the ability to download any movie by default. We can disable this video the controlList
                try {
                    var video = el.find("video")[0];
                    if (video && video.controlsList) {
                        video.controlsList.add("nodownload");
                    }
                } catch (err) {
                    // do not break playback
                    console.error("could not disable video download", err);
                }

                _player.destroy = function () {
                    // also called by cleanupPotentialOldPlayer!
                    // super()
                    Player.prototype.destroy.call(this, arguments);
                    // mute and remove src to prevent background play after navigating too fast
                    // this is not known to work always 100%, but fixes the reproducable edge cases
                    // regarding this issue
                    config.log("avo - mute");
                    this.element.find("video").prop("muted", true);
                    this.element.find("video").attr("src", "");
                    this.element.remove();
                    tracker.destroy();
                };

                _player.on("play", function () {
                    if (!document.body.contains(el[0])) {
                        cleanupPotentialOldPlayer(
                            "avo - destroying avo on play start as the page is no longer in view"
                        );
                    } else {
                        tracker.playbackStarted(_player.isAd);
                    }

                    el.trigger("play", {
                        isAd: _player.isAd,
                    });
                });
                // bind play to watchduration tracking
                _player.on("play", function () {
                    tracker.playbackStarted();
                });
                _player.on("pause", function () {
                    tracker.playbackStopped();
                });
                _player.on("timeupdate", function (time) {
                    if (!_player.isAd) {
                        tracker.playbackTimeupdate(time);
                    }
                });

                var avoConfig = {
                    mainMovie: {
                        //url: 'http://multiplatform-f.akamaihd.net/z/multi/akamai10year/Akamai_10_Year_,200,300,600,800,1000,1500,2500,4000,k.mp4.csmil/manifest.f4m',
                        slug: movie.attr("custom_fields.Streaming.0"),
                        title: movie.attr("title"),
                        host: "akamai",
                        akamaiOptions:
                            config.moviepage.akamaiOptionsForMovie(movie),
                        type: "auto",
                        urlTemplate: config.moviepage.videoSourceTemplate(
                            movie.isPlusMovie(),
                            sourceReplacements
                        ),
                        // safari requires direct interaction with the video element, while chrome needs any interaction beforehand
                        // voucher movies must be triggered manually
                        autoplay:
                            DID_CLICK_ONCE &&
                            !utils.isSafari() &&
                            !movie.hasMovieProperty("voucher-exclusive"),
                    },
                    adTitle: "Gleich geht's weiter...",
                    posterImage: movie.attr("custom_fields.featured_img_all.0"),
                };
                var adScheduleURL = config.moviepage.scheduleURLForMovie(
                    movie,
                    isPlus === true
                );

                if (adScheduleURL) {
                    avoConfig.scheduleURL = adScheduleURL;
                } else {
                    config.log(
                        "skipping ads, because scheduleURLForMovie did not return any URL"
                    );
                    avoConfig.schedule = []; // no ads at all
                }

                if (!config.general.isLanding()) {
                    avoConfig.trackingId = config.google_analytics.avo_id;
                }

                window.avoCore = new AVOCore(avoConfig, _player);
                window.avoCore.playScheduleManager.on("ended", function () {
                    tracker.playbackEnded();
                    el.trigger("ended");
                });
                window.avoCore.playScheduleManager.on("stop", function () {
                    tracker.playbackStopped();
                });

                // disable context menu for video element to make download more difficult
                $("video").bind("contextmenu", function () {
                    return false;
                });

                // enable inline-playback so the video does not enter fullscreen on iOS
                $("video").attr("playsinline", "");

                avoplayerInitDef.resolve(_player);
            },
            function (err) {
                // we did not instantiate properly because we could not get a token to watch the film
                // TODO notify user?
                avoplayerInitDef.reject();
                console.error("no valid token could be generated");
            }
        );

    return avoplayerInitDef;
}

can.Component.extend({
    tag: "avo-player",
    template: View,
    scope: {
        movie: null, // required
        sourceOverride: "",
    },
    events: {
        // Careful! Inserted needs to be called manually, when scope changes (e.g. movie) in order to rerender
        // the player for e.g. selecting a new episode. This is happening in the below function listening for "{scope} movie"
        inserted: function () {
            config.log("avodbg - component init");
            var movie = this.scope.attr("movie"),
                el = this.element,
                hasAlreadySeeked = false,
                self = this;
            config.log("Initializing this movie:", movie);

            // if not enabled, directly init, else wait for promise
            var tracker = new ContentTracker(movie);
            if (!config.my.enabled) {
                self._playerDef = initAvoplayer(el, movie, false, tracker); // we do not care for promise resolve
            } else {
                self._playerDef = state.currentUserPromise.then(
                    function (user) {
                        var isPlus = user.isPlus;
                        // we are loggedIn, start movie
                        return initAvoplayer(el, movie, isPlus, tracker).then(
                            function (player) {
                                window.avoCore.player.on("play", function () {
                                    var isAd = player.isAd;
                                    // if currentMovie is on watchlist, we want to seek to latest saved position after the ads have played
                                    // we also have to wait for mainmovie-play (by checking for first occurence of isAd=false)
                                    // TODO - this does not work when there is no ad present. This needs to be mitigated by having at least the
                                    // netzkino-default-preroll present / some 100% delivery ad in fallback-position
                                    if (!isAd && !hasAlreadySeeked) {
                                        var timeForVideo =
                                            watchlist.getTimeForVideoSync(
                                                movie
                                            );
                                        if (timeForVideo) {
                                            config.log(
                                                "Found current movie in watchlist. Seeking to",
                                                timeForVideo + "."
                                            );

                                            setTimeout(function () {
                                                window.avoCore.seekTo(
                                                    timeForVideo
                                                );
                                            }, 250); // we need some time to reach state 'playing'
                                            hasAlreadySeeked = true;
                                        }
                                    }
                                });
                            }
                        );
                    },
                    function () {
                        return initAvoplayer(el, movie, false, tracker); // we do not care for promise resolve
                    }
                );
            }
        },
        removed: function () {
            config.log("avodbg - removed", this);
            // called when the component's tag is removed from the DOM
            // destroy avoplayer if it exists in window
            // this fixes back navigation while still initializing resulting in background play of the video
            if (window.avoCore) {
                cleanupPotentialOldPlayer(
                    "avo - destroying avo on component removal"
                );
            }

            // maybe leftover in some navigation cases (not consistent though)
            config.log(
                "clearInterval",
                this.options.scope.attr("intervalSaveCurrentTime")
            );
            clearInterval(this.options.scope.attr("intervalSaveCurrentTime"));
        },
        // eslint-disable-next-line no-unused-vars
        "{scope} movie": function (scope, source) {
            var self = this;

            function getOrSetVolume(vol) {
                if (self.element) {
                    var $video = self.element.find("video");
                    if ($video.length > 0) {
                        var vid = $video[0];
                        if (!vol) {
                            // we ignore the mute state, the user should know when he/she switched the video
                            return vid.volume;
                        } else {
                            vid.volume = vol;
                            return vol;
                        }
                    }
                }
            }

            // reinitialize and keep the volume at the same level
            var oldVolume = getOrSetVolume();
            this.inserted(); // this works thanks to cleanupPotentialOldPlayer!
            // eslint-disable-next-line no-unused-vars
            this._playerDef.then(function (_player) {
                getOrSetVolume(oldVolume);
            });
        },
    },
});
