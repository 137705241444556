<div class="navPlaceholder"></div>

<div class="fullscreen-bg" style="background: url('{{config.plus.heroImageUrl}}') center center / cover no-repeat;">
    <video loop muted autoplay poster="{{landingPreview.placeholderURL}}" class="fullscreen-bg__video hidden-xs">
        <source src="{{landingPreview.videoURL}}" type="video/mp4">
    </video>
</div>

<div class="container-fluid plus-container text-center hidden-md hidden-lg responsive-register-block hidden-xs">
    <div class="row">
        <div class="col-xs-12">
            <h2 class="bold">{{config.plus.slogan}}</h2>
        </div>
    </div>
</div>

<div class="container plus-container teaser-container text-center hidden-xs">
    <div class="row">
        <div class="col-xs-12">
            <h2 class="bold hidden-xs hidden-sm">{{config.plus.slogan}}</h2>
            <img src="{{imagePath 'webseite/plus/plus_pricebadge.png'}}" id="price-badge" />
        </div>
    </div>
    <a href="#" alt="Jetzt abonnieren" class="register-now hidden-xs hidden-sm"></a>
</div>

<div class="container-fluid plus-container text-center hidden-md hidden-lg responsive-register-block hidden-xs">
    <div class="row">
        <div class="col-xs-12">
            <a href="#" alt="Jetzt abonnieren" class="register-now"></a>
        </div>
    </div>
</div>

{{! replacement for containers above on mobile: xs width}}
<div class="container-fluid plus-container text-center visible-xs no-padding">
    <div class="row row-xs-no-padding">
        <div class="col-xs-12">
            <a href="#" class="register-now" alt="Jetzt abonnieren" style="display: block;">
                <img src="{{landingMobilePreviewImageUrl}}" style="margin: auto;" class="img-responsive" />
            </a>
        </div>
    </div>
</div>

<div class="bg-white">
    <div class="container-fluid benefit-container plus-container text-center">
        <div class="row">
            <div class="col-xs-12">
                <h2 class="highlight bold">Deine Vorteile mit {{config.plus.title}}*</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <ul style="padding: 0px;">
                    <li class="benefit-element">
                        <div>
                            <img src="{{imagePath 'webseite/plus/icon_thumb.png'}}" class="img-responsive" />
                        </div>
                        <h4 class="visible-xs visible-sm">MEHR KINO</h4>
                        <span class="visible-xs visible-sm">Viele weitere Filme</span>
                    </li>
                    <li class="benefit-element">
                        <div>
                            <img src="{{imagePath 'webseite/plus/icon_tuete.png'}}" class="img-responsive" />
                        </div>
                        <h4 class="visible-xs visible-sm">2.000 FILME</h4>
                        <span class="visible-xs visible-sm">ohne Werbung**</span>
                    </li>
                    <li class="benefit-element">
                        <div>
                            <img src="{{imagePath 'webseite/plus/icon_pig.png'}}" class="img-responsive" />
                        </div>
                        <h4 class="visible-xs visible-sm">TOP PREIS</h4>
                        <span class="visible-xs visible-sm">Nur {{config.plus.price}} / Monat</span>
                    </li>
                    <li class="benefit-element">
                        <div>
                            <img src="{{imagePath 'webseite/plus/icon_hd.png'}}" class="img-responsive" />
                        </div>
                        <h4 class="visible-xs visible-sm">HIGH DEFINITION</h4>
                        <span class="visible-xs visible-sm">Brilliante HD Qualität***</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row hidden-xs hidden-sm">
            <div class="col-xs-12">
                <ul style="padding: 0px;">
                    <li class="benefit-element vcenter">
                        <h4>MEHR KINO</h4>
                        <span>Viele weitere Filme</span>
                    </li>
                    <li class="benefit-element vcenter">
                        <h4>2.000 FILME</h4>
                        <span>ohne Werbung**</span>
                    </li>
                    <li class="benefit-element vcenter">
                        <h4>TOP PREIS</h4>
                        <span>Nur {{config.plus.price}} / Monat</span>
                    </li>
                    <li class="benefit-element vcenter">
                        <h4>HIGH DEFINITION</h4>
                        <span>Brilliante HD Qualität***</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <a href="#" alt="Jetzt abonnieren" class="register-now highlight-register"></a>
            </div>
        </div>
    </div>
</div>


<div class="bg-white">
    <div class="container text-center" style="padding-top: 15px; padding-bottom: 15px;">
        <p>
            {{#each config.plus.registrationOptionFootnote}}
                {{.}}<br/>
            {{/each}}
        </p>
    </div>
</div>
