import can from "can";
import swal from "sweetalert";
import BaseControl from "../../../lib/can.basecontrol.js";
import Cookie from "../../../lib/cookie-handler";
import netletixRefresh from "../../../lib/netletix-refresh";
import ogury from "../../../lib/ogury";
import utils from "../../../lib/utils";
import config from "../../config";
import Category from "../../models/category";
import Movie from "../../models/movie";
import User from "../../models/user";
import geolocation from "../../modules/geolocation";
import voucher from "../../modules/voucher";
import watchlist from "../../modules/watchlist";
import state from "../../state";
import View from "./view.stache";

var countryCodePromise = config.geoBlocking.enabled
    ? geolocation.getCountry()
    : new can.Deferred().resolve("XX"); // load when loading the page

function getCat(catId, data, useParent) {
    var existingCat =
            data.categoriesById[catId] || data.categoriesBySlug[catId],
        isSubcategory = false;
    if (existingCat && existingCat.posts && existingCat.posts.length) {
        return new can.Deferred().resolve(existingCat);
    } else if (useParent && existingCat && existingCat.parent !== 0) {
        // TODO: root category
        // We try to request a child category. The parent category does not need to have been fetched right now (thanks to featured movies and stuff on the front page).
        // So we have the existing cat initialized, but without any movies.
        // As the API does not return subcategories via Id, we have to get parent category, to populate subcategories at this very point.
        isSubcategory = true;
    }
    var catIdToFetch = catId;
    if (isSubcategory) {
        catIdToFetch = existingCat.parent;
    }

    return Category.findOne({
        id: catIdToFetch,
    }).then(function (cat) {
        data.categoriesById[cat.id] = cat;
        data.categoriesBySlug[cat.slug] = cat;
        if (cat.posts) {
            cat.posts.forEach(function (movie) {
                data.moviesBySlug[movie.slug] = movie;
                data.moviesById[movie.id] = movie;
            });
        }
        if (isSubcategory) {
            cat.getSubCategories();
            if (
                data.categoriesById[catId] &&
                data.categoriesById[catId].posts &&
                data.categoriesById[catId].posts.length > 0
            ) {
                config.log(
                    "returning similarCategory (sub):",
                    data.categoriesById[catId]
                );
                return data.categoriesById[catId];
            }
        }
        config.log("returning similarCategory (main):", cat);

        return cat;
    });
}

function getWishlistText(onWishlist) {
    if (onWishlist) {
        return "Von " + config.my.wishlist.name + " entfernen";
    }
    return "Zur " + config.my.wishlist.name + " hinzufügen";
}

export default BaseControl.extend(
    "MoviePage",
    {
        defaults: {
            template: View,
        },
    },
    {
        init: function () {
            var self = this;

            /**
             * Navigating between multiple movie pages quickly might result in the map having the old values.
             * For kixi this can lead to errors when switching from audioContent to series/movies it will
             * try to render the audio player again, but with an incompatible model.
             * We found this by using throttling network, navigating to an audio and quickly
             * loading a series for the first time.
             * This requires that the series will be loaded asynchronously, creating an invalid state.
             */
            this.options.viewOptions = new can.Map({});
            // jQuery Deferreds do not allow returning a resolved
            // check whether user is allowed to use plus films and init page content
            state.currentUserPromise.then(
                function (user) {
                    if (user.isPlus) {
                        self.initContentWithCountryCheck({
                            loggedIn: true,
                            isPlus: true,
                        });
                    } else {
                        self.initContentWithCountryCheck({
                            loggedIn: true,
                            isPlus: false,
                        });
                    }
                },
                function () {
                    self.initContentWithCountryCheck({
                        loggedIn: false,
                        isPlus: false,
                    });
                }
            );
            // Always check for User Consent when loading the Movie page
            ogury.getUserConsent();
        },
        destroy: function onDestroy() {
            clearInterval(this.voucherInfoRefresher);
        },
        initContentWithCountryCheck: function (initContentData) {
            countryCodePromise.then(
                function (code) {
                    initContentData.countryCode = code;
                    this.initContent(initContentData);
                }.bind(this),
                function () {
                    initContentData.countryCode = "XX";
                    this.initContent(initContentData);
                }.bind(this)
            );
        },
        initContent: function (userInformation) {
            var movieSlug = (can.route.attr("id") || "").replace(/\.html$/, ""),
                movie = this.options.state.data.moviesBySlug[movieSlug];

            if (!movie || !movie.attr("categories")) {
                //damn, we gotta get it
                var loadMovie = function () {
                    movie = this.options.state.data.moviesBySlug[movieSlug]; // reload from cache

                    if (!movie) {
                        // audioContent & Co. are automatically returned, they use modelFactory
                        Movie.findOne({
                            slug: movieSlug,
                        }).then(
                            this.proxy(function (movie) {
                                if (!movie) {
                                    console.error("movie not found!: ", movie);
                                    can.route.attr({
                                        control: "404",
                                    });
                                } else {
                                    this.loadSeriesData(movie, userInformation);
                                }
                            }),
                            this.proxy(function (err) {
                                console.error(
                                    "movie could not get loaded!: ",
                                    err
                                );
                                can.route.attr({
                                    control: "404",
                                });
                            })
                        );
                    } else {
                        this.loadSeriesData(movie, userInformation);
                    }
                }.bind(this);

                // catSlug can also be "search" or something invalid, should not break site
                var catSlug = can.route.attr("control");
                getCat(catSlug, this.options.state.data, true).then(
                    loadMovie,
                    loadMovie
                );
            } else {
                this.loadSeriesData(movie, userInformation);
            }
        },
        loadSeriesData: function (movie, userInformation) {
            if (window.platform === "kixi") {
                var prepare = function () {
                    if (movie.type === "seriesContent") {
                        getCat(
                            movie.attr("seriesModel.id"),
                            this.options.state.data,
                            false
                        ).then(
                            this.proxy(function (data) {
                                if (!data.error) {
                                    this.options.viewOptions.attr({
                                        similarCat: data,
                                        selectedSeason: movie.attr(
                                            "seriesModel.seasons.0"
                                        ), // is now loaded
                                    });
                                }
                                this.showMovie(movie, userInformation);
                            }),
                            this.proxy(function (err) {
                                console.error("could not series", err);
                                this.showMovie(movie, userInformation); // show something
                            })
                        );
                    } else {
                        this.showMovie(movie, userInformation);
                    }
                }.bind(this);
                User.watchlistpromise.then(prepare, prepare);
            } else {
                // stay sync if we do not need the series support. Series requires the watchlist more strictly
                this.showMovie(movie, userInformation);
            }
        },

        showMovie: function (movie, userInformation) {
            var self = this,
                indexCats = this.options.state.data.categoriesById,
                relatedCatId,
                isSeries = movie.attr("type") === "seriesContent",
                partFromURL = (can.route.attr("subId") || "").replace(
                    /\.html$/,
                    ""
                );

            if (window.platform === "netzkino" && movie) {
                this.seo.setTitle(
                    movie.title,
                    " - Jetzt kostenlos ansehen | Netzkino"
                );
                this.seo.setDescription(movie.content);
                this.seo.setUrl("/filme/" + movie.slug);
            }

            if (isSeries) {
                relatedCatId = movie.attr("seriesModel.id");
            } else {
                movie.attr("categories").forEach(function (catId) {
                    if (
                        indexCats[catId] &&
                        !config.categoryTree.isRoot(indexCats[catId])
                    ) {
                        // root category can not be accessed directly by id
                        relatedCatId = catId;
                        return false;
                    }
                });
            }

            // if movie has YT_Delivery_Id AND YT_Delivery_Active.0 == '1', we want to show YT instead of avoplayer
            if (
                utils.getDeep(
                    movie,
                    "custom_fields.Youtube_Delivery_Active.0"
                ) == "1" &&
                utils.getDeep(movie, "custom_fields.Youtube_Delivery_Id.0") &&
                // we also only want to play the youtube video, if the youtube video is not only for preview purposes
                utils.getDeep(
                    movie,
                    "custom_fields.Youtube_Delivery_Preview_Only.0"
                ) != "1"
            ) {
                movie.attr("custom_fields.Youtube_Delivery_Active.0", true);
            } else if (movie.custom_fields.Youtube_Delivery_Active) {
                movie.attr("custom_fields.Youtube_Delivery_Active.0", false);
            }

            var defaultSelectedPart = movie.attr("partList")
                ? movie.attr("partList")[0]
                : null;
            if (movie.type === "seriesContent") {
                defaultSelectedPart = movie.attr(
                    "seriesModel.seasons.0.partList"
                )[0];
            }
            var latestWatchlistEntry = watchlist.getLatestEntry(movie);
            var partToLoad =
                partFromURL ||
                (latestWatchlistEntry && latestWatchlistEntry.part);
            if (partToLoad) {
                if (movie.type === "audioContent") {
                    defaultSelectedPart = movie
                        .attr("partList")
                        .filter(function (part) {
                            return part.number + "" === partToLoad + "";
                        })[0];
                } else if (movie.type === "seriesContent") {
                    defaultSelectedPart = movie
                        .attr("seriesModel")
                        .getPartByNumber(partToLoad);
                }
            }

            var isVoucherMovie = movie.hasMovieProperty("voucher-exclusive");
            var voucherInfo = isVoucherMovie && voucher.getInfo(movie);

            if (isVoucherMovie && voucherInfo.valid) {
                this.setupVoucherRefresher();
            }

            this.options.viewOptions.attr({
                geoBlock:
                    config.geoBlocking.enabled &&
                    movie.isGeoBlocked(userInformation.countryCode),
                voucherBlock: isVoucherMovie && !voucherInfo.valid,
                voucherInfo: voucherInfo,
                isValidVoucherMovie: isVoucherMovie && voucherInfo.valid,
                jsOrigin: location.protocol + "//" + location.host,
                movie: movie,
                config: config,
                missingPlus: movie.isPlusMovie() && !userInformation.isPlus,
                isPlus: userInformation.isPlus,
                isMobile: utils.isMobile(),
                isNetzkino: window.platform === "netzkino",
                isBronco: window.platform === "bronco",
                isGrjngo: window.platform === "grjngo",
                isIOS: utils.isIOS(),
                loggedIn: userInformation.loggedIn,
                showAdNoteForPlus:
                    window.platform === "netzkino" &&
                    userInformation.isPlus &&
                    !movie.hasMovieProperty("svod"),
                wishlistFirstText: getWishlistText(false),
                goToPlusPayment: this.goToPlusPayment.bind(this),
                selectedPart: defaultSelectedPart,
                nextPart: movie.attr("partList")
                    ? movie.attr("partList")[1]
                    : null,
                selectPartToPlay: function () {
                    // passing down function to other components requires wrapping
                    return this.selectPartToPlay.bind(this);
                }.bind(this),
                selectNextPartToPlay: this.selectNextPartToPlay.bind(this), // next part will autoplay if when the first stops
                selectNextEpisodeToPlay:
                    this.selectNextEpisodeToPlay.bind(this), // next episode will autoplay if when the first stops
                showVideoPlayer: movie.type !== "audioContent",
                selectSeason: function (season) {
                    this.attr("selectedSeason", season);
                },
            });
            if (relatedCatId) {
                getCat(relatedCatId, this.options.state.data, !isSeries).then(
                    this.proxy(function (data) {
                        if (!data.error) {
                            this.options.viewOptions.attr({
                                similarCat: data,
                                selectedSeason: movie.attr(
                                    "seriesModel.seasons.0"
                                ), // is now loaded
                            });
                        }
                    })
                );
            }

            if (config.my.enabled) {
                User.getWishlist().then(function () {
                    self.options.viewOptions.attr(
                        "wishlistFirstText",
                        getWishlistText(
                            state.attr("currentWishlist") &&
                                state
                                    .attr("currentWishlist")
                                    .indexOf(movie.id + "") > -1
                        )
                    ); // movie.id is an integer, while the list uses strings
                });
            }

            netletixRefresh.refresh();

            // check if this is the first time a user navigates to the moviepage after subscribing...
            // ... this navigation takes place in plus-payment component on my page where the user is redirected by the payment-service
            var firstPlus = Cookie.readCookie("firstPlus");
            if (firstPlus === "1" || firstPlus === "2") {
                Cookie.clearCookie("firstPlus");
                var notificationText = config.plus.welcomeMessage;
                swal(notificationText);
            }

            BaseControl.prototype.init.apply(this, arguments);
        },
        goToPlusPayment: function () {
            var now = new Date();

            var time = now.getTime();
            time += 60 * 60 * 1000;
            now.setTime(time);
            Cookie.createCookie(
                "previouslyWatchedMovie",
                this.options.viewOptions.attr("movie.slug"),
                now.toUTCString()
            );

            if (this.options.viewOptions.attr("loggedIn")) {
                Cookie.createCookie(
                    "directPaymentOffer",
                    "1",
                    now.toUTCString()
                );
                state.goTo("my", "plus");
            } else {
                state.goTo("my", "payment");
            }

            var movieInfo = Cookie.readCookie("movieToWishlist");
            if (movieInfo !== null) {
                var id = movieInfo.split(/\|(.+)/)[0];
                User.wishlistToggleForMovie(id + "").then(function (onList) {
                    // movie.id is an integer, while the list uses strings
                    $("#wishlist-toggle")
                        .removeClass("btn-loading")
                        .button("reset")
                        .text(getWishlistText(onList));
                });
                Cookie.clearCookie("movieToWishlist");
            }

            netletixRefresh.refresh();

            BaseControl.prototype.init.apply(this, arguments);
        },
        setupVoucherRefresher: function setupVoucherRefresher() {
            // refresh the voucher info every few minutes. Refresh time left and reload if the movie is no longer viewable
            clearInterval(this.voucherInfoRefresher);
            this.voucherInfoRefresher = setInterval(
                function voucherInfoRefresher() {
                    var newVoucherInfo = voucher.getInfo(
                        this.options.viewOptions.attr("movie")
                    );
                    this.options.viewOptions.attr(
                        "voucherInfo",
                        newVoucherInfo
                    );
                    if (!newVoucherInfo.valid) {
                        window.location.reload(); // time's up
                    }
                }.bind(this),
                1 * 1000
            );
        },
        selectPartToPlay: function (part) {
            var viewOptions = this.options.viewOptions;
            viewOptions.attr("selectedPart", part);
            can.$(window).scrollTop(0);
            // if(part.movie) { // is series
            //     viewOptions.attr('movie', part.source);
            // }
        },
        selectNextPartToPlay: function () {
            // called on part end
            var viewOptions = this.options.viewOptions;
            var currentPartNumber = viewOptions.attr("selectedPart.number");
            var parts = viewOptions.attr("movie.partList");
            var next = parts.filter(function (part) {
                return part.number === currentPartNumber + 1;
            })[0];
            if (next) {
                can.$(window).scrollTop(0);
                viewOptions.attr("selectedPart", next);
            }
        },
        selectNextEpisodeToPlay: function () {
            // called on part end
            var viewOptions = this.options.viewOptions;
            var selectedPart = viewOptions.attr("selectedPart");
            var model = selectedPart.attr("source");
            var series = model.attr("seriesModel");

            var sortedParts = series.attr("allParts"); // season does not matter
            var currentIndex;
            sortedParts.forEach(function (part, index) {
                if (currentIndex !== undefined) {
                    return;
                }
                if (part.identifier === selectedPart.identifier) {
                    currentIndex = index;
                }
            });
            var next;
            if (currentIndex >= 0) {
                next = sortedParts.attr(currentIndex + 1);
            }

            if (next) {
                // if the end of the series is reached, do nothing
                can.$(window).scrollTop(0);
                viewOptions.attr("selectedPart", next);
            }
        },
        ".plusAdContainerBelow .goToOffer click": function ($el, ev) {
            ev.preventDefault();
            this.goToPlusPayment();
        },
        "#offer-btn click": function ($el, ev) {
            ev.preventDefault();
            this.goToPlusPayment();
        },
        "#wishlist-toggle click": function ($el, ev) {
            ev.preventDefault();

            if (!this.options.viewOptions.attr("loggedIn")) {
                Cookie.createCookie(
                    "movieToWishlist",
                    this.options.viewOptions.attr("movie").id +
                        "|" +
                        this.options.viewOptions.attr("movie").slug
                );
                if (window.platform === "kixi") {
                    state.goTo("my", "payment");
                } else {
                    state.goTo("my", "register");
                }
            } else if (!$el.hasClass("btn-loading")) {
                $el.addClass("btn-loading").button("loading");
                var movieId = this.options.viewOptions.attr("movie").id + ""; // movie.id is an integer, while the list uses strings
                User.wishlistToggleForMovie(movieId).then(function () {
                    var onWishlist =
                        state.attr("currentWishlist") &&
                        state.attr("currentWishlist").indexOf(movieId) > -1;
                    $el.removeClass("btn-loading")
                        .button("reset")
                        .text(getWishlistText(onWishlist));
                });
            }
        },
        "#remove-ad click": function ($el, ev) {
            ev.preventDefault();

            if (this.options.viewOptions.attr("loggedIn")) {
                // var now = new Date();
                // var time = now.getTime();
                // time += 60 * 60 * 1000;
                // now.setTime(time);
                // Cookie.createCookie('directPaymentOffer', '1', now.toUTCString());
                state.goTo("my", "plus");
            } else {
                state.goTo("my", "payment");
            }
        },
        "avoplayer play": function ($el, ev, payload) {
            // this code might never be relevant as the coupons are supposed to have an expiration once the coupon is validated
            // this implementents that the code will get an expiration once the user starts the movie.

            // sometimes the main movie starts before the ad, we give it some time
            if (
                !payload.isAd &&
                !this.options.viewOptions.attr("voucherInfo.validUntil")
            ) {
                var movie = this.options.viewOptions.attr("movie");

                // basically like a debounce for 5000 seconds
                this._nonAdVideoPlayback = setTimeout(function () {
                    voucher.setExpirationInHours(
                        movie,
                        config.vouchers.validFor
                    );
                }, 5000);
            } else if (payload.isAd) {
                // if the ad starts running, do not set the voucher expiration
                clearTimeout(this._nonAdVideoPlayback);
            }
        },
    }
);
