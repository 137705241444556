<header class="navbar navbar-fixed-top" id="top" role="banner">
  <div class="container">
    <div class="navbar-header">
      <button class="navbar-toggle collapsed" type="button" data-toggle="collapse" data-target=".bs-navbar-collapse">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a href="#" class="navbar-brand">
        <img class="hidden-sm" src="{{#if isPlus}}{{imagePath config.plus.headerLogoImagePath}}{{else}}{{imagePath 'webseite/logo_header_small.png'}}{{/if}}" alt="{{config.general.name}} Logo">
        <img class="hidden-lg hidden-md hidden-xs" src="{{imagePath 'webseite/logo.png'}}" alt="{{config.general.name}} Logo">
      </a>

      {{#if isKixi}}
      <a href="https://www.fragfinn.de/" class="navbar-brand fragFinn">
        <img class="hidden-sm fragFinn" src="{{imagePath 'webseite/fragfinn.png'}}" alt="Kindersuchmaschine fragFINN">
        <img class="hidden-lg hidden-md hidden-xs fragFinn" src="{{imagePath 'webseite/fragfinn.png'}}" alt="Kindersuchmaschine fragFINN">
      </a>
      {{/if}}

    </div>
    <nav class="collapse navbar-collapse bs-navbar-collapse newVersion" role="navigation">
      <ul class="nav navbar-nav navbar-right">

        {{#if config.plus.enabled}}
            {{#if config.plus.infoPage.show}}
              {{#unless isPlus}}
                <li class="dropdown">
                    <a href="#!/plus" id="nkPlus" style="padding: 13px 0;" class="plusDropDownLink" data-toggle="collapse" data-target=".bs-navbar-collapse.in">
                      <i class="nav-icon" id="plus-icon"></i><span class="nav-text">{{config.plus.title}}</span>
                    </a>
                </li>
              {{/unless}}
            {{/if}}
        {{/if}}

        {{#if config.header.categories.enabled}}
            {{#switch config.header.categories.displayType}}
              {{#case 'dropdown'}}
                <li class="dropdown">
                  <button class="dropdown-toggle mainMenuItem" type="button" id="nkCategories" data-toggle="dropdown">
                    <i class="nav-icon" id="genre-icon"></i><span class="nav-text">{{config.header.categories.label}}</span>
                  </button>
                  <ul class="dropdown-menu dropdown-categories" role="menu" aria-labelledby="nkCategories">
                    {{#each genreCategories}}
                      <li role="presentation">
                        <a role="menuitem" tabindex="-1" href="#!/{{#if shouldUsePaywallPlusCategoryLinks}}plus/{{/if}}{{slug}}" data-toggle="collapse" data-target=".bs-navbar-collapse.in">
                            {{#if config.header.categories.icons}}<img src="{{imagePath config.header.categories.iconPath}}{{id}}.png" height="28" class="catIcons">{{/if}}
                            {{title}}
                        </a>
                      </li>
                    {{/each}}
                  </ul>
                </li>
              {{/case}}
              {{#case 'link'}}
                {{#each genreCategories}}
                  <li>
                    <a role="menuitem" href="#!/{{#if shouldUsePaywallPlusCategoryLinks}}plus/{{/if}}{{slug}}">
                        {{#if config.header.categories.icons}}<img src="{{imagePath config.header.categories.iconPath}}{{id}}.png" height="28" class="catIcons">{{/if}}
                        {{title}}
                    </a>
                  </li>
                {{/each}}
              {{/case}}
              {{#case 'fixed'}}
                {{#each config.header.categories.overrides}}
                  <li>
                    <a role="menuitem" href="{{href}}" class="categoryLinkOverride {{class}}">
                        {{#if faIcon}}
                          <i class="fa fa-{{faIcon}}"></i>&nbsp;
                        {{/if}}
                        {{title}}
                    </a>
                  </li>
                {{/each}}
              {{/case}}
            {{/switch}}

        {{/if}}
        {{#if config.header.apps.show}}
            <li class="dropdown">
              <button class="dropdown-toggle" type="button" id="nkApps" data-toggle="dropdown">
                <i class="nav-icon" id="apps-icon"></i><span class="nav-text">Apps</span>
              </button>
              <ul class="dropdown-menu" role="menu" aria-labelledby="nkApps">
                {{#each config.header.apps.buttons}}
                    <li role="presentation">
                    {{#if isKixi}}
                      <a role="menuitem" target="_blank" href="{{link}}" data-toggle="collapse" data-target=".bs-navbar-collapse.in">
                        <img src="{{imagePath img}}" class="visible-gt-992" alt="{{label}}">
                        <span class="visible-lt-992">{{label}}</span>
                      </a>
                    {{/else}}
                      <a role="menuitem" target="_blank" href="{{link}}" data-toggle="collapse" data-target=".bs-navbar-collapse.in">
                        <img src="{{imagePath img}}" class="visible-gt-768" alt="{{label}}">
                        <span class="visible-lt-768">{{label}}</span>
                      </a>
                    {{/if}}
                    </li>
                {{/each}}
              </ul>
            </li>
        {{/if}}

        {{#if config.header.info.show}}
            <li class="dropdown">
              <button class="dropdown-toggle" type="button" id="nkInfo" data-toggle="dropdown">
                {{#unless config.header.info.faIcon}}
                  <i class="nav-icon" id="info-icon"></i>
                {{/if}}
                <span class="nav-text">
                  {{#if config.header.info.faIcon}}
                    <i class="fa fa-{{config.header.info.faIcon}}"></i>&nbsp;
                  {{/if}}
                  {{config.header.info.label}}
                </span>
              </button>
              <ul class="dropdown-menu" role="menu" aria-labelledby="nkInfo">
                {{#each config.header.info.links}}
                  <li role="presentation">
                      <a role="menuitem" href="{{link}}" {{#if blank}}target="_blank"{{/if}} data-toggle="collapse" data-target=".bs-navbar-collapse.in">{{label}}</a>
                  </li>
                {{/each}}
              </ul>
            </li>
        {{/if}}

        {{#if enableSearch}}
          {{#ifAny config.my.enabled {#isEqual config.general.name 'Bronco'}}}
              <li class="searchboxWrapper noInput">
                <div id="collapsable-search" class="collapse">
                  <input type="form-control search" placeholder="Suche" can-enter="search" />
                </div>
                <a href="#collapsable-search" data-toggle="collapse">
                  {{#unless config.search.faIcon}}
                    <i class="nav-icon search-icon" id="search-icon"></i>
                  {{/unless}}
                  <span class="nav-text" style="margin-right: 20px;">
                    {{#if config.search.faIcon}}
                      <i class="fa fa-{{config.search.faIcon}}"></i>&nbsp;
                    {{/if}}
                    {{config.search.menuLabel}}
                  </span>
                </a>
              </li>
            {{else}}
              <li class="searchboxWrapper">
                <input class="search" type="form-control search" placeholder="Suche" can-enter="search" />
  -                <i class="nav-icon search-icon" id="search-icon" can-click="search"></i>
              </li>
          {{/ifAny}}
        {{/if}}

              {{#if config.my.smartLogin.enabled}}{{#unless state.isHospital}}
                {{#if config.my.smartLogin.showHeaderButton}}
                    <li>
                        <button class="btn btn-big {{config.my.header.buttonClass}} my-btn btn-magenta" can-click="navigateToSmartlogin">
                            <span>MagentaTV</span>
                        </button>
                    </li>
                                <li>
                        <button class="btn btn-big {{config.my.header.buttonClass}} my-btn btn-yellow" can-click="navigateToSmartlogin">
                            <span>SmartTV</span>
                        </button>
                    </li>
                {{/if}}
            {{/unless}}{{/if}}

        {{#if config.my.enabled}}
          <li class="dropdown" style="margin-right: 0px;" {{#if isLoveisawonder}}style="margin-right: 25px;"{{/if}}>
            {{#state.currentUser}}
              {{#if config.my.header.isLoggedInButton}}
                <button class="btn btn-primary btn-big {{config.my.header.buttonClass}} my-btn" can-click="navigateToMy">
                  <span>{{config.my.title}}</span>
                </button>
              {{else}}
                <button class="dropdown-toggle" can-click="navigateToMy">
                  <i class="nav-icon" id="my-icon"></i><span class="nav-text">{{config.my.title}}</span>
                </button>
              {{/if}}
            {{/state.currentUser}}
            {{^state.currentUser}}
              <button class="btn btn-primary btn-big {{config.my.header.buttonClass}} my-btn" can-click="navigateToMy">
                <span>{{config.my.header.loginLabel}}</span>
              </button>
            {{/state.currentUser}}
          </li>
        {{/if}}
        {{#if isLoveisawonder}}
            <li class="dropdown" style="margin-right: 5px;">
                {{^state.currentUser}}
                <button class="btn btn-primary btn-big {{config.my.header.buttonClass}} my-btn" can-click="navigateToRegister">
                    <span>Jetzt Registrieren</span>
                </button>
                {{/state.currentUser}}
          </li>
        {{/if}}
      </ul>
    </nav>
  </div>
</header>
