<div class="navPlaceholder"></div>

<netzkino-title-social-bar cat="Datenschutz" show-text="false"></netzkino-title-social-bar>

<br>
<div class="container subpage-container">
    <div class="row">
        <div class="col-xs-12 autocountHeaders">
            <h2 class="main-section-headline">Datenschutzerklärung {{config.general.companyShort}}</h2>


    <h3>Kontaktdaten des Verantwortlichen und des Datenschutzbeauftragten</h3>
<p>
    Die {{config.general.company}} (nachfolgend auch „{{config.general.companyShort}}“, “wir” oder “uns”
    genannt) bietet Dir die Möglichkeit, verschiedene Dienste unserer Website
    {{config.general.websiteShort}} und unserer Applikationen (“Apps”) (nachfolgend gemeinsam
    “Angebot” oder {{config.general.companyShort}}-Seiten“) kostenfrei zu nutzen.
</p>
<p>
    Wir sind die Verantwortliche im Sinne der Datenschutz-Grundverordnung
    (DSGVO). Unsere Kontaktdaten sind:
</p>
<p class="address">
    {{{config.general.address}}}
</p>
<p>
    Unser Datenschutzbeauftragter ist erreichbar unter:
</p>
<p class="address">
    {{{config.general.privacyContact}}}
</p>
<p>
    Nachstehend informieren wir Dich ausführlich darüber, zu welchem Zweck und
    in welchem Umfang wir im Zuge der Nutzung der {{config.general.companyShort}}-Seiten Deine
    personenbezogenen Daten verarbeiten.
</p>

    <h3>Erhebung und Verarbeitung personenbezogener Daten</h3>
<p>
    In der Regel kannst Du die {{config.general.companyShort}}-Seiten, für die keine Bezahlung oder
    Registrierung erforderlich ist, ohne Angabe personenbezogener Daten nutzen.
    In bestimmten Fällen verarbeiten wir jedoch die in Ziffer 3 aufgelisteten
    personenbezogenen Daten. Dies geschieht grundsätzlich nur, soweit dies zur
    Bereitstellung einer funktionsfähigen Website bzw. App sowie unserer
    Inhalte und Leistungen erforderlich ist. Ferner verarbeiten wir
    personenbezogene Daten im Zusammenhang mit der Nutzung von {{config.general.companyShort}}, wenn
    Du diese von Dir aus angibst, z. B. im Rahmen einer Registrierung, eines
    Gewinnspiels, einer Anfrage an uns, oder weil hierfür eine andere
    Rechtsgrundlage vorliegt (siehe Ziffer 4).
</p>

    <h3>Kategorien verarbeiteter Daten</h3>
<p>
    Sobald Du {{config.general.websiteShort}} besuchst oder eine der Apps nutzt, erfasst unser
    System automatisiert bestimmte technische Informationen. Hierzu können
    gehören:
</p>
<ul>
    <li>
        Informationen über den Browsertyp und die verwendete Version
    </li>
    <li>
        Betriebssystem des anfragen Geräts
    </li>
    <li>
        Mobile Device ID
    </li>
    <li>
        Datum und Uhrzeit des Zugriffs
    </li>
    <li>
        Webanalysedaten / pseudonyme Nutzungsprofile (Cookie ID, Ad ID etc.,
        mehr hierzu siehe unten)
    </li>
    <li>
        Websites, von denen der Nutzer auf unsere Website gelangt
    </li>
    <li>
        Websites, die der Nutzer über unsere Website aufruft
    </li>
</ul>
<p>
    Darüber hinaus verarbeiten wir die folgenden personenbezogenen Daten,
    sofern ein Vertragsverhältnis zwischen Dir und uns besteht oder Du die
    Daten anderweitig an uns übermittelt hast:
</p>
<ul>
    <li>
        Personenstammdaten (Name, Adresse, Geburtsdatum)
    </li>
    <li>
        Kommunikationsdaten (Telefonnummer, E-Mail-Adresse)
    </li>
    <li>
        Vertragsstammdaten (Vertragsbeziehung, Produkt- bzw. Vertragsinteresse,
        Vertragshistorie)
    </li>
    <li>
        Login Daten mit Passwort
    </li>
    <li>
        Abrechnungs- und Zahlungsdaten
    </li>
    <li>
        Kommentare, Beiträge u.ä.
    </li>
</ul>

    <h3>Anlass, Inhalt und Zwecke der Verarbeitung: Die {{config.general.companyShort}}-Seiten und -Dienste generell</h3>
<p>
    Wir verarbeiten Deine Daten stets auf der Grundlage eines oder mehrerer
    gesetzlicher Erlaubnistatbestände oder Deiner Einwilligung.
</p>

    <h4>Abgabe von Kommentaren</h4>
<p>
    Personenbezogene Daten werden von {{config.general.companyShort}} erhoben, wenn Du zu einem Film
    einen Kommentar abgibst. In diesem Zusammenhang erhebt {{config.general.companyShort}} die in dem
    jeweiligen Formular angegebenen Daten und die dem von Dir genutzten Gerät
    zum betreffenden Zeitpunkt zugewiesene IP-Adresse. Angaben zu Dir werden
    nur auf den betreffenden {{config.general.companyShort}}-Seiten und gegebenenfalls Partnerseiten
    (zum Beispiel Facebook) veröffentlicht, wenn dies im Formularfeld so
    gekennzeichnet ist. Wir erheben und verarbeiten die von Dir angegebenen
    Daten, um Deine Bewertung oder Deinen Kommentar wunschgemäß veröffentlichen
    zu können (Art. 6 Abs. 1 lit. b DSGVO). Insbesondere benötigen wir hierbei
    auch Deine E-Mail-Adresse, um Dich im Falle von Beanstandungen kontaktieren
    und Dir die Möglichkeit zur Stellungnahme geben zu können (Art. 6 Abs. 1
    lit. c DSGVO).
</p>

    <h4>{{config.general.name}} Konto und {{config.general.name}} Premium</h4>
<p>
    Auf der Grundlage von Art. 6 Abs. 1 lit. b) DSGVO erheben wir
    personenbezogene Daten, wenn Du ein Konto zur Nutzung von {{config.general.name}} Konto
    anlegst und das Abonnement {{config.general.name}} Premium abschließt. {{config.general.companyShort}} erhebt diese
    Daten zur Vertragsanbahnung und Abwicklung Deines Kundenkontos und
    Abonnement.<br>
    {{config.general.companyShort}} übermittelt die personenbezogenen Daten an den jeweiligen
    Zahlungsdienstleister, soweit dies für den Abschluss und die Abwicklung
    Deines Vertrages erforderlich ist. Wenn {{config.general.companyShort}} für die Durchführung und
    Abwicklung des Vertrages bzw. der gebuchten Leistungen Dritte
    beispielsweise für die Durchführung der Zahlungsabwicklung als
    Dienstleister in Anspruch nimmt (im Folgenden: “Leistungserbringer”),
    werden die erforderlichen Daten nur zur Erfüllung und Abwicklung Deiner
    Bestellung an diese Dritten übermittelt. Im Rahmen der Abwicklung Deiner
    Bestellung bzw. Buchung erhältst Du in der Folge auch E-Mails an die von
    Dir angegebene E-Mail-Adresse vom jeweiligen Leistungserbringer (z.B.
    Zahlungsbestätigung oder Informationen zur Buchung).
</p>

    <h4>Formulare und Zahlungsdienste</h4>
<p>
    Soweit nicht anders gekennzeichnet, erhebt {{config.general.companyShort}} Deine in die jeweiligen
    Kontaktformulare eingegebenen Daten selbst. Wir weisen darauf hin, dass die
    Datenübertragung über das Internet unter Umständen ungesichert erfolgt und
    Daten somit unter Umständen von Unbefugten zur Kenntnis genommen oder auch
    verfälscht werden können.
</p>
<p>
    Gibst Du Daten in Kontaktformulare ein, werden wir die Daten nur dafür
    verwenden, die Kommunikation mit Dir zu führen und/oder die von Dir
    erwünschte Leistung zu erbringen; hierin liegt unser berechtigtes
    Interesse. Die Verarbeitung erfolgt aufgrund von Art. 6 Abs. 1 lit. b)
    DSGVO.
</p>
<p>
    {{config.general.companyShort}} integriert teilweise mit originären Inhalten von Partnern,
    beispielsweise für die Konto-Anmeldung über Facebook. In diesem Fall
    erfolgt die Erhebung der Daten, die Du eingibst, nicht durch uns, sondern
    durch den jeweiligen Partner. Die Partner werden auf den jeweiligen
    {{config.general.companyShort}}-Seiten genannt. Die Partner haben gegenüber uns den
    datenschutzkonformen Umgang mit Deinen Daten bestätigt. Gleichwohl hat
    {{config.general.companyShort}} keinen Einfluss auf die Erhebung, Verarbeitung und Nutzung Deiner
    Daten durch die Partner. Für Informationen, ob und in welchem Umfang der
    Partner Deine personenbezogenen Daten erhebt und wozu er diese verwendet,
    lies bitte die Datenschutzerklärung des jeweiligen Partners, die über die
    entsprechende {{config.general.companyShort}}-Seite bzw. den Inhalt des Partners zugänglich ist
    (siehe unten). Insbesondere Deine Zahlungsdaten werden nicht von {{config.general.companyShort}}
    selbst, sondern einem von uns beauftragten, auf Kartensicherheit
    spezialisierten Unternehmen verarbeitet und an den Zahlungsdienstanbieter
    weitergeleitet.
</p>

    <h4>E-Mails und Newsletter</h4>
<p>
    Deine im Zuge der Registrierung oder im Rahmen der Vertragsdurchführung
    erhobene E-Mail-Adresse können wir verwenden, um Dich per E-Mail über Waren
    und Dienstleistungen, Deine Filme und Buchungen oder generell über {{config.general.companyShort}}
    zu informieren. Die Verarbeitung der E-Mail-Adresse erfolgt in diesem Fall
    auf Grundlage unseres berechtigten Interesses an der Bewerbung unserer
    Waren und Dienstleistungen (Art. 6 Abs. 1 lit. f DSGVO).
</p>
<p>
    Darüber hinaus nutzen wir Deine E-Mail-Adresse, um Dir unseren Newsletter
    zuzusenden, wenn Du diesen bestellt hast und uns Deine entsprechende
    Einwilligung in den Erhalt des Newsletters mit ggf. werblichen Inhalten
    erteilt hast. In diesem Fall verarbeiten wir Deine E-Mail-Adresse, um Dir
    wunschgemäß den Newsletter zustellen zu können (Art. 6 Abs. 1 lit. b
    DSGVO). Der Verwendung Deiner E-Mail-Adresse für solche Zwecke kannst Du
jederzeit schriftlich oder in Textform an    <a href="mailto:{{config.general.newsletter}}">{{config.general.newsletter}}</a> mit
    Wirkung für die Zukunft widersprechen, ohne dass hierfür andere als die
    Übermittlungskosten nach den Basistarifen entstehen.
</p>
<p>
    Für unsere Newsletter wählen wir die Inhalte zudem teilweise auf der
    Grundlage deiner vorherigen Nutzung der {{config.general.companyShort}}-Seiten und Deinen dabei
    zum Ausdruck gekommenen Interessen und Produkten und Inhalten aus, damit
    wir den jeweiligen Newsletter bestmöglich auf Dich und Deine Interessen hin
    individualisieren können. Hast du auf den {{config.general.companyShort}}-Seiten zum Beispiel in
    jüngerer Zeit nach Science-Fiction-Filmen gesucht, möchten wir Dir in
    unserem Newsletter eher neue Science-Fiction-Filme vorstellen als andere
    Kategorien. Zu diesem Zweck verarbeiten wir auch Daten, die wir mit Hilfe
    unserer Cookies erhoben haben (zu diesen Cookies siehe unten Ziffer 9 c.),
    und verknüpfen diese mit Deiner E-Mail-Adresse. Rechtsgrundlage für diese
    Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, wobei Du der
    Datenverarbeitung auch jederzeit widersprechen kannst (vgl. unten Ziffer 16
    a.).
</p>

    <h4>IP-Adressen</h4>
<p>
    Im Internet benötigt jedes Gerät zur Übertragung von Daten eine eindeutige
    Adresse, die sogenannte IP-Adresse. Die zumindest vorübergehende
    Speicherung der IP-Adresse ist technisch erforderlich, um eine Auslieferung
    der Internetseite wie auch von App-Inhalten an das Gerät des Nutzers zu
    ermöglichen.
</p>
<p>
    <strong>aa) Logfiles</strong>
</p>
<p>
    Unsere Server speichern die IP-Adresse in sog. Logfiles für 14 Tage zu
    eigenen Sicherheitszwecken, zum Beispiel um nachvollziehen zu können, wenn
    es einen Angriff (DoS) auf {{config.general.companyShort}}-Seiten gibt oder ob unsere Datenbanken
    illegal ausgewertet werden. Grundlage hierfür ist Art. 6 Abs. 1 lit. f
    DSGVO.
</p>
<p>
    <strong>bb) Geolokalisierung u.a.</strong>
</p>
<p>
    Vor jeglicher weiteren bzw. anderen Verarbeitung kürzen wir die IP-Adressen
    vor jeglicher Verarbeitung und verarbeiten diese dann nur anonymisiert
    weiter. Es erfolgt keine Speicherung oder weitere Verarbeitung ungekürzter
    IP-Adressen.
</p>
<p>
    Durch die Verarbeitung der (so gekürzten) IP-Adressen können wir auf allen
    {{config.general.companyShort}}-Seiten, die aus einer bestimmten Region abgerufen werden, Inhalte
    mit regionalem Bezug anzeigen. Diese sogenannte Geo-Lokalisierung, also die
    Zuordnung eines Websiteaufrufs zum Ort des Aufrufs, erfolgt ausschließlich
    auf der Grundlage der anonymisierten IP-Adresse und nur bis zur
    geographischen Ebene der Bundesländer / Regionen. Aus den so gewonnenen
    geographischen Informationen kann in keinem Fall ein Rückschluss auf den
    konkreten Aufenthaltsort eines Nutzers gezogen werden.
</p>

    <h3>
            Anlass, Inhalt und Zwecke der Verarbeitung: Kundenkonto
            “{{config.general.name}} Konto”
    </h3>
<p>
    {{config.general.companyShort}} bietet Dir die Möglichkeit, Dir ein kostenloses Kundenkonto
    anzulegen. Um das „{{config.general.name}} Konto“-Kundenkonto nutzen zu können, musst Du
    Dich mit einer gültigen E-Mail-Adresse und einem Passwort registrieren.
    Diese Daten verwenden wir, um Dir das Nutzerkonto bereitzustellen, dies zu
    verwalten und seine Nutzung zu ermöglichen. Die Verarbeitung beruht hier
    auf Art. 6 Abs. 1 lit. b DSGVO.
</p>
<p>
    Bei der Registrierung für das „{{config.general.name}} Konto" Kundenkonto willigst Du ein,
    dass {{config.general.companyShort}} mittels sogenannter Cookies Angaben zu Deinem
    Nutzungsverhalten auf {{config.general.companyShort}} erhebt und zum Zweck der personalisierten
    Ansprache per E-Mail mit Deinem Kundenkonto verknüpft. Nutzungsdaten werden
    erhoben und gespeichert, wenn Du Filme suchst, ansiehst und auf der
    Watchlist merkst. {{config.general.companyShort}} leitet auf Basis Deines Nutzungsverhaltens
    potenzielle Interessen ab, um Inhalte speziell für Dich auszuwählen und auf
    den {{config.general.companyShort}}-Seiten anzeigen zu können und Dir damit den vollen
    Funktionsumfang des „{{config.general.name}} Konto“-Kundenkontos bieten zu können. Die
    Verarbeitung und insbesondere die Verknüpfung beruhen auf Art. 6 Abs. 1
    lit. a DSGVO.
</p>
<p>
    Auf der Basis dieser Daten senden wir Dir zudem produkt- und
    {{config.general.companyShort}}-bezogene Nachrichten, bei denen wir davon ausgehen, dass sie Dich
    interessieren, an die von uns gespeicherte E-Mail-Adresse Deines
    Kundenkontos. Mit Hilfe von produkt- und {{config.general.companyShort}}-bezogenen Nachrichten
    unterstützen wir Dich dabei, die für Dich passenden Filme und Angebote zu
    finden und gegebenenfalls zu buchen. Bei produktbezogenen Nachrichten
    handelt es sich um Produktempfehlungen und Preisinformationen. So
    benachrichtigen wir Dich, wenn von Dir gesuchte oder angesehene Filme im
    Abonnement oder kostenlos verfügbar sind. Auch zu Deinen gemerkten Filmen
    („Watchlist“) informieren wir Dich im Fall von Veränderungen (zum Beispiel
    die vorübergehende kostenlose Verfügbarkeit). {{config.general.companyShort}}-bezogene Nachrichten
    beziehen sich auf Aktionen und Neuigkeiten wie z.B. Filmaktionen,
    Gutscheinaktionen und Gewinnspiele, über die wir Dich per E-Mail
    informieren. Darüber hinaus dienen {{config.general.companyShort}}-bezogene Nachrichten dazu, Dir
    Informationen zu neuen Funktionen und Nutzungsmöglichkeiten von {{config.general.companyShort}}
    und Deinem {{config.general.companyShort}} Kundenkonto zukommen zu lassen. Das Versenden der
    E-Mails ist als Direktwerbung und/oder als Information über {{config.general.companyShort}} in
    unserem berechtigten Interesse und findet seine Rechtfertigung in Art. 6
    Abs. 1 lit. f DSGVO.
</p>
<p>
    Das „Mein {{config.general.companyShort}}“ Kundenkonto bietet Dir neben den beschriebenen
    Leistungen noch weitere Funktionen, wie nachfolgend beschrieben:
</p>

    <h4>Watchlist</h4>
<p>
    Du kannst Dir auf {{config.general.companyShort}} Filme „merken“, die Dir dann auf Deiner
    „Watchlist“ zur Verfügung stehen. Dabei wirst Du mit Hilfe von eingesetzten
    Cookies als Inhaber Deiner Watchlist identifiziert. Über die Watchlist
    kannst Du Filme schneller wiederfinden. Auf Basis Deiner Watchlisteinträge
    erhältst Du Film- und Produktempfehlungen auf der {{config.general.companyShort}}-Webseite oder
    per E-Mail, beispielsweise wenn sich die Verfügbarkeit der gemerkten Filme
    ändern.
</p>
<p>
    Rechtsgrundlage ist Art. 6 Abs. 1 lit. b DSGVO.
</p>

    <h4>Kontoeinstellungen</h4>
<p>
    In den Einstellungen Deines Kundenkontos kannst Du Dein Passwort ändern
    oder Dein Kundenkonto löschen.
</p>

    <h3>Ort der Verarbeitung</h3>
<p>
    Wir haben nicht die Absicht, Deine personenbezogene Daten an ein Drittland
    oder eine internationale Organisation zu übermitteln. Ändert sich dies
    informieren wir Dich entsprechend der gesetzlichen Vorgaben. Ob Dritte, mit
    denen Du eine eigene Vertragsbeziehung hast (wie z. B. mit Facebook, falls
    Du einen Facebook-Account hast) Daten in Länder außerhalb des Europäischen
    Wirtschaftsraums übertragen, entzieht sich unserer Kenntnis und unserem
    Einfluss.
</p>

    <h3>Herkunft der Daten</h3>
<p>
    In bestimmten Fällen erhalten wir auch Daten, weil Du in die Übermittlung
    an uns eingewilligt hast.
</p>
<p>
    Wie Du weißt, werden Apps regelmäßig auf den Seiten dritter Anbieter (wie
    iTunes, Google, SmartTV-Store etc.) zum Herunterladen bereitgestellt. Wenn
    nach den anwendbaren Nutzungsbedingungen eines solchen Anbieters die
    {{config.general.companyShort}} Service GmbH Dein Vertragspartner für den Erwerb der App wird,
    verarbeiten wir die Daten, die uns der Drittanbieter zur Verfügung stellt,
    in dem zur Vertragserfüllung erforderlichen Umfang, damit Du die App auf
    Dein mobiles Endgerät herunterladen kannst.
</p>

    <h3>Weitergabe Deiner Daten an Dritte</h3>
<p>
    Wir übermitteln Deine personenbezogenen Daten nur dann an Dritte, wenn die
    Übermittlung erforderlich ist, um unsere rechtlichen Pflichten Dir
    gegenüber zu erfüllen und dies ersichtlich bei oder gemeinsam mit einem
    anderen Anbieter erfolgt (z. B. bei Kooperationen), wir zur Weitergabe in
    sonstiger Weise gesetzlich berechtigt oder verpflichtet sind, oder Du uns
    eine entsprechende Einwilligung erteilt hast.
</p>
<p>
    In bestimmten Fällen setzen wir auch externe Dienstleister oder verbundene
    Unternehmen ein, die von uns beauftragt sind, Daten für uns
    weisungsgebunden zu verarbeiten. Solche Dienstleister werden von uns nach
    den strengen Vorgaben der DSGVO vertraglich als Auftragsverarbeiter
    verpflichtet und dürfen Deine Daten zu keinen anderen Zwecken
    weiterverwenden. Von uns eingesetzte Auftragsverarbeiter erbringen für uns
    insbesondere die folgenden Dienstleistungen: Newsletterversand und
    Web/Appanalyse.
</p>
<p>
    Die Weitergabe von Daten an Auftragsverarbeiter erfolgt auf Grundlage von
    Art. 28 Abs. 1 DSGVO, hilfsweise auf Grundlage unseres berechtigten
    Interesses an den mit dem Einsatz von spezialisierten Auftragsverarbeitern
    verbundenen wirtschaftlichen und technischen Vorteilen, Art. 6 Abs. 1 lit.
    f DSGVO.
</p>
<p>
    Sofern wir gesetzlich dazu verpflichtet sind oder dies datenschutzrechtlich
    erlaubt ist, übermitteln wir personenbezogene Daten an Behörden, zum
    Beispiel die Polizei oder Staatsanwaltschaft (Art. 6 Abs. 1 lit. c DSGVO).
    Die Weitergabe dieser Daten erfolgt auf Grundlage unseres berechtigten
    Interesses an der Bekämpfung von Missbrauch, der Verfolgung von Straftaten
    und der Sicherung, Geltendmachung und Durchsetzung von Ansprüchen und dass
    Deine Rechte und Interessen am Schutz Deiner personenbezogenen Daten nicht
    überwiegen, Art. 6 Abs. 1 lit. f DSGVO.
</p>

    <h3>Cookies und andere Technologien</h3>

    <h4>Allgemeines</h4>
<p>
    Um die volle Funktionalität der {{config.general.companyShort}}-Seiten zu gewährleisten,
    speichern {{config.general.companyShort}} und unten genannte Dritte auf Deinem Endgerät Daten, die
    unter anderem zur Erfassung von Informationen bezüglich der Nutzung einer
    Webseite oder App dienen, sowie weitere Daten wie die IP-Adresse des
    zugreifenden Rechners und Informationen über die zugreifende Software.
</p>
<p>
    In der Regel kommen hierbei sogenannte Cookies zum Einsatz. Cookies sind
    kleine Dateien, die Dein Browser auf Deinem Gerät in einem dafür
    vorgesehenen Verzeichnis ablegt. Durch sie kann u. a. festgestellt werden,
    ob Du eine Website bzw. Webseite schon einmal besucht hast. Sofern Du Dich
    dafür entscheiden hast, trotz Verlassens der {{config.general.companyShort}}-Seiten in Ihrem
    Kundenkonto eingeloggt zu bleiben können in Cookies auch Deine Login-Daten
    z.B. für das Kundenkonto gespeichert werden, so dass Du diese Login-Daten
    nicht bei jedem Aufruf der Seite eingeben musst.
</p>
<p>
    Viele Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine
    eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch
    welche Webseiten und Server dem konkreten Internetbrowser zugeordnet werden
    können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den
    besuchten Websites, den individuellen Browser der betroffenen Person von
    anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden.
    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
    vorgenommen.
</p>
<p>
    Die Verwendung der Cookies kann auch zusammen mit sogenannten Web Beacons
    (auch ClearGIFs oder Zählpixel genannt) erfolgen. Dies sind kleine Grafiken
    (z.B. in E-Mails und auf Webseiten), die eingesetzt werden, um ein besseres
    Verständnis von der Interaktion der Besucher mit der Website zu vermitteln.
</p>
<p>
    Wir verwenden zwei Arten von Cookies: Zum einen technisch Cookies, ohne die
    die Funktionalität der {{config.general.companyShort}}-Seiten eingeschränkt wäre, sowie optionale
    (s.u.) Analyse-Cookies, um unser Angebot nutzerfreundlicher zu gestalten.
    Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualität
    unserer und ihre Inhalte zu verbessern. Durch die Analyse-Cookies erfahren
    wir, wie {{config.general.companyShort}} genutzt wird, und können so unser Angebot stetig
    optimieren. Weitere Informationen zu den einzelnen Analyse-Cookies bzw.
    -Diensten findest Du in Ziffer 10 dieser Datenschutzerklärung.
</p>
<p>
    Du kannst das Setzen von Cookies durch uns jederzeit mittels einer
    entsprechenden Einstellung des genutzten Internetbrowsers verhindern und so
    dauerhaft widersprechen. Ferner können bereits gesetzte Cookies jederzeit
    über einen Internetbrowser oder andere Softwareprogramme gelöscht werden.
    Dies ist in allen gängigen Internetbrowsern möglich. Deaktivierst Du das
    Setzen von Cookies in dem genutzten Internetbrowser, sind unter Umständen
    nicht alle Funktionen unserer Website vollumfänglich nutzbar. Zudem hast
    die Möglichkeit in Bezug auf Analyse-Cookies bzw. –Dienste dezidiert zu
    widersprechen (sog. Opt-out; siehe hierzu unter in Ziffer 10).
</p>
<p>
    Bei der Nutzung von Apps wird statt des Cookies eine in ihrer Funktion
    vergleichbare Technik verwendet.
</p>
<p>
    Cookies können Dich als Person nicht identifizieren. In jedem Fall ist die
    Verwendung von Cookies auf Grundlage unseres berechtigten Interesses an
    einer bedarfsgerechten Gestaltung sowie der statistischen Auswertung von
    {{config.general.companyShort}} gerechtfertigt (Art. 6 Abs. 1 lit. f DSGVO).
</p>

    <h4>Web Storage (Session Storage und Local Storage)</h4>
<p>
    {{config.general.companyShort}} verwendet die Web Storage Technologie, eine Methode zur
    Speicherung von Anwendungsdaten in Deinem Browser auf Deinem Endgerät,
    sofern Dein Browser diese Technologie unterstützt und Du JavaScript
    aktiviert hast. Der Web Storage dient dazu, unseren Internetauftritt und
    unsere Services zu optimieren. Wir verwenden diese Technologie zur
    Speicherung von Anwendungsdaten während der Benutzung der {{config.general.companyShort}}-Seiten,
    z.B. um die Navigation bei der Suche nach Produkten oder Dienstleistungen
    komfortabler zu gestalten und die Funktion “Zuletzt angesehen”
    bereitzustellen.
</p>
<p>
    Der Web Storage stellt zwei Speicherobjekte zur Verfügung, den Session
    Storage und den Local Storage. Die Einträge im Session Storage werden nach
    dem Schließen des Browsers oder der App automatisch entfernt. Die Einträge
    im Local Storage („Zuletzt angesehen“) kannst Du jederzeit durch Löschen
    des Verlaufs / der Chronik in Deinem Browser löschen.
</p>
<p>
    Der Session Storage speichert Informationen, um Deinen Browser bzw. Dein
    Gerät automatisch wiederzuerkennen und Dir so einen leichteren Zugang zu
    unseren Diensten zu ermöglichen. Du kannst die Verwendung des Web Storage
    durch entsprechende Einstellung in Deinem Browser verhindern, wodurch
    jedoch die Funktionalität der {{config.general.companyShort}}-Seiten eingeschränkt sein kann.
</p>
<p>
    Die Verarbeitung der Daten im Web Storage erfolgt aufgrund von Art. 6 Abs.
    1 lit. f) DS-GVO. Unser berechtigtes Interesse liegt in den soeben
    beschriebenen Zwecken der Verarbeitung.
</p>

    <h4>{{config.general.companyShort}}-Cookies</h4>
<p>
    Mittels von {{config.general.companyShort}} gesetzter Cookies können Informationen zu
    Browsertyp/-version, Auflösung, bisherige/neue Darstellungsvariante,
    URL-Clickstream (Reihenfolge der von Dir besuchten Seiten unseres
    Internetangebots), Zeit des Besuchs/Deiner Besuche der Webseiten, die
    Referenz zu in der {{config.general.companyShort}}-Datenbank für Deinen Merkzettel notierten
    Produkte/Angebote sowie die Cookie-Nummer erhoben werden, nicht jedoch
    personenbezogene Angaben wie Name, Anschrift, E-Mail-Adresse. Wir nutzen
    diese Cookies zu Zwecken der Werbung, der Marktforschung oder zur
    bedarfsgerechten Gestaltung unserer Dienste. Die Cookies von {{config.general.companyShort}} haben
    eine Gültigkeit von maximal zwei Jahren, sofern sie nicht zuvor von Dir
    gelöscht werden. Die Verarbeitung ist in diesem Fall gerechtfertigt durch
    Art. 6 Abs. 1 lit. f) DS-GVO.
</p>

    <h3>Web-/Appanalysedienste</h3>
<p>
    Um unsere Inhalte stetig zu verbessern und an die Interessen unserer Nutzer
    anzupassen sowie nutzungsbasierte Online Werbung anzuzeigen, setzen wir
    einige Dienste ein, die auf unserer Website bzw. in der App Daten erheben
    und für uns auswerten. Sofern diese Dienstleister nicht selbst
    Verantwortliche im datenschutzrechtlichen Sinne sind, verarbeiten sie die
    pseudonymisierten Nutzerdaten stets weisungsgebunden auf Grundlage einer
    Auftragsverarbeitungsvereinbarung. Die Rechtsgrundlage für die
    Datenverarbeitung ist dabei stets Art. 6 Abs. 1 lit. f) DS-GVO.
</p>
<p>
    Im Folgenden kannst Du Einzelheiten über die von uns eingesetzten
    Analysedienste erfahren:
</p>

    <h4>Crashlytics</h4>
<p>
    Crashlytics ist ein Analysewerkzeug, das von der Crashlytics Inc. mit Sitz
    in Cambridge MA, USA, angeboten und betrieben wird. Crashlytics erzeugt
    sog. Crash Reports, also Berichte über Funktionsstörungen oder -ausfälle
    der App, wo und in welchen Zusammenhang diese aufgetreten sind, wie viele
    Nutzer der App davon betroffen sind etc. Wir nutzen diese Berichte, um von
    Funktionsstörungen oder -ausfällen zu erfahren, im Interesse unserer Nutzer
    schneller, zielgerichteter und effizienter reagieren und die App
    entsprechend technisch verbessern zu können. Hierzu zeichnet Crashlytics
    zusammen mit Deiner Symphony-bezogenen App-ID, die Du jederzeit löschen
    kannst, Daten zu dem „Crash“ und zu generellen Daten des jeweiligen
    IT-Umfeldes auf (insbesondere zum verwendeten Endgerät, dem
    Mobilfunkanbieter und dem Betriebssystem). Soweit darin personenbezogene
    oder pseudonyme Daten enthalten sind, werden diese bei Crashlytics nach
    spätestens sieben Tagen gelöscht. Mit den Informationen erhält Crashlytics
    in der Folge wir Erkenntnisse darüber, ob und wie die App funktioniert und
    genutzt wird, vor allem einschließlich der Funktionsstörungen und
    -ausfälle, die auftreten. Weitere Informationen sind unter
    <a href="https://try.crashlytics.com/terms/privacy-policy.pdf">
        https://try.crashlytics.com/terms/privacy-policy.pdf
    </a>
    verfügbar.
</p>

    <h4>Google AdSense</h4>
<p>
    Wir haben auf dieser Website Google AdSense integriert. Google AdSense ist
    ein Online-Dienst, über welchen eine Vermittlung von Werbung auf
    Drittseiten ermöglicht wird. Google AdSense beruht auf einem Algorithmus,
    welcher die auf Drittseiten angezeigten Werbeanzeigen passend zu den
    Inhalten der jeweiligen Drittseite auswählt. Google AdSense gestattet ein
    interessenbezogenes Targeting des Internetnutzers, welches mittels
    Generierung von individuellen Benutzerprofilen umgesetzt wird.
</p>
<p>
    Betreibergesellschaft der Google-AdSense-Komponente ist die Alphabet Inc.,
    1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
</p>
<p>
    Der Zweck der Google-AdSense-Komponente ist die Einbindung von
    Werbeanzeigen auf unserer Website. Google-AdSense setzt ein Cookie auf dem
    informationstechnologischen System der betroffenen Person. Was Cookies
    sind, wurde oben bereits erläutert. Mit der Setzung des Cookies wird der
    Alphabet Inc. eine Analyse der Benutzung unserer Website ermöglicht. Durch
    jeden Aufruf einer der Einzelseiten dieser Website, die durch uns betrieben
    wird und auf welcher eine Google-AdSense-Komponente integriert wurde, wird
    der Internetbrowser auf dem informationstechnologischen System der
    betroffenen Person automatisch durch die jeweilige
    Google-AdSense-Komponente veranlasst, Daten zum Zwecke der Online-Werbung
    und der Abrechnung von Provisionen an die Alphabet Inc. zu übermitteln. Im
    Rahmen dieses technischen Verfahrens erhält die Alphabet Inc. Kenntnis über
    personenbezogene Daten, wie der IP-Adresse der betroffenen Person, die der
    Alphabet Inc. unter anderem dazu dienen, die Herkunft der Besucher und
    Klicks nachzuvollziehen und in der Folge Provisionsabrechnungen zu
    ermöglichen.
</p>
<p>
    Die betroffene Person kann die Setzung von Cookies durch unsere Website,
    wie oben bereits dargestellt, jederzeit mittels einer entsprechenden
    Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung
    von Cookies dauerhaft widersprechen. Eine solche Einstellung des genutzten
    Internetbrowsers würde auch verhindern, dass die Alphabet Inc. ein Cookie
    auf dem informationstechnologischen System der betroffenen Person setzt.
    Zudem kann ein von der Alphabet Inc. bereits gesetzter Cookie jederzeit
    über den Internetbrowser oder andere Softwareprogramme gelöscht werden.
</p>
<p>
    Google AdSense verwendet zudem sogenannte Zählpixel. Ein Zählpixel ist eine
    Miniaturgrafik, die in Websites eingebettet wird, um eine
    Logdatei-Aufzeichnung und eine Logdatei-Analyse zu ermöglichen, wodurch
    eine statistische Auswertung durchgeführt werden kann. Anhand des
    eingebetteten Zählpixels kann die Alphabet Inc. erkennen, ob und wann eine
    Website von einer betroffenen Person geöffnet wurde und welche Links von
    der betroffenen Person angeklickt wurden. Zählpixel dienen unter anderem
    dazu, den Besucherfluss einer Website auszuwerten.
</p>
<p>
    Über Google AdSense werden personenbezogene Daten und Informationen, was
    auch die IP-Adresse umfasst und zur Erfassung und Abrechnung der
    angezeigten Werbeanzeigen notwendig ist, an die Alphabet Inc. in die
    Vereinigten Staaten von Amerika übertragen. Diese personenbezogenen Daten
    werden in den Vereinigten Staaten von Amerika gespeichert und verarbeitet.
    Die Alphabet Inc. gibt diese über das technische Verfahren erhobenen
    personenbezogenen Daten unter Umständen an Dritte weiter.
</p>
<p>
    Google-AdSense wird unter diesem Link
    <a href="https://www.google.de/intl/de/adsense/start/">
        https://www.google.de/intl/de/adsense/start/
    </a>
    genauer erläutert.
</p>

    <h4>Google AdWords</h4>
<p>
    Wir haben auf dieser Website Google AdWords integriert. Google AdWords ist
    ein Dienst zur Internetwerbung, der es Werbetreibenden gestattet, sowohl
    Anzeigen in den Suchmaschinenergebnissen von Google als auch im
    Google-Werbenetzwerk zu schalten. Google AdWords ermöglicht es einem
    Werbetreibenden, vorab bestimmte Schlüsselwörter festzulegen, mittels derer
    eine Anzeige in den Suchmaschinenergebnissen von Google ausschließlich dann
    angezeigt wird, wenn der Nutzer mit der Suchmaschine ein
    schlüsselwortrelevantes Suchergebnis abruft. Im Google-Werbenetzwerk werden
    die Anzeigen mittels eines automatischen Algorithmus und unter Beachtung
    der zuvor festgelegten Schlüsselwörter auf themenrelevanten Websites
    verteilt. Betreibergesellschaft der Dienste von Google AdWords ist die
    Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA. Der
    Zweck von Google AdWords ist die Bewerbung unserer Website durch die
    Einblendung von interessenrelevanter Werbung auf den Websites von
    Drittunternehmen und in den Suchmaschinenergebnissen der Suchmaschine
    Google und eine Einblendung von Fremdwerbung auf unserer Website. Gelangt
    eine betroffene Person über eine Google-Anzeige auf unsere Website, wird
    auf dem informationstechnologischen System der betroffenen Person durch
    Google ein sogenannter Conversion-Cookie abgelegt. Was Cookies sind, wurde
    oben bereits erläutert. Ein Conversion-Cookie verliert nach dreißig Tagen
    seine Gültigkeit und dient nicht zur Identifikation der betroffenen Person.
    Über den Conversion-Cookie wird, sofern das Cookie noch nicht abgelaufen
    ist, nachvollzogen, ob bestimmte Unterseiten, beispielsweise der Warenkorb
    von einem Online-Shop-System, auf unserer Website aufgerufen wurden. Durch
    den Conversion-Cookie können sowohl wir als auch Google nachvollziehen, ob
    eine betroffene Person, die über eine AdWords-Anzeige auf unsere Website
    gelangt ist, einen Umsatz generierte, also einen Warenkauf vollzogen oder
    abgebrochen hat.
</p>
<p>
    Die durch die Nutzung des Conversion-Cookies erhobenen Daten und
    Informationen werden von Google verwendet, um Besuchsstatistiken für unsere
    Website zu erstellen. Diese werden durch uns wiederum genutzt, um die
    Gesamtanzahl der Nutzer zu ermitteln, welche über AdWords-Anzeigen an uns
    vermittelt wurden, also um den Erfolg oder Misserfolg der jeweiligen
    AdWords-Anzeige zu ermitteln und um unsere AdWords-Anzeigen für die Zukunft
    zu optimieren. Weder unser Unternehmen noch andere Werbekunden von
    Google-AdWords erhalten Informationen von Google, mittels derer die
    betroffene Person identifiziert werden könnte.
</p>
<p>
    Mittels des Conversion-Cookies werden personenbezogene Informationen,
    beispielsweise die durch die betroffene Person besuchten Websites,
    gespeichert. Bei jedem Besuch unserer Websites werden demnach
    personenbezogene Daten, einschließlich der IP-Adresse des von der
    betroffenen Person genutzten Internetanschlusses, an Google in den
    Vereinigten Staaten von Amerika übertragen. Diese personenbezogenen Daten
    werden durch Google in den Vereinigten Staaten von Amerika gespeichert.
    Google gibt diese über das technische Verfahren erhobenen personenbezogenen
    Daten unter Umständen an Dritte weiter.
</p>
<p>
    Die betroffene Person kann die Setzung von Cookies durch unsere Website,
    wie oben bereits dargestellt, jederzeit mittels einer entsprechenden
    Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung
    von Cookies dauerhaft widersprechen. Eine solche Einstellung des genutzten
    Internetbrowsers würde auch verhindern, dass Google einen Conversion-Cookie
    auf dem informationstechnologischen System der betroffenen Person setzt.
    Zudem kann ein von Google AdWords bereits gesetzter Cookie jederzeit über
    den Internetbrowser oder andere Softwareprogramme gelöscht werden.
</p>
<p>
    Ferner besteht für die betroffene Person die Möglichkeit, der
    interessenbezogenen Werbung durch Google zu widersprechen. Hierzu muss die
    betroffene Person von jedem der von ihr genutzten Internetbrowser aus den
    Link
    <a href="https://www.google.de/settings/ads">
        https://www.google.de/settings/ads
    </a>
    aufrufen und dort die gewünschten Einstellungen vornehmen.
</p>
<p>
    Weitere Informationen und die geltenden Datenschutzbestimmungen von Google
    können unter
    <a href="https://www.google.de/intl/de/policies/privacy/">
        https://www.google.de/intl/de/policies/privacy/
    </a>
    abgerufen werden.
</p>

    <h4>Google Analytics</h4>
<p>
    Wir haben Google Analytics (mit Anonymisierungsfunktion) integriert. Google
    Analytics ist ein Web-Analyse-Dienst. Web-Analyse ist die Erhebung,
    Sammlung und Auswertung von Daten über das Verhalten von Besuchern von
    Websites. Ein Web-Analyse-Dienst erfasst unter anderem Daten darüber, von
    welcher Website eine betroffene Person auf eine Website gekommen ist
    (sogenannte Referrer), auf welche Unterseiten der Website zugegriffen oder
    wie oft und für welche Verweildauer eine Unterseite betrachtet wurde. Eine
    Web-Analyse wird überwiegend zur Optimierung einer Website und zur
    Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.
</p>
<p>
    Betreibergesellschaft der Google-Analytics-Komponente ist die Google Inc.,
    1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
</p>
<p>
    Wir verwenden für die Web-Analyse über Google Analytics den Zusatz
    "_gat._anonymizeIp". Mittels dieses Zusatzes wird die IP-Adresse des
    Internetanschlusses der betroffenen Person von Google gekürzt und
    anonymisiert, wenn der Zugriff auf unsere Websites aus einem Mitgliedstaat
    der Europäischen Union oder aus einem anderen Vertragsstaat des Abkommens
    über den Europäischen Wirtschaftsraum erfolgt.
</p>
<p>
    Der Zweck der Google-Analytics-Komponente ist die Analyse der
    Besucherströme auf unserer Website. Google nutzt die gewonnenen Daten und
    Informationen unter anderem dazu, die Nutzung unserer Website auszuwerten,
    um für uns Online-Reports, welche die Aktivitäten auf unseren Websites
    aufzeigen, zusammenzustellen, und um weitere mit der Nutzung unserer
    Website in Verbindung stehende Dienstleistungen zu erbringen.
</p>
<p>
    Google Analytics setzt ein Cookie auf dem informationstechnologischen
    System der betroffenen Person. Was Cookies sind, wurde oben bereits
    erläutert. Mit Setzung des Cookies wird Google eine Analyse der Benutzung
    unserer Website ermöglicht. Durch jeden Aufruf einer der Einzelseiten
    dieser Website, die durch uns betrieben wird und auf welcher eine
    Google-Analytics-Komponente integriert wurde, wird der Internetbrowser auf
    dem informationstechnologischen System der betroffenen Person automatisch
    durch die jeweilige Google-Analytics-Komponente veranlasst, Daten zum
    Zwecke der Online-Analyse an Google zu übermitteln. Im Rahmen dieses
    technischen Verfahrens erhält Google Kenntnis über personenbezogene Daten,
    wie der IP-Adresse der betroffenen Person, die Google unter anderem dazu
    dienen, die Herkunft der Besucher und Klicks nachzuvollziehen und in der
    Folge Provisionsabrechnungen zu ermöglichen.
</p>
<p>
    Mittels des Cookies werden personenbezogene Informationen, beispielsweise
    die Zugriffszeit, der Ort, von welchem ein Zugriff ausging und die
    Häufigkeit der Besuche unserer Website durch die betroffene Person,
    gespeichert. Bei jedem Besuch unserer Websites werden diese
    personenbezogenen Daten, einschließlich der IP-Adresse des von der
    betroffenen Person genutzten Internetanschlusses, an Google in den
    Vereinigten Staaten von Amerika übertragen. Diese personenbezogenen Daten
    werden durch Google in den Vereinigten Staaten von Amerika gespeichert.
    Google gibt diese über das technische Verfahren erhobenen personenbezogenen
    Daten unter Umständen an Dritte weiter.
</p>
<p>
    Die betroffene Person kann die Setzung von Cookies durch unsere Website,
    wie oben bereits dargestellt, jederzeit mittels einer entsprechenden
    Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung
    von Cookies dauerhaft widersprechen. Eine solche Einstellung des genutzten
    Internetbrowsers würde auch verhindern, dass Google ein Cookie auf dem
    informationstechnologischen System der betroffenen Person setzt. Zudem kann
    ein von Google Analytics bereits gesetzter Cookie jederzeit über den
    Internetbrowser oder andere Softwareprogramme gelöscht werden.
</p>
<p>
    Ferner besteht für die betroffene Person die Möglichkeit, einer Erfassung
    der durch Google Analytics erzeugten, auf eine Nutzung dieser Website
    bezogenen Daten sowie der Verarbeitung dieser Daten durch Google zu
    widersprechen und eine solche zu verhindern. Hierzu muss die betroffene
    Person ein Browser-Add-On unter dem Link
    <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
        http://tools.google.com/dlpage/gaoptout?hl=de
    </a>
</p>
<p>
    herunterladen und installieren. Dieses Browser-Add-On teilt Google
    Analytics über JavaScript mit, dass keine Daten und Informationen zu den
    Besuchen von Websites an Google Analytics übermittelt werden dürfen. Die
    Installation des Browser-Add-Ons wird von Google als Widerspruch gewertet.
    Wird das informationstechnologische System der betroffenen Person zu einem
    späteren Zeitpunkt gelöscht, formatiert oder neu installiert, muss durch
    die betroffene Person eine erneute Installation des Browser-Add-Ons
    erfolgen, um Google Analytics zu deaktivieren. Sofern das Browser-Add-On
    durch die betroffene Person oder einer anderen Person, die ihrem
    Machtbereich zuzurechnen ist, deinstalliert oder deaktiviert wird, besteht
    die Möglichkeit der Neuinstallation oder der erneuten Aktivierung des
    Browser-Add-Ons.
</p>
<p>
    Weitere Informationen und die geltenden Datenschutzbestimmungen von Google
    können unter
    <a href="https://www.google.de/intl/de/policies/privacy/">
        https://www.google.de/intl/de/policies/privacy/
    </a>
    und unter
    <a href="http://www.google.com/analytics/terms/de.html">
        http://www.google.com/analytics/terms/de.html
    </a>
    abgerufen werden. Google Analytics wird unter diesem Link
    <a href="https://www.google.com/intl/de_de/analytics/">
        https://www.google.com/intl/de_de/analytics/
    </a>
    genauer erläutert.
</p>

    <h4>Google DoubleClick</h4>
<p>
    Wir haben auf dieser Website Komponenten von DoubleClick by Google
    integriert. DoubleClick ist eine Marke von Google, unter welcher vorwiegend
    spezielle Online-Marketing-Lösungen an Werbeagenturen und Verlage
    vermarktet werden.
</p>
<p>
    Betreibergesellschaft von DoubleClick by Google ist die Google Inc., 1600
    Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
</p>
<p>
    DoubleClick by Google überträgt sowohl mit jeder Impression als auch mit
    Klicks oder anderen Aktivitäten Daten auf den DoubleClick-Server. Jede
    dieser Datenübertragungen löst eine Cookie-Anfrage an den Browser der
    betroffenen Person aus. Akzeptiert der Browser diese Anfrage, setzt
    DoubleClick ein Cookie auf dem informationstechnologischen System der
    betroffenen Person. Was Cookies sind, wurde oben bereits erläutert. Zweck
    des Cookies ist die Optimierung und Einblendung von Werbung. Das Cookie
    wird unter anderem dazu verwendet, nutzerrelevante Werbung zu schalten und
    anzuzeigen sowie um Berichte zu Werbekampagnen zu erstellen oder diese zu
    verbessern. Ferner dient das Cookie dazu, Mehrfacheinblendungen derselben
    Werbung zu vermeiden.
</p>
<p>
    DoubleClick verwendet eine Cookie-ID, die zur Abwicklung des technischen
    Verfahrens erforderlich ist. Die Cookie-ID wird beispielsweise benötigt, um
    eine Werbeanzeige in einem Browser anzuzeigen. DoubleClick kann über die
    Cookie-ID zudem erfassen, welche Werbeanzeigen bereits in einem Browser
    eingeblendet wurden, um Doppelschaltungen zu vermeiden. Ferner ist es
    DoubleClick durch die Cookie-ID möglich, Conversions zu erfassen.
    Conversions werden beispielsweise dann erfasst, wenn einem Nutzer zuvor
    eine DoubleClick-Werbeanzeige eingeblendet wurde und dieser in der Folge
    mit dem gleichen Internetbrowser einen Kauf auf der Website des
    Werbetreibenden vollzieht.
</p>
<p>
    Ein Cookie von DoubleClick enthält keine personenbezogenen Daten. Ein
    DoubleClick-Cookie kann aber zusätzliche Kampagnen-Kennungen enthalten.
    Eine Kampagnen-Kennung dient einer Identifizierung der Kampagnen, mit denen
    der Nutzer bereits in Kontakt war.
</p>
<p>
    Durch jeden Aufruf einer der Einzelseiten dieser Website, die durch uns
    betrieben wird und auf welcher eine DoubleClick-Komponente integriert
    wurde, wird der Internetbrowser auf dem informationstechnologischen System
    der betroffenen Person automatisch durch die jeweilige
    DoubleClick-Komponente veranlasst, Daten zum Zwecke der Online-Werbung und
    der Abrechnung von Provisionen an Google zu übermitteln. Im Rahmen dieses
    technischen Verfahrens erhält Google Kenntnis über Daten, die Google auch
    dazu dienen, Provisionsabrechnungen zu erstellen. Google kann unter anderem
    nachvollziehen, dass die betroffene Person bestimmte Links auf unserer
    Website angeklickt hat.
</p>
<p>
    Die betroffene Person kann die Setzung von Cookies durch unsere Website,
    wie oben bereits dargestellt, jederzeit mittels einer entsprechenden
    Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung
    von Cookies dauerhaft widersprechen. Eine solche Einstellung des genutzten
    Internetbrowsers würde auch verhindern, dass Google ein Cookie auf dem
    informationstechnologischen System der betroffenen Person setzt. Zudem
    können von Google bereits gesetzte Cookies jederzeit über einen
    Internetbrowser oder andere Softwareprogramme gelöscht werden.
</p>
<p>
    Weitere Informationen und die geltenden Datenschutzbestimmungen von
    DoubleClick by Google können unter
    <a href="https://www.google.com/intl/de/policies/">
        https://www.google.com/intl/de/policies/
    </a>
    abgerufen werden.
</p>

    <h4>Google Dynamic Remarketing</h4>
<p>
    Wir verwenden auf der Website die Remarketing- oder "Ähnliche Zielgruppen"-
    Funktion der Google Inc. („Google“). Betreibergesellschaft der Dienste von
    Google Remarketing ist die Google Inc., 1600 Amphitheatre Pkwy, Mountain
    View, CA 94043-1351, USA. Mittels dieser Funktion kann der Anbieter die
    Besucher der Website zielgerichtet mit Werbung ansprechen, indem für
    Besucher der Website des Anbieters personalisierte, interessenbezogene
    Werbung-Anzeigen geschaltet werden, wenn sie andere Websites im Google
    Display-Netzwerk besuchen. Zur Durchführung der Analyse der
    Website-Nutzung, welche die Grundlage für die Erstellung der
    interessenbezogenen Werbeanzeigen bildet, setzt Google sog. Cookies ein.
    Hierzu speichert Google eine kleine Datei mit einer Zahlenfolge in den
    Browsern der Besucher der Website. Über diese Zahl werden die Besuche der
    Website sowie anonymisierte Daten über die Nutzung der Website erfasst. Es
    erfolgt keine Speicherung von personenbezogenen Daten der Besucher der
    Website. Besuchst Du nachfolgend eine andere Website im Google
    Display-Netzwerk werden Dir Werbeeinblendungen angezeigt, die mit hoher
    Wahrscheinlichkeit zuvor aufgerufene Produkt- und Informationsbereiche
    berücksichtigen. Du kannst die Verwendung von Cookies durch Google
    dauerhaft deaktivieren, indem Du dem nachfolgenden Link folgst und das dort
    bereitgestellte Plug-In herunterladen und installierst:
    <a href="https://www.google.com/settings/ads/plugin">
        https://www.google.com/settings/ads/plugin
    </a>
    . Ferner besteht für die betroffene Person die Möglichkeit, der
    interessenbezogenen Werbung durch Google zu widersprechen. Hierzu muss die
    betroffene Person von jedem der von ihr genutzten Internetbrowser aus den
    Link
    <a href="https://www.google.com/settings/ads/">
        https://www.google.com/settings/ads/
    </a>
    aufrufen und dort die gewünschten Einstellungen vornehmen. Alternativ
    kannst Du die Verwendung von Cookies durch Drittanbieter deaktivieren,
    indem Du die Deaktivierungsseite der Netzwerkwerbeinitiative (Network
    Advertising Initiative) unter
    <a href="http://www.networkadvertising.org/choices/">
        http://www.networkadvertising.org/choices/
    </a>
    aufrufst und die dort genannten weiterführenden Information zum Opt-Out
    umsetzt. Weiterführende Informationen zu Google Remarketing sowie die
    Datenschutzerklärung von Google kannst Du einsehen unter:
    <a href="http://www.google.com/privacy/ads/">
        http://www.google.com/privacy/ads/
    </a>
    .
</p>

    <h4>Google Firebase</h4>
<p>
    Wir verwenden Firebase. Dies ist eine Echtzeit-Datenbank, mit der sich
    Echtzeitinformationen in die eigene Website einbetten lassen, um das
    Nutzerverhalten in den Apps besser verstehen und optimieren zu können.
    Darüber hinaus werden auch andere Funktionen von Firebase verwendet, die
    eine bessere Nutzerführung oder eine Auswertung von Absturzursachen in den
    Apps ermöglichen. Firebase ist eine Google-Tochter und hat seinen Sitz in
    San Francisco (CA), USA. Weitere Informationen findest Du in der
Datenschutzerklärung von Firebase unter    <a href="https://www.firebase.com/terms/privacy-policy.html."> </a>
    <a href="https://www.firebase.com/terms/privacy-policy.html">
        https://www.firebase.com/terms/privacy-policy.html
    </a>
    .
</p>

    <h4>Google Tag Manager</h4>
<p>
    {{config.general.companyShort}} nutzt den Tag-Manger der Google Inc., 1600 Amphitheatre Parkway,
    Mountain View, CA 94043, USA („Google“). Tags sind Kennzeichnungen von
    Datenelementen. Messpixel von hier genannten Drittanbietern werden auf die
    Webseiten geladen. Das Tool Tag-Manager selbst (das die Tags implementiert)
    ist eine cookielose Domain und erfasst keine personenbezogenen Daten. Das
    Tool sorgt für die Auslösung anderer Tags, die ihrerseits unter Umständen
    Daten erfassen. Google Tag Manager greift nicht auf diese Daten zu.
    Weiterführende Informationen kannst Du hier abrufen:
    <a href="https://www.google.com/intl/de/tagmanager/faq.html">
        https://www.google.com/intl/de/tagmanager/faq.html
    </a>
    und
    <a href="http://www.google.com/intl/de/policies/privacy">
        http://www.google.com/intl/de/policies/privacy
    </a>
</p>

    <h4>Hotjar</h4>
<p>
    {{config.general.companyShort}} verwendet die Analysesoftware "Hotjar" der Hotjar Ltd, St Julians
    Business Centre, 3, Elia Zammit Street, St Julians STJ 1000, Malta, um
    Nutzerverhalten (Mausbewegungen, Klicks, Scrollhöhe etc.) auf den Webseiten
    zu messen und auszuwerten. Zu diesem Zweck setzt Hotjar Cookies auf
    Endgeräten der Nutzer und kann nicht-personenbezogene Daten von Nutzern wie
    z. B. Browser Informationen, Betriebssystem, Verweildauer etc. (nur mit
anonymisierter IP-Adresse) erfassen. Weitere Informationen hier:    <a href="https://www.hotjar.com/privacy">https://www.hotjar.com/privacy</a>
, Möglichkeiten zum Ausschalten dieser Funktion hier:    <a href="https://www.hotjar.com/opt-out">https://www.hotjar.com/opt-out</a>
</p>

    <h4>
            Skalierbares Zentrales Messverfahren next Generation
            (Reichweitenmessung)
    </h4>
<p>
    Die Webseite nutzt das Messverfahren („SZMnG“) der INFOnline GmbH, Brühler
    Str. 9, 53119 Bonn, zur Ermittlung statistischer Kennwerte über die Nutzung
    dieser Website. Ziel der Reichweitenmessung ist es, die Nutzungsintensität,
    die Anzahl der Nutzer einer Webseite und das Surfverhalten statistisch –
    auf Basis eines einheitlichen Standardverfahrens - zu bestimmen und somit
    marktweit vergleichbare Werte zu erhalten.
</p>
<p>
    Die INFOnline GmbH erhebt und verarbeitet Daten nach deutschem
    Datenschutzrecht. Durch technische und organisatorische Maßnahmen wird
    sichergestellt, dass einzelne Nutzer zu keinem Zeitpunkt identifiziert
    werden können. Daten, die möglicherweise einen Bezug zu einer bestimmten,
    identifizierbaren Person haben, werden frühestmöglich anonymisiert.
</p>
<p>
    Die IP-Adressen werden vor jeglicher Verarbeitung gekürzt und nur
    anonymisiert weiterverarbeitet. Es erfolgt keine Speicherung oder
    Verarbeitung der ungekürzten IP-Adressen. Eine sogenannte Geolokalisierung,
    also die Zuordnung eines Webseitenaufrufs zum Ort des Aufrufs, erfolgt
    ausschließlich auf der Grundlage der anonymisierten IP-Adresse und nur bis
    zur geographischen Ebene der Bundesländer / Regionen. Aus den so gewonnenen
    geographischen Informationen kann in keinem Fall ein Rückschluss auf den
    konkreten Wohnort eines Nutzers gezogen werden.
</p>
<p>
    Die Reichweitenmessung verwendet zur Wiedererkennung von Computersystemen
    alternativ entweder ein Cookie mit der Kennung „ioam.de“, ein „Local
    Storage Object“ oder eine anonyme Signatur, die aus verschiedenen
    automatisch übertragenen Informationen Ihres Browsers erstellt wird. Die
    Gültigkeit des Cookies ist auf maximal 1 Jahr beschränkt. Zur Messung von
    verteilter Nutzung (Nutzung eines Dienstes von verschiedenen Geräten) kann
    die Nutzerkennung beim Login, falls vorhanden, als anonymisierte Prüfsumme
    an INFOnline übertragen werden.
</p>
<p>
    Die gespeicherten Nutzungsvorgänge werden nach spätestens 7 Monaten
    gelöscht.
</p>
<p>
    Wenn Du an der Messung nicht teilnehmen möchtest, kannst Du unter folgendem
Link widersprechen:    <a href="https://optout.ioam.de/">https://optout.ioam.de</a>
</p>
<p>
    Weitere Informationen zum Datenschutz im Messverfahren finden Sie auf der
Webseite der INFOnline GmbH (    <a href="http://www.infonline.de/">http://www.infonline.de</a>), die das
Messverfahren betreibt, der Datenschutzwebseite der AGOF (    <a href="http://www.agof.de/datenschutz">http://www.agof.de/datenschutz</a>
) und der Datenschutzwebseite der IVW (    <a href="http://www.ivw.eu/">http://www.ivw.eu</a>).
</p>

    <h4>Akamai Technologies GmbH</h4>
<p>
    Akamai ist ein Content Delivery und Cloud Infrastruktur Dienstleiter, der
    Online Anwendungen verbreitet, beschleunigt und schützt. Akamai hat hierfür
    die Akamai Intelligent Platform mit über 230.000 Servern, in über 1.600
    Netzwerken, in über 130 Ländern gebaut. Die Akamai Server werden von Akamai
    Technologies, Inc., Cambridge, MA, USA, kontrolliert. Akamai hat
    Vertriebsgesellschaften in den USA, Europa und Asien. Die Mitarbeiter des
    Service und Support Teams von Akamai arbeiten ebenfalls in den USA, Europa
    und Asien und können die Kunden so rund um die Uhr betreuen.
</p>
<p>
    Die Akamai Dienstleistungen basieren auf der Verarbeitung von Daten von
    Internet Endnutzer, die durch die Online Anwendungen der Akamai Kunden
    erhoben werden, und von Daten in Log Dateien, die sich auf den Zugriff der
    Internet Endnutzern auf diese Online Anwendungen beziehen. Für die Online
    Anwendungen entscheidet der Kunde, welche Daten er beim Internet Endnutzer
    erhebt und von Akamai ausliefern, beschleunigt und sichern lässt. Bei den
    Daten kann es sich zum Beispiel um Logos, Videos, Streams, Namen, E-Mail,
    Anmeldedaten, Adresse, Telefonnr. oder Zahlungs-Details handeln. Für diese
    „Endnutzer Daten“ entscheidet der Kunde, ob persönliche Daten darin
    enthalten sind und wenn ja, ob und wie diese verschlüsselt sind. Er
    entscheidet, ob diese Daten auf der Akamai Intelligent Platform gecacht
    werden und wenn ja, wie lange. Und der Kunde entscheidet, ob diese Daten
    auf den Akamai NetStorage Servern gespeichert werden oder nicht. Zudem
    führt Akamai Sicherheitschecks des Zugriffs auf die Online Anwendungen des
    Kunden durch und entscheidet mit Hilfe von Erkenntnissen aus seiner
    Sicherheitsdatenbank, ob ein Zugriff auf die Online Anwendungen gut oder
    schädlich ist und ob dieser Zugriff geblockt werden muss. Hierfür
    verarbeitet Akamai die Daten, die sich in den Log Dateien befinden. Das
    sind z.B. IP Adresse, Kopfdaten des Browser, Gerätetyp, Ortungsdaten oder
    die Zeit eines Zugriffs auf die Online Anwendung. Die Daten aus den Log
    Dateien verarbeitet Akamai auch zur Analyse des Datenverkehrs, der über die
    Akamai Intelligent Platform läuft und um ggfs. schädliche Zugriffe auf die
    Online Anwendungen der Kunden und/oder die Akamai Platform zu verhindern.
    Die Daten aus den Log Dateien werden auch für die Verbesserung der Akamai
    Dienstleistungen und für die Erhebung von Analysen über die Performance der
    Online Anwendungen des Kunden, wenn diese Analysen vom Kunden gewünscht
    sind. Akamai nutzt die verarbeiteten Daten und erstellten Analysen nicht,
    um die Internet Endnutzer zu identifizieren.
</p>
<p>
    Die Analysen beziehen sich auf Typ, Natur, Inhalt, Identität, Verhalten,
    Signatur, Quelle, Häufigkeit, Ruf oder andere Merkmale des Zugriffs auf die
    Online Anwendungen. Die aus den Daten gewonnenen Erkenntnisse über
    Bedrohungen im Internet pflegt Akamai in seine Systeme und Dienste ein, um
    und seine Kunden und die Akamai Intelligent Platform vor schädlichem
    Zugriffen zu schützen. Darüber hinaus werden die Erkenntnisse für den
    „State of the Internet“ Bericht von Akamai aufbereitet, der dann
    veröffentlicht wird. Schließlich werden die Daten (insbesondere IP
    Adressen) aus den Log Dateien auch für die Abrechnung, Störungsbeseitigung
    oder Beantwortung von Behörden Anfragen zu bestimmten schädlichen
    Internetaktivitäten verarbeitet. Bei seiner Datenverarbeitung agiert Akamai
    in Hinblick auf die Auslieferung, Beschleunigung und Sicherung der Online
    Anwendungen. In Hinblick auf die Analyse der Performance und Sicherheit der
    Akamai Integlligent Platform sowie die Sicherheitsdatenbank nimmt Akamai
    Datenverarbeitung zu eigenen Zwecken vor und tritt damit als
    Verantwortlicher auf. Akamai überträgt bei jeder Datenverarbeitung
    persönliche Daten (aus den Log Dateien, z.B. IP Adressen) in die USA. Dies
    geht auf die Struktur der Akamai Intelligent Platform zurück, die von
    Akamai Technologies, Inc. kontrolliert wird und bei der bestimmte Server
    zur Verarbeitung der Log Dateien nur in den USA stehen. Um diesen Transfer
    von persönlichen Daten von der EU in die USA datenschutz-konform zu
    gestalten hat Akamai sich unter dem Privacy Shield Programm zertifiziert
    und vereinbart zusätzlich mit seinen Kunden die EU Standard
    Vertragsklauseln. Zudem haben die verschiedenen Akamai Gesellschaften
    untereinander ebenfalls die EU Standard Vertragsklauseln vereinbart. Für
    die 24/7 Service und Support Leistungen durch Akamai werden persönliche
    Daten in Log Dateien (insbesondere IP Adressen) in den USA, Europa und
    Asien (Indien) verarbeitet. Für diesen Transfer persönlicher Daten von der
    EU in die USA bzw. Asien (Indien) haben die verschiedenen Akamai
    Gesellschaften untereinander ebenfalls die EU Standard Vertragsklauseln
    vereinbart. Nach Inkrafttreten der Datenschutz Grundverordnung2 plant
    Akamai zusätzlich Binding Corporate Rules einzuführen. Akamai gründet seine
    Datenverarbeitungsaktivitäten auf sein berechtigtes Interesses an der
    Erbringung seiner Dienstleistungen und der Sicherstellung von Information
    und Netzwerksicherheit. Da Akamai die Internet Endnutzer, die die Online
    Anwendungen seiner Kunden benutzen, nicht kennt und nicht identifiziert,
    kann Akamai seine Aktivitäten nicht auf die Zustimmung der Endnutzer
    stützen. Die technische und organisatorische Schutzmaßnahmen von Akamai:
    Akamai setzt sich für den Schutz aller über seine Intelligent Platform
    ausgelieferten Daten ein und hat unternehmensweit ein Programm zur
    Informationssicherheit gemäß dem internationalen Standard ISO/IEC ISO 2700x
    zur Informationssicherheitsverwaltung eingeführt. Akamai nimmt zahlreiche
    jährliche Zertifizierungen und Bewertungen vor, welche die Einhaltung und
    Effektivität der getroffenen Maßnahmen nachweisen: Die Akamai Intelligent
    Platform wird anhand der Standards ISO 27002 und nach dem US-amerikanischen
    FedRamp-Standard (Federal Risk and Authorization Management Program)
    bewertet. Darüber hinaus wird die Akamai Intelligent Platform jährlich auf
    die Einhaltung der Sicherheitsstandards der Zahlungskartenbranche (Payment
    Card Industry, PCI) und der HIPPA-Gesetzgebung zur Vertraulichkeit der
    Informationen in der Gesundheitsfürsorge (Health Insurance Portability and
    Accountability Act) überprüft. Außerdem führt Akamai eine jährliche Prüfung
    und Berichterstattung unter dem Auditsystem Service Organization Control 2
    Type 2 (SOC2 Type II) durch. Alle Zertifizierungen und Berichte sind über
    die Akamai Community verfügbar.<br>
    Weitere Informationen zu den Datenschutzbestimmungen von Akamai lassen sich hier einsehen:
    <a href="https://www.akamai.com/de/de/privacy-policies/privacy-statement.jsp">
        https://www.akamai.com/de/de/privacy-policies/privacy-statement.jsp
    </a>
</p>

    <h4>Host Europe GmbH </h4>
<p>
    Wir nutzen Host Europe als unseren Mailanbieter. Host Europe GmbH
    Hansestraße 111 51149 Köln. Weitere Informationen findest du unter
    <a href="https://www.hosteurope.de/AGB/Datenschutzerklaerung/">
        https://www.hosteurope.de/AGB/Datenschutzerklaerung/
    </a>
    .
</p>

    <h4> TechnikDoc-IT</h4>
<p>
    Wir haben TechnikDoc-IT als externen IT Dienstleister beauftragt um uns
    intern und bei technischen Problemen zu unterstützen. TechnikDoc-IT
    Karl-Liebknecht-Straße 30 14482 Potsdam. Weitere Informationen findest du
    unter
    <a href="https://technikdoc-it.de/datenschutz/">
        https://technikdoc-it.de/datenschutz/
    </a>
    .
</p>

    <h4>Broadsoft Germany GmbH </h4>
<p>
    Placetel ist unser Ansprechpartner im Kommunikationsbereich, über den wir
    unsere Telefonanlage nutzen. Broadsoft Germany GmbH Lothringer Straße 56
    D-50677 Köln. Weitere Informationen findest du unter
    https://www.placetel.de/rechtliches/datenschutzerklaerung .
</p>

    <h4>Netpoint Media GmbH</h4>
<p>
    Um Werbung für Sie anhand Ihrer Nutzungsinteressen zu optimieren, haben wir
    es folgendem Unternehmen gestattet, die vorgenannten Nutzungsdaten zu
    erheben und zu nutzen: Netpoint Media GmbH Rheinallee 60 55283 Nierstein
    Weitere Informationen hierzu – sowie die Möglichkeiten zur Deaktivierung
    nutzungsbasierter Online-Werbung - finden Sie in den Datenschutzhinweisen
    unseres Vermarkters: www.netpoint-media.de/technik/datenschutz.
</p>

    <h4> Smartclip Holding AG </h4>
<p>
    Smartclip erhält Daten um die Einblendung von Werbemitteln, für den User
    auszusteuern und zu optimieren. Dieser Anbieter verarbeitet Daten nur als
    Auftragsverarbeiter gemäß unseren Weisungen und nicht zu eigenen Zwecken.
    Smartclip Holding AG Kleiner Burstah 12 / Nikolaikontor 20457 Hamburg.
    Weitere Informationen zu diesem Tool finden Sie unter
    <a href="http://www.smartclip.com/de/datenschutz/">
        http://www.smartclip.com/de/datenschutz/
    </a>
    .
</p>

    <h4>MAIRDUMONT NETLETIX GmbH &amp; Co. KG</h4>
<p>
    Mit Mairdumont nutzt {{config.general.companyShort}} Daten, um die Einblendung von Werbemitteln,
    für den User auszusteuern und zu optimieren. Dieser Anbieter verarbeitet
    Daten nur als Auftragsverarbeiter gemäß unseren Weisungen und nicht zu
    eigenen Zwecken. MAIRDUMONT NETLETIX GmbH &amp; Co. KG Marco-Polo-Straße 1
    D – 73760 Ostfildern (Kemnat). Weitere Informationen zu diesem Tool finden
    Sie unter
    <a
        href="https://www.mairdumont-netletix.com/wer-wir-sind/datenschutzerklaerung/"
    >
        https://www.mairdumont-netletix.com/wer-wir-sind/datenschutzerklaerung/
    </a>
    .
</p>

    <h4> Ströer SSP GmbH</h4>
<p>
    Stroer Digital erhält anonymsierte Daten um Werbemittel, für den User
    auszusteuern und zu optimieren. Dieser Anbieter verarbeitet Daten nur als
    Auftragsverarbeiter gemäß unseren Weisungen und nicht zu eigenen Zwecken.
    Ströer SSP GmbH St.-Martin-Str. 106. 81669 München. Weitere Informationen
    zu diesem Tool finden Sie unter
    <a href="https://www.stroeer.com/de/service/datenschutz.html">
        https://www.stroeer.com/de/service/datenschutz.html
    </a>
    .
</p>

    <h4> madvertise media GmbH</h4>
<p>
    Die madvertise media GmbH nutzt Technologien, um die Einblendung von
    Werbemitteln, für den User auszusteuern und zu optimieren. Dieser Anbieter
    verarbeitet Daten nur als Auftragsverarbeiter gemäß unseren Weisungen und
    nicht zu eigenen Zwecken. madvertise media GmbH Kottbusser Damm 79 10967
Berlin. Weitere Informationen zu diesem Tool finden Sie unter    <a href="http://madvertise.com/de/agb/">http://madvertise.com/de/agb/</a> .
</p>

    <h4>inwave media GmbH</h4>
<p>
    Unsere Webseiten werden durch die inwave media GmbH, Koppel 34-36, 20099
    Hamburg, nachfolgend „inwave“ genannt, bzw. von Dritten in Ihrem Auftrag
    vermarktet. Inwave arbeitet mit den nachfolgend aufgelisteten Partnern und
    Dienstleistern zusammen, um die Werbung anhand Ihrer Interessen zu
    optimieren.
    Nähere Datenschutzbestimmungen und den Partnern von inwave media GmbH:
    <a href="http://www.inwave-media.de/vermarkter-datenschutz/">
        http://www.inwave-media.de/vermarkter-datenschutz/
    </a>
</p>

    <h4>zoom.in Deutschland GmbH</h4>
<p>
    Zoom.in darf auf anonymisierte Daten zugreifen, um Kundenorientierte
    Werbemittel beim User anzeigen zu lassen. Zoom.in Deutschland GmbH
    Reichenberger Str. 36, 10999 Berlin
    Näheres zu den Datenschutzbestimmungen lassen sich hier nachlesen:
    <a href="http://zoomin.tv/legal/ZOOMINTV-PrivacyPolicy-May2018.pdf">
        http://zoomin.tv/legal/ZOOMINTV-PrivacyPolicy-May2018.pdf
    </a>

</p>

    <h4>Weischer.Online GmbH</h4>


<p>
    Die Weischer.Online GmbH, Elbberg 7, 22767 Hamburg, vermarktet Werbeflächen
    auf den Online-Angeboten ihrer Vermarktungspartner. Eine Zusammenstellung der
    Vermarktungspartner finden Sie auf der Website http://www.weischeronline.de unter
    dem Reiter „Portfolio“.
</p>
<p>
    Weischer.Online legt auf das Recht auf informationelle Selbstbestimmung der Nutzer
    unserer Inhalte größten Wert und beachtet selbstverständlich die gesetzlichen Vorgaben zu Datenschutz und
    Datensicherheit. Es werden keine personenbezogenen Daten erhoben oder verarbeitet, also keine Angaben, die
    einen Rückschluss auf Ihre Identität zulassen (z. B. Ihr Name, Ihre Anschrift oder Ihre E-Mail-Adresse).
    Dieser Anbieter verarbeitet Daten nur als Auftragsverarbeiter gemäß unseren Weisungen und nicht zu eigenen Zwecken.
    Weitere Informationen zu diesem Tool finden Sie unter
    <a href="https://weischer.media/de/de/datenschutzerklaerung/">
        https://weischer.media/de/de/datenschutzerklaerung/
    </a>
    .
</p>

    <h4>SMARTSTREAM.TV GmbH</h4>
<p>
    Anbieter der SMARTSTREAM.TV ist die SMARTSTREAM.TV GmbH, Dachauer Straße
    15c, 80335 München, die Technologien einsetzt um die Einblendung von
    Werbemitteln, für den User auszusteuern und zu optimieren. Mehr Informationen
    finden Sie unter:
    <a href="https://www.smartstream.tv/de/datenschutz">
        https://www.smartstream.tv/de/datenschutz
    </a>
<p>
    Sie haben das Recht, jederzeit die Aufzeichnung von Messdaten durch das
    SMARTSTREAM.TV System zu unterbinden, indem Sie die Möglichkeit eines Widerspruchs (Opt-Out)
    <a href="https://www.smartstream.tv/en/cookie-opt-out">
        https://www.smartstream.tv/en/cookie-opt-out
    </a>
     nutzen. Eine bereits erteilte Einwilligung können Sie ebenfalls jederzeit mit Wirkung für die Zukunft widerrufen.
</p>

    <h4> Stailamedia AG</h4>
<p>
    Dieser Anbieter verarbeitet Daten nur als Auftragsverarbeiter gemäß unseren
    Weisungen und nicht zu eigenen Zwecken. Stailamedia AG Limmatstrasse 214
    CH-8005 Zürich Schweiz. Weitere Informationen zu diesem Tool finden Sie
    unter
    <a href="http://www.stailamedia.com/privacy/">
        http://www.stailamedia.com/privacy/
    </a>
    .
</p>
<p>
    Sie haben das Recht, jederzeit die Aufzeichnung von Messdaten durch das
    SMARTSTREAM.TV System zu unterbinden, indem Sie die Möglichkeit eines
    Widerspruchs (Opt-Out)
    <a href="https://www.smartstream.tv/en/cookie-opt-out%2520">
        https://www.smartstream.tv/en/cookie-opt-out
    </a>
    nutzen. Eine bereits erteilte Einwilligung können Sie ebenfalls jederzeit
    mit Wirkung für die Zukunft widerrufen.
</p>

    <h4> Yieldlab AG</h4>
<p>
    Die Yieldlab AG verarbeitet Daten nur als Auftragsverarbeiter gemäß unseren
    Weisungen und nicht zu eigenen Zwecken.
</p>
<p>
    Yieldlab AG, Colonnaden 41, 20345 Hamburg Weitere Informationen zur
    Datennutzung und zum Opt-out erhalten Sie in der Datenschutzerklärung des
    Unternehmens:
    <a href="http://www.yieldlab.de/metanavigation/datenschutzerklaerung/">
        http://www.yieldlab.de/metanavigation/datenschutzerklaerung/
    </a>
</p>

    <h4>SpotX</h4>
<p>
    Dieser Anbieter verarbeitet Daten nur als Auftragsverarbeiter gemäß unseren
    Weisungen und nicht zu eigenen Zwecken.
</p>
<p>
    SpotX Deutschland GmbH, Kleiner Burstah 12 20457 Hamburg Weitere
    Informationen zur Datennutzung und zum Opt-out erhalten Sie in der
    Datenschutzerklärung des Unternehmens:
    <a href="https://www.spotx.tv/privacy-policy/">
        https://www.spotx.tv/privacy-policy/
    </a>
</p>

    <h4> SmartAd</h4>
<p>
    Über den von {{config.general.companyShort}} eingesetzten Smart AdServer, der Firma SMART ADSERVER
    SAS, Registered office: 66 Rue de la Chaussée d’Antin 75009 der zur
    technischen Ausspielung von Werbung neben unserem eigenen System eingesetzt
    wird, werden keine personenbezogenen Daten gesammelt. Etwaige Targetings,
    die mit dem Targeting-Parameter übergeben werden, werden nicht für den User
    gespeichert. Eingesetzt wird dazu eine individuelle User ID, die es
    ermöglicht, die Häufigkeit der Anzeige von Werbemitteln innerhalb einer
    Kampagne pro User zu beschränken. Diese User ID wechselt pro App und lässt
    keinerlei Rückschlüsse auf einzelnen Personen zu.
</p>
<p>
    Die Datenschutzerklärung von Smart Adserver finden Sie unter folgendem
    Link:
</p>
<p>
    <a href="http://smartadserver.com/company/privacy-policy/.%2520">
        http://smartadserver.com/company/privacy-policy/.
    </a>
    Um sich vom Targeting durch den Smart Adserver auf Websites auszuschließen,
    haben sie darüber hinaus die Möglichkeit zum Opt.-Out mittels Klicken
    dieses Links:
    <a href="http://ww.smartadserver.com/diffx/opt%2520">
        http://ww.smartadserver.com/diffx/opt
    </a>
    Sie werden nach dem Klicken auf eine Seite weitergeleitet, die Ihr Opt-Out
    bestätigt. Danach wird der Smart AdServer keine Cookies mehr auf Ihrem
    Computer oder Ihrem Smartphone speichern.
</p>

    <h3> Soziale Netzwerke</h3>
<p>
    Du findest uns auch in sozialen Netzwerken fremder Unternehmen, wie z. B.
    Facebook oder Twitter. Zudem haben wir einzelne Funktionen dieser Netzwerke
    auch in unsere Onlinedienste integriert. Beides kannst Du allerdings nur
    nutzen, wenn Du bei dem jeweiligen sozialen Netzwerk registriert und
    angemeldet bist. Bitte beachte, dass für die Nutzung des jeweiligen
    sozialen Netzwerks die Nutzungs- und Datenschutzbedingungen dieses
    Unternehmens gelten, auf die wir keinen Einfluss haben. Wir erklären Dir
    aber gern, wie solche Netzwerke Deine personenbezogenen Daten in diesem
    Zusammenhang verarbeiten:
</p>

    <h4>Facebook</h4>
<p>
    Wir haben auf dieser Website Komponenten des Unternehmens Facebook
    integriert. Facebook ist ein soziales Netzwerk.
</p>
<p>
    Ein soziales Netzwerk ist ein im Internet betriebener sozialer Treffpunkt,
    eine Online-Gemeinschaft, die es den Nutzern in der Regel ermöglicht,
    untereinander zu kommunizieren und im virtuellen Raum zu interagieren. Ein
    soziales Netzwerk kann als Plattform zum Austausch von Meinungen und
    Erfahrungen dienen oder ermöglicht es der Internetgemeinschaft, persönliche
    oder unternehmensbezogene Informationen bereitzustellen. Facebook
    ermöglicht den Nutzern des sozialen Netzwerkes unter anderem die Erstellung
    von privaten Profilen, den Upload von Fotos und eine Vernetzung über
    Freundschaftsanfragen.
</p>
<p>
    Betreibergesellschaft von Facebook ist die Facebook Inc., 1 Hacker Way,
    Menlo Park, CA 94025, USA. Für die Verarbeitung personenbezogener Daten
    Verantwortlicher ist, wenn eine betroffene Person außerhalb der USA oder
    Kanada lebt, die Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
    Harbour, Dublin 2, Ireland.
</p>
<p>
    Durch jeden Aufruf einer der Einzelseiten dieser Website, die durch uns
    betrieben wird und auf welcher eine Facebook-Komponente (Facebook-Plug-In)
    integriert wurde, wird der Internetbrowser auf dem
    informationstechnologischen System der betroffenen Person automatisch durch
    die jeweilige Facebook-Komponente veranlasst, eine Darstellung der
    entsprechenden Facebook-Komponente von Facebook herunterzuladen. Eine
    Gesamtübersicht über alle Facebook-Plug-Ins kann unter
    <a href="https://developers.facebook.com/docs/plugins/?locale=de_DE">
        https://developers.facebook.com/docs/plugins/?locale=de_DE
    </a>
    abgerufen werden. Im Rahmen dieses technischen Verfahrens erhält Facebook
    Kenntnis darüber, welche konkrete Unterseite unserer Website durch die
    betroffene Person besucht wird.
</p>
<p>
    Sofern die betroffene Person gleichzeitig bei Facebook eingeloggt ist,
    erkennt Facebook mit jedem Aufruf unserer Website durch die betroffene
    Person und während der gesamten Dauer des jeweiligen Aufenthaltes auf
    unserer Website, welche konkrete Unterseite unserer Website die betroffene
    Person besucht. Diese Informationen werden durch die Facebook-Komponente
    gesammelt und durch Facebook dem jeweiligen Facebook-Account der
    betroffenen Person zugeordnet. Betätigt die betroffene Person einen der auf
    unserer Website integrierten Facebook-Buttons, beispielsweise den „Gefällt
    mir“-Button, oder gibt die betroffene Person einen Kommentar ab, ordnet
    Facebook diese Information dem persönlichen Facebook-Benutzerkonto der
    betroffenen Person zu und speichert diese personenbezogenen Daten.
</p>
<p>
    Facebook erhält über die Facebook-Komponente immer dann eine Information
    darüber, dass die betroffene Person unsere Website besucht hat, wenn die
    betroffene Person zum Zeitpunkt des Aufrufs unserer Website gleichzeitig
    bei Facebook eingeloggt ist; dies findet unabhängig davon statt, ob die
    betroffene Person die Facebook-Komponente anklickt oder nicht. Ist eine
    derartige Übermittlung dieser Informationen an Facebook von der betroffenen
    Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass
    sie sich vor einem Aufruf unserer Website aus ihrem Facebook-Account
    ausloggt.
</p>
<p>
    Die von Facebook veröffentlichte Datenrichtlinie, die unter
    <a href="https://de-de.facebook.com/about/privacy/">
        https://de-de.facebook.com/about/privacy/
    </a>
    abrufbar ist, gibt Aufschluss über die Erhebung, Verarbeitung und Nutzung
    personenbezogener Daten durch Facebook. Ferner wird dort erläutert, welche
    Einstellungsmöglichkeiten Facebook zum Schutz der Privatsphäre der
    betroffenen Person bietet. Zudem sind unterschiedliche Applikationen
    erhältlich, die es ermöglichen, eine Datenübermittlung an Facebook zu
    unterdrücken. Solche Applikationen können durch die betroffene Person
    genutzt werden, um eine Datenübermittlung an Facebook zu unterdrücken.
</p>

    <h4>Google+</h4>
<p>
    Wir haben auf dieser Website als Komponente die Google+ Schaltfläche
    integriert. Google+ ist ein sogenanntes soziales Netzwerk. Ein soziales
    Netzwerk ist ein im Internet betriebener sozialer Treffpunkt, eine
    Online-Gemeinschaft, die es den Nutzern in der Regel ermöglicht,
    untereinander zu kommunizieren und im virtuellen Raum zu interagieren. Ein
    soziales Netzwerk kann als Plattform zum Austausch von Meinungen und
    Erfahrungen dienen oder ermöglicht es der Internetgemeinschaft, persönliche
    oder unternehmensbezogene Informationen bereitzustellen. Google+ ermöglicht
    den Nutzern des sozialen Netzwerkes unter anderem die Erstellung von
    privaten Profilen, den Upload von Fotos und eine Vernetzung über
    Freundschaftsanfragen.
</p>
<p>
    Betreibergesellschaft von Google+ ist die Google Inc., 1600 Amphitheatre
    Pkwy, Mountain View, CA 94043-1351, USA.
</p>
<p>
    Durch jeden Aufruf einer der Einzelseiten dieser Website, die durch uns
    betrieben wird und auf welcher eine Google+ Schaltfläche integriert wurde,
    wird der Internetbrowser auf dem informationstechnologischen System der
    betroffenen Person automatisch durch die jeweilige Google+ Schaltfläche
    veranlasst, eine Darstellung der entsprechenden Google+ Schaltfläche von
    Google herunterzuladen. Im Rahmen dieses technischen Verfahrens erhält
    Google Kenntnis darüber, welche konkrete Unterseite unserer Website durch
    die betroffene Person besucht wird. Genauere Informationen zu Google+ sind
    unter
    <a href="https://developers.google.com/+/">
        https://developers.google.com/+/
    </a>
    abrufbar.
</p>
<p>
    Sofern die betroffene Person gleichzeitig bei Google+ eingeloggt ist,
    erkennt Google mit jedem Aufruf unserer Website durch die betroffene Person
    und während der gesamten Dauer des jeweiligen Aufenthaltes auf unserer
    Website, welche konkrete Unterseite unserer Website die betroffene Person
    besucht. Diese Informationen werden durch die Google+ Schaltfläche
    gesammelt und durch Google dem jeweiligen Google+-Account der betroffenen
    Person zugeordnet.
</p>
<p>
    Betätigt die betroffene Person einen der auf unserer Website integrierten
    Google+-Buttons und gibt damit eine Google+1 Empfehlung ab, ordnet Google
    diese Information dem persönlichen Google+-Benutzerkonto der betroffenen
    Person zu und speichert diese personenbezogenen Daten. Google speichert die
    Google+1-Empfehlung der betroffenen Person und macht diese in
    Übereinstimmung mit den von der betroffenen Person diesbezüglich
    akzeptierten Bedingungen öffentlich zugänglich. Eine von der betroffenen
    Person auf dieser Website abgegebene Google+1-Empfehlung wird in der Folge
    zusammen mit anderen personenbezogenen Daten, wie dem Namen des von der
    betroffenen Person genutzten Google+1-Accounts und dem in diesem
    hinterlegten Foto in anderen Google-Diensten, beispielsweise den
    Suchmaschinenergebnissen der Google-Suchmaschine, dem Google-Konto der
    betroffenen Person oder an sonstigen Stellen, beispielsweise auf Websites
    oder im Zusammenhang mit Werbeanzeigen, gespeichert und verarbeitet. Ferner
    ist Google in der Lage, den Besuch auf dieser Website mit anderen bei
    Google gespeicherten personenbezogenen Daten zu verknüpfen. Google zeichnet
    diese personenbezogenen Informationen ferner mit dem Zweck auf, die
    unterschiedlichen Dienste von Google zu verbessern oder zu optimieren.
</p>
<p>
    Google erhält über die Google+-Schaltfläche immer dann eine Information
    darüber, dass die betroffene Person unsere Website besucht hat, wenn die
    betroffene Person zum Zeitpunkt des Aufrufs unserer Website gleichzeitig
    bei Google+ eingeloggt ist; dies findet unabhängig davon statt, ob die
    betroffene Person die Google+-Schaltfläche anklickt oder nicht.
</p>
<p>
    Ist eine Übermittlung personenbezogener Daten an Google von der betroffenen
    Person nicht gewollt, kann diese eine solche Übermittlung dadurch
    verhindern, dass sie sich vor einem Aufruf unserer Website aus ihrem
    Google+-Account ausloggt.
</p>
<p>
    Weitere Informationen und die geltenden Datenschutzbestimmungen von Google
    können unter
    <a href="https://www.google.de/intl/de/policies/privacy/">
        https://www.google.de/intl/de/policies/privacy/
    </a>
    abgerufen werden. Weitere Hinweise von Google zur Google+1-Schaltfläche
    können unter
    <a href="https://developers.google.com/+/web/buttons-policy">
        https://developers.google.com/+/web/buttons-policy
    </a>
    abgerufen werden.
</p>

    <h4>Instagram</h4>
<p>
    Wir setzen Komponenten des Dienstes Instagram ein. Instagram ist ein
    Dienst, der als audiovisuelle Plattform zu qualifizieren ist und den
    Nutzern das Teilen von Fotos und Videos und zudem eine Weiterverbreitung
    solcher Daten in anderen sozialen Netzwerken ermöglicht. Betreiber von
    Instagram ist die Instagram LLC, 1 Hacker Way, Building 14 First Floor,
    Menlo Park, CA, USA. Durch jeden Aufruf einer der Einzelseiten dieser
    Website, auf der eine Instagram-Komponente (Insta-Button) integriert wurde,
    erhält Instagram Kenntnis darüber, welche konkrete Unterseite unserer
    Website durch die betroffene Person besucht wird. Sofern Sie gleichzeitig
    bei Instagram eingeloggt sind, erkennt Instagram mit jedem Aufruf unserer
    Website und während der gesamten Dauer des jeweiligen Aufenthaltes auf
    unserer Website, welche konkrete Unterseite besucht wurde. Diese
    Informationen werden durch die Instagram-Komponente gesammelt und durch
    Instagram Ihrem Instagram-Account zugeordnet. Betätigen Sie einen der auf
    unserer Website integrierten Instagram-Buttons, werden die damit
    übertragenen Daten und Informationen dem persönlichen
    Instagram-Benutzerkonto zugeordnet und von Instagram gespeichert und
    verarbeitet. Instagram erhält über die Instagram-Komponente immer dann eine
    Information darüber, dass Sie unsere Website besucht haben, wenn Sie zum
    Zeitpunkt des Aufrufs unserer Website gleichzeitig bei Instagram eingeloggt
    sind; dies findet unabhängig davon statt, ob Sie die Instagram-Komponente
    anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen
    an Instagram von Ihnen nicht gewollt, kann diese die Übermittlung dadurch
    verhindern, dass Sie sich vor einem Aufruf unserer Website aus Ihrem
    Instagram-Account ausloggt. Weitere Informationen und die geltenden
    Datenschutzbestimmungen von Instagram können unter
    <a href="https://help.instagram.com/155833707900388">
        https://help.instagram.com/155833707900388
    </a>
    und
    <a href="https://www.instagram.com/about/legal/privacy/">
        https://www.instagram.com/about/legal/privacy/
    </a>
    abgerufen werden.
</p>

    <h4>LinkedIn</h4>
<p>
    Wir haben auf dieser Website Komponenten der LinkedIn Corporation
    integriert. LinkedIn ist ein Internetbasiertes soziales Netzwerk, das eine
    Konnektierung der Nutzer mit bestehenden Geschäftskontakten sowie das
    Knüpfen von neuen Businesskontakten ermöglicht. Über 400 Millionen
    registrierte Personen nutzen LinkedIn in mehr als 200 Ländern. Damit ist
    LinkedIn derzeit die größte Plattform für Businesskontakte und eine der
    meistbesuchten Websites der Welt.
</p>
<p>
    Betreibergesellschaft von LinkedIn ist die LinkedIn Corporation, 2029
    Stierlin Court Mountain View, CA 94043, USA. Für Datenschutzangelegenheiten
    außerhalb der USA ist LinkedIn Ireland, Privacy Policy Issues, Wilton
    Plaza, Wilton Place, Dublin 2, Ireland, zuständig.
</p>
<p>
    Bei jedem einzelnen Abruf unserer Website, die mit einer
    LinkedIn-Komponente (LinkedIn-Plug-In) ausgestattet ist, veranlasst diese
    Komponente, dass der von der betroffenen Person verwendete Browser eine
    entsprechende Darstellung der Komponente von LinkedIn herunterlädt. Weitere
    Informationen zu den LinkedIn-Plug-Ins können unter
    <a href="https://developer.linkedin.com/plugins">
        https://developer.linkedin.com/plugins
    </a>
    abgerufen werden. Im Rahmen dieses technischen Verfahrens erhält LinkedIn
    Kenntnis darüber, welche konkrete Unterseite unserer Website durch die
    betroffene Person besucht wird.
</p>
<p>
    Sofern die betroffene Person gleichzeitig bei LinkedIn eingeloggt ist,
    erkennt LinkedIn mit jedem Aufruf unserer Website durch die betroffene
    Person und während der gesamten Dauer des jeweiligen Aufenthaltes auf
    unserer Website, welche konkrete Unterseite unserer Website die betroffene
    Person besucht. Diese Informationen werden durch die LinkedIn-Komponente
    gesammelt und durch LinkedIn dem jeweiligen LinkedIn-Account der
    betroffenen Person zugeordnet. Betätigt die betroffene Person einen auf
    unserer Website integrierten LinkedIn-Button, ordnet LinkedIn diese
    Information dem persönlichen LinkedIn-Benutzerkonto der betroffenen Person
    zu und speichert diese personenbezogenen Daten.
</p>
<p>
    LinkedIn erhält über die LinkedIn-Komponente immer dann eine Information
    darüber, dass die betroffene Person unsere Website besucht hat, wenn die
    betroffene Person zum Zeitpunkt des Aufrufes unserer Website gleichzeitig
    bei LinkedIn eingeloggt ist; dies findet unabhängig davon statt, ob die
    betroffene Person die LinkedIn-Komponente anklickt oder nicht. Ist eine
    derartige Übermittlung dieser Informationen an LinkedIn von der betroffenen
    Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass
    sie sich vor einem Aufruf unserer Website aus ihrem LinkedIn-Account
    ausloggt.
</p>
<p>
    LinkedIn bietet unter
    <a href="https://www.linkedin.com/psettings/guest-controls">
        https://www.linkedin.com/psettings/guest-controls
    </a>
    die Möglichkeit, E-Mail-Nachrichten, SMS-Nachrichten und zielgerichtete
    Anzeigen abzubestellen sowie Anzeigen-Einstellungen zu verwalten. LinkedIn
    nutzt ferner Partner wie Quantcast, Google Analytics, BlueKai, DoubleClick,
    Nielsen, Comscore, Eloqua und Lotame, die Cookies setzen können. Solche
    Cookies können unter
    <a href="https://www.linkedin.com/legal/cookie-policy">
        https://www.linkedin.com/legal/cookie-policy
    </a>
    abgelehnt werden. Die geltenden Datenschutzbestimmungen von LinkedIn sind
    unter
    <a href="https://www.linkedin.com/legal/privacy-policy">
        https://www.linkedin.com/legal/privacy-policy
    </a>
    abrufbar. Die Cookie-Richtlinie von LinkedIn ist unter
    <a href="https://www.linkedin.com/legal/cookie-policy">
        https://www.linkedin.com/legal/cookie-policy
    </a>
    abrufbar.
</p>

    <h4>Pinterest</h4>
<p>
    Wir haben auf dieser Website Komponenten der Pinterest Inc. integriert.
    Pinterest ist ein sogenanntes soziales Netzwerk. Ein soziales Netzwerk ist
    ein im Internet betriebener sozialer Treffpunkt, eine Online-Gemeinschaft,
    die es den Nutzern in der Regel ermöglicht, untereinander zu kommunizieren
    und im virtuellen Raum zu interagieren. Ein soziales Netzwerk kann als
    Plattform zum Austausch von Meinungen und Erfahrungen dienen oder
    ermöglicht es der Internetgemeinschaft, persönliche oder
    unternehmensbezogene Informationen bereitzustellen. Pinterest ermöglicht
    den Nutzern des sozialen Netzwerkes unter anderem, Bilderkollektionen und
    Einzelbilder sowie Beschreibungen an virtuellen Pinnwänden zu
    veröffentlichen (sogenanntes pinnen), welche dann wiederum von anderen
    Nutzern geteilt (sogenanntes repinnen) oder kommentiert werden können.
</p>
<p>
    Betreibergesellschaft von Pinterest ist die Pinterest Inc., 808 Brannan
    Street, San Francisco, CA 94103, USA.
</p>
<p>
    Durch jeden Aufruf einer der Einzelseiten dieser Website, die durch uns
    betrieben wird und auf welcher eine Pinterest-Komponente
    (Pinterest-Plug-In) integriert wurde, wird der Internetbrowser auf dem
    informationstechnologischen System der betroffenen Person automatisch durch
    die jeweilige Pinterest-Komponente veranlasst, eine Darstellung der
    entsprechenden Pinterest-Komponente von Pinterest herunterzuladen. Mehr
Informationen zu Pinterest sind unter    <a href="https://pinterest.com/"> https://pinterest.com/</a> abrufbar. Im
    Rahmen dieses technischen Verfahrens erhält Pinterest Kenntnis darüber,
    welche konkrete Unterseite unserer Website durch die betroffene Person
    besucht wird.
</p>
<p>
    Sofern die betroffene Person gleichzeitig bei Pinterest eingeloggt ist,
    erkennt Pinterest mit jedem Aufruf unserer Website durch die betroffene
    Person und während der gesamten Dauer des jeweiligen Aufenthaltes auf
    unserer Website, welche konkrete Unterseite unserer Website die betroffene
    Person besucht. Diese Informationen werden durch die Pinterest-Komponente
    gesammelt und durch Pinterest dem jeweiligen Pinterest-Account der
    betroffenen Person zugeordnet. Betätigt die betroffene Person einen auf
    unserer Website integrierten Pinterest-Button, ordnet Pinterest diese
    Information dem persönlichen Pinterest-Benutzerkonto der betroffenen Person
    zu und speichert diese personenbezogenen Daten.
</p>
<p>
    Pinterest erhält über die Pinterest-Komponente immer dann eine Information
    darüber, dass die betroffene Person unsere Website besucht hat, wenn die
    betroffene Person zum Zeitpunkt des Aufrufs unserer Website gleichzeitig
    bei Pinterest eingeloggt ist; dies findet unabhängig davon statt, ob die
    betroffene Person die Pinterest-Komponente anklickt oder nicht. Ist eine
    derartige Übermittlung dieser Informationen an Pinterest von der
    betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch
    verhindern, dass sie sich vor einem Aufruf unserer Website aus ihrem
    Pinterest-Account ausloggt.
</p>
<p>
    Die von Pinterest veröffentlichte Datenschutzrichtlinie, die unter
    <a href="https://about.pinterest.com/privacy-policy">
        https://about.pinterest.com/privacy-policy
    </a>
    abrufbar ist, gibt Aufschluss über die Erhebung, Verarbeitung und Nutzung
    personenbezogener Daten durch Pinterest.
</p>

    <h4>Twitter</h4>
<p>
    Wir haben auf dieser Website Komponenten von Twitter integriert. Twitter
    ist ein multilingualer öffentlich zugänglicher Mikroblogging-Dienst, auf
    welchem die Nutzer sogenannte Tweets, also Kurznachrichten, die auf 140
    Zeichen begrenzt sind, veröffentlichen und verbreiten können. Diese
    Kurznachrichten sind für jedermann, also auch für nicht bei Twitter
    angemeldete Personen abrufbar. Die Tweets werden aber auch den sogenannten
    Followern des jeweiligen Nutzers angezeigt. Follower sind andere
    Twitter-Nutzer, die den Tweets eines Nutzers folgen. Ferner ermöglicht
    Twitter über Hashtags, Verlinkungen oder Retweets die Ansprache eines
    breiten Publikums.
</p>
<p>
    Betreibergesellschaft von Twitter ist die Twitter Inc., 1355 Market Street,
    Suite 900, San Francisco, CA 94103, USA.
</p>
<p>
    Durch jeden Aufruf einer der Einzelseiten dieser Website, die durch den für
    uns betrieben wird und auf welcher eine Twitter-Komponente (Twitter-Button)
    integriert wurde, wird der Internetbrowser auf dem
    informationstechnologischen System der betroffenen Person automatisch durch
    die jeweilige Twitter-Komponente veranlasst, eine Darstellung der
    entsprechenden Twitter-Komponente von Twitter herunterzuladen. Weitere
    Informationen zu den Twitter-Buttons sind unter
    <a href="https://about.twitter.com/de/resources/buttons">
        https://about.twitter.com/de/resources/buttons
    </a>
    abrufbar. Im Rahmen dieses technischen Verfahrens erhält Twitter Kenntnis
    darüber, welche konkrete Unterseite unserer Website durch die betroffene
    Person besucht wird. Zweck der Integration der Twitter-Komponente ist es,
    unseren Nutzern eine Weiterverbreitung der Inhalte diese Website zu
    ermöglichen, diese Website in der digitalen Welt bekannt zu machen und
    unsere Besucherzahlen zu erhöhen.
</p>
<p>
    Sofern die betroffene Person gleichzeitig bei Twitter eingeloggt ist,
    erkennt Twitter mit jedem Aufruf unserer Website durch die betroffene
    Person und während der gesamten Dauer des jeweiligen Aufenthaltes auf
    unserer Website, welche konkrete Unterseite unserer Website die betroffene
    Person besucht. Diese Informationen werden durch die Twitter-Komponente
    gesammelt und durch Twitter dem jeweiligen Twitter-Account der betroffenen
    Person zugeordnet. Betätigt die betroffene Person einen der auf unserer
    Website integrierten Twitter-Buttons, werden die damit übertragenen Daten
    und Informationen dem persönlichen Twitter-Benutzerkonto der betroffenen
    Person zugeordnet und von Twitter gespeichert und verarbeitet.
</p>
<p>
    Twitter erhält über die Twitter-Komponente immer dann eine Information
    darüber, dass die betroffene Person unsere Website besucht hat, wenn die
    betroffene Person zum Zeitpunkt des Aufrufs unserer Website gleichzeitig
    bei Twitter eingeloggt ist; dies findet unabhängig davon statt, ob die
    betroffene Person die Twitter-Komponente anklickt oder nicht. Ist eine
    derartige Übermittlung dieser Informationen an Twitter von der betroffenen
    Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass
    sie sich vor einem Aufruf unserer Website aus ihrem Twitter-Account
    ausloggt.
</p>
<p>
    Die geltenden Datenschutzbestimmungen von Twitter sind unter
    <a href="https://twitter.com/privacy?lang=de">
        ttps://twitter.com/privacy?lang=de
    </a>
    abrufbar.
</p>

    <h4>Xing</h4>
<p>
    Wir haben auf dieser Website Komponenten von Xing integriert. Xing ist ein
    Internetbasiertes soziales Netzwerk, das die Konnektierung der Nutzer mit
    bestehenden Geschäftskontakten sowie das Knüpfen von neuen
    Businesskontakten ermöglicht. Die einzelnen Nutzer können bei Xing ein
    persönliches Profil von sich anlegen. Unternehmen können beispielsweise
    Unternehmensprofile erstellen oder Stellenangebote auf Xing
    veröffentlichen.
</p>
<p>
    Betreibergesellschaft von Xing ist die XING SE, Dammtorstraße 30, 20354
    Hamburg, Deutschland.
</p>
<p>
    Durch jeden Aufruf einer der Einzelseiten dieser Website, die durch den für
    die uns betrieben wird und auf welcher eine Xing-Komponente (Xing-Plug-In)
    integriert wurde, wird der Internetbrowser auf dem
    informationstechnologischen System der betroffenen Person automatisch durch
    die jeweilige Xing-Komponente veranlasst, eine Darstellung der
    entsprechenden Xing-Komponente von Xing herunterzuladen. Weitere
Informationen zum den Xing-Plug-Ins können unter    <a href="https://dev.xing.com/plugins"> https://dev.xing.com/plugins</a>
    abgerufen werden. Im Rahmen dieses technischen Verfahrens erhält Xing
    Kenntnis darüber, welche konkrete Unterseite unserer Website durch die
    betroffene Person besucht wird.
</p>
<p>
    Sofern die betroffene Person gleichzeitig bei Xing eingeloggt ist, erkennt
    Xing mit jedem Aufruf unserer Website durch die betroffene Person und
    während der gesamten Dauer des jeweiligen Aufenthaltes auf unserer Website,
    welche konkrete Unterseite unserer Website die betroffene Person besucht.
    Diese Informationen werden durch die Xing-Komponente gesammelt und durch
    Xing dem jeweiligen Xing-Account der betroffenen Person zugeordnet.
    Betätigt die betroffene Person einen der auf unserer Website integrierten
    Xing-Buttons, beispielsweise den „Share“-Button, ordnet Xing diese
    Information dem persönlichen Xing-Benutzerkonto der betroffenen Person zu
    und speichert diese personenbezogenen Daten.
</p>
<p>
    Xing erhält über die Xing-Komponente immer dann eine Information darüber,
    dass die betroffene Person unsere Website besucht hat, wenn die betroffene
    Person zum Zeitpunkt des Aufrufs unserer Website gleichzeitig bei Xing
    eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene
    Person die Xing-Komponente anklickt oder nicht. Ist eine derartige
    Übermittlung dieser Informationen an Xing von der betroffenen Person nicht
    gewollt, kann diese die Übermittlung dadurch verhindern, dass sie sich vor
    einem Aufruf unserer Website aus ihrem Xing-Account ausloggt.
</p>
<p>
Die von Xing veröffentlichten Datenschutzbestimmungen, die unter    <a href="https://www.xing.com/privacy"> https://www.xing.com/privacy</a>
    abrufbar sind, geben Aufschluss über die Erhebung, Verarbeitung und Nutzung
    personenbezogener Daten durch Xing. Ferner hat Xing unter
    <a
        href="https://www.xing.com/app/share?op=data_protection%2520Datenschutzhinweise"
    >
        https://www.xing.com/app/share?op=data_protection Datenschutzhinweise
    </a>
    für den XING-Share-Button veröffentlicht.
</p>

    <h4>YouTube</h4>
<p>
    Wir haben auf dieser Website Komponenten von YouTube integriert. YouTube
    ist ein Internet-Videoportal, dass Video-Publishern das kostenlose
    Einstellen von Videoclips und anderen Nutzern die ebenfalls kostenfreie
    Betrachtung, Bewertung und Kommentierung dieser ermöglicht. YouTube
    gestattet die Publikation aller Arten von Videos, weshalb sowohl komplette
    Film- und Fernsehsendungen, aber auch Musikvideos, Trailer oder von Nutzern
    selbst angefertigte Videos über das Internetportal abrufbar sind.
</p>
<p>
    Betreibergesellschaft von YouTube ist die YouTube, LLC, 901 Cherry Ave.,
    San Bruno, CA 94066, USA. Die YouTube, LLC ist einer Tochtergesellschaft
    der Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
</p>
<p>
    Durch jeden Aufruf einer der Einzelseiten dieser Website, die durch den für
    uns betrieben wird und auf welcher eine YouTube-Komponente (YouTube-Video)
    integriert wurde, wird der Internetbrowser auf dem
    informationstechnologischen System der betroffenen Person automatisch durch
    die jeweilige YouTube-Komponente veranlasst, eine Darstellung der
    entsprechenden YouTube-Komponente von YouTube herunterzuladen. Weitere
    Informationen zu YouTube können unter
    <a href="https://www.youtube.com/yt/about/de/">
        https://www.youtube.com/yt/about/de/
    </a>
    abgerufen werden. Im Rahmen dieses technischen Verfahrens erhalten YouTube
    und Google Kenntnis darüber, welche konkrete Unterseite unserer Website
    durch die betroffene Person besucht wird.
</p>
<p>
    Sofern die betroffene Person gleichzeitig bei YouTube eingeloggt ist,
    erkennt YouTube mit dem Aufruf einer Unterseite, die ein YouTube-Video
    enthält, welche konkrete Unterseite unserer Website die betroffene Person
    besucht. Diese Informationen werden durch YouTube und Google gesammelt und
    dem jeweiligen YouTube-Account der betroffenen Person zugeordnet.
</p>
<p>
    YouTube und Google erhalten über die YouTube-Komponente immer dann eine
    Information darüber, dass die betroffene Person unsere Website besucht hat,
    wenn die betroffene Person zum Zeitpunkt des Aufrufs unserer Website
    gleichzeitig bei YouTube eingeloggt ist; dies findet unabhängig davon
    statt, ob die betroffene Person ein YouTube-Video anklickt oder nicht. Ist
    eine derartige Übermittlung dieser Informationen an YouTube und Google von
    der betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch
    verhindern, dass sie sich vor einem Aufruf unserer Website aus ihrem
    YouTube-Account ausloggt.
</p>
<p>
    Die von YouTube veröffentlichten Datenschutzbestimmungen, die unter
    <a href="https://www.google.de/intl/de/policies/privacy/">
        https://www.google.de/intl/de/policies/privacy/
    </a>
    abrufbar sind, geben Aufschluss über die Erhebung, Verarbeitung und Nutzung
    personenbezogener Daten durch YouTube und Google.
</p>
<p>
    Auf Smartphones und Tablets werden die vorgenannten Services häufig nicht
    durch Plug-ins umgesetzt, sondern durch eine geräteinterne
    „Teilen“-Funktion. Entsprechend dessen Einstellungen können Informationen
    auch an weitere Social-Media-Dienstleister gegeben werden. Bitte entnehmen
    Sie Details hierzu Ihren Geräteinformationen.
</p>

    <h3>Zahlungsdienstleister</h3>
<p>
    Für den Fall, dass Du einen zahlungspflichtigen Dienst nutzt oder etwas
    über unsere Website / App erwirbst, bieten wir verschiedene
    Zahlungsmethoden an. Solltest Du sich dafür entscheiden, einen dieser
    Zahlungsdienstleister zu nutzen, verlässt Du unsere Seite. Sämtliche Daten
    werden dann von diesem Zahlungsdienstleister erhoben und verarbeitet. Wir
    erhalten dabei keinerlei personenbezogene Daten, insbesondere keine Bank-
    oder Kreditkartendaten, sondern lediglich die Information, dass die Zahlung
    erfolgreich getätigt wurde. Die folgenden Zahlungsdienstleister stehen Dir
    zur Verfügung:
</p>

    <h4>Wirecard AG </h4>
<p>
    Wirecard ist unser Zahlungsabwickler für {{config.general.companyShort}} Plus. Wirecard entwickelt
    individuelle Lösungen für Kunden aus Handel, Service und Banken. Damit
    schaffen wir die Voraussetzung für eine sichere, transparente, effiziente
    und auch grenzüberschreitende Zahlungsabwicklung. Wir übermitteln an
    Wirecard folgendene Daten: Vor- und Nachnahme, Kreditkartendaten. Wirecard
    AG Einsteinring 35 85609 Aschheim Deutschland. Weitere Informationen
    findest Du unter
    <a href="https://www.wirecard.de/datenschutz/">
        https://www.wirecard.de/datenschutz/
    </a>
    .
</p>

    <h3>Sonstige Dienste</h3>
<p>
    Weitere Dienste, die wir verwenden sind:
</p>

    <h4>Facebook Connect</h4>
<p>
    Du kannst Dich bei der Abgabe von Kommentaren auf bestimmten
    {{config.general.companyShort}}-Seiten auch über einen Facebook-Account einloggen.
</p>
<p>
    Facebook Connect ist ein Angebot von Facebook Ireland Limited, 5-7 Hanover
    Quay, Dublin 2, Ireland. Die Nutzung von Facebook Connect unterliegt den
    Datenschutzbedingungen und Nutzungsbedingungen von Facebook. Entscheidest
    Du dich für die Registrierung mit einem Facebook-Konto, wirst Du in einem
    ersten Schritt unmittelbar zu Facebook weitergeleitet. Dort bittet Facebook
    darum, die Zugangsdaten anzugeben und sich bei Facebook anzumelden oder zu
    registrieren. Ist man in diesem Moment bereits bei Facebook eingeloggt,
    wird diese Abfrage zur Anmeldung übersprungen. Wichtig: Wir erhalten
    dadurch keine Zugangsdaten. Bei der Anmeldung über Facebook Connect werden
    Facebook-Profildaten und laut Facebook-Definition „öffentliche
    Informationen“ (
    <a href="https://www.facebook.com/about/privacy/your-info/">
        https://www.facebook.com/about/privacy/your-info/
    </a>
    ) aus Deinem Facebook-Profil an uns übertragen, also solche, die Du
    öffentlich zugänglich machst oder für die jeweilige Anwendung freigibst.
    „Öffentlich“ bedeutet im Zusammenhang mit Facebook, dass jeder auch
    außerhalb von Facebook diese Daten sehen kann. Hierzu zählen Dein Name,
    Dein Profil- und Titelbild, Dein Geschlecht, Netzwerke, Nutzername
    (Facebook URL) und Nutzerkennnummer (Facebook ID). Umgekehrt können auch
    Daten von uns an Dein Facebook-Profil übertragen werden. Wenn Du dich über
    Facebook Connect bei uns anmeldest, speichern und verarbeiten wir Deine an
    uns übertragenen Daten zum Zweck der Registrierung.
</p>

    <h4>Mailchimp</h4>
<p>
    Der Versand der Newsletter erfolgt mittels des Versanddienstleisters
    „MailChimp“, einer Newsletterversandplattform des US-Anbieters Rocket
    Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA.
    Die Datenschutzbestimmungen des Versanddienstleisters können Sie hier
    einsehen:
    <a href="https://mailchimp.com/legal/privacy/.%2520">
        https://mailchimp.com/legal/privacy/.
    </a>
    The Rocket Science Group LLC d/b/a MailChimp ist unter dem
    Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie,
    das europäisches Datenschutzniveau einzuhalten (
    <a
        href="https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&amp;status=Active"
    >
        https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&amp;status=Active
    </a>
    ). Der Versanddienstleister wird auf Grundlage unserer berechtigten
    Interessen gem. Art. 6 Abs. 1 lit. f DSGVO und eines
    Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DSGVO eingesetzt.
    Der Versanddienstleister kann die Daten der Empfänger in pseudonymer Form,
    d.h. ohne Zuordnung zu einem Nutzer, zur Optimierung oder Verbesserung der
    eigenen Services nutzen, z.B. zur technischen Optimierung des Versandes und
    der Darstellung der Newsletter oder für statistische Zwecke verwenden. Der
    Versanddienstleister nutzt die Daten unserer Newsletterempfänger jedoch
    nicht, um diese selbst anzuschreiben oder um die Daten an Dritte
    weiterzugeben.
</p>
<p>
    Ihre bei der Newsletter Anmeldung gespeicherten Daten (E-Mail-Adresse, ggf.
    Name, IP-Adresse, Datum sowie die Uhrzeit Ihrer Anmeldung) werden an einen
    Server der Firma The Rocket Science Group in den USA übertragen und dort
    unter Beachtung des „EU-US Privacy Shield“ gespeichert.
</p>
<p>
    Weitere Informationen zum Datenschutz bei MailChimp finden Sie unter:
    <a href="http://mailchimp.com/legal/privacy/">
        http://mailchimp.com/legal/privacy/
    </a>
</p>
<p>
    Weitere Informationen zu dem „EU-US Privacy Shield“ finden Sie unter:
</p>
<p>
    <a
        href="https://www.bfdi.bund.de/DE/Europa_International/International/Artikel/EU-US_PrivacyShield_Daten%25C3%25BCbermittlungenUSA.html?nn=5217040"
    >
        Die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
    </a>
</p>
<p>
    <a
        href="http://ec.europa.eu/justice/data-protection/international-transfers/eu-us-privacy-shield/index_en.htm"
    >
        http://ec.europa.eu/justice/data-protection/international-transfers/eu-us-privacy-shield/index_en.htm
    </a>
</p>
<p>
    Das Abonnement dieses Newsletters und damit die Einwilligung in die
    Speicherung Ihrer Daten können Sie jederzeit für die Zukunft kündigen bzw.
    widerrufen. Einzelheiten hierzu können Sie der Bestätigungsmail sowie jedem
    einzelnen Newsletter entnehmen.
</p>
<p>
    Newsletter – Erfolgsmessung
</p>
<p>
    Die Newsletter enthalten einen sog. „web-beacon“, d.h. eine pixelgroße
    Datei, die beim Öffnen des Newsletters von unserem Server, bzw. sofern wir
    einen Versanddienstleister einsetzen, von dessen Server abgerufen wird. Im
    Rahmen dieses Abrufs werden zunächst technische Informationen, wie
    Informationen zum Browser und Ihrem System, als auch Ihre IP-Adresse und
    Zeitpunkt des Abrufs erhoben. Diese Informationen werden zur technischen
    Verbesserung der Services anhand der technischen Daten oder der Zielgruppen
    und ihres Leseverhaltens anhand derer Abruforte (die mit Hilfe der
    IP-Adresse bestimmbar sind) oder der Zugriffszeiten genutzt. Zu den
    statistischen Erhebungen gehört ebenfalls die Feststellung, ob die
    Newsletter geöffnet werden, wann sie geöffnet werden und welche Links
    geklickt werden. Diese Informationen können aus technischen Gründen zwar
    den einzelnen Newsletterempfängern zugeordnet werden. Es ist jedoch weder
    unser Bestreben, noch, sofern eingesetzt, das des Versanddienstleisters,
    einzelne Nutzer zu beobachten. Die Auswertungen dienen uns viel mehr dazu,
    die Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte auf sie
    anzupassen oder unterschiedliche Inhalte entsprechend den Interessen
    unserer Nutzer zu versenden.
</p>

    <h4>SMPL – simpleTechs</h4>
<p>
    Die Datenerfassung sowie Datenspeicherung für einen „{{config.general.name}} Konto“ Account
    erfolgt in unserem Auftrag über die simpleTechs GmbH, Kurfürstendamm 193C, 10707 Berlin.
</p>
<p>
    Dabei werden Daten wie die E-Mail-Adresse, die ID’s der angeschauten Filme
    &amp; ein Zeitstempel bei Abbruch des Filmes erhoben und gespeichert, um
    dem User die bestmögliche Usability zu gewährleisten.
</p>
<p>
    Weitere Informationen zum Datenschutz bei simpleTechs finden Sie unter:
    <a href="https://www.simpletechs.net/datenschutz">
        https://www.simpletechs.net/datenschutz
    </a>
</p>

    <h3>Speicherdauer</h3>
<p>
    Wir speichern personenbezogene Daten nur solange wir dazu berechtigt sind
    und der Verarbeitungszweck nicht entfallen ist. Danach werden sie binnen
    kurzer Frist gelöscht.
</p>
<p>
    Neben den Angaben zur Speicherdauer bestimmter Daten an anderen Stelle in
    dieser Datenschutzerklärung informieren wir dich wie folgt:
</p>
<ul>
    <li>
        Hast Du ein Kundenkonto (dazu s.o.), werden Deine Bestellungen und
        Buchungen dort grundsätzlich so lange gespeichert, bis Du Dein Konto
        wieder löschst. Länger als vier Jahre zurückliegende Vorgänge werden
        danach sofort, jüngere Vorgänge rollierend mit Ablauf des vierten
        Jahres seit Buchung gelöscht. Nutzen Duals Gast die
        Direktbuchen-Funktion, so löschen wir die so erhobenen Daten nach einem
        Zeitraum von vier Jahren ab der Buchung. Die (sonstigen) Daten des
        Kundenkontos speichern wir so lange, bis Du Dein Konto wieder löschst
        bzw. Dich länger als 13 Monate nicht angemeldet hast.
    </li>
    <li>
        Die Verarbeitung von Daten auf der Grundlage Deiner Einwilligung wird
        solange vorgenommen und die Daten werden so lange gespeichert, bis Du
        Deine Einwilligung uns gegenüber widerrufst.
    </li>
    <li>
        Die genaue Speicherdauer eines Cookies kannst dem jeweiligen Cookie
        entnehmen, indem Du Dir den Cookie in Deinem Browser anzeigen lässt.
    </li>
    <li>
        Zudem sehen die geltenden Gesetze bestimmte Mindestaufbewahrungsdauern
        vor. So verlangt das HGB und die AO die Aufbewahrung von
        Geschäftsbriefen für (mindestens) 6 Jahre; „Geschäftsbriefe“ sind dabei
        auch alle E-Mails, die wir mit Dir spezifisch austauschen. Die AO
        (Abgabenordnung) verlangt zudem für eine Reihe von anderen Daten eine
        Aufbewahrung von mindestens 10 Jahren.
    </li>
</ul>
<p>
    Es ist uns indes nicht möglich, für alle Daten bzw. Datenkategorien die
    Speicherdauer exakt anzugeben. Wir richten und bei der Speicherdauer strikt
    an rechtlichen Erwägungen aus. Sind, zum Beispiel, aus einem Vertrag noch
    Ansprüche möglich, halten wir die entsprechenden Daten gespeichert, und bei
    der Anwendung von Art. 6 Abs. 1 lit. f DSGVO begrenzen Deine Interessen
    bzw. Grundrechte und Grundfreiheiten im Rahmen der vorzunehmenden Abwägung
    die Speicherdauer und bestimmten diese wesentlich mit.
</p>

    <h3>Wiederholung von Einwilligungstexten</h3>
<p>
    Im Folgenden finden Sie die Texte der Einwilligungen, die {{config.general.companyShort}} auf
    seinen Webseiten nutzt und die Sie {{config.general.companyShort}} ggf. erteilt haben. {{config.general.companyShort}}
    hat diese Einwilligungen dann ggf. protokolliert. Sie können Ihre
    Einwilligung(en) jederzeit mit Wirkung für die Zukunft widerrufen.
</p>
<p>
    Registrierung für das „Mein {{config.general.companyShort}}” Kundenkonto
</p>
<ul>
    <li>
        {{config.general.companyShort}} internet GmbH darf mir auf Basis meiner Interessen produkt-
        und {{config.general.companyShort}}-bezogene Nachrichten per E-Mail senden. Diese Einwilligung
        kann ich jederzeit widerrufen. Ich habe die Datenschutzerklärung zur
        Kenntnis genommen und stimme den Nutzungsbedingungen zu.
    </li>
</ul>
<p>
    Erlaubnis zur E-Mail-Werbung
</p>
<ul>
    <li>
        {{config.general.companyShort}} Schnäppchen &amp; Aktionen per E-Mail
    </li>
    <li>
        {{config.general.companyShort}} informiert Dich über Händler-Angebote und {{config.general.companyShort}} Dienste. Du
        kannst Dich jederzeit wie in den Datenschutzrichtlinien beschrieben
        abmelden.
    </li>
    <li>
        {{config.general.companyShort}} informiert Dich über Händler-Angebote und {{config.general.companyShort}} Dienste. Du
        kannst Dich jederzeit wieder abmelden.
    </li>
    <li>
        {{config.general.companyShort}} Schnäppchen, Aktionen &amp; News per E-Mail erhalten.
        Abmeldung jederzeit möglich.
    </li>
    <li>
        Ich bin einverstanden, dass {{config.general.companyShort}} mich über Händler-Angebote und
        {{config.general.companyShort}} Dienste informiert. Meine Einwilligung kann ich jederzeit
        widerrufen, siehe Datenschutzerklärung.
    </li>
</ul>

    <h3> Kontaktdaten und Deine Rechte als betroffene Person</h3>
<p>
    Bitte wende Dich bei Fragen und Anregungen zum Datenschutz sowie zur
    Ausübung Deiner Rechte als betroffene Person (zu diesen sogleich) jederzeit
    an unseren Datenschutzbeauftragten:
</p>
<p class="address">
    {{{config.general.privacyContact}}}
</p>

    <h4>
            Widerruf von Einwilligungen / Widerspruch gegen die
            Datenverarbeitung
    </h4>
<div class="revoke-block">
    <p>
        Deine abgegebenen Einwilligungen kannst Du jederzeit mit
        Wirkung für die Zukunft unter der. o. g. Kontaktadresse
        widerrufen. Insbesondere kannst Du der Verwendung Deiner
        E-Mail-Adresse zum Zwecke des Newsletterversandes jederzeit
        schriftlich oder in Textform an {{config.general.newsletterUnsubscribe}}
        oder Ritterstraße 11 in 10969 Berlin mit Wirkung für die
        Zukunft widersprechen, ohne dass hierfür andere als die
        Übermittlungskosten nach den Basistarifen entstehen.
    </p>
    <p>
        Ferner hast Du das Recht aus Gründen, die sich aus Deiner
        besonderen Situation ergeben, jederzeit gegen die
        Verarbeitung Dich betreffender personenbezogener Daten, die
        aufgrund eines berechtigten oder öffentlichen Interesses
        erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf
        diese Bestimmungen gestütztes Profiling. Wir verarbeiten
        die personenbezogenen Daten im Falle des Widerspruchs nicht
        mehr, es sei denn, wir können zwingende schutzwürdige
        Gründe für die Verarbeitung nachweisen, die den Interessen,
        Rechten und Freiheiten der betroffenen Person überwiegen,
        oder die Verarbeitung dient der Geltendmachung, Ausübung
        oder Verteidigung von Rechtsansprüchen.
    </p>
    <p>
        Sofern wir personenbezogene Daten verarbeiten, um
        Direktwerbung zu betreiben, so hast Du das Recht, jederzeit
        Widerspruch gegen die Verarbeitung der personenbezogenen
        Daten zum Zwecke derartiger Werbung unter der. o. g.
        Kontaktadresse einzulegen. Dies gilt auch für das
        Profiling, soweit es mit solcher Direktwerbung in
        Verbindung steht. Zudem hast Du das Recht, aus Gründen, die
        sich aus Deiner besonderen Situation ergeben, gegen die
        Dich betreffende Verarbeitung personenbezogener Daten, die
        bei uns zu wissenschaftlichen oder historischen
        Forschungszwecken oder zu statistischen Zwecken erfolgen,
        Widerspruch einzulegen, es sei denn, eine solche
        Verarbeitung ist zur Erfüllung einer im öffentlichen
        Interesse liegenden Aufgabe erforderlich.
    </p>
</div>


    <h4>Art. 15 DS-GVO – Auskunftsrecht der betroffenen Person</h4>
<p>
    Du hast das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie
    betreffende personenbezogene Daten verarbeitet werden und wenn ja, welche
    dies sind sowie die näheren Umstände der Datenverarbeitung.
</p>

    <h4>Art. 16 DS-GVO – Recht auf Berichtigung:</h4>
<p>
    Du hast das Recht, von uns unverzüglich die Berichtigung Dich betreffender
    unrichtiger personenbezogener Daten zu verlangen. Dabei hast Du unter
    Berücksichtigung der Zwecke der Verarbeitung auch das Recht, die
    Vervollständigung unvollständiger personenbezogener Daten – auch mittels
    einer ergänzenden Erklärung – zu verlangen.
</p>

    <h4>Art. 17 DS-GVO – Recht auf Löschung: </h4>
<p>
    Du hast das Recht, von uns zu verlangen, dass Dich betreffende
    personenbezogene Daten unverzüglich gelöscht werden, wenn und soweit dafür
    die gesetzlichen Voraussetzungen vorliegen.
</p>

    <h4>
            Art. 18 DS-GVO – Recht auf Einschränkung der Verarbeitung:
    </h4>
<p>
    Du hast das Recht, von uns bei Vorliegen der gesetzlichen Voraussetzungen
    die Einschränkung der Verarbeitung zu verlangen.
</p>

    <h4>Art. 20 DS-GVO – Recht auf Datenübertragbarkeit: </h4>
<p>
    Du hast das Recht, im Falle der Verarbeitung aufgrund einer Einwilligung
    oder zur Erfüllung eines Vertrags, die Dich betreffenden personenbezogenen
    Daten, die Du uns bereitgestellt haben, in einem strukturierten, gängigen
    und maschinenlesbaren Format zu erhalten, und diese Daten einem anderen
    Verantwortlichen ohne Behinderung durch uns zu übermitteln oder die Daten
    direkt an den anderen Verantwortlichen übermitteln zu lassen, soweit dies
    technisch machbar ist.
</p>

    <h4>
            Art. 77 DSGVO i. V. m. § 19 BDSG (neu) – Recht auf Beschwerde bei
            einer Aufsichtsbehörde:
    </h4>
<p>
    Du hast das Recht, jederzeit Beschwerde bei einer Aufsichtsbehörde,
    insbesondere in dem Mitgliedstaat Deines Aufenthaltsorts, Deines
    Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, einzulegen, wenn
    Du der Ansicht bist, dass die Verarbeitung der sie betreffenden
    personenbezogenen Daten gegen geltendes Recht verstößt.
</p>

    <h4>Bestehen einer automatisierten Entscheidungsfindung</h4>
<p>
    Wir verzichten auf eine automatische Entscheidungsfindung oder ein
    Profiling.
</p>

<p>
    <strong>Stand:</strong>
    30. April 2021
</p>

<p>
<div data-nx-container="privacy"></div>
</p>
        </div>
    </div>
</div>
