import can from "can";
import oguryCmp from "../../../lib/ogury";
import utils from "../../../lib/utils";
import config from "../../config";
import ContentTracker from "../../modules/contentTracker";
import watchlist from "../../modules/watchlist";
import state from "../../state";
import View from "./view.stache";

function loadVideoJSWithIMA() {
    return Promise.resolve(
        can.$.getScript("https://imasdk.googleapis.com/js/sdkloader/ima3.js")
    ).then(() => {
        return import("./videojsDependencies").then(({ videoJs }) => videoJs);
    });
}

var videoElement;
var videojsPlayer;
var videojsRef;
var tracker;
var isImaScriptLoaded = false;

function initIMAPlayer(movie, tracker, isPlus) {
    videoElement = document.getElementById("content_video");

    // chrome adds the ability to download any movie by default. We can disable this video the controlList
    try {
        // var video = el.find("video")[0];
        if (videoElement && videoElement.controlsList) {
            videoElement.controlsList.add("nodownload");
        }
    } catch (err) {
        // do not break playback
        config.log("could not disable video download", err);
    }
    // // disable context menu for video element to make download more difficult
    // $("video").bind("contextmenu", function () {
    //     return false;
    // });
    videoElement.setAttribute(
        "poster",
        movie.attr("custom_fields.featured_img_all.0")
    );
    initVideoPlayerSource();

    tracker.contentDisplayed();

    //check if the movie is stored in the watchlist and seek respectively
    checkWatchlist();

    setTimeout(requestAds, 100);

    // Set up tracking for video controlls (play,pause,timeupdate)
    videoElement.addEventListener("play", function (event) {
        config.log("imadgb - Play Triggered");
        tracker.playbackStarted();
    });

    videoElement.addEventListener("pause", function (event) {
        config.log("imadgb - Pause Triggered");
        tracker.playbackStopped();
    });

    videoElement.addEventListener("timeupdate", function (event) {
        config.log("imadbg - Timeupdate Triggered");
        tracker.playbackTimeupdate(videoElement.currentTime);
    });

    function checkWatchlist() {
        var timeForVideo = watchlist.getTimeForVideoSync(movie);
        if (timeForVideo) {
            config.log(
                "imadbg - found current movie in watchlist. Seeking to",
                timeForVideo + "."
            );
            videoElement.currentTime = timeForVideo;
        }
    }

    function requestAds() {
        // only load the ima sdk when we are  sure that we receive a proper tc string from our cmp
        if (!isImaScriptLoaded) {
            window.__tcfapi("addEventListener", 2, function (tcData, success) {
                config.log("imadbg - tcfapi eventlistener called", tcData);
                if (success) {
                    if (
                        tcData.eventStatus == "useractioncomplete" ||
                        tcData.eventStatus == "tcloaded"
                    ) {
                        config.log(
                            "imadbg - preparing ima-script load since tcString should be available now"
                        );
                        // prepare call for IMA script
                        loadScriptsAndRunInit();
                    }
                }
            });
        }
        // the ima sdk is already loaded ad this point
        else {
            loadScriptsAndRunInit();
        }

        // oguryCmp.getUserConsentData(function (tcData) {
        //     loadScriptsAndRunInit(tcData);
        // });

        function loadScriptsAndRunInit() {
            // scripts are already loaded, we can begin the player initialization right away
            if (isImaScriptLoaded) {
                initVideoJSIMA();
            }
            // we have to load the required scripts before running the player initialization
            else {
                loadVideoJSWithIMA().then((videojs) => {
                    videojsRef = videojs;
                    isImaScriptLoaded = true;
                    initVideoJSIMA();
                });
            }
        }

        function initVideoJSIMA() {
            // Request video ads if IMA script is loaded.
            if (window.google) {
                config.log("imadbg - request ads");
                // Collect proper description url
                var currentRoute = can.route.attr();
                var description_url = encodeURIComponent(
                    config.general.baseURL +
                        "#!/" +
                        currentRoute.control +
                        "/" +
                        currentRoute.id
                );

                var selectedTag = "";

                // NP-890
                var movieId = movie.attr("id") ? movie.attr("id") : "";
                var cmsId = 2526549;

                var grjngoTag =
                    "https://pubads.g.doubleclick.net/gampad/ads?iu=/27665794/Bronco.de_Website/BroncoWeb/BroncoWebDesktop&description_url=" +
                    description_url +
                    "&tfcd=0&npa=0&sz=640x480&cmsid=" +
                    cmsId +
                    "&vid=" +
                    movieId +
                    "&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=&vpa=click";

                var netzkinoTag =
                    "https://pubads.g.doubleclick.net/gampad/ads?iu=/27665794/Netzkino/Netzkino_Web&description_url=" +
                    description_url +
                    "&tfcd=0&npa=0&sz=640x480&cmsid=" +
                    cmsId +
                    "&vid=" +
                    movieId +
                    "&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=&vpa=click";

                var netzkinoPlusAvodTag =
                    "https://pubads.g.doubleclick.net/gampad/ads?iu=/27665794/NetzkinoPlus&description_url=" +
                    description_url +
                    "&tfcd=0&npa=0&sz=640x480&cmsid=" +
                    cmsId +
                    "&vid=" +
                    movieId +
                    "&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=&vpa=click";

                var currentPlatform = window.platform;
                if (currentPlatform === "netzkino") {
                    if (isPlus) {
                        selectedTag = movie.hasMovieProperty("svod")
                            ? ""
                            : netzkinoPlusAvodTag;
                    } else {
                        selectedTag = netzkinoTag;
                    }
                } else if (currentPlatform === "grjngo") {
                    selectedTag = grjngoTag;
                }
                try {
                    videojsPlayer = videojsRef("content_video", {
                        // sources: [
                        //     {
                        //         src: initVideoPlayerSource(),
                        //         type: "video/mp4",
                        //     },
                        // ],
                        //fluid: true,
                    });
                    // disable custom videojs ad countdown, since we let the ima sdk handle the ad ui
                    var options = {
                        adTagUrl: selectedTag,
                        showCountdown: false,
                        locale: "de",
                    };
                    videojsPlayer.ima(options);
                    videojsPlayer.on("ready", function () {
                        try {
                            if (!utils.isSafari() && !utils.isIOS()) {
                                videoElement.play();
                            }
                        } catch (err) {
                            config.error(
                                "imadbg - could not autopla via player.play()",
                                err
                            );
                        }
                    });
                } catch (error) {
                    config.error(
                        "imadbg - could not initiate videojs-ima",
                        error
                    );
                }
            }
        }
    }

    function initVideoPlayerSource() {
        var aclsToProcess = [
            {
                key: "hls",
                tokenType: "hls",
                acl: "/i/" + movie.attr("custom_fields.Streaming.0") + ".mp4/*",
            },
            {
                key: "hds",
                tokenType: "hds",
                acl:
                    "/z/" +
                    movie.attr("custom_fields.Streaming.0") +
                    ".mp4/manifest.f4m",
            },
            {
                key: "pmd",
                tokenType: "pmd",
                acl: "/" + movie.attr("custom_fields.Streaming.0") + ".mp4",
            },
        ];

        // Provide video sources. HTML5-Video loads the first source which is supported in the current browser/os
        var sourceTemplates = config.moviepage.videoSourceTemplate(
            movie.isPlusMovie(),
            null
        );
        // videoElement.src =
        //     sourceTemplates.pmd.replace("/{}", "") + aclsToProcess[2].acl;
        // videoElement.type = "film/mp4";

        var sourcePmd = document.createElement("source");
        sourcePmd.setAttribute(
            "src",
            sourceTemplates.pmd.replace("/{}", "") + aclsToProcess[2].acl
        );

        sourcePmd.setAttribute("type", "video/mp4");
        videoElement.appendChild(sourcePmd);

        //sourcePmd.setAttribute("type", "video/mp4");
        return sourceTemplates.pmd.replace("/{}", "") + aclsToProcess[2].acl;
    }
}

can.Component.extend({
    tag: "ima-player",
    template: View,
    scope: {
        movie: null, // required
        sourceOverride: "",
    },
    events: {
        inserted: function () {
            config.log("imadbg - component init");
            // Get User Consent
            oguryCmp.getUserConsent();
            var movie = this.scope.attr("movie");
            config.log("imadbg - Initializing this movie:", movie);
            tracker = new ContentTracker(movie);
            // Check if a user is logged in and plus user
            Promise.resolve(state.currentUserPromise).then(
                function (user) {
                    if (user.isPlus) {
                        // user logged in and a plus member
                        delayedIMAPlayerInit(true);
                    } else {
                        // user logged in, but not a plus member
                        delayedIMAPlayerInit(false);
                    }
                },
                function () {
                    // no user logged in
                    delayedIMAPlayerInit(false);
                }
            );
            function delayedIMAPlayerInit(isPlus) {
                initIMAPlayer(movie, tracker, isPlus);
            }
        },
        destroyed: function () {
            config.log("imadbg - component destroyed");
        },
        removed: function () {
            config.log("imadbg - component removed", this);
            // Clear tracker instance on component removal
            if (tracker) tracker.destroy();
            // Clear the Video-Element
            videoElement.pause();
            if (videoElement.firstElementChild) {
                videoElement.removeChild(videoElement.firstElementChild);
                videoElement.load();
            }
            // clean up videojs
            if (videojsPlayer) {
                videojsPlayer.dispose();
                videojsPlayer = null;
            }
        },
        "{scope} movie": function (scope, source) {},
    },
});
