import can from "can";
import view from "./view.stache";
can.Component.extend({
    tag: "moviepage-sharing-bar",
    template: view,
    scope: {},
    events: {
        inserted: function (el, options) {
            if (typeof IN !== "undefined") {
                can.defer(function () {
                    /* eslint-disable-next-line no-undef */
                    IN.parse();
                });
            }
        },
    },
});
