import can from "can";
import View from "./view.stache";
can.Component.extend({
    tag: "gad",
    template: View,
    scope: {
        style: "@",
        dataAdClient: "@",
        dataAdSlot: "@",
    },
    events: {
        init: function () {
            setTimeout(function () {
                (window.adsbygoogle || []).push({});
            }, 100);
        },
    },
});
