import can from "can";
import swal from "sweetalert";
import BaseControl from "../../../lib/can.basecontrol.js";
import Cookie from "../../../lib/cookie-handler";
import config from "../../config";
import User from "../../models/user";
import state from "../../state";
import View from "./view.stache";

function subscriptionInfo(user, self) {
    var subscription = user.activeSubscription;
    var subscriptionTerm = user.activeSubscriptionTerm;
    var currentOrFutureSubscription = user.activeSubscription;
    var currentOrFutureSubscriptionTerm = user.activeSubscriptionTerm;
    // Get newest subscription
    if (
        user.subscriptionsByUserId &&
        user.subscriptionsByUserId.subscriptions &&
        user.subscriptionsByUserId.subscriptions.length > 0
    ) {
        currentOrFutureSubscription =
            user.subscriptionsByUserId.subscriptions[0];
        var hasFutureSubscription =
            subscription.id != currentOrFutureSubscription.id ? true : false;

        var latestTerm = null;
        if (hasFutureSubscription) {
            latestTerm =
                currentOrFutureSubscription.subscriptionTermsBySubscriptionId
                    .subscriptionTerms[0];
            currentOrFutureSubscriptionTerm = latestTerm;
        }
        var nextRenewal = currentOrFutureSubscription.nextRenewal;
        var latestTermValidUntilIsGreaterThenNextRenewal = false;
        if (latestTerm && nextRenewal) {
            latestTermValidUntilIsGreaterThenNextRenewal =
                new Date(latestTerm.validUntil) > new Date(nextRenewal);
        }
        var infoText = "";
        var subscriptionInfo = {
            isCancelled: false,
            validUntil: parseDate(currentOrFutureSubscriptionTerm.validUntil),
            infoText: infoText,
            subscriptionId: currentOrFutureSubscription.id,
        };
        // subscription cancelled or a package used
        if (!subscription.isRecurringActive && !hasFutureSubscription) {
            subscriptionInfo.infoText =
                "Mitgliedschaft läuft am " +
                parseDate(subscriptionTerm.validUntil) +
                " ab und wird nicht mehr verlängert!";
            subscriptionInfo.isCancelled = true;
        }
        // current subscription will be renewed
        else if (subscription.isRecurringActive) {
            if (latestTermValidUntilIsGreaterThenNextRenewal) {
                subscriptionInfo.infoText =
                    "Wenn du vor dem " +
                    parseDate(addDay(nextRenewal, -1).toLocaleDateString()) +
                    " kündigst, verlängert sich dein Abonnement nicht mehr. Durch noch vorhandene Gutschriften ist dein Abo auch danach noch bis zum " +
                    parseDate(latestTerm.validUntil) +
                    " nutzbar.";
            } else {
                subscriptionInfo.infoText =
                    "Deine Mitgliedschaft verlängert sich das nächste Mal am " +
                    parseDate(subscriptionTerm.validUntil);
            }
        }
        // current subscription runs out, there might be a future subscription with another payment service
        else {
            // future subscription exists
            if (
                latestTermValidUntilIsGreaterThenNextRenewal &&
                hasFutureSubscription &&
                currentOrFutureSubscription.isRecurringActive
            ) {
                subscriptionInfo.infoText =
                    "Wenn du vor dem " +
                    parseDate(addDay(nextRenewal, -1).toLocaleDateString()) +
                    " kündigst, verlängert sich dein Abonnement nicht mehr. Durch noch vorhandene Gutschriften ist dein Abo auch danach noch bis zum " +
                    parseDate(latestTerm.validUntil) +
                    " nutzbar.";
            } else {
                // customer ordered a package via paymentchange
                if (hasFutureSubscription) {
                    subscriptionInfo.infoText =
                        "Deine Mitgliedschaft läuft zum " +
                        parseDate(latestTerm.validUntil) +
                        " aus und wird nicht mehr automatisch verlängert!";
                    subscriptionInfo.isCancelled = true;
                }

                // regular subscription runs out
                else {
                    subscriptionInfo.infoText =
                        "Deine Mitgliedschaft läuft zum " +
                        parseDate(subscriptionTerm.validUntil) +
                        " aus und wird nicht mehr automatisch verlängert!";
                    subscriptionInfo.isCancelled = true;
                }
            }
        }
        self.options.viewOptions.attr("subscription", subscriptionInfo);
    }
}
function addDay(validUntil, daysToAdd) {
    var date = new Date(validUntil);
    date.setDate(date.getDate() + daysToAdd);
    return date;
}

function parseDate(date) {
    return new Date(date).toLocaleDateString();
    // var isLocaleDateString = date.includes("/");
    // var dateSplit = !isLocaleDateString ? date.split("-") : date.split("/");
    // var validUntil = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0];

    // if (isLocaleDateString) {
    //     validUntil = dateSplit[0] + "." + dateSplit[1] + "." + dateSplit[2];
    // }
    // return validUntil;
}

export default BaseControl.extend(
    "Konto",
    {
        defaults: {
            template: View,
        },
    },
    {
        init: function (options) {
            var state = this.options.state,
                self = this;

            self.options.viewOptions.attr("userNotification", null);
            self.options.viewOptions.attr("config", config);
            this.options.viewOptions.attr(
                "useImageBackdrop",
                window.platform === "loveisawonder"
            );

            state.currentUserPromise.then(
                function (user) {
                    can.batch.start();
                    /**
                     * get info text based on renewal status and
                     */
                    self.options.viewOptions.attr("user", user);
                    if (user.isPlus) {
                        // var dateSplit = user.activeSubscriptionTerm.validUntil.split(
                        //     "-"
                        // );
                        // var validUntil =
                        //     dateSplit[2] +
                        //     "." +
                        //     dateSplit[1] +
                        //     "." +
                        //     dateSplit[0];
                        // var subscription = {
                        //     isCancelled: !user.activeSubscription
                        //         .isRecurringActive,
                        //     validUntil: validUntil,
                        //     subscriptionId: user.activeSubscription.id,
                        // };
                        // self.options.viewOptions.attr(
                        //     "subscription",
                        //     subscription
                        // );
                        subscriptionInfo(user, self);
                        self.shouldOpenCancelSubscription();
                    }
                    Cookie.clearCookie("openCancelSubscription");
                    can.batch.stop();
                },
                function () {
                    state.goTo("my");
                }
            );

            return this._super.apply(this, arguments);
        },
        shouldOpenCancelSubscription: function () {
            var cancelSubscription = Cookie.readCookie(
                "openCancelSubscription"
            );
            can.defer(function () {
                if (cancelSubscription) {
                    can.$("#cancelSubscription").click();
                }
            });
        },
        "#logout click": function ($el, ev) {
            if (ev && ev.preventDefault) {
                ev.preventDefault();
            }
            User.logout();
        },
        "#save click": function ($el, ev) {
            if (ev && ev.preventDefault) {
                ev.preventDefault();
            }
            var $container = can.$("#user-settings-container"),
                userRequestObject = {};

            userRequestObject.email = $container.find("#username").val();

            var newPass = $container.find("#password").val();
            if (newPass.length > 0) {
                userRequestObject.password = newPass;
            }

            $el.attr("disabled", "disabled");
            User.updateUser(userRequestObject)
                .then(function (response) {
                    if (response && response.id) {
                        swal("Nutzer-Anpassungen erfolgreich.");
                    } else {
                        swal(
                            "Nutzer-Anpassungen fehlgeschlagen. Bitte später erneut versuchen."
                        );
                    }
                })
                .fail(function () {
                    swal(
                        "Nutzer-Anpassungen fehlgeschlagen. Bitte später erneut versuchen."
                    );
                })
                .always(function () {
                    $el.attr("disabled", false);
                });
        },
        "#delete click": function ($el, ev) {
            if (ev && ev.preventDefault) {
                ev.preventDefault();
            }

            $el.attr("disabled", "disabled");
            User.deleteUser()
                .then(function () {
                    swal("Nutzer gelöscht.");
                })
                .fail(function () {
                    swal(
                        "Der Nutzer konnte nicht gelöscht werden. Bitte an den Support wenden."
                    );
                })
                .always(function () {
                    $el.attr("disabled", false);
                });
        },
        "#changePaymentOption click": function ($el, ev) {
            ev.preventDefault();
            if (this.options.viewOptions.attr("subscription.isCancelled")) {
                swal(
                    "Du hast dein aktuelles Abo gekündigt. Schließe bei Ablauf der aktuellen Mitgliedschaft einfach ein neues Abo mit deiner gewünschten Zahlungsmthode ab. Bei Fragen wende dich bitte an den Support."
                );
            } else {
                swal(
                    "Um die Zahlungsmethode zu ändern, kündige bitte dein Abo und schließe es erneut ab. Eine Anpassung zur Laufzeit ist leider nicht möglich. Bei Fragen wende dich bitte an den Support."
                );
            }
        },
        "#changePaymentData click": function ($el, ev) {
            ev.preventDefault();
            swal(
                {
                    html: true,
                    title: 'Damit deine Zahlungsdaten aktualisiert werden können, müssen wir die erste Gebühr direkt einziehen. Die Resttage des aktuell laufenden Abo-Monats werden dir gut geschrieben.\n<br /><br/>Ist dir hier etwas unklar? Dann schaue in unseren <br /><a href="https://inside.netzkino.de/faq#d555a706032882684" target="_blank">FAQ-Bereich</a>.',
                    showCancelButton: true,
                    cancelButtonText: "Abbrechen",
                    confirmButtonText:
                        "Zahlungsdaten jetzt zahlungspflichtig aktualisieren",
                },
                function () {
                    state.goTo("my", "changePaymentData");
                }
            );
        },
        "#cancelSubscription click": function ($el, ev) {
            ev.preventDefault();
            var self = this;

            if (self.options.viewOptions.attr("subscription.isCancelled")) {
                swal(
                    "Mitgliedschaft läuft noch bis zum " +
                        self.options.viewOptions.attr(
                            "subscription.validUntil"
                        ) +
                        ".\nDu hast dein aktuelles Abo gekündigt."
                );
            } else {
                $el.attr("disabled", "disabled");
                swal(
                    {
                        title: "Möchtest du wirklich dein Abo kündigen?",
                        showCancelButton: true,
                        cancelButtonText: "Nein",
                        confirmButtonText: "Ja",
                    },
                    function () {
                        var state = self.options.state;
                        User.cancelSubscription(
                            self.options.viewOptions.attr(
                                "subscription.subscriptionId"
                            )
                        )
                            .then(function () {
                                if (config.plus.paywall.enabled) {
                                    state.goTo(config.plus.paywall.redirectTo);
                                } else {
                                    swal(
                                        {
                                            title:
                                                "Das Abo wurde gekündigt und läuft am " +
                                                self.options.viewOptions.attr(
                                                    "subscription.validUntil"
                                                ) +
                                                " aus.",
                                            showCancelButton: false,
                                        },
                                        function () {
                                            window.location.reload();
                                        }
                                    );
                                }
                            })
                            .fail(function () {
                                swal(
                                    "Das Abo konnte nicht gekündigt werden. Bitte an den Support wenden."
                                );
                            })
                            .always(function () {
                                $el.attr("disabled", false);
                            });
                    }
                );
            }
        },
    }
);
