// require.config({
//     paths: {
//         jquery: "bower_components/jquery/jquery",
//         "jquery.lazyload": "bower_components/jquery.lazyload/jquery.lazyload",
//         hammer: "bower_components/hammerjs/hammer.min",
//         swal: "bower_components/sweetalert/dist/sweetalert.min",
//         can: "bower_components/canjs/amd-dev/can",
//         "jquery.isInViewport": "bower_components/isInViewport/lib/isInViewport",
//         lib: "src/lib",
//         views: "src/views",
//         state: "src/js/state",
//         config: "src/js/config",
//         postscribe: "bower_components/postscribe.min/index",

//         // "avoplayer": "/Volumes/simpleTechs/internal/avoplayer/src/public/avoplayer-web",
//         // "avoplayer": "src/lib/avoplayer-web",

//         components: "src/js/components",
//         controls: "src/js/controls",
//         models: "src/js/models",
//         modules: "src/js/modules",
//         API: "src/js/api",

//         // //Google IMA via VideoJS
//         videojs:
//             "https://cdnjs.cloudflare.com/ajax/libs/video.js/7.11.7/video.min",
//         ads:
//             "https://cdnjs.cloudflare.com/ajax/libs/videojs-contrib-ads/6.6.0/videojs.ads.min",
//         ima:
//             "https://cdnjs.cloudflare.com/ajax/libs/videojs-ima/1.9.1/videojs.ima.min",
//         googleima: "https://imasdk.googleapis.com/js/sdkloader/ima3",
//     },
//     shim: {
//         "bower_components/bootstrap/dist/js/bootstrap.js": {
//             deps: ["jquery"],
//         },
//         "jquery.lazyload": {
//             deps: ["jquery"],
//         },
//         ima: {
//             deps: ["ads"],
//         },
//         ads: {
//             deps: ["videojs"],
//         },
//     },
//     map: {
//         "*": {
//             css: "bower_components/require-css/css",
//             less: "lib/canjs-plugins/lib/require-less/less",
//             mustache: "lib/canjs-plugins/lib/require-mustache/mustache",
//             stache: "lib/canjs-plugins/lib/require-stache/stache",
//             "video.js": "videojs",
//         },
//     },
// });

// require.config({
//     paths: {
//         jquery: "bower_components/jquery/jquery",
//         "jquery.lazyload": "bower_components/jquery.lazyload/jquery.lazyload",
//         hammer: "bower_components/hammerjs/hammer.min",
//         swal: "bower_components/sweetalert/dist/sweetalert.min",
//         can: "bower_components/canjs/amd-dev/can",
//         "jquery.isInViewport": "bower_components/isInViewport/lib/isInViewport",
//         lib: "src/lib",
//         views: "src/views",
//         state: "src/js/state",
//         config: "src/js/config",
//         postscribe: "bower_components/postscribe.min/index",

//         // "avoplayer": "/Volumes/simpleTechs/internal/avoplayer/src/public/avoplayer-web",
//         // "avoplayer": "src/lib/avoplayer-web",

//         components: "src/js/components",
//         controls: "src/js/controls",
//         models: "src/js/models",
//         modules: "src/js/modules",
//         API: "src/js/api",

//         // //Google IMA via VideoJS
//         videojs:
//             "https://cdnjs.cloudflare.com/ajax/libs/video.js/7.11.7/video.min",
//         ads:
//             "https://cdnjs.cloudflare.com/ajax/libs/videojs-contrib-ads/6.6.0/videojs.ads.min",
//         ima:
//             "https://cdnjs.cloudflare.com/ajax/libs/videojs-ima/1.9.1/videojs.ima.min",
//         googleima: "https://imasdk.googleapis.com/js/sdkloader/ima3",
//     },
//     shim: {
//         "bower_components/bootstrap/dist/js/bootstrap.js": {
//             deps: ["jquery"],
//         },
//         "jquery.lazyload": {
//             deps: ["jquery"],
//         },
//         ima: {
//             deps: ["ads"],
//         },
//         ads: {
//             deps: ["videojs"],
//         },
//     },
//     map: {
//         "*": {
//             css: "bower_components/require-css/css",
//             less: "lib/canjs-plugins/lib/require-less/less",
//             mustache: "lib/canjs-plugins/lib/require-mustache/mustache",
//             stache: "lib/canjs-plugins/lib/require-stache/stache",
//             "video.js": "videojs",
//         },
//     },
// });

import can from "can";
import ogury from "../lib/ogury";
import "./components/netzkino-app";
import "./config";

// Render #app-template
var $wrapper = can.$("div.takeover__content-wrapper");
$wrapper.prepend(
    can.view(
        can.view.stache(
            '<netzkino-app></netzkino-app><generic-ad identifier="layer" /><generic-ad identifier="teads" />'
        )
    )
);

var $globalLoadindIndicator = can.$("#pageloadingPlaceholder");
if ($globalLoadindIndicator.length > 0) {
    $globalLoadindIndicator.addClass("hideLoader"); // must have pointer-events: none!
    setTimeout(function () {
        $globalLoadindIndicator.hide();
        // Initiate Ogury CMP for the first time if there are no previous settings found
        ogury.getUserConsent();
    }, 1000); // equal to opacity animation
}
