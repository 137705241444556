import BaseControl from "../../../lib/can.basecontrol.js";
import View from "./view.stache";

export default BaseControl.extend(
    "Gewinnspiel",
    {
        defaults: {
            template: View,
        },
    },
    {}
);
