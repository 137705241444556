import can from "can";
import utils from "../../lib/utils";
import API from "../api";
import config from "../config";
import state from "../state";

var modelFactory; // has to be evaluated lazily to avoid cyclic dependencies
function getModelFactory() {
    if (!modelFactory) {
        modelFactory = require("./modelFactory").default;
    }
    return modelFactory;
}

export default can.Model.extend(
    "Movie",
    {
        findOne: "GET " + API.getURLFromConfig("movie.findOne"), //config.paths.api.base + config.paths.api.movie.findOne + config.paths.api.movie.findOneParams,
        makeFindOne: API.makeFindOneProxy,

        //return correct data on fine-one call
        model: function (data) {
            if (data.error) {
                return undefined;
            }
            return getModelFactory().parsePost(data);
        },
        findMultipleMoviesByIds: function (movieIdList) {
            return API.getMultipleMoviesByIdString(movieIdList.join("|")).then(
                function (movies) {
                    return getModelFactory().parsePostList(movies);
                }
            );
        },
    },
    {
        type: "movie",
        init: function () {
            this.attr("dateStr", this._dateStr());
            if (!this.attr("isMoreLink")) {
                this.attr("isMoreLink", false);
            }
            if (!this.attr("hasSimpleOverlay")) {
                this.attr("hasSimpleOverlay", false);
            }
            //     this.attr({
            //         'blockAd': false,
            //         'showVideo': true
            //     });
            //     this.attr(args);
        },

        _simpleLink: can.compute(function () {
            var slug = can.route.attr("control");
            if (slug === "suche" || slug === "my") {
                var categories = Array.prototype.slice.apply(
                    this.attr("categories") || []
                );
                var knownCategories = categories
                    .map(function (catId) {
                        return state.data.categoriesById[catId];
                    })
                    .filter(function (cat) {
                        return cat && cat.attr && cat.attr("slug");
                    })
                    .sort(function (cat) {
                        return config.categoryTree.isRoot(cat) ? 1 : -1; // base category is the last entry, used as fallback
                    })
                    .map(function (cat) {
                        return cat.slug;
                    });

                slug = knownCategories[0];

                //if we have not found a slug for any category, we must set it to something other than "suche"
                // because else it would just be pasted into the search field
                if (!slug || slug === "suche") {
                    slug = "index";
                }
            }

            return can.route.url({
                control: slug,
                id: this.attr("slug"),
            });
        }),

        _link: can.compute(function () {
            return this.attr("_simpleLink");
        }),

        _internalMovieLink: can.compute(function () {
            var slug = can.route.attr("control");
            if (window.platform === "netzkino" && slug !== "my") {
                return can.route.url({
                    control: "filme",
                    id: this.attr("slug"),
                });
            }
            return this.attr("_simpleLink");
        }),

        _dateStr: function () {
            var str = this.attr("date") || "",
                parts = str.substring(0, 10).split("-");

            if (str && parts.length == 3) {
                return [parts[2], parts[1], parts[0]].join(".");
            }
            return "";
        },

        Video: function () {
            if (this.attr("custom_fields.IPTV")) {
                return;
            }
            return this.attr("custom_fields.Video");
        },

        _permalink: function () {
            return config.general.baseURL + this._link().replace("#!/", "");
        },

        whatsAppLink: can.compute(function () {
            return (
                "whatsapp://send?text=" +
                encodeURIComponent(
                    config.moviepage.sharing.whatsApp.shareText(this)
                )
            );
        }),
        // setTitle: function(title) {
        //  var slug = title.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/-+/g, '-');
        //  this.attr('slug', slug);
        //  return title;
        // },

        showVideo: function () {
            config.log("showVideo: ", !this.block18());
            return !this.block18();
        },
        isPlusMovie: function () {
            //
            return this.hasMovieProperty("plus-exclusive");
        },

        nosharing: function () {
            var _nosharing = false;

            if (this.attr("categories")) {
                this.attr("categories").each(function () {
                    _nosharing |= this === 111; //if movie is in cat 111 (eKino) don't ever share!
                });
            }

            return _nosharing;
        },

        block18: function () {
            var a = (new Date().getHours() + 1) % (Math.pow(3, 3) - 3) < 7; //this means: hours between 23 and 6 in the morning, but obfuscated so it's harder to find
            return !(
                a || parseInt(this.attr("custom_fields.FSK.0") || "0", 10) < 18
            ); //if we're in the safe hours OR film is not rated 18, show it...
        },

        hasMovieProperty: function (propertyName) {
            return (this.attr("properties") || []).indexOf(propertyName) >= 0;
        },
        isGeoBlocked: function (countryCode) {
            if (["DE", "CH", "AT"].indexOf(countryCode) < 0) {
                return true;
            }

            var countryLookup = {
                G: "DE",
                S: "CH",
                A: "AT",
            };

            // if there is a specific availability exlusion, block these countries
            var exclusive = this.attr(
                "custom_fields.GEO_Availability_Exclusion.0"
            );
            if (exclusive) {
                var exclusiveCountries = exclusive
                    .split(",")
                    .map(function (wordpressName) {
                        return countryLookup[wordpressName];
                    })
                    .filter(function (e) {
                        return !!e;
                    });

                return exclusiveCountries.indexOf(countryCode) > -1; // not in allowed country
            }
            return false;
        },

        hasYoutubePreview: function hasYoutubePreview() {
            // we do not need Start and End time to be defined. Fallback should always be taken otherwise
            return !!(
                this.attr("custom_fields.Youtube_Delivery_Active.0") &&
                this.attr("custom_fields.Youtube_Delivery_Active.0") !== "" &&
                this.attr("custom_fields.Youtube_Delivery_Id.0")
            );
        },
        hasHTML5VideoPreview: function hasHTML5VideoPreview() {
            return !!this.attr("custom_fields.Featured_Video_Slider.0");
        },
        getHtml5VideoPreviewUrl: function html5VideoPreviewUrl() {
            if (utils.isIOS()) {
                return false;
            }
            return config.frontpage.buildVideoSliderUrl(this);
        },
        isSeries: function () {
            return !!this.attr("series.id");
        },
        fskAge: can.compute(function () {
            var fromFields = this.attr("movie.custom_fields.FSK.0");
            if (fromFields) {
                return fromFields;
            } else if (window.platform === "kixi") {
                var age;
                if (this.hasMovieProperty("fsk-12")) {
                    age = 12;
                } else if (this.hasMovieProperty("fsk-6")) {
                    age = 6;
                } else if (this.hasMovieProperty("fsk-0")) {
                    age = 0;
                }
                return age + ""; // 0 will be treated as false
            }
        }),
        // https://schema.org/Movie for rich google results
        jsonLDMovieSchema: can.compute(function generateJsonLD() {
            var schema = {
                "@context": "http://schema.org",
                "@type": "Movie",
                name: this.attr("title"),
                description: utils.stripHTML(this.attr("content")),
                potentialAction: {
                    "@type": "ViewAction",
                    target: this.attr("_permalink"),
                    name: "Jetzt ansehen!",
                },
            };

            var stars = (this.attr("custom_fields.Stars.0") || "").split(/, ?/);
            if (stars.length > 0) {
                schema.actor = stars.map(function (name) {
                    return {
                        "@type": "Person",
                        name: name,
                    };
                });
            }

            var director = this.attr("custom_fields.Regisseur.0");
            if (director) {
                schema.director = {
                    "@type": "Person",
                    name: director,
                };
            }

            var dateCreated = this.attr("custom_fields.Jahr.0");
            if (dateCreated) {
                schema.dateCreated = dateCreated;
            }

            schema.image = [
                this.attr("custom_fields.featured_img_all_small.0"),
                this.attr("custom_fields.featured_img_all.0"),
                this.attr("custom_fields.Featured-Img.0"),
                this.attr("thumbnail"),
            ];

            return schema;
        }),
        // https://schema.org/Movie for rich google results
        jsonLDVideoSchema: can.compute(function generateJsonLD() {
            var schema = {
                "@context": "http://schema.org",
                "@type": "VideoContent",
                name: this.attr("title"),
                description: utils.stripHTML(this.attr("content")),
                potentialAction: {
                    "@type": "ViewAction",
                    target: this.attr("_permalink"),
                    name: "Jetzt ansehen!",
                },
            };

            schema.thumbnailUrl = [
                this.attr("custom_fields.featured_img_all_small.0"),
                this.attr("custom_fields.featured_img_all.0"),
                this.attr("custom_fields.Featured-Img.0"),
                this.attr("thumbnail"),
            ];

            return schema;
        }),
        // using <script> inside a template will not go through the can js renderer
        jsonLDMovieSchemaEmbedScript: can.compute(function () {
            var schema = this.attr("jsonLDMovieSchema");
            if (schema) {
                return (
                    '<script type="application/ld+json">' +
                    JSON.stringify(schema) +
                    "</script>"
                );
            } else {
                return null;
            }
        }),
        // using <script> inside a template will not go through the can js renderer
        jsonLDVideoSchemaEmbedScript: can.compute(function () {
            var schema = this.attr("jsonLDVideoSchema");
            if (schema) {
                return (
                    '<script type="application/ld+json">' +
                    JSON.stringify(schema) +
                    "</script>"
                );
            } else {
                return null;
            }
        }),
        /*function() {
        return adblocker;
        // return window.location.protocol !== 'file:' && ($('#dd4eefe6').length > 0) && !($('#dd4eefe6').is(':visible'));
    }*/
        // Returns if this instance matches a given filter
        // (currently `active` and `complete`)
        // matches : function() {
        //  var filter = can.route.attr('filter');
        //  return !filter || (filter === 'active' && !this.attr('complete'))
        //      || (filter === 'completed' && this.attr('complete'));
        // }
    }
);
