import can from "can";
import config from "../config";
var countryLoader;

var LOCAL_STORAGE_KEY = "cc"; // country code
var INVALID_COUNTRY = "XX";

function getFromStorage() {
    try {
        var fromStorage = window.localStorage.getItem(LOCAL_STORAGE_KEY);
        if (fromStorage) {
            var stored = JSON.parse(window.atob(fromStorage));
            var countryCode = stored.code;

            if (stored.exp < Date.now()) {
                window.localStorage.removeItem(LOCAL_STORAGE_KEY);
                return null;
            }
            return countryCode;
        }
    } catch (e) {
        // safari in private mode
        console.error("could not get country information", e);
    }
    return null;
}

// since we use cache busting, we have to implement caching ourselves
function cacheInStorage(countryCode) {
    try {
        var sevenDays = 7 * 24 * 60 * 60 * 1000;
        var fromStorage = window.localStorage.setItem(
            LOCAL_STORAGE_KEY,
            window.btoa(
                JSON.stringify({
                    exp: Date.now() + sevenDays,
                    code: countryCode,
                })
            )
        );
    } catch (e) {
        // safari in private mode
        console.error("could not set country information", e);
    }
    return null;
}

export default {
    getCountry: function () {
        if (countryLoader) {
            return countryLoader.promise();
        } else {
            countryLoader = new can.Deferred();
        }
        var fromStorage = getFromStorage();
        if (fromStorage) {
            countryLoader.resolve(fromStorage);
            return countryLoader;
        }

        // get current country from API, must use cache busting to avoid cloudflare
        can.$.get(
            config.paths.api.base +
                config.paths.api.movie.countryCheck +
                "?" +
                Math.random()
        ).then(
            function (data) {
                var countryCode = data && data.country;
                if (!countryCode) {
                    console.error("could not resolve country code", data);
                } else if (countryCode !== INVALID_COUNTRY) {
                    cacheInStorage(countryCode);
                }
                countryLoader.resolve(countryCode || INVALID_COUNTRY);
            },
            function () {
                console.error("could not resolve country code");
                countryLoader.resolve(INVALID_COUNTRY);
            }
        );
        return countryLoader.promise();
    },
};
