<!-- TODO: Dynamisch machen -->
<div class="navPlaceholder"></div>

<netzkino-title-social-bar cat="Gewinnspiel" show-text="false"></netzkino-title-social-bar>

<br>
<div class="container subpage-container">
	<div class="row">
		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
			<h2>Teilnahmebedingungen</h2>

			<h2 style="color: #5b9bd5;">1. Allgemeines</h2>

			<ul>
			    <li>Das Gewinnspiel wird von der Netzkino Services GmbH, Görzallee 299, 14167 Berlin durchgeführt.</li>
			    <li>Das Gewinnspiel ist offen für alle Personen mit Wohnsitz in Deutschland &amp; Österreich.</li>
			    <li>Das Gewinnspiel endet zum im Facebook-Post festgelegten Zeitpunkt. Wenn nicht anders festgelegt, werden die Gewinner in den Kommentaren verkündet.</li>
			</ul>
			<h2 style="color: #5b9bd5;">2. Bedingungen</h2>

			<ul>
			    <li>Das Mindestalter für die Teilnahme ist 18 Jahre.</li>
			    <li>Die Teilnahme erfolgt je nach Angaben im Facebook-Posting.</li>
			</ul>
			<h2 style="color: #5b9bd5;">3. Gewinnerwahl</h2>

			<ul>
			    <li>Die Gewinner werden zufällig von einer aus Netzkino-Mitarbeitern bestehenden Jury ausgewählt.</li>
			    <li>Alle Änderungen des Gewinnspiels werden auf der Facebook-Seite bekanntgegeben.</li>
			    <li>Netzkino-Mitarbeiter dürfen nicht teilnehmen.</li>
			    <li>Der Rechtsweg ist ausgeschlossen.</li>
			</ul>
			<h2 style="color: #5b9bd5;">4. Disqualifizierung</h2>

			<ul>
			    <li>Jeder User, der mit technologischen Mitteln oder auf anderem Wege versucht, das Ergebnis zu verfälschen, wird unverzüglich disqualifiziert.</li>
			    <li>Im Falle einer Disqualifizierung erhält der nächstplatzierte Kandidat den Gewinn, sofern dieser ursprünglich dem Disqualifizierten zustand.</li>
			</ul>
			<h2 style="color: #5b9bd5;">5. Preise</h2>

			<ul>
			    <li>Es ist nicht möglich, sich den Barwert der Preise auszahlen zu lassen.</li>
			    <li>Der Teilnehmer hat keinen Anspruch auf eine Veränderung der Eigenschaften des Preises, so wie er von Netzkino angeboten wird.</li>
			    <li>Der Anspruch auf den oben genannten Preis tritt nach Rückmeldung in Form einer E-Mail in Kraft. Diese muss innerhalb von sieben Tagen erfolgen. Nach Ablauf dieser Frist wählt Netzkino einen neuen Gewinner aus.</li>
			</ul>
			<h2 style="color: #5b9bd5;">6. Zusatz</h2>

			<ul>
			    <li>Netzkino hat das Recht, die Aktion jederzeit zeitweise oder endgültig einzustellen, ohne Angabe von Gründen und ohne, dass einer oder mehrere Sieger bekanntgegeben wurden.</li>
			    <li>In diesem Fall (vor regulärem Ablauf des Gewinnspiels) erhält niemand einen Preis.</li>
			</ul>
			<h2 style="color: #5b9bd5;">Rechtliche Regelungen</h2>

			<ul>
			    <li>Das Gewinnspiel steht in keinem direkten Zusammenhang mit Facebook.</li>
			    <li>Mit der Teilnahme an dem Gewinnspiel akzeptieren die Teilnehmer die <a href="http://www.netzkino.de/#!/agb" target="_blank" style="color: #5b9bd5; font-weight: bold;">AGB</a> und die Bestimmungen zum <a href="http://www.netzkino.de/#!/datenschutz" target="_blank" style="font-weight: bold; color:  black;">Datenschutz</a>.</li>
			    <li>Die Teilnehmer erklären sich einverstanden, dass mit ihrer Teilnahme am Gewinnspiel ihre persönlichen Daten der Netzkino Services GmbH zum Zwecke der Durchführung des Gewinnspiels sowie für Werbezwecke und für das Zusenden von Angeboten und anderen Informationen gespeichert und anderweitig verarbeitet werden.</li>
			</ul>
			<br/>
			<br/>
			<br/>
		</div>
	</div>
</div>

