import config from "../js/config";
function getUserConsentData(callback) {
    // Default tc string obtained from ogury cmp when giving consent to all options available
    var consent =
        "CPCpY8nPCpY8nAtABBENBQCsAP_AAH_AAAAAHhNf_X_fb3_j-_59__t0eY1f9_7_v-0zjhfds-8N2f_X_L8X_2M7vF36pr4KuR4ku3bBIQdtHOncTUmx6olVrTPsbk2Mr7NKJ7Pkmnsbe2dYGH9_n93T_ZKZ7__v___7________7_______3_v_____-_____9___8DwACTDUvgIsxLHAkmjSqFECEK4kOgFABRQjC0TWEDK4KdlcBHqCBgAgNQEYEQIMQUYsAgAAAgCSiICQA8EAiAIgEAAIAVICEABGwCCwAsDAIABQDQsQIoAhAkIMjgqOUwICJFooJ5KwBKLvYwwhDKLACgUf0VGAiUIIAA.f_gAAAAAAAAA";
    try {
        __tcfapi("getTCData", 2, function (tcData, success) {
            if (success && tcData.tcString) {
                // flow is finished or an answer was retrieved

                // Custom code
                config.log(
                    "consent data successfully retrieved for scheduling movie url"
                );
                callback(tcData.tcString);
            } else {
                // An error occured
                // Refer to Error Handling page

                // Custom code
                config.error(
                    "error while retrieving consent data for scheduling movie url",
                    tcData
                );
                callback(consent);
            }
        });
    } catch (error) {
        config.error(
            "an error happened while trying to fetch the user consent string (tc-string)",
            error
        );
        callback(consent);
    }
}

function getUserConsent() {
    try {
        __tcfapi("ask", 2, function (result, success) {
            if (success) {
                // Ask flow is finished or an answer was retrieved
                // result contains the following:
                // iabString, isAccepted, isPartial

                // Custom code
                config.log("CMP ask flow successful" + success);
            } else {
                // An error occured
                // Refer to Error Handling page

                // Custom code
                config.error("CMP ask flow failed with an error", result);
            }
        });
    } catch (error) {
        config.error(
            "An error happened while trying to collect user consent",
            error
        );
    }
}

function editUserConsent() {
    try {
        // Currently we have only set up Google CMP for Netzkino
        if (window.platform === "netzkino") {
            // eslint-disable-next-line no-undef
            window.googlefc.callbackQueue.push(googlefc.showRevocationMessage);
        }
        // Ogury CMP
        else {
            __tcfapi("edit", 2, function (result, success) {
                if (success) {
                    // Ask flow is finished or an answer was retrieved
                    // result contains the following:
                    // iabString, isAccepted, isPartial
                    config.log("Ogury CMP settings successfully edited");
                } else {
                    // An error occured
                    config.log(
                        "Ogury CMP settings edit failed with an error",
                        result
                    );
                }
            });
        }
    } catch (error) {
        config.error("Could not open the consent revoke dialogue", error);
    }
}

export default {
    getUserConsentData: getUserConsentData,
    getUserConsent: getUserConsent,
    editUserConsent: editUserConsent,
};
