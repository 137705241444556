<div class="wideCover lazy" data-original="{{placeholderImage}}" style="display: block;">
    <div class="embed-responsive embed-responsive-16by9 outOfView">
        <div class="youtube-player"></div>
    </div>


    <cover-overlay-infobox movie="{movie}" /> {{! has to be outside of embed-responsive for safari }}
        {{#if _allowUnmuteBoolean}}
            <div class="youtubePlayer-muter">
                <img src="{{imagePath 'webseite/mainSlider/unmute.png'}}" class="{{#if isMuted}}hidden{{/if}}" />
                <img src="{{imagePath 'webseite/mainSlider/mute.png'}}"  class="{{#unless isMuted}}hidden{{/unless}}" />
                {{#if isMuted}}
                        Ton anschalten
                {{else}}
                        Ton ausschalten
                {{/if}}
            </div>
        {{/if}}
</div>