<div class="navPlaceholder"></div>

<generic-ad identifier="na-skyscraper" />

<div class="main-background">
  {{#if missingPlus}}
    {{! not loggedIn vs. notPlus is considered only in the submit action of the button}}
    <div class="container missing-plus-background" {{#getWithFallback movie 'custom_fields.featured_img_all.0' 'custom_fields.Featured-Img.0' }}style="background-image: url('{{.}}');"{{/getWithFallback}}>
      <div class="container text-center missing-plus">
        <div class="row">
          <div class="col-xs-12">
            <p class="bold" style="margin-bottom: 15px;">Diesen Inhalt kannst du nur mit einem {{config.plus.title}} Abo abrufen!*</p>
          </div>
        </div>

        <div class="row">
          <div class="hidden-xs col-sm-6 vcenter">
            <img src="{{imagePath 'webseite/plus/register_plus.jpg'}}" class="img-responsive" alt="{{config.plus.title}}" />
          </div><!--
          --><div class="col-xs-12 col-sm-6 vcenter">
            {{#each config.plus.registrationOptions}}
                {{^firstOption}}
                    <div class="offer-option"><i class="fa fa-check checkMark"></i>&nbsp;&nbsp;<span>{{name}}</span></div>
                {{/firstOption}}
            {{/each}}
            <div class="offer-option"><i class="fa fa-check"></i>&nbsp;&nbsp;<span>Monatlich kündbar</span></div>
            <a href="#" id="offer-btn" class="btn btn-big btn-dark">Zum Angebot</a>{{! TODO: border: none !important; for nk? }}
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <p class="login-span bold" style="margin-top: 25px; margin-bottom: 0px;">Du hast einen Account? <a class="login-link bold" href="#!/my">Jetzt anmelden!</a></p>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <p class="footnote">{{#each config.plus.registrationOptionFootnote}}{{.}}<br/>{{/each}}</p>
          </div>
        </div>
      </div>
    </div>
  {{else}}
    {{#if showVideoPlayer}}
      <div class="container" style="z-index:1;position:relative;">
        <div class="row">
          {{#if movie.custom_fields.Youtube_Delivery_Active.0}}
              <div class="yt-player embed-responsive embed-responsive-16by9">
                  <iframe class="yt-video avoplayer-sizing embed-responsive-item" type="text/html"
                    src="https://www.youtube.com/embed/{{movie.custom_fields.Youtube_Delivery_Id.0}}?autoplay=1&origin={{jsOrigin}}" frameborder="0"></iframe>
              </div>
          {{else}}
            {{#if selectedPart.source}}
              <avo-player class="col-xs-12 avoplayer-sizing" movie="{selectedPart.source}" can-ended="selectNextEpisodeToPlay" />
            {{else}}
              <avo-player class="col-xs-12 avoplayer-sizing" movie="{movie}" can-ended="selectNextEpisodeToPlay" />
            {{/if}}
          {{/if}}
        </div>
      {{/if}}
    </div>
  {{/if}}

  <div class="container movieDesc">
    <div class="row">
      <div class="hidden-xs col-sm-2 text-center">
        <img class="img-responsive" style="margin: auto; margin-top: 20px;" src="{{#getWithFallback movie 'custom_fields.Artikelbild.0' 'custom_fields.Cover.0' 'thumbnail'}}{{.}}{{/getWithFallback}}" />
      </div>
      <div class="col-xs-12 col-sm-10">
          <h2>
            {{useFallback selectedPart.title movie.title}}
          </h2>
          <p class="movieMetaInformations">
            <ul class="list-inline">
              {{#if movie.fskAge}}
                    <li>Freigabe: ab {{movie.fskAge}} Jahren</li>
              {{/if}}
              {{#if movie.custom_fields.Year.0}}
                    <li>Jahr: {{movie.custom_fields.Year.0}}</li>
              {{/if}}
              {{#if movie.custom_fields.Duration.0}}
                    <li>Länge: {{movie.custom_fields.Duration.0}} min</li>
              {{/if}}
              {{#if movie.seriesModel.title}}
                    <li>Serie: {{movie.seriesModel.title}}</li>
              {{/if}}
            </ul>
          </p>
          <br>
          <p>
            {{{useFallback selectedPart.description movie.content}}}
          </p>

          <button style="width: auto" class="btn btn-big btn-dark btn-responsive" id="wishlist-toggle" data-loading-text="<i class='fa fa-spinner fa-spin'></i>">{{wishlistFirstText}}</button>
        </div>
        {{#if config.moviepage.sharing.enabled}}
          <div class="col-xs-12">
              <moviepage-sharing-bar />
          </div>
        {{/if}}
      </div>
    </div>
  </div>

  {{#unless missingPlus}}
    {{#isEqual movie.type 'audioContent'}}
      <div class="container" style="z-index:1;position:relative;">
        <div class="row">
          <div class="audioPlayerWrapper">
            <strong>
              Spielt {{selectedPart.title}}
            </strong>
            <audio-player autoplay model="{movie}" part-number="{selectedPart.number}" can-ended="selectNextPartToPlay"></audio-player>
          </div>
        </div>
      </div>
    {{/isEqual}}
  {{/unless}}

  <!-- for series! Similar category is the series -->
  {{#isEqual movie.type 'seriesContent'}}
    <div class="container episodeSelection">
      <div class="col-xs-12">
        {{#if similarCat}}
          <div class="btn-group seasons">
            {{#each movie.seriesModel.seasons}}
              <button class="seasonBtn btn {{#isEqual selectedSeason .}}btn-primary{{else}}btn-default{{/isEqual}}" can-click="selectSeason">
                Staffel {{number}}
              </button>
            {{/each}}
          </div>
          {{#if selectedSeason}}
            <part-selector class="text-center" part-list="{selectedSeason.partList}" on-select="{selectPartToPlay}" selected-part='{selectedPart}'/>
          {{/if}}
        {{else}}
          <div class="text-center" style="margin: auto">
            Lade Serien-Daten...
          </div>
        {{/if}}
      </div>
    </div>
  {{/isEqual}}

  <!-- for audio! -->
  {{#if movie.partList}}
    <div class="container" style="margin-top: 30px">
      <div class="row">
        <div class="col-xs-12">
          <part-selector part-list="{movie.partList}" on-select="{selectPartToPlay}" selected-part='{selectedPart}'/>
        </div>
      </div>
    </div>
  {{/if}}

  {{#isEqual movie.type 'movie'}}
    {{#if similarCat}}
      <div class="container">
        <div class="row">
            <netzkino-category-slider cat="{similarCat}" id="{{similarCat.id}}" class="extra-slider-padding" max-posts="40" title="Ähnliche Filme" slider-type-override="{sliderTypeOverride}" {{#if useLargeSlider}}type="large"{{/if}} />
        </div>
      </div>
    {{/if}}
  {{/isEqual}}

  {{#if config.plus.enabled}}
    {{#unless isPlus}}
      {{#if config.plus.showExtraCTA}}
        <div class="container plusAdContainerBelow">
          <div class="row">
           {{! we need to handle .goToOffer click, because we cannot pass down callback functions without maximum call stack exceptions }}
            <plus-information />
          </div>
        </div>
      {{/if}}
    {{/unless}}
  {{/if}}
</div>
