import can from "can";
import config from "../../config";
import state from "../../state";
import coverOverlayInfoboxView from "./view.stache";
can.Component.extend({
    tag: "cover-overlay-infobox",
    template: coverOverlayInfoboxView,
    scope: {
        movie: null,
        title: null, // if unset, uses movie.title
        coverSize: null,
        classes: "@",
    },
    events: {
        init: function (el, options) {
            if (window.platform === "netzkino") {
                // we want to show a different hover image if we are a plus user wanting to watch a film which as ads (avod)
                // fallback to avod always for movies which do not have svod set (do not check for avod explicitly)
                var isFakeFilm =
                    options.scope.attr("movie.custom_fields.Streaming") ===
                    undefined;
                if (
                    state.currentUser &&
                    state.currentUser.isPlus &&
                    !options.scope.attr("movie").hasMovieProperty("svod") &&
                    !isFakeFilm
                ) {
                    var coverSize = options.scope.attr("coverSize");
                    var overlayURL = config.paths.images.get(
                        "webseite/playIcons/default.png"
                    );
                    if (coverSize) {
                        overlayURL = config.paths.images.get(
                            "webseite/playIcons/" +
                                coverSize +
                                "_mit_werbung" +
                                ".png"
                        );
                    }
                    options.scope.attr("overlayURL", overlayURL);
                }
            }
        },
        ".movie-remove-link click": function ($el, ev) {
            if ($el.data("function") === "remove") {
                can.trigger(
                    this.element,
                    "removeMovieFromList",
                    this.scope.movie
                );
            }
            ev.preventDefault();
            return false;
        },
    },
});
