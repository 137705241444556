import can from "can";
import "hammer";
import $ from "jquery";
import shuffle from "../../../lib/shuffle";
import "../../../lib/simple-lemmon-slider";
import utils from "../../../lib/utils";
import config from "../../config";
import Movie from "../../models/movie";
import User from "../../models/user";
import ga from "../../modules/googleanalytics";
import watchlist from "../../modules/watchlist";
import state from "../../state";
import lazyrefresh from "../img-lazy";
import View from "./view.stache";
// sometimes we do not know if the object is a proper Model or a hacky workaround (e.g. fake category slider posts)
function gracefulGet(obj, path) {
    if (obj.attr) {
        return obj.attr(path);
    } else {
        return utils.getDeep(obj, path);
    }
}
can.Component.extend({
    tag: "netzkino-category-slider",
    template: View,
    scope: {
        function: "@",
        type: "@",
        title: "@",
        noHeadline: "@",
        hideNav: "@",
        maxPosts: "",
        catposts: [],
        sliderTypeOverride: null,
        shuffle: true,
        shouldShuffle: can.compute(function () {
            return (
                this.attr("shuffle") !== "false" &&
                this.attr("shuffle") !== false
            );
        }),
        linkGenerator: null,
        allowMoreButton: config.frontpage.categorySlider.showMoreButton,
        showMoreButton: can.compute(function () {
            return (
                this.attr("allowMoreButton") !== "false" &&
                this.attr("allowMoreButton") !== false
            );
        }),
        allowArrowsMobile: "@",
    },
    events: {
        init: function (el, options) {
            var scope = options.scope;

            var max = scope.attr("maxPosts") || scope.attr("cat.posts.length");
            max = parseInt(max); // for attributes

            var addLastMoreLink = false;
            if (max > 40) {
                max = 40;
                addLastMoreLink = true;
            }
            var catposts = scope.attr("catposts");
            var posts = scope.attr("cat.posts");
            if (scope.attr("shouldShuffle")) {
                posts = shuffle(posts);
            }

            // NP-739. Display an Ad-Tile on the 'Neu bei Netzkino' category
            if (
                scope.attr("cat.title") &&
                scope.attr("cat.title").includes("Neu bei Netzkino") &&
                (!state.currentUser || !state.currentUser.isPlus) &&
                window.platform === "netzkino"
            ) {
                // check for plus user here, so that the smartAd init logic is not called (breaks DOM) and the extra element does not need to be removed again
                // 'Neu bei Netzkino' slider should get an ad slot in the first position (NP-241)
                // therefore we add a generic ad at the beginning
                // in case the ad is not delivered, we also make sure, that the additional element is removed and the slider is rerendered
                // in noAd callback in generic-ad.js
                max += 1;
                posts.splice(
                    0,
                    0,
                    Movie.model({
                        genericAd: true,
                        type: "genericAd",
                    })
                );
            }

            [].push.apply(catposts, posts.slice(0, max));

            var override = scope.attr("sliderTypeOverride");
            var defaultType = override || "sliderDefaultVertical";
            if (
                !scope.attr("cat").sliderType ||
                scope.attr("cat.slug").indexOf("-all") > -1
            ) {
                if (window.platform === "kixi") {
                    defaultType = config.frontpage.sliderTypeForCategory(
                        scope.attr("cat")
                    ); // handle audio
                }
                scope.attr("cat").sliderType = defaultType;
            }

            var sliderType = scope.attr("cat").sliderType;
            if (
                sliderType.indexOf("DoubleRow") > -1 &&
                !scope.attr("function")
            ) {
                // do not pop element for wish/watchlist - we do want to show all movies even if it does look a bit weird
                // pop last item if even number of catposts and we do not add one via addLastMoreLink
                if (
                    scope.attr("catposts.length") % 2 === 0 &&
                    !addLastMoreLink &&
                    scope.attr("type") !== "large" &&
                    scope.attr("cat.title") !== "Genre"
                ) {
                    config.log(
                        'removing last item from slider to support a "more" link',
                        catposts[catposts.length - 1]
                    );
                    catposts.pop();
                }

                // TODO: .firstItem is set by lemon slider, check if other problem appear
                // catposts.forEach(function(item){
                //     item.attr('isBig', false);
                // });
                // // scope.attr('catposts.0.isBig', true);
            }

            if (addLastMoreLink) {
                var moreFilm = this.getSpecialCoverImageBySliderTypeAndPrefix(
                    sliderType,
                    "mehrfilme"
                );
                catposts.push(Movie.model(moreFilm));
            } else if (
                scope.attr("function") &&
                scope.attr("catposts").length == 0
            ) {
                // the slider is empty
                this.handleEmptySlider();
            }

            // genre slider consists of fake categories to use the category-slider structure
            // special case handling here:
            if (
                scope.attr("cat.title") == "Genre" ||
                scope.noHeadline === "true"
            ) {
                scope.attr("hideHeadline", false);
            } else if (
                $.inArray(
                    scope.attr("cat.title"),
                    config.frontpage.categorySlider.showNoHeadline
                ) != -1
            ) {
                scope.attr("hideHeadline", true);
            } else {
                scope.attr("hideHeadline", false);
            }
            var self = this;
            can.defer(function () {
                var $slider = can.$(".slider", el);
                var lemonSliderOptions = {
                    loop: false,
                };
                if (scope.attr("type") === "large") {
                    lemonSliderOptions.firstItemClass = false; // by replacing the class we disable the enlargement of the first item - and therefore do not increase the size of the playIcon
                }
                $slider.lemmonSlider(lemonSliderOptions);

                // LazyLoad stops looking for unloaded images if an image off-screen is found.
                // This search is done in DOM-element order. failureCount is increased for each offscreen image that is found.
                // => Adding the number of images from the first slider row (total/2) to failure_limit leads to loading the second row successfully.
                // Necessary for double row sliders

                lazyrefresh(can.$(el), {
                    failure_limit: Math.ceil(max / 2) + 1,
                });
                // this seems to be needed in case the first image is not a real lazyloaded image, but an iframe generic-ad
                // above refresh does not trigger the images that are off
                lazyrefresh(can.$("#neu"), {
                    failure_limit: Infinity,
                });
            });
        },
        getImagePath: function (key) {
            return config.paths.images.get("webseite/" + key + ".jpg");
        },
        getSpecialCoverImageBySliderTypeAndPrefix: function (
            sliderType,
            prefix
        ) {
            var f = {};
            f.custom_fields = {};
            switch (sliderType) {
                case "sliderDefaultHorizontalSmall":
                    f.custom_fields.featured_img_seven = [
                        this.getImagePath(prefix + "_img_seven_small"),
                    ];
                    break;

                case "sliderDefaultHorizontal":
                    f.custom_fields.featured_img_seven_small = [
                        this.getImagePath(prefix + "_img_seven_small"),
                    ];
                    break;

                case "sliderDefaultVertical":
                    f.custom_fields.Artikelbild = [
                        this.getImagePath(prefix + "_artikelbild"),
                    ];
                    break;

                case "audioContentSlider":
                    f.custom_fields.Artikelbild = [
                        this.getImagePath(prefix + "_artikelbild"),
                    ];
                    break;

                case "adDoubleRow": // fallthrough
                case "sliderDoubleRow":
                case "dynamicDoubleRowSlider":
                    f.custom_fields.featured_img_all_small = [
                        this.getImagePath(prefix + "_img_all_small"),
                    ];
                    break;

                default:
                    f.custom_fields.featured_img_all_small = [];
            }
            f.title = this.options.scope.attr("cat.slug");
            f.hasSimpleOverlay = true;
            f.isMoreLink = true;
            return f;
        },
        handleEmptySlider: function () {
            // add 'empty slider cover'
            var prefix =
                this.options.scope.attr("type") == "watchlist"
                    ? "plus/no_movies_in_wishlist"
                    : "plus/no_movies_in_watchlist";
            var emptySliderCover = this.getSpecialCoverImageBySliderTypeAndPrefix(
                this.options.scope.attr("cat").sliderType,
                prefix
            );
            this.options.scope
                .attr("catposts")
                .push(Movie.model(emptySliderCover));
            lazyrefresh(can.$(".slider"));
        },
        ".slider scrollingDone": can.throttle(function () {
            // throttle as it gets called twice, from lemon slider
            var allCovers = this.element.find("youtube-cover");

            allCovers.each(function notifyMovieCovers() {
                can.$(this).trigger("sliderScrollingDone");
            });
        }, 10),
        "cover-overlay-infobox removeMovieFromList": function (
            caller,
            event,
            eventData
        ) {
            var self = this,
                movie = eventData,
                sliderSize = this.options.scope.attr("catposts").length;

            for (var i = sliderSize - 1; i >= 0; i--) {
                var item = this.options.scope.attr("catposts")[i];
                if (item !== undefined && item.id === movie.id) {
                    this.scope.attr("catposts").splice(i, 1);
                    if (sliderSize == 1) {
                        self.handleEmptySlider();
                    }
                }
            }
            var type = this.options.scope.type;
            if (type === "watchlist") {
                watchlist.removeId(movie.id);
            } else {
                User.removeMovieFromList(movie.id, this.options.scope.type); // wishlist
            }
        },
        "movie-cover clickMovie": function (caller, event, eventData) {
            if (
                ["wishlist", "watchlist"].indexOf(this.options.scope.type) > -1
            ) {
                ga.sendEvent(
                    this.options.scope.type == "wishlist"
                        ? "Watchlist"
                        : "Weiterschauen",
                    "Click",
                    eventData
                );
            }
        },
    },
    helpers: {
        isLarge: function (options) {
            if (this.attr("type") == "large") {
                return options.fn(this);
            } else {
                return options.inverse(this);
            }
        },
        isSliderDefaultHorizontal: function (options) {
            if (this.cat.sliderType == "sliderDefaultHorizontal")
                return options.fn(this);
        },
        isSliderDefaultHorizontalSmall: function (options) {
            if (this.cat.sliderType == "sliderDefaultHorizontalSmall")
                return options.fn(this);
        },
        isSliderDoubleRow: function (options) {
            if (this.cat.sliderType == "sliderDoubleRow")
                return options.fn(this);
        },
        isSliderDefaultVertical: function (options) {
            if (this.cat.sliderType == "sliderDefaultVertical")
                return options.fn(this);
        },
        isSliderYoutube: function (options) {
            if (this.cat.sliderType == "sliderYoutube") return options.fn(this);
        },
        isDynamicDoubleRowSlider: function (options) {
            if (this.cat.sliderType == "dynamicDoubleRowSlider")
                return options.fn(this);
        },
        isDynamicDoubleRowSliderWithAdTile: function (options) {
            if (this.cat.sliderType == "dynamicDoubleRowSliderWithAdTile")
                return options.fn(this);
        },
        isAudioContentSlider: function (options) {
            if (this.cat.sliderType == "audioContentSlider")
                return options.fn(this);
        },
        getCoverLink: function (movieCompute, options) {
            var linkGenerator = options.scope.attr("linkGenerator");
            var movie = utils.resolveCompute(movieCompute);
            if (linkGenerator) {
                return linkGenerator(movie);
            }
            var path = "#!/";
            // if(movie.type === 'audio') {
            //     path += 'audio/'
            // } else if (movie.isSeries) {
            //     path += 'serien/'
            // }

            if (
                (window.platform === "netzkino" ||
                    window.platform === "bronco" ||
                    window.platform === "grjngo") &&
                !movie.isMoreLink
            ) {
                // the fake-film with the isMoreLink == true has a category slug set for correct subpage linking - thus "filme" should not be applied
                //NP-210 fixate all movie links to /filme
                path += "filme";
            } else {
                path += gracefulGet(this.cat, "slug");
            }

            if (!movie.isMoreLink) {
                path += "/" + gracefulGet(movie, "slug");
            }

            return path;
        },
        prepareMoviesForDynamicDoubleRow: function (moviesCompute, options) {
            var self = this;
            var movies = utils.resolveCompute(moviesCompute);
            /* Example:
                {
                    type: 'big',
                    movie: movies.attr('0')
                }, {
                    type: 'verticalPair',
                    movies: [
                        movies.attr('1'),
                        movies.attr('2')
                    ]
                }
             */
            var sections = [],
                lastSection = {},
                MAX_VERTICALS = 2,
                BIG_ITEM_WIDTH = 458,
                windowWidth = window.innerWidth;

            movies.forEach(function buildSliderSections(movie, index) {
                var isLastMovie = index === movies.length - 1;

                function bigWasTooLongAgo() {
                    var reversed = Array.prototype.slice
                        .call(sections)
                        .reverse();
                    if (reversed.length) {
                        var cnt = 0;
                        reversed.some(function (section) {
                            if (
                                section.type === "big" ||
                                section.type === "adTileBig"
                            ) {
                                return true; // stop
                            }
                            cnt++;
                        });
                        return cnt >= MAX_VERTICALS; // allow at most 3 vertical sections
                    }
                    return false;
                }

                function avoidOddNumberInPair() {
                    if (!isLastMovie) return false;
                    if (
                        (lastSection.type === "verticalPair" ||
                            lastSection.type === "adTileSmall") &&
                        lastSection.movies.length < 2
                    ) {
                        return false; // still fits
                    } else {
                        return true; // would end up with 1 movie in vertical pair
                    }
                }
                if (
                    (lastSection.type === "verticalPair" ||
                        lastSection.type === "adTileSmall") &&
                    lastSection.movies.length < 2
                ) {
                    lastSection.movies.push(movie);

                    // let every second be at least one vertical, first has to be big
                } else if (
                    (sections.length === 0 ||
                        movie.hasYoutubePreview() ||
                        bigWasTooLongAgo() ||
                        avoidOddNumberInPair()) &&
                    lastSection.type !== "big" &&
                    lastSection.type !== "adTileBig" &&
                    windowWidth >= BIG_ITEM_WIDTH
                ) {
                    if (movie.type === "genericAd") {
                        sections.push({
                            type: "adTileBig",
                            movie: movie,
                        });
                    } else {
                        sections.push({
                            type: "big",
                            allowYoutubeOverride:
                                sections.length === 0 && self.cat.ytAllowed,
                            movie: movie,
                        });
                    }
                } else {
                    if (movie.type === "genericAd") {
                        sections.push({
                            type: "adTileSmall",
                            movies: [movie],
                        });
                    } else {
                        sections.push({
                            type: "verticalPair",
                            movies: [movie],
                        });
                    }
                }
                lastSection = sections[sections.length - 1];
            });
            return options.fn({
                sections: sections,
            });
        },
    },
});
