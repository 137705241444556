import BaseControl from "../../../lib/can.basecontrol.js";
import Cookie from "../../../lib/cookie-handler";
import config from "../../config";
import state from "../../state";
import View from "./view.stache";

export default BaseControl.extend(
    "AGB",
    {
        defaults: {
            template: View,
        },
    },
    {
        init: function (el, options) {
            this.options.viewOptions.attr("config", config);
            return this._super.apply(this, arguments);
        },
        "#cancel-subscription click": function (el) {
            Cookie.createCookie("openCancelSubscription", true);
            state.goTo("konto");
        },
    }
);
