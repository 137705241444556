<div class="navPlaceholder"></div>

<netzkino-title-social-bar cat="Suchergebnisse für '{{cat.search}}'" show-text="true"></netzkino-title-social-bar>

<div class="main-content main-background">
  <div class="container subpage-container">
    <div class="row catRow">
      {{#switch config.search.displayType}}
        {{#case 'coverflow'}}
          <div class="coverflow">
            {{#each catposts}}
                <movie-cover cover-size="artikelbild" movie="{.}" href="{{_internalMovieLink}}" image-src="{{#getWithFallback . 'custom_fields.Artikelbild.0' 'custom_fields.Cover.0' 'thumbnail'}}{{.}}{{/getWithFallback}}" classes="catPageCover lazy"/>
            {{/each}}
          </div>
        {{/case}}
        {{#case 'typeSliders'}}
          {{#sliderCategories}}
            <netzkino-category-slider 
                cat="{.}"
                shuffle="false"
                id="{{title}}"
                allow-more-button="false"
            />
          {{/sliderCategories}}
        {{/case}}
      {{/switch}}
      {{#unless loading}}
        {{#unless catposts.length}}
          Keine Suchergebnisse gefunden.
        {{/unless}}
      {{/if}}
      {{!instead of calling this once per item, just call it once per this list}}
    </div>
  </div>
  <br/>
</div>
<generic-ad identifier="interstitialAd"></generic-ad>