<div class="{{#if useImageBackdrop}}withBackdrop{{/if}}">
    {{#if useImageBackdrop}}
        <div class="backdrop backdrop-left"></div>
        <div class="backdrop backdrop-right"></div>
    {{/if}}

    <div class="{{#if useImageBackdrop}}container{{/if}}">
        <div class="navPlaceholder"></div>
        <netzkino-title-social-bar cat="Konto" show-text="false" />

        {{#user}}

            <div class="main-background">
                <div id="user-settings-container" class="container">
                    {{#if config.my.accountPage.subscriptionRequired}}
                        {{#if user.isPlus}}
                            {{#if subscription.isCancelled}}
                                <div class="alert alert-warning" style="color: black;">
                                    Dein Abo ist beendet und läuft zum {{subscription.validUntil}} aus.
                                    {{!<a href="#!/my/plus">Klicke hier</a>, um ein neues Abo abzuschließen.}}
                                </div>
                            {{/if}}
                        {{else}}
                            <div class="alert alert-warning">
                                Du hast kein gültiges Abo.
                                <a href="#!/my/plus">Klicke hier</a>, um ein neues Abo abzuschließen.
                            </div>
                        {{/if}}
                    {{/if}}
                    <div class="row">
                        <h2 class="text-uppercase text-center">Nutzerdaten ändern</h2>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">
                            <label for="username">Emailadresse</label><br/>
                            <input type="text" id="username" name="username" value="{{user.email}}" />
                        </div>
                        <div class="col-xs-12">
                            <label for="password">Passwort</label><br/>
                            <input type="password" id="password" name="password" value="" placeholder="Neues Passwort" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">
                            <a href="#" id="save" class="btn btn-big btn-dark btn-konto">Änderungen speichern</a>
                        </div>

                        <div class="col-xs-12">
                            <hr />
                        </div>
                        <div class="col-xs-12">
                            <a href="#" id="logout" class="btn btn-big btn-dark btn-konto">Abmelden</a>
                        </div>
                        {{#user.isPlus}}
                            {{#if subscription.isCancelled}}
                                <div class="col-xs-12">
                                    <a href="#" id="delete" class="btn btn-big btn-dark btn-konto">Benutzerkonto löschen</a>
                                </div>
                                <div class="col-xs-12 text-center" style="color: black;">
                                        {{subscription.infoText}}
                                </div>
                            {{else}}
                                <div class="col-xs-12 text-center">
                                    <a href="" id="cancelSubscription" class="btn btn-big btn-dark btn-konto">{{config.plus.title}} Mitgliedschaft kündigen</a>
                                </div>
                                <div class="col-xs-12 text-center">
                                    <a href="" id="changePaymentData" class="btn btn-big btn-dark btn-konto">Zahlungsdaten ändern</a>
                                </div>
                                <div class="col-xs-12 text-center"  style="color: black;">
                                        {{subscription.infoText}}
                                </div>
                            {{/if}}
                        {{/user.isPlus}}
                        {{^user.isPlus}}
                            <div class="col-xs-12">
                                <a href="#" id="delete" class="btn btn-big btn-dark btn-konto">Benutzerkonto löschen</a>
                            </div>
                        {{/user.isPlus}}
                    </div>
                </div>
                {{#unless config.my.accountPage.subscriptionRequired}}
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12">
                                <plus-payment/>
                            </div>
                        </div>
                    </div>
                {{/unless}}
            </div>

        {{/user}}
    </div>
</div>
