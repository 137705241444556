// this is getting preprocessed by grunt to require specific components and controls depending
// on the platform to be built. This is necessary as these components and controls might use
// the configuration and are required for routing
import can from 'can'
import $ from 'jquery'
import '../../../bower_components/bootstrap/dist/js/bootstrap.js'
import '../../../bower_components/jquery.xdomainrequest/jQuery.XDomainRequest'
import '../../lib/document-write-HACK'
import externalAdvertisers from '../../lib/externalAdvertisers'
import ogury from '../../lib/ogury'
import '../../lib/stache-helpers'
import utils from '../../lib/utils'
import config from '../config'
import AGB from '../controls/agb/agb'
import CategoryPage from '../controls/categorypage/categorypage'
import Datenschutz from '../controls/datenschutz/datenschutz'
import Gewinnspiel from '../controls/gewinnspiel/gewinnspiel'
import Impressum from '../controls/impressum/impressum'
import Jugendschutz from '../controls/jugendschutz/jugendschutz'
import Konto from '../controls/konto/konto'
import LoadingPage from '../controls/loadingpage/loadingpage'
import MainPage from '../controls/mainpage/mainpage'
import MoviePage from '../controls/moviepage/moviepage'
import My from '../controls/my/my'
import Plus from '../controls/plus/plus'
import SearchPage from '../controls/searchpage/searchpage'
import SmartLogin from '../controls/smartlogin/smartlogin'
import HospitalLandingPage from '../controls/hospitallandingpage/hospitallandingpage'
import ga from '../modules/googleanalytics'
import IndexLoader from '../modules/indexloader'
import state from '../state'
import './audio-player/audio-player'
import './avoplayer/avoplayer'
import './category-slider/category-slider'
import './cover-overlay-infobox/cover-overlay-infobox'
import './footer/footer'
import './generic-ad/generic-ad'
import './google-adsense/google-adsense'
import './header/header'
import './html5-video-preview/html5-video-preview'
import './imaplayer/imaplayer'
import './img-lazy'
import './mainpage-slider/mainpage-slider'
import './movie-cover/movie-cover'
import './moviepage-sharing-bar/moviepage-sharing-bar'
import './my-user-login-social/my-user-login-social'
import './my-user-login/my-user-login'
import './my-user-register/my-user-register'
import view from './netzkino-app.stache'
import './part-selector/part-selector'
import './plus-information/plus-information'
import './plus-payment/plus-payment'
import './title-social-bar/title-social-bar'
import './youtube-cover/youtube-cover'
import './notification/notification'

var $el

if (!window.platform) {
  throw 'window.platform not set'
}

$.support.cors = true

function onNavigate() {
  if (config.general.isLanding()) {
    return
  }
  try {
    FB.XFBML.parse()
  } catch (ex) {
    config.log('cannot use FB.XFBML, it is probably blocked by the browser.')
  }
  try {
    twttr.widgets.load()
  } catch (ex) {
    config.log(
      'cannot use twttr.widgets, it is probably blocked by the browser.'
    )
  }
  try {
    gapi.plusone.go()
  } catch (ex) {
    config.log(
      'cannot use gapi.plusone, it is probably blocked by the browser.'
    )
  }
  try {
    gapi.ytsubscribe.go()
  } catch (ex) {
    config.log(
      'cannot use gapi.ytsubscribe, it is probably blocked by the browser.'
    )
  }
  try {
    gapi.follow.go()
  } catch (ex) {
    config.log('cannot use gapi.follow, it is probably blocked by the browser.')
  }
}

state.modules.indexloader = new IndexLoader(state)

// Start the router
can.route('/:control/:id/:subId')
can.route('/:control/:id')
can.route('/:control')
can.route('/')
can.route.ready()
// this controls routing
var controls = {
    loading: LoadingPage,
    main: MainPage,
    category: CategoryPage,
    movie: MoviePage,
    filme: MoviePage,
    suche: SearchPage,
    search: SearchPage,
    impressum: Impressum,
    datenschutz: Datenschutz,
    agb: AGB,
    gewinnspiel: Gewinnspiel,
    jugendschutz: Jugendschutz,
    krankenhaus: HospitalLandingPage,
    /* @if FEATURE_PLUS */
    my: My,
    konto: Konto,
    plus: Plus,
    /* @endif */
  },
  activeControlCompute = can.compute()

if (config.my && config.my.smartLogin.enabled) controls.smartlogin = SmartLogin

function isBehindPayWall(newControlName) {
  var paywall = config.plus.paywall
  return (
    paywall.enabled &&
    paywall.redirectTo !== newControlName &&
    newControlName !== 'loadingpage' && // shown while currentUser gets loaded
    paywall.whitelist.indexOf(newControlName) < 0 &&
    (!state.currentUser || !state.currentUser.isPlus)
  )
}

function activateControl(NewControl) {
  window.scrollTo(0, 0)

  can.batch.start()
  var activeControl = activeControlCompute()
  if (activeControl) {
    activeControl.element && activeControl.element.empty() // with a paywall, the redirect can occur before the old control was created.
    activeControl.destroy()
    try {
      activeControl.seo.reset()
    } catch (e) {
      console.error('could not reset seo', e)
    }
  }
  var $netzkinoApp = $('netzkino-app')
  var newControlName = NewControl.fullName.toLowerCase()
  var paywall = config.plus.paywall
  if (isBehindPayWall(newControlName)) {
    // show the loading page and then redirect to the paywall!
    newControlName = 'loading'
    NewControl = LoadingPage
    config.log(
      '[netzkino-app]: redirecting to "%s", because of the paywall. The whitelist does not match',
      paywall.redirectTo,
      newControlName,
      paywall.whitelist
    )
    state.goTo(paywall.redirectTo) // async!
  }
  $netzkinoApp.removeClass().addClass('control-' + newControlName)

  // special nav-bar background for some controls
  if (window.platform === 'loveisawonder') {
    if (['mainpage', 'categorypage', 'plus'].indexOf(newControlName) > -1) {
      $('.navbar').addClass('navbar-gradient')
    } else {
      $('.navbar').removeClass('navbar-gradient')
    }
  }

  if (window.platform == 'netzkino') {
    can.$('body').removeClass()
    if (
      ['agb', 'datenschutz', 'impressum', 'jugendschutz'].indexOf(
        newControlName
      ) > -1
    ) {
      can.$('body').addClass('subpage')
    }
  }

  $(window).scroll(scrollHandler)

  function scrollHandler() {
    var shouldFadeIn =
      ($netzkinoApp.hasClass('control-mainpage') ||
        $netzkinoApp.hasClass('control-categorypage')) &&
      !$netzkinoApp.hasClass('subcategory')
    if ($(window).scrollTop() > 80 && shouldFadeIn) {
      $('.navbar').addClass('navbar-background')
    } else {
      if (!shouldFadeIn) {
        $('.navbar').addClass('navbar-background')
      } else {
        $('.navbar').removeClass('navbar-background')
      }
    }
    $('.navbar').addClass('navbar-fixed')
  }
  $(document).ready(function () {
    scrollHandler()
  })

  if (newControlName != 'loadingpage') {
    if (utils.getPageCounter() >= 0) {
      // trigger google publisher tag refresh, but only if we are no longer on the initial page
      externalAdvertisers.refresh()
    }
    utils.incrementPageCounter()
  }
  activeControlCompute(
    new NewControl($el, {
      state: state,
      pageViewCounter: utils.getPageCounter(),
    })
  )

  can.batch.stop()
}

function handleRouteChange() {
  // Get the current route control and ID
  const routeControl = can.route.attr('control')
  const routeID = can.route.attr('id')

  config.log('Route changed to:', routeControl, routeID)
  // Function to redirect to the "Krankenhause (Netopsie)" landing page
  function redirectToHospitalLandingPage() {
    state.goTo('krankenhaus')
    setTimeout(() => {
      window.location.reload()
    }, 10)
  }

  // Handle KIXI-49: Special logic for users accessing from Hospitals
  if (window.platform === 'kixi') {
    if (routeControl === 'krankenhaus') {
      state.attr('hideHeader', true)
    } else {
      state.attr('hideHeader', false)
    }
    state.currentUserPromise.then(
      function (user) {
        if (user && user.institutionNameFromIP) {
          if (routeControl !== 'krankenhaus' && !user.isPlus) {
            // Redirect to the Hospital landing page
            redirectToHospitalLandingPage()
            return
          }
        }
      },
      function (errorResponse) {
        if (errorResponse && errorResponse.institutionNameFromIP) {
          if (routeControl !== 'krankenhaus') {
            // Redirect to the Hospital landing page
            redirectToHospitalLandingPage()
            return
          }
        }
      }
    )
  }

  // Handle custom navigation logic for the 'suche' route
  if (routeControl === 'suche') {
    // Use Google's Site Search functionality with parameters
    ga.onNavigate(
      `/${routeControl
        .replace('//', '/')
        .replace(/\/$/, '')}?q=${encodeURIComponent(routeID)}`
    )
  } else {
    // Default navigation logic
    ga.onNavigate(
      `/${[routeControl, routeID]
        .join('/')
        .replace('//', '/')
        .replace(/\/$/, '')}`
    )
  }

  // Handle special redirections 'netzkino'
  if (window.platform === 'netzkino') {
    const redirections = {
      presse: 'https://inside.netzkino.de/presse',
      about: 'https://inside.netzkino.de/ueber-uns',
      'so-funktionierts': 'https://inside.netzkino.de/so-gehts',
    }

    if (routeControl in redirections) {
      // Redirect to specific URLs and skip activating control
      document.location.href = redirections[routeControl]
      return
    }
  }

  // Define the default control as MainPage
  let control = controls[routeControl] || MainPage

  if (control !== MainPage && routeID) {
    // Set the control based on the route control and ID
    control = routeControl === 'suche' ? SearchPage : MoviePage

    if (
      routeControl === 'my' ||
      (process.env.PLATFORM === 'loveisawonder' && routeControl === 'plus')
    ) {
      control = My
    }
  }

  // KIXI-49:
  // disable access to hospital landing page if user is already logged and accessing from the institution IP
  if (
    routeControl === 'krankenhaus' &&
    state.attr('isHospital') &&
    state?.currentUser?.isPlus
  ) {
    // enable header in case of being redirected from the "krankenhaus"-route (landing-page) when a user is already logged in
    state.attr('hideHeader', false)
    control = MainPage
  }

  // Activate the selected control and set a timeout for navigation handling
  activateControl(control)
  setTimeout(onNavigate, 100)
}

var _routeChanceDebounced = can.debounce(handleRouteChange, 100)
can.route.bind('change', _routeChanceDebounced)
can.route.bind('id', _routeChanceDebounced)

var loadIndex = function (index) {
  var cats = []
  index.all_categories.forEach(function (cat) {
    // only add route to category page if we are top level. (all_categories does only contain homepagecategories and categories)
    // SubCategories may not be accessible by direct route. (faked by category-page)

    if (['blog'].indexOf(cat.attr('slug')) < 0) {
      controls[cat.attr('slug')] = CategoryPage
    }

    cats.push(cat.attr('slug'))
  })

  state.defs.initialView.then(function () {
    config.log('Cats organized 🐈')

    handleRouteChange()
    // Initiate Ogury CMP for the first time if there are no previous settings found
    ogury.getUserConsent()

    // this is important upon loading the page
    // var currentControl = can.route.attr('control');
    // if(cats.indexOf(currentControl) == -1) {
    //     activateControl(controls['main']);
    // } else {
    //     activateControl(controls[currentControl]);
    // }
  })
}

// first thing to check: are we here after login ?
// - if we are plus user we want to load another index.json with device www-plus
// - if we are not a plus user, we want to go to the mainpage
if (
  can.route.attr('control') === 'mainpage' &&
  can.route.attr('id') === 'loggedIn'
) {
  state.currentUserPromise.then(
    function (user) {
      if (user.isPlus) {
        state.goTo('mainpage')
        setTimeout(function () {
          window.location.reload()
        }, 10)
      } else {
        // just continue as if nothing happend (except for extended load time)
        state.defs.indexLoader.then(loadIndex)
      }
    },
    function (err) {
      console.error(err)
      state.defs.indexLoader.then(loadIndex) // load the regular page if currentUser check breaks
    }
  )
} else {
  state.defs.indexLoader.then(loadIndex)
}

can.Component.extend({
  tag: 'netzkino-app',
  template: view,
  scope: {
    state: state,
  },
  events: {
    init: function (el, options) {
      var self = this

      // add the isLoveisawonder-scope for conditional rendering of the
      self.options.scope.attr(
        'isLoveisawonder',
        window.platform === 'loveisawonder'
      )
      // wait for the browser to render the page, then activate the first control
      can.defer(function () {
        $el = can.$('netzkino-content')
        activateControl(controls['loading'])

        state.currentUserPromise.then(
          function (currentUser) {
            if (currentUser && currentUser.institutionNameFromIP) {
              state.attr('isHospital', true)
            }
            state.defs.initialView.resolve()
            return currentUser
          },
          function (err) {
            if (err && err.institutionNameFromIP) {
              state.attr('isHospital', true)
            }
            state.defs.initialView.resolve()
            console.error('paywall could not resolve current user', err)
            // throw err; Throwing an error here actually breaks jQuerys .then chain handling. No other error handler can react to a missing user
          }
        )

        // make sure that the cookie note never overlaps the imprint by adding a padding to the footer
        var $cookieNote = can.$('.cookie-notification')
        if ($cookieNote.is(':visible')) {
          var $footer = $('footer')
          var oldPadding = parseInt($footer.css('padding-bottom'))
          $footer.css(
            'padding-bottom',
            (oldPadding || 0) + $cookieNote.outerHeight()
          )

          $('.js-btn-cookie').click(function () {
            $footer.css(
              'padding-bottom',
              Number.isNaN(oldPadding) ? '' : oldPadding
            )
          })
        }
        // NP-870: We initialize the GTP-script on "indexload" instead, since the ogury doesn't respond with a proper tc string if requested on "pageload"
        // externalAdvertisers.init("pageload");
      })
    },
  },
})
