import can from "can";
import netletixRefresh from "./netletix-refresh";

export default {
    // same handling as in the mainpage, add categories asynchronously to improve visual rendering performance
    // the user will see the page earlier and might not even notice the increased page load time.
    asyncCategoryRendering: function asyncCategoryRendering(
        viewModel,
        viewProperty,
        allCategories
    ) {
        var categories = new can.List();
        var requestAnimationFrame =
            window.requestAnimationFrame || can.defer.bind(can.defer);

        var index = 0;
        function categoryIterator() {
            if (index >= allCategories.length) {
                can.defer(function () {
                    // after rendering, as the categories contain ad-container, netletix needs to rescan the dom for ad-placements
                    netletixRefresh.refresh();
                });
                return;
            } else {
                categories.push(allCategories[index]);
                index++;
                requestAnimationFrame(categoryIterator);
            }
        }

        requestAnimationFrame(categoryIterator);
        // allCategories.forEach(function(item, index) { // might introduce a memory leak for at most (length * 10) ms
        //     setTimeout(function() {
        //         can.defer(function() {
        //         })
        //     }, index*16); // rough estimation of frame, 60Hz => 16ms per frame, does not need to be accurate, just has to be async
        // });
        viewModel.attr(viewProperty, categories);
    },
};
