import can from "can";
import API from "../api";
import config from "../config";
import state from "../state";
import analytics from "./googleanalytics";

let AUDIO_SEPERATOR = ":";
let SERIES_SEPERATOR = "-";
let MOVIE_ID_REGEX = new RegExp("[" + AUDIO_SEPERATOR + SERIES_SEPERATOR + "]");

// fire 'n forget
// as long as we do not have the movie in our watchlist, we want to add, else update currentTime
function sendWatchtimeUpdate(trackId, currentTimePrecise, ajaxOptions) {
    let currentTime = Math.floor(currentTimePrecise); // we only need the value as an integer

    if (state.attr("currentWatchlistByMovieId." + trackId)) {
        state.currentUserPromise.then(function () {
            API.post(
                config.my.api.getUrl("watchlistUpdate") + "/" + trackId,
                {
                    currentTime: currentTime,
                },
                ajaxOptions
            ).then(
                function (response) {
                    if (response && response.success) {
                        config.log("updated watchlist item", response);
                    } else {
                        config.log("error updating watchlist item", response);
                    }
                },
                function (err) {
                    config.log("error updating watchlist item", err);
                }
            );
            // assume response as cached state
            state.attr(
                "currentWatchlistByMovieId." + trackId + ".currentTime",
                currentTime
            );
        });
    } else {
        analytics.sendEvent("Weiterschauen", "Add", trackId);
        state.currentUserPromise.then(function () {
            API.post(
                config.my.api.getUrl("watchlistAdd") + "/" + trackId,
                {
                    currentTime: currentTime,
                },
                ajaxOptions
            ).then(
                function (response) {
                    if (response && response.success) {
                        watchlist.load(); // update current watchlist to fetch freshly inserted movie
                        config.log("added to watchlist", response);
                    } else {
                        config.log("error adding to watchlist", response);
                    }
                },
                function (err) {
                    config.log("error adding to watchlist", err);
                }
            );
            // assume response as cached state
            state.attr(
                "currentWatchlistByMovieId." + trackId,
                new can.Map({
                    movieId: trackId,
                    currentTime: currentTime,
                })
            );
        });
    }
}

function trackIdForVideo(model) {
    var trackId = model.attr("id");
    if (model.type === "seriesContent") {
        trackId =
            model.attr("seriesModel.firstEpisode.id") +
            SERIES_SEPERATOR +
            model.attr("normalizedPart");
    }
    return trackId;
}

function trackIdForAudio(audioModel, partNumber) {
    return audioModel.attr("id") + AUDIO_SEPERATOR + partNumber;
}

var watchlist = {
    updateWatchlistForVideo: function (model, time, ajaxOptions) {
        return sendWatchtimeUpdate(trackIdForVideo(model), time, ajaxOptions);
    },
    updateWatchlistForAudio: function (
        audioModel,
        partNumber,
        time,
        ajaxOptions
    ) {
        var current = this.getTimeForAudioSync(audioModel, partNumber);
        var trackId = trackIdForAudio(audioModel, partNumber);
        return sendWatchtimeUpdate(trackId, time, ajaxOptions);
    },
    getTimeForVideoSync: function (model) {
        return state.attr(
            "currentWatchlistByMovieId." +
                trackIdForVideo(model) +
                ".currentTime"
        );
    },
    getTimeForAudioSync: function (model, partNumber) {
        return state.attr(
            "currentWatchlistByMovieId." +
                trackIdForAudio(model, partNumber) +
                ".currentTime"
        );
    },
    removeId: function name(idToRemove) {
        analytics.sendEvent("Weiterschauen", "Remove", idToRemove);
        var map = state.attr("currentWatchlistByMovieId");
        can.Map.keys(map).forEach(function (trackId) {
            if (trackId.indexOf(idToRemove) >= 0) {
                map.removeAttr(trackId);
                config.log("removed movie from list", trackId);
                API.post(
                    config.my.api.getUrl("watchlist") + "remove/" + trackId
                ).then(
                    function () {},
                    function (err) {
                        config.error("removal failed", trackId, err);
                        return err;
                    }
                );
            }
        });
    },
    getLatestEntry: function (movie) {
        var trackIds = [];
        var byId = state.attr("currentWatchlistByMovieId");
        if (!byId) return;
        can.Map.keys(byId).forEach(function (trackId) {
            if (trackId.indexOf(movie.id) >= 0) {
                var parts = trackId.split(MOVIE_ID_REGEX);
                if (!parts[1] || !isNaN(parseInt(parts[1]))) {
                    trackIds.push(trackId);
                }
            }
        });
        trackIds.sort();
        var latestTrackId = trackIds[trackIds.length - 1];
        if (!latestTrackId) {
            return;
        }
        var latest = byId.attr(latestTrackId);
        if (latest) {
            var p = latest.attr("movieId").split(MOVIE_ID_REGEX);
            return {
                currentTime: latest.attr("currentTime"),
                movieId: p[0],
                part: p[1],
            };
        } else {
            return;
        }
    },
    load: function () {
        config.log("getting watchlist for current user");
        return state.currentUserPromise
            .then(function () {
                return API.get(config.my.api.getUrl("watchlist"));
            })
            .then(
                function (watchlist) {
                    var watchlistItemsByMovieId = {};
                    watchlist.forEach(function (item) {
                        watchlistItemsByMovieId[item.movieId] = item;
                    });
                    state.attr(
                        "currentWatchlistByMovieId",
                        new can.Map(watchlistItemsByMovieId)
                    );
                    var latestParts = {
                        // movieId: movie
                    };

                    // series and audiobook should only include the latest part
                    watchlist.forEach(function (item, index) {
                        var movieIdParts = item.movieId.split(MOVIE_ID_REGEX);
                        var movieId = movieIdParts[0];
                        var partIdentifier = movieIdParts[1];
                        var part = parseInt(partIdentifier); // can be NaN

                        // normalize to the first part of the series or track of the audioContent
                        item.movieId = movieId;
                        if (part) {
                            // NaN check
                            item.part = part;
                        }
                        item._sortBy = index;

                        if (!partIdentifier || !latestParts[movieId]) {
                            latestParts[movieId] = item;
                        } else {
                            var saved = latestParts[movieId];
                            if (saved.part < part) {
                                latestParts[movieId] = item;
                            }
                        }
                    });

                    var listToReturn = Object.keys(latestParts)
                        .map(function (key) {
                            return latestParts[key];
                        })
                        .sort(function (a, b) {
                            return a._sortBy - b._sortBy; // latest at the front
                        })
                        .map(function (i) {
                            delete i._sortBy;
                            return i;
                        });

                    return listToReturn;
                },
                function (err) {
                    config.error("could not get watchlist", err);
                    return err;
                }
            );
    },
};
export default watchlist;
