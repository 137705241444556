import utils from "../../lib/utils";
import amogoConfig from "./amogoBaseConfig";
var isProduction = process.env.NODE_ENV === "production";
var smplEnv = utils.getSMPLEnv(isProduction);

var kixiProxyUrlPrefix =
    smplEnv === "dev"
        ? "https://proxy.kixi.smpl.dev"
        : "https://proxy.kixi." + smplEnv + ".smpl.services";
var kixiPaymentUrlPrefix =
    smplEnv === "dev"
        ? "https://payment.kixi.smpl.dev"
        : "https://payment.kixi." + smplEnv + ".smpl.services";
var kixiTokenUrlPrefix =
    smplEnv === "dev"
        ? "https://token.kixi.smpl.dev"
        : "https://token.kixi." + smplEnv + ".smpl.services";

export default utils.mergeDeep({}, amogoConfig, {
    general: {
        name: "Kixi",
        support: "info@kixi.de",
        privacy: "datenschutz@kixi.de",
        baseURL: "https://www.kixi.de/",
        websiteShort: "Kixi.de",
        company: "Kixi Entertainment GmbH ",
        companyShort: "Kixi",
        newsletter: "info@kixi.de",
        newsletterUnsubscribe: "info@kixi.de",
        address:
            "Kixi Entertainment GmbH<br />" +
            "August-Bebel-Straße 27<br />" +
            "14482 Potsdam<br />" +
            "Telefon: 0331-23220021<br />" +
            'E-Mail: <a href="mailto:info@kixi.de">info@kixi.de</a><br />' +
            'Website: <a href="https://www.kixi-entertainment.com/">https://www.kixi-entertainment.com</a><br />',
        privacyContact:
            "Kixi Entertainment GmbH<br />" +
            "Peter von Ondarza<br />" +
            '<a href="mailto:info@kixi.de">info@kixi.de</a><br />' +
            "August-Bebel-Straße 27<br />" +
            "14482 Potsdam",
        stacheKey: "kixi", // use template files with this name
        isLanding: function () {
            return typeof window.kixilanding !== "undefined" && kixilanding;
        },
    },
    google_analytics: {
        id:
            process.env.NODE_ENV === "production"
                ? "UA-10769707-15"
                : "UA-10769707-14",
        avo_id:
            process.env.NODE_ENV === "production"
                ? "UA-10769707-15"
                : "UA-10769707-14",
    },
    paths: {
        api: {
            base:
                process.env.NODE_ENV === "production"
                    ? "https://api.kixi.de.simplecache.net/capi-2.0a"
                    : "https://kixi-tapi.herokuapp.com/capi-2.0a",
            baseParams: {
                // p: 'kixi',
                d: "www",
                v: "1.2.0", // for CAPI checks, similar to android
            },
        },
        images: {
            base: "https://pmd.kinderkino-bilder.kixi.de/Bilder/", // trailing slash!
        },
    },
    categoryTree: {
        enabled: true,
        isRoot: function (cat) {
            var parentId = cat.parentId;
            if (cat instanceof can.Map) {
                parentId = cat.attr("parent");
            }
            return parentId === 0 || parentId === 641;
        },
    },
    frontpage: {
        isFeaturedSliderCategory: function (cat) {
            return cat.slug === "featured";
        },
        sliderTypeForCategory: function (cat) {
            var isAudio = function (c) {
                return c.slug.indexOf("audio") >= 0;
            };
            if (isAudio(cat) || cat.hasParentWithCondition(isAudio)) {
                return "audioContentSlider";
            }
            return "sliderDefaultVertical";
        },
        categorySlider: {
            showMoreButton: true,
        },
    },
    ads: {
        frontpagebanner: {
            show: true,
            everyNthSlider: 100, // shows at least once

            target: "#!/my/payment",
            src: "https://pmd.kinderkino-bilder.kixi.de/Bilder/webseite/plus/promo-banner.jpg",
        },
    },
    moviepage: {
        preferredAvoPlayer: "html5", // flash requires HDS support
        sharing: {
            enabled: false,
            twitterVia: "kixi",
        },
        audioSourceTemplate: function () {
            return "https://pmd.kinderkino-seite.kixi.de/Seite/{}.mp3";
        },
        videoSourceTemplate: function (isProtected, sourceReplacements) {
            var sourceTemplate = {
                // nklanding for luigi
                hds: "https://kixi_kost_seite-vh.akamaihd.net/z/Ads/blacky.mp4/manifest.f4m",
                hls: "https://kixi_kost_and-vh.akamaihd.net/i/Ads/blacky.mp4/master.m3u8",
                pmd: "https://pmd.kixi-kos-seite.kixi.de/Ads/blacky.mp4",
            };

            if (!window.kixilanding) {
                // if (isProtected && sourceReplacements) {
                //     sourceTemplate = {
                //         hds: 'https://nk_plus_hds-vh.akamaihd.net/z/' + sourceReplacements['hds'],
                //         hls: 'https://nk_plus_hls-vh.akamaihd.net/i/' + sourceReplacements['hls'],
                //         pmd: 'https://pmd.netzkino-plus.netzkino.de/' + sourceReplacements['pmd']
                //     }
                sourceTemplate = {
                    // hds: 'https://kinderkino_and-vh.akamaihd.net/z/Seite/{}', // not supported
                    // hls: "https://kinderkino_and-vh.akamaihd.net/i/Seite/{}", // not supported (TODO: playlist urls are currently not working. Is this due to change from Akamai to AWS?)
                    pmd: "https://pmd.kinderkino-seite.kixi.de/Seite/{}",
                };
            }

            return sourceTemplate;
        },
        scheduleURLForMovie: function (movie) {
            // see AVO-137 - this should speedup loading the player
            return null;
        },
    },
    my: {
        enabled: true,
        title: "Mein Kixi",
        feedbackEmail: "info@kixi.de",
        registerLabel: "Jetzt abonnieren!",
        accountPageOnly: false,
        accountPage: {
            subscriptionRequired: true,
        },
        wishlist: {
            name: "Merkliste",
            sliderType: "sliderDefaultVertical",
        },
        api: {
            getUrl: function (request) {
                var mapping = {
                    login: "/auth/login",
                    logingoogle: "/auth/google",
                    loginfacebook: "/auth/facebook",
                    logout: "/auth/logout",
                    users: "/users/",
                    me: "/users/me",
                    createLoginToken: "/users/createLoginToken",
                    wishlist: "/wishlist/",
                    watchlist: "/watchlist/",
                    watchlistAdd: "/watchlist/add",
                    watchlistUpdate: "/watchlist/update",
                    base: "/",
                };
                if (mapping[request]) {
                    return kixiProxyUrlPrefix + mapping[request]; //'https://localhost:60099' + mapping[request]; //https://login.kixi.de' + mapping[request];
                } else {
                    return null;
                }
            },
            headerPlatformName: "kixi", // used for the X-{headerPlatformName}-Version headers set in api calls - needs to match backend deploy configuration
        },
        header: {
            loginLabel: "Los geht's!",
            buttonClass: "kixi-blue-btn",
            isLoggedInButton: true,
        },
        socialLoginUrl: function (provider) {
            return (
                "https://www.kixi.de/auth?next=" +
                encodeURIComponent(window.location.href.split("#")[0] + "#!/my")
            );
        },
        smartLogin: {
            enabled: true,
            showHeaderButton: true,
            showMyButton: true,
        },
        forgotPasswordUrl:
            process.env.NODE_ENV === "production"
                ? "https://www.kixi.de/forgot.html"
                : "https://www.kixi.de/forgot-" + smplEnv + ".html",
        // redirects to mainpage where depending on user plus state redirection takes place
        localLoginUrl: function () {
            // since this is now used via xhr, we do not need all the query params.

            // var successRedirect = encodeURIComponent('https://www.kixi.de/auth?next=' + encodeURIComponent(window.location.href.split('#')[0] + '#!/mainpage'));
            // var movieInfo = Cookie.readCookie('movieToWishlist');
            // if (movieInfo) {
            //     var slug = movieInfo.split(/\|(.+)/)[1];
            //     successRedirect = encodeURIComponent('https://www.kixi.de/auth?next=' + encodeURIComponent(window.location.href.split('#')[0] + '#!/search/' + slug));
            // }
            return kixiProxyUrlPrefix + "/auth/login"; // ?plainRedirect=true&redirectURL=' + successRedirect + '&errorRedirectURL=' + encodeURIComponent('https://www.kixi.de/auth?next=' + encodeURIComponent(window.location.href.split('#')[0] + '#!/my/loginerror'));
        },
        localRegisterUrl: function () {
            // since this is now used via xhr, we do not need all the query params.

            // var successRedirect = encodeURIComponent('https://www.kixi.de/auth?next=' + encodeURIComponent(window.location.href.split('#')[0] + '#!/mainpage'));
            // var movieInfo = Cookie.readCookie('movieToWishlist');
            // if (movieInfo) {
            //     var slug = movieInfo.split(/\|(.+)/)[1];
            //     successRedirect = encodeURIComponent('https://www.kixi.de/auth?next=' + encodeURIComponent(window.location.href.split('#')[0] + '#!/search/' + slug));
            // }
            return kixiProxyUrlPrefix + "/users/"; // ?plainRedirect=true&redirectURL=' + successRedirect + '&errorRedirectURL=' + encodeURIComponent('https://www.kixi.de/auth?next=' + encodeURIComponent(window.location.href.split('#')[0] + '#!/my/registrationerror'));
        },
        loginProviders: [
            // {
            //     name: 'Mit Facebook einloggen',
            //     registerName: 'Mit Facebook registrieren',
            //     key: 'facebook',
            //     image: 'webseite/social_f.png'
            // },
            // {
            //     name: 'Mit Google einloggen',
            //     registerName: 'Mit Google registrieren',
            //     key: 'google',
            //     image: 'webseite/social_g.png'
            // }
        ],
    },
    plus: {
        enabled: true,
        title: "Kixi",
        supportsPackages: false,
        slogan: "Filme, Serien & Hörspiele für Kinder",
        socialBar: {
            claim: "",
        },
        welcomeMessage:
            "Herzlich Willkommen als Kixi Nutzer! Du hast jetzt Zugriff auf alle Funktionen.",
        price: "4,99€",
        headerLogoImagePath: "webseite/logo_header_small.png",
        paywall: {
            enabled: false,
        },
        excludedHomepageCategoriesLanding: [],
        // expectationCategoryParent: 'landingpage-love-is-a-wonder',
        api: {
            getUrl: function (request) {
                switch (request) {
                    case "preparePayment":
                        return kixiPaymentUrlPrefix + "/payment/prepare";
                    case "cancelPayment":
                        return kixiPaymentUrlPrefix + "/payment/cancel";
                    case "getProducts":
                        return kixiPaymentUrlPrefix + "/products";

                    case "postPaypalPayment":
                        return (
                            kixiPaymentUrlPrefix + "/paypal/custom/notification"
                        );
                    case "createToken":
                        return kixiTokenUrlPrefix + "/createToken";
                    default:
                        throw new Error("Invalid request type: " + request);
                }
            },
        },
        infoPage: {
            show: false, // accessible by route => automatically through paywall
        },
        socialLoginUrl: function (provider) {
            return (
                "https://www.kixi.de/auth?next=" +
                encodeURIComponent(
                    window.location.href.split("#")[0] + "#!/my/plus"
                )
            );
        },
        plusCategorySlug: "kixi",
        plusCategoryId: 181, // better have it defined together instead of relying on correct spelling / lookup
        // plusCategorySlug: 'kixiplus',
        // plusCategoryId: 9681, // better have it defined together instead of relying on correct spelling / lookup // FIXME not kixiplus!
        isPlusCategory: function (cat) {
            // does not matter for kixi, plus status of categories does not matter
            return cat.id === 181;
        },
        // overridePlusCategoryInheritance: function (cat) { // does not matter for kixi, plus status of categories does not matter
        //     return cat.id === 551; // from liaw, does not matter
        // },
        showExtraCTA: false, //show extra payment/plus subscribe form on movie page
        genreCategories: {
            appearAfter: 9692,
            categoryIds: [/*10081,*/ 10131, 10151, 10171, 10191, 10211, 10231],
        },
        getLandingPagePreviewURLs: function (targetCategorySlug) {
            var minIndex = 1;
            var maxIndex = 3;
            var randomIndex =
                Math.floor(Math.random() * (maxIndex - minIndex + 1)) +
                minIndex;
            var teaserBaseUrl =
                "https://pmd.kinderkino-bilder.kixi.de/Bilder/webseite/teaservideos";

            var landingPagePreviewUrl = {
                placeholderURL:
                    teaserBaseUrl +
                    "/mobilePlaceholder/mainpage_landing_{}.jpg".replace(
                        "{}",
                        "" + randomIndex
                    ),
                videoURL:
                    teaserBaseUrl +
                    "/mainpage_landing_{}.mp4".replace("{}", "" + randomIndex),
            };
            if (targetCategorySlug) {
                if (
                    ["serien", "audio", "filme"].indexOf(targetCategorySlug) < 0
                ) {
                    return null;
                }
                landingPagePreviewUrl = {
                    placeholderURL:
                        teaserBaseUrl +
                        "/mobilePlaceholder/landing_{}.jpg".replace(
                            "{}",
                            targetCategorySlug
                        ),
                    videoURL:
                        teaserBaseUrl +
                        "/landing_{}.mp4".replace("{}", targetCategorySlug),
                };
            }
            return landingPagePreviewUrl;
        },
        getLandingPageMobileImageURL: function (targetCategorySlug) {
            var imageBaseUrl =
                "https://pmd.love-bilder.kixi.com/bilder/liaw/mobile/";
            var imageUrl = imageBaseUrl + "landingpage.jpg";

            if (targetCategorySlug) {
                imageUrl =
                    imageBaseUrl + "landingpage_" + targetCategorySlug + ".jpg";
            }
            return imageUrl;
        },
        registrationOptionHeader: {
            firstOptionTitle: "Standardkonto",
            firstOptionPrice: "kostenlos",
            secondOptionTitle: "Premium",
            secondOptionPrice: "4,99 € / Monat",
        },
        registrationOptions: [
            {
                name: "Werbefrei",
                firstOption: false,
                secondOption: true,
            },
            {
                name: "Pädagogisch wertvoll",
                firstOption: false,
                secondOption: true,
            },
            {
                name: "Sicher & legal",
                firstOption: false,
                secondOption: true,
            },
            {
                name: "Bildungsmedien für Kinder",
                firstOption: false,
                secondOption: true,
            },
            {
                name: "Umfangreicher Audiobereich",
                firstOption: false,
                secondOption: true,
            },
            {
                name: "Monatlich kündbar",
                firstOption: true,
                secondOption: true,
            },
        ],
        registrationOptionFootnote:
            "*Die High-Definition-Verfügbarkeit ist abhängig von deiner Internetgeschwindigkeit. Nicht alle Inhalte sind in HD verfügbar.",
        paymentFootnoteHTML:
            'Mit dem Kauf berechtigst du Kixi Entertainment GmbH deine Zahlungsmethode jeden Monat mit 4,99€/Monat (inkl. gesetzlicher MwSt) zu belasten. Das Abo verlängert sich jeden Monat automatisch und kann jederzeit gekündigt werden. Es gelten die Kixi Entertainment GmbH haftungsbeschränkt <a href="#!/agb" class="login-link-blue" target="_blank">AGBs</a> inkl. der Widerrufsbelehrung.',
    },
    header: {
        info: {
            show: false,
            label: "Informationen",
            faIcon: "info",
            links: [
                {
                    link: "#!/impressum",
                    label: "Impressum",
                },
                {
                    link: "#!/datenschutz",
                    label: "Datenschutz",
                },
                {
                    link: "#!/impressum", //yep, that's correct
                    label: "Jugendschutz",
                },
            ],
        },
        categories: {
            displayType: "fixed",
            iconPath: "webseite/categories_trans/",
            overrides: [
                {
                    title: "Filme",
                    href: "#!/filme",
                    // faIcon: 'film',
                    class: "kixi-red",
                },
                {
                    title: "Serien",
                    href: "#!/serien",
                    // faIcon: 'play',
                    class: "kixi-yellow",
                },
                {
                    title: "Bildung",
                    href: "#!/bildungsmedien",
                    // faIcon: 'play',
                    class: "kixi-blue",
                },
                {
                    title: "Audio",
                    href: "#!/audio",
                    // faIcon: 'headphones',
                    class: "kixi-blue",
                },
            ],
        },
    },
    search: {
        displayType: "typeSliders",
        faIcon: "search",
        menuLabel: "",
    },
    socialBar: {
        show: false,
        claim: "Pädagogisch wertvolle Kinderfilme",
        links: [
            {
                label: "Kixi bei Facebook liken",
                link: "https://www.facebook.com/Kixi.de/",
                image: "https://pmd.bilder.netzkino.de/bilder/webseite/smbutton_fb_small.png",
            },
            {
                label: "Kixi bei Twitter folgen",
                link: "https://twitter.com/kixikinderapp",
                image: "https://pmd.bilder.netzkino.de/bilder/webseite/smbutton_tw_small.png",
            },
            {
                label: "Kixi bei Google+",
                link: "https://plus.google.com/+kinderkino",
                image: "https://pmd.bilder.netzkino.de/bilder/webseite/smbutton_g+_small.png",
            },
            {
                label: "Kixi bei Youtube abonnieren",
                link: "https://www.youtube.com/channel/UCF2IFFQyO5gbhvOCObh1WTQ",
                image: "https://pmd.bilder.netzkino.de/bilder/webseite/smbutton_yt_small.png",
            },
        ],
        extraLogo: {
            show: false,
        },
    },
    footer: {
        info: "",
        copyrightLink: "https://www.kixi-entertainment.com/",
        copyright: "Kixi Entertainment GmbH",
        links: [
            {
                link: "#!/",
                label: "Startseite",
            },
            {
                link: "#!/impressum", //yep, that's correct
                label: "Jugendschutz",
            },
            {
                link: "#!/agb",
                label: "AGB",
            },
            {
                link: "#!/datenschutz",
                label: "Datenschutz",
            },
            {
                link: "#!/impressum",
                label: "Impressum",
            },
            {
                link: "#!/",
                label: "Cookie Policy",
            },
        ],
    },
});
