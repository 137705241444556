import can from "can";
var MOBILE_WIDTH = 468;
var pageCounter = -1;

function getDeep(obj, path) {
    return can.getObject(path, [obj]);
}

function checkSubscriptionStatus(user) {
    var subscriptions = getDeep(user, "subscriptionsByUserId.subscriptions");

    if (subscriptions) {
        // this logic needs to be improved and extended to support multiple subscriptions
        var activeSubscriptions = subscriptions.filter(
            (subscription) => subscription.isActive
        );

        if (activeSubscriptions.length > 0) {
            activeSubscriptions.sort(
                (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
            );

            var subscription = activeSubscriptions[0];

            // let's extract other information about the user subscription
            var terms =
                subscription.subscriptionTermsBySubscriptionId
                    .subscriptionTerms;
            var activeSubscriptionTerm = terms[0];
            terms.forEach(function (term) {
                // check for longest availability term
                var validUntil = new Date(term.validUntil);
                var currentValidUntil = new Date(
                    activeSubscriptionTerm.validUntil
                );
                term.subscriptionId = subscription.id;
                if (validUntil > currentValidUntil) {
                    activeSubscriptionTerm = term;
                }
            });
            // check if wirecard warning should be enabled
            // subs are ordered by created date DESC
            var shouldWarnAboutWirecard = false;
            if (
                subscription &&
                subscription.subscriptionProduct &&
                subscription.subscriptionProduct.type === "SUBSCRIPTION"
            ) {
                var firstNonSupportSub = subscriptions.find(function (sub) {
                    if (sub && sub.paymentProvider !== "support") {
                        return true;
                    } else {
                        return false;
                    }
                });
                if (
                    firstNonSupportSub &&
                    firstNonSupportSub.paymentProvider &&
                    firstNonSupportSub.paymentProvider === "wirecard"
                ) {
                    shouldWarnAboutWirecard = true;
                }
            }

            return {
                isPlus: true,
                activeSubscriptionTerm: activeSubscriptionTerm,
                activeSubscription: subscription,
                shouldWarnAboutWirecard: shouldWarnAboutWirecard,
            };
        }
    }
    return {
        isPlus: false,
    };
}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default {
    parseQueryString: function parseQuery() {
        var queryString = location.search;
        var query = {};
        var pairs = (
            queryString[0] === "?" ? queryString.substr(1) : queryString
        ).split("&");
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split("=");
            query[decodeURIComponent(pair[0])] = decodeURIComponent(
                pair[1] || ""
            );
        }
        return query;
    },
    mergeDeep: function mergeDeep(out) {
        // http://youmightnotneedjquery.com/ but array safe
        out = out || {};

        for (var i = 1; i < arguments.length; i++) {
            var obj = arguments[i];

            if (!obj) continue;

            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (
                        typeof obj[key] === "object" &&
                        !Array.isArray(obj[key])
                    ) {
                        // do not merge arrays!
                        out[key] = mergeDeep(out[key], obj[key]);
                    } else {
                        out[key] = obj[key];
                    }
                }
            }
        }

        return out;
    },
    getDeep: getDeep,
    resolveCompute: function resolveCompute(compute) {
        var value = compute;
        if (typeof compute === "function") {
            value = compute();
            if (value === undefined) {
                value = compute;
            }
        } else {
            value = compute;
        }
        if (typeof value === "function") {
            value = value(); // can be multicomputed
        }
        return value;
    },
    getParameterByName: getParameterByName,
    checkSubscriptionStatus: checkSubscriptionStatus,
    mightBePlusUser: function () {
        // only localstorage, not validated from the API. Should not be trusted!
        if (window.localStorage) {
            var serializedUser = window.localStorage.getItem("currentUser");
            if (serializedUser) {
                var cachedUser = JSON.parse(serializedUser);
                return checkSubscriptionStatus(cachedUser).isPlus;
            }
        }
        return false;
    },
    forceReloadAtPath: function (path) {
        window.location.href =
            window.location.href.split("#")[0] + "#!/" + path;
    },
    removeParamFromURL: function (key, value) {
        var currentUrl = window.location.href;
        // get URL after last'/'
        var tail = currentUrl.substring(currentUrl.lastIndexOf("?"));

        var toBeReplaced = key + "=" + value;
        var positionInTail = tail.lastIndexOf(toBeReplaced);
        if (tail.charAt(positionInTail - 1) === "&") {
            toBeReplaced = "&" + toBeReplaced;
        }

        // remove key+value pair from url
        tail = tail.replace(new RegExp(toBeReplaced, "g"), "");

        if (
            window.history &&
            typeof window.history.replaceState === "function"
        ) {
            window.history.replaceState({}, "", tail);
        }
    },
    // https://stackoverflow.com/a/23522755
    isSafari: () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    isIOS: () =>
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform),
    isAndroid: () => !!navigator.userAgent.match(/Android/i),
    isMobile: () => window.innerWidth <= MOBILE_WIDTH,
    getPageCounter: () => pageCounter,
    incrementPageCounter: function () {
        pageCounter++;
    },
    getMobileWidth: () => MOBILE_WIDTH,
    getSMPLEnv: function (isProduction) {
        var smplEnv =
            getParameterByName("smpl", window.location.href) || undefined;
        var currentEnv = window.sessionStorage
            ? window.sessionStorage.getItem("smpl")
            : undefined;
        // write smpl env to sessionStorage
        if (
            currentEnv !== undefined &&
            smplEnv !== undefined &&
            ((!currentEnv && currentEnv !== undefined) ||
                (smplEnv !== undefined && smplEnv !== currentEnv))
        ) {
            window.sessionStorage.setItem("smpl", smplEnv);
            currentEnv = smplEnv;
        }
        if (isProduction) {
            return currentEnv || "eu1";
        } else {
            return currentEnv || "dev";
        }
    },
    normalizePart: function normalizePart(rawPart) {
        var part = parseInt(rawPart);
        if (part < 100) {
            // Sometimes, some people forget the part of the series. ->  "15" instead of "115"
            part += 100;
        }
        return part;
    },
    stripHTML: function stripHTML(html) {
        var doc = new DOMParser().parseFromString(html, "text/html");
        return doc.body.textContent || "";
    },
};
