import BaseControl from "../../../lib/can.basecontrol.js";
import Cookie from "../../../lib/cookie-handler";
import config from "../../config";
import User from "../../models/user";
import state from "../../state";
import View from "./view.stache";

export default BaseControl.extend(
    "SmartLogin",
    {
        defaults: {
            template: View,
        },
    },
    {
        init: function () {
            initViewOptions.call(this);
            determineIfLoggedIn.call(this);
            return this._super.apply(this, arguments);
        },
        "#btn-code click": function () {
            this.options.viewOptions.attr("showCode", "pending");

            User.createLoginToken().then(
                function (response) {
                    this.options.viewOptions.attr("showCode", "true");
                    this.options.viewOptions.attr("code", response.token);
                    config.log(
                        "SmartTV Login - Gotten Code: " +
                            this.options.viewOptions.attr("code")
                    );
                }.bind(this),
                function (error) {
                    this.options.viewOptions.attr("showCode", "error");
                    config.log("SmartTV Login - Error: " + error);
                }.bind(this)
            );
        },
    }
);

function initViewOptions() {
    this.options.viewOptions.attr({
        state: state,
        config: config,
        platform: config.general.name,
        isLoggedIn: "pending",
        code: null,
        showCode: "false",
    });
}

function determineIfLoggedIn() {
    var state = this.options.state;
    config.log("SmartTV Login - check status of login");
    state.currentUserPromise = User.getCurrentUser();
    state.currentUserPromise.then(
        function () {
            config.log("SmartTV Login - User is loggedIn");
            this.options.viewOptions.attr("isLoggedIn", "true");
        }.bind(this),
        function () {
            config.log("SmartTV Login - User is not loggedIn");
            Cookie.createCookie("goToSmartlogin", true);
            state.goToWithParams("my", { redirectToSmartlogin: true });
        }.bind(this)
    );
}
