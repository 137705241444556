{{#if parentCategorySliderCategory}}
  <netzkino-mainpage-slider cat="{parentCategorySliderCategory}" hideextraslider="true"></netzkino-mainpage-slider>

{{/else}}

  <div class="navPlaceholder"></div>
  {{#if isKixi}}
    {{#if landingPreview}}
      {{#unless state.currentUser.isPlus}}
        {{!-- copied from mainpage... --}}
        <div class="kixiPromoVideoBanner" style="background-image: url('{{landingPreview.placeholderURL}}')">
            <video loop muted autoplay poster="{{landingPreview.placeholderURL}}" class="hidden-xs">
                <source src="{{landingPreview.videoURL}}" type="video/mp4">
            </video>
            <div class="slider-register">
                <a href="#!/my/payment" alt="Jetzt abonnieren" class="register-now highlight-shadow"></a>
            </div>
        </div>
      {{/unless}}
    {{/unless}}
  {{/if}}
{{/if}}
<!--{{#index.featured_homepage_category}}
  <netzkino-mainpage-slider cat="{.}"></netzkino-mainpage-slider>
{{/index.featured_homepage_category}}-->
{{#unless state.currentUser.isPlus}}
  <!--  <generic-ad blacklist='{{adBlacklist}}' identifier="na-skyscraper" /> 
    <generic-ad identifier="googlePublisherTag-mainpage-skyscraper-left"></generic-ad> 
    <generic-ad identifier="googlePublisherTag-mainpage-skyscraper-right"></generic-ad>
  <generic-ad identifier="googlePublisherTag-mainpage-billboard"></generic-ad> -->


{{/unless}}

<netzkino-title-social-bar cat="{{#mainCategoryTitle}}{{mainCategoryTitle}}{{/mainCategoryTitle}}{{cat.title}}" show-text="false"></netzkino-title-social-bar>

<div class="main-content main-background">
  <div class="container {{#hasSubcategories}}category-container-large{{/hasSubcategories}}">
    {{#hasSubcategories}}
      {{#displayBanner}} {{!set for netzkino only}}
        {{^state.currentUser.isPlus}}
          <generic-ad identifier="coverAdRow" />
        {{/state.currentUser.isPlus}}
      {{/displayBanner}}
      {{#each subcategories}}

        {{^isMainSliderCategory}}

          <netzkino-category-slider cat="{.}" id="{{id}}" {{#isLarge}}type="large"{{/isLarge}}/></netzkino-category-slider>
          {{#showPlusGenre}}
            {{#plusGenreCategory}}
              <netzkino-category-slider cat="{.}" id="{{id}}" {{#isLarge}}type="large"{{/isLarge}}/></netzkino-category-slider>
            {{/plusGenreCategory}}
          {{/showPlusGenre}}
          {{#showPlusHighlightGenre}}
            {{#plusGenreHighlightCategory}}
              <netzkino-category-slider cat="{.}" id="{{id}}" {{#isLarge}}type="large"{{/isLarge}}/></netzkino-category-slider>
            {{/plusGenreHighlightCategory}}
          {{/showPlusHighlightGenre}}
        {{/isMainSliderCategory}}
        {{#displayBanner}} {{!set for netzkino only}}
          {{#shouldDisplayAd}}
            {{^state.currentUser.isPlus}}
                <generic-ad blacklist='{{adBlacklist}}' identifier="na-superbanner" class="container category-slider-banner-container" />
            {{/state.currentUser.isPlus}}
          {{/shouldDisplayAd}}
        {{/displayBanner}}

        {{#isEqual @index 2}}
            {{#unless state.currentUser.isPlus}}
                <!--<generic-ad identifier="parallax" style="margin-top: 30px; display: block;"></generic-ad>-->
            {{/unless}}

        {{/isEqual}}

        {{#isEqual 2 @index}}
          {{#if isKixi}}
            {{#unless state.currentUser.isPlus}}
              <a href="#!/my/payment" style="display: block; margin: 20px auto; text-align: center">
                <img src="{{config.ads.frontpagebanner.src}}" class="img-responsive" />
              </a>
            {{/unless}}
          {{/if}}
        {{/isEqual}}
      {{/each}}
      <span {{lazyImage}}/>
    {{else}}
      <div class="row catRow">
        <div class="coverflow col-lg-12 col-md-12 col-sm-12 col-xs-12 singleKino" style="margin-top:7px;">
          {{#each catpostsads}}
            {{#if isBig}}
                <movie-cover cover-size="img_all_small" movie="{.}" href="{{#if isNetzkino}}{{_internalMovieLink}}{{else}}#!/{{cat.slug}}/{{slug}}{{/if}}" image-src="{{#getWithFallback . 'custom_fields.featured_img_all_small.0' 'custom_fields.featured_img_all.0' 'custom_fields.Featured-Img-Small.0' 'custom_fields.Featured-Img.0' 'custom_fields.Artikelbild.0' 'thumbnail' 'custom_fields.Cover.0'}}{{.}}{{/getWithFallback}}" classes="lazy catPageCover big-category-all-cover"/>

            {{else}}
                <movie-cover cover-size="artikelbild" movie="{.}" href="{{#if isNetzkino}}{{_internalMovieLink}}{{else}}#!/{{cat.slug}}/{{slug}}{{/if}}" image-src="{{#getWithFallback . 'custom_fields.Artikelbild.0' 'thumbnail' 'custom_fields.Cover.0'}}{{.}}{{/getWithFallback}}" classes="wideCover catPageCover"/>
            {{/if}}
          {{/each}}
          {{!instead of calling this once per item, just call it once per this list}}
          <span {{lazyImage}}/>
        </div>
      </div>
      {{#unless state.currentUser.isPlus}}
        <generic-ad blacklist='{{adBlacklist}}' identifier="na-superbanner-only" />
      {{/unless}}
    {{/if}}

    {{#if loading}}
      <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
      </div>
    {{/if}}
  </div>
  <br/>
</div>
<generic-ad identifier="interstitialAd"></generic-ad>
