import can from "can";
import config from "../../config";
import ContentTracker from "../../modules/contentTracker";
import watchlist from "../../modules/watchlist";
import view from "./view.stache";
can.Component.extend({
    tag: "audio-player",
    template: view,
    scope: {
        model: null,
        partNumber: 1,
        autoplay: false,
        onEnded: function name(ev) {
            // nothing to do by default
        },

        // computed
        source: can.compute(function (params) {
            var partNumber = this.attr("partNumber");
            return this.attr("model").getSourceForPartNumber(partNumber);
        }),
        sourceUrl: can.compute(function () {
            var src = this.attr("source");
            var template = config.moviepage.audioSourceTemplate();
            return template.replace(/\{\}/, src);
        }),
        part: can.compute(function (params) {
            var partNumber = this.attr("partNumber");
            return this.attr("model").getPartForPartNumber(partNumber);
        }),
        shouldAutoplay: can.compute(function () {
            var autoplay = this.attr("autoplay");
            return autoplay !== false && autoplay !== "false"; // just adding the autoplay attribute will be an empty string
        }),
    },
    events: {
        inserted: function insertedEvent($el) {
            this.$player = $el.children("audio:first");
            this.player = this.$player[0];
            var currentPlayer = this.player;

            // cleanup old players, but workaround, just by doing
            var otherPlayers = $("audio").filter(function () {
                return this !== currentPlayer;
            });
            otherPlayers.each(function () {
                this.pause();
            });
            otherPlayers.remove();
            this.$source = this.$player.children("source:first");
            this.source = this.$source[0];
            var scope = this.scope;

            this.tracker = new ContentTracker(
                this.scope.attr("model"),
                scope.attr("part")
            );

            if (scope.attr("shouldAutoplay")) {
                this.tracker.contentDisplayed(); // only shown for the content, not every part TODO: verify
            }

            this.skipToSavedPosition = function () {
                var skipped = false;
                this.$player.on(
                    "loadedmetadata",
                    function () {
                        var scope = this.scope;
                        var timeToSkipTo = watchlist.getTimeForAudioSync(
                            scope.attr("model"),
                            scope.attr("partNumber")
                        );
                        if (
                            timeToSkipTo &&
                            timeToSkipTo < this.player.duration
                        ) {
                            this.player.currentTime = timeToSkipTo;
                            skipped = true;
                        }
                    }.bind(this)
                );
            }.bind(this);
            this.skipToSavedPosition();

            this.$player.on(
                "ended",
                function () {
                    this.tracker.playbackEnded();
                    this.element.trigger("ended");
                }.bind(this)
            );

            this.$player.on(
                "pause",
                function () {
                    this.element.trigger("pause");
                    this.tracker.playbackStopped();
                }.bind(this)
            );

            this.$player.on(
                "timeupdate",
                function (ev) {
                    var currentTime = ev.target.currentTime;
                    this.element.trigger("timeupdate", currentTime);
                    this.tracker.playbackTimeupdate(currentTime);
                }.bind(this)
            );

            this.$player.on(
                "play",
                function () {
                    this.element.trigger("play");
                    this.tracker.playbackStarted();
                }.bind(this)
            );
        },
        "{scope} model": function () {
            if (this.tracker) {
                this.tracker.destroy();
            }
            this.tracker = new ContentTracker(
                this.scope.attr("model"),
                this.scope.attr("part")
            );
            this.tracker.contentDisplayed(); // only shown for the content, not every part TODO: verify
        },
        "{scope} part": function () {
            if (this.tracker) {
                this.tracker.destroy();
            }
            this.tracker = new ContentTracker(
                this.scope.attr("model"),
                this.scope.attr("part")
            );
        },
        "{scope} sourceUrl": function (scope) {
            // update the player source if the URL changes
            this.source.src = scope.attr("sourceUrl");
            this.player.load();
            this.skipToSavedPosition();
            // if (scope.attr('shouldAutoplay')){
            this.player.play(); // always play, because the user chose the source
            // }
        },
        removed: function () {
            this.tracker.destroy();
            try {
                this.player.pause();
            } catch (e) {
                console.error(
                    "exception while stopping playback on removed",
                    e
                );
            }
        },
    },
    helpers: {},
});
