import can from "can";

var State = can.Map.extend({
    modules: {},
    defs: {
        initialView: new can.Deferred(),
        indexLoader: new can.Deferred(),
    },
    data: {
        categoriesById: {},
        categoriesBySlug: {},
        moviesBySlug: {},
        moviesById: {},
        // kixi has series, see modelFactory
        seriesById: {},
    },
    goTo: function (controller, subpage) {
        var route = {
            control: controller,
            id: subpage,
        };
        can.route.attr(route);
    },
    goToWithParams: function (controller, params) {
        var routeWithParams = Object.assign({ control: controller }, params);
        can.route.attr(routeWithParams);
    },
    currentUserPromise: new can.Deferred(),
    currentWatchlistByMovieId: new can.Map(),
    isHospital: false,
    hideHeader: false
});
export default new State();
