import swal from "sweetalert";
import BaseControl from "../../../lib/can.basecontrol.js";
import categoryRenderingPerformanceHACK from "../../../lib/categoryRenderingPerformanceHACK";
import Cookie from "../../../lib/cookie-handler";
import googlePublisherTag from "../../../lib/googlePublisherTag";
import utils from "../../../lib/utils";
import config from "../../config";
import state from "../../state";
import View from "./view.stache";

export default BaseControl.extend(
    "MainPage",
    {
        defaults: {
            template: View,
        },
    },
    {
        init: function (options) {
            this.options.viewOptions.attr(
                "index",
                this.options.state.data.index
            );
            this.options.viewOptions.attr("state", this.options.state);
            this.options.viewOptions.attr("config", config);

            var isMobile = utils.isMobile();
            this.options.viewOptions.attr("isMobile", isMobile);

            this.options.viewOptions.attr("showTitleBar", true);

            // TODO: should those ads be removed?
            if (window.platform === "netzkino") {
                this.options.viewOptions.attr("displayBanner", false);
            } else {
                this.options.viewOptions.attr("displayBanner", false);
            }

            this.options.viewOptions.attr("useBackgroundSlider", false);

            if (window.platform === "loveisawonder") {
                this.options.viewOptions.attr({
                    showTitleBar: false,
                    useBackgroundSlider: true,
                    useLargeSlider: true,
                    sliderTypeOverride: "sliderDoubleRow",
                });
            } else if (window.platform === "kixi") {
                this.options.viewOptions.attr({
                    isKixi: true,
                    landingPreview: config.plus.getLandingPagePreviewURLs(),
                });
            } else if (window.platform === "netzkino") {
                this.options.viewOptions.attr({
                    isNetzkino: true,
                });
            }

            var $indexLoaderDef = this.options.state.defs.indexLoader;
            $indexLoaderDef.always(
                function (index) {
                    this.options.viewOptions.attr(
                        "featuredSlider",
                        index.featured_homepage_category
                    );
                    categoryRenderingPerformanceHACK.asyncCategoryRendering(
                        this.options.viewOptions,
                        "categories",
                        index.homepage_categories
                    ); // writes to viewOptions!
                }.bind(this)
            );

            // check if this is the first time a user navigates to mainpage after subscribing...
            // ... this navigation takes place in plus-payment component on my page where the user is redirected to by the payment-service
            var firstPlus = Cookie.readCookie("firstPlus");
            if (firstPlus) {
                // 1: initial payment, 2: payment data changed
                Cookie.clearCookie("firstPlus");
                var notificationText =
                    firstPlus === "2"
                        ? config.plus.paymentDataChangedMessage
                        : config.plus.welcomeMessage;
                swal(notificationText);
            }

            state.currentUserPromise.then(
                function () {
                    var smartlogin = Cookie.readCookie("goToSmartlogin");
                    if (smartlogin) {
                        Cookie.clearCookie("goToSmartlogin");
                        state.goTo("smartlogin");
                    }

                    var cancelSubscription = Cookie.readCookie(
                        "openCancelSubscription"
                    );
                    if (cancelSubscription) {
                        state.goTo("konto");
                    }
                },
                function () {
                    Cookie.clearCookie("openCancelSubscription");
                    Cookie.clearCookie("goToSmartlogin");
                }
            );

            //netletixRefresh.refresh(); // this is responsible for loading the skyscraper after control-initialization (category banners are taken care of in performanceHACK)
            // Initialize Billboard-Ads provided via Google Publisher Tags
            if (document.readyState == "complete") {
                setTimeout(function () {
                    googlePublisherTag.loadAndRefreshBillboards();
                }, 200);
            } else {
                document.onreadystatechange = function () {
                    if (document.readyState === "complete") {
                        setTimeout(function () {
                            googlePublisherTag.loadAndRefreshBillboards();
                        }, 200);
                    }
                };
            }

            return this._super.apply(this, arguments);
        },
        destroy: function () {
            // Destroy Billboard-Ads provided when leaving the page
            googlePublisherTag.destroyBillboards();
            this._super.apply(this, arguments);
        },
        ".kixiPromoVideoBanner click": function () {
            window.location.href = "#!/my/payment";
        },
    }
);
