import can from "can";
import utils from "../../../lib/utils";
import config from "../../config";
import Movie from "../../models/movie";
import googleanalytics from "../../modules/googleanalytics";
import state from "../../state";
import lazyrefresh from "../img-lazy";
import View from "./view.stache";
//can.view.preload('View', View);
var coverAdRowFailsLeft = 0;

export default can.Component.extend({
    tag: "generic-ad",
    template: View,
    scope: {
        identifier: "@",
        containerClass: "@", // currently only used for inread
        selfAd: "@",
        blacklist: "@",
        provider: "@",
        smartAdPageId: "@",
        posts: [],
        removed: false,
        preventRender: false,
        allowedViaConfig: can.compute(function () {
            var allowed = config.ads.isAllowed(
                this.attr("identifier"),
                this.attr("provider")
            );
            return allowed;
        }),
    },
    events: {
        init: function (el, options) {
            var scope = this.scope;
            scope.attr("state", state);

            var ad, ads;
            var isMobile = utils.isMobile();
            scope.attr("isMobile", isMobile);

            var provider = scope.attr("provider"),
                identifier = scope.attr("identifier");

            var coverAdRowFakeCat = [];
            if (identifier === "na-superbanner") {
                ads = config.ads.selfAdBanner.small;
            } else if (identifier === "na-content") {
                ads = config.ads.selfAdBanner.rectangle;
            } else if (identifier === "interstitialAd") {
                scope.attr(
                    "shouldDisplayInterstitial",
                    window.platform == "netzkino" &&
                        isMobile &&
                        utils.getPageCounter() % 5 == 0
                );
            } else if (identifier === "coverAdRow") {
                // initialize static list of cover generic-ads for smartAd-provider
                // this *should*/*could* be one pageId with 1 formatId and multiple placement
                // configuration smartAd-wise is unclear about how to handle this use case the best way
                // (delivering multiple static ads on one page in one format)
                coverAdRowFakeCat.push(
                    {
                        pageId: 998396,
                        identifier: "artikelbild-cover-1",
                    },
                    {
                        pageId: 998397,
                        identifier: "artikelbild-cover-2",
                    },
                    {
                        pageId: 998398,
                        identifier: "artikelbild-cover-3",
                    },
                    {
                        pageId: 998399,
                        identifier: "artikelbild-cover-4",
                    },
                    {
                        pageId: 998400,
                        identifier: "artikelbild-cover-5",
                    },
                    {
                        pageId: 998401,
                        identifier: "artikelbild-cover-6",
                    },
                    {
                        pageId: 1010778,
                        identifier: "artikelbild-cover-7",
                    },
                    {
                        pageId: 1010779,
                        identifier: "artikelbild-cover-8",
                    }
                );
                coverAdRowFailsLeft = coverAdRowFakeCat.length; // reset

                scope.attr("coverAdRowFakeCat", {
                    adOnly: true,
                    slug: "cover-ad-slider", // serves as id of slider
                    posts: Movie.models(coverAdRowFakeCat),
                });
                var clear = function () {
                    clearInterval(scope._checkSmartAdIframeInterval);
                };
                // writing directly does not trigger a rerender
                scope._checkSmartAdIframeInterval = setInterval(function () {
                    if (scope.attr("removed") || scope.attr("preventRender")) {
                        return clear();
                    }
                    var $el = $(el);
                    if ($el.find("iframe:first").length) {
                        // SmartAd does not provide a callback if an ad is loaded!
                        $el.find(".cover-ad-row-show-on-delivery:first").show();
                        clear();
                    }
                }, 300);
            } else if (identifier === "parallax") {
                scope.attr({
                    supportsParallax: !utils.isIOS() && !utils.isAndroid(),
                    trackingPixels: config.ads.parallax.trackingPixels.map(
                        function (src) {
                            return can.sub(src, {
                                timestamp:
                                    Date.now() +
                                    Math.floor(Math.random() * 1000),
                            });
                        }
                    ),
                });
            }

            var smartAdNoAdCallback;
            if (provider === "smartAd") {
                var smartAdFormatId =
                    config.ads.smartAd.formatIdsByDisplayPosition[identifier];

                if (!smartAdFormatId) {
                    config.log("no formatId: This ad can not be displayed.");
                    return;
                }
                scope.attr("smartAdFormatId", smartAdFormatId);

                window.sas.cmd.push(function () {
                    can.defer(function () {
                        // needs to be defered as it would override document if element is not found on back navigation
                        if (scope.attr("removed")) {
                            console.log(
                                "skipping smartad initialization of ad that is no longer rendered!",
                                smartAdFormatId
                            );
                        } else {
                            // this calls document.write if a specific div is no longer in the DOM (unmounted/removed component)
                            // document.write would delete the whole page, leaving a blank screen
                            window.sas.render(smartAdFormatId);
                        }
                    });
                });
                if (identifier === "slider-tile-big-genre") {
                    // specific callback function in case no ad is served - works only for 'neu' slider ad position
                    smartAdNoAdCallback = function () {
                        var firstItem = scope.attr("posts")[0];
                        if (firstItem.attr("genericAd")) {
                            can.defer(function () {
                                if (scope.attr("removed")) return; // nothing to do
                                scope.attr("posts").splice(0, 1); // remove first post
                                var $slider = can.$("#neu.slider");
                                $slider.lemmonSlider("reinit");
                                lazyrefresh($slider, {
                                    failure_limit: Infinity, // make sure the second row gets refreshed
                                });
                                config.log(
                                    "Generic Ad Tile not loaded (Probably Ad-Blocker) - reload 'Neu bei Netzkino'-category without Ad Tile"
                                );
                            });
                        }
                    };
                } else if (identifier.includes("artikelbild-cover-")) {
                    // the first generic-ad of type 'artikelbild-cover-*' with successful impression will show the slider title "Werbung: Netflix Highlights"
                    // if none of the cover-ads delivers, no space will be empty on page
                    // smartAdAdCallback = function () {
                    //     can.defer(function () {
                    //         if(scope.attr('removed')) return; // nothing to do
                    //         // this code is called from a single cover image. Notify the slider that it should show the title
                    //         el.trigger('coverAdRow-ads');
                    //     });
                    // };

                    smartAdNoAdCallback = function () {
                        if (scope.attr("removed")) return; // nothing to do
                        coverAdRowFailsLeft--;
                        if (coverAdRowFailsLeft === 0) {
                            el.trigger("coverAdRow-noads");
                        }
                        can.defer(function () {
                            if (scope.attr("removed")) return; // nothing to do
                            can.$("#delivery-" + identifier).remove();
                            var $slider = can.$("#coverAdRow .slider");
                            $slider.lemmonSlider("reinit");
                        });
                    };
                }

                // initializes and configures window.sas, also preloads formatId(s)
                // TODO check if *all* formatIds should be preloaded at the beginning?
                initSmartAd(
                    scope.attr("smartAdPageId"),
                    smartAdFormatId,
                    /* smartAdAdCallback, */ function () {
                        if (scope.attr("removed")) return; // nothing to do
                        el.trigger("no-smartad-ad");
                        smartAdNoAdCallback && smartAdNoAdCallback();
                    }
                );
            }

            // check whether adBlock is preventing the smartAd-SDK to be loaded
            // this needs to be done for coverAdRow as well as it is a wrapper around smartAd-Elements and thus needs to execute its own smartAdNoAdCallback
            if (identifier === "coverAdRow" || provider === "smartAd") {
                // if smartAd is not available (== blocked by adblocker), do not try to render the ad at all
                // this does remove unnecessary whitespace and is sufficient as a check, as initialization of
                // smartAd-SDK should take place way before component rendering
                if (window.sas && !window.sas.utils) {
                    scope.attr("preventRender", true);
                    smartAdNoAdCallback && smartAdNoAdCallback();
                    return;
                }
            }

            if (ads) {
                var componentBlacklist = (scope.attr("blacklist") || "").split(
                    ","
                );
                var adsToUse = ads.filter(function (a) {
                    // only allow ads that do not have any filters or are not on the blacklist
                    if (!a.blacklist) {
                        return true;
                    }
                    var filter = can.$.isArray(a.blacklist)
                        ? a.blacklist
                        : [a.blacklist];
                    return filter.some(function (forbidden) {
                        return componentBlacklist.indexOf(forbidden) < 0;
                    });
                });
                ad = adsToUse[Math.floor(Math.random() * adsToUse.length)];
                if (ad.title) {
                    googleanalytics.sendEvent("Ad", ad.title, "Impression", 1);
                }
                scope.attr("selfAd", ad);
            }
        },
        "generic-ad coverAdRow-noads": function () {
            this.scope.attr("preventRender", true);
        },
        removed: function () {
            // removed from DOM, should skip any smartad initialisation
            this.scope.attr("removed", true);
            clearInterval(this.scope._checkSmartAdIframeInterval);
        },
    },
});

function initSmartAd(pageId, formatId, /* adCallback, */ noAdCallback) {
    if (!formatId) {
        return;
    }
    // main initialization with fixed network id occurs in index.html as multiple setup-calls are not
    // allowed and we can not determine if the setup has already occured in a nice way
    if (window.sas && window.sas.cmd) {
        window.sas.cmd.push(function () {
            window.sas.call(
                "onecall",
                {
                    siteId: config.ads.smartAd.siteId,
                    pageId: pageId,
                    formatId: formatId, // list all formats comma separated (grouping of requests by pageId - currently we have one pageId per ad)
                    target: "",
                },
                {
                    // THERE IS NO onAd...
                    onNoad: function (data) {
                        config.log("smartad: no ad callback - ", data);
                        if (data.formatId) {
                            noAdCallback && noAdCallback();
                        }
                    },
                    beforeRender: function (data) {
                        config.log("smartad: beforeRender callback - ", data);
                        // this is NOT a callback for the case that advertisements are visible. There is no event for that...
                        // if (data.formatId) {
                        //     adCallback && adCallback();
                        // }
                    },
                    load: function (data) {
                        config.log("smartad: load callback - ", data);
                    },
                    error: function (data) {
                        config.log("smartad: error callback - ", data);
                    },
                }
            );
        });
    } else {
        noAdCallback && noAdCallback();
    }
}
