/* globals loadVioomyPlayers  */
import can from "can";
import config from "../js/config";
var scriptPromise;

export default {
    init: function () {
        config.log("[vidoomy] init");
        var scr = document.createElement("script"),
            head = document.head || document.getElementsByTagName("head")[0];
        scr.src = "https://ads.vidoomy.com/netzkino-function_5509.js";
        scr.async = true; // optionally
        scr.defer = true; // optionally
        scriptPromise = can.$.Deferred();
        scr.addEventListener("load", function () {
            can.defer(function () {
                config.log("[vidoomy] script loaded");
                scriptPromise.resolve();
                // YES, there is a typo, but this this is seriously the right one... Thanks vi"d"oomy!
                loadVioomyPlayers();
            });
        });
        head.appendChild(scr);
    },
    refresh: function () {
        config.log("[vidoomy] refresh");

        // YES, there is a typo, but this this is seriously the right one... Thanks vi"d"oomy!
        if (loadVioomyPlayers) {
            loadVioomyPlayers();
        }
    },
};
