<div class="kostenloser-promo-film">
    <div id="carousel-example-generic" class="carousel slide" data-ride="carousel" data-interval="false">
      <div id="loading-mainpage-slider">
        <div class="blockG" id="rotateG_01"></div>
        <div class="blockG" id="rotateG_02"></div>
        <div class="blockG" id="rotateG_03"></div>
        <div class="blockG" id="rotateG_04"></div>
        <div class="blockG" id="rotateG_05"></div>
        <div class="blockG" id="rotateG_06"></div>
        <div class="blockG" id="rotateG_07"></div>
        <div class="blockG" id="rotateG_08"></div>
      </div>

      <div class="carousel-inner">
          {{! extraSlider and mobile version of images handled by component init logic }}
          {{#each catposts}}

            {{#isEqual specialType 'SmartAdSlot'}}
            <div class="item">
              <generic-ad smart-ad-page-id="{{pageId}}" provider="smartAd" identifier="{{identifier}}" class="{{#if isMobile}}smartAdFeaturedImage{{{else}}smartAdSliderImage{{/if}}" style="display: block;"></generic-ad>
            </div>
              {{else}}
              <div class="item{{#isEqual @index 0}} active{{/isEqual}}{{#ifAll movie.hasYoutubePreview notIsMobile}} youtube{{/ifAll}}{{#isEqual specialType 'FeaturedSlot'}} featuredSlot{{/isEqual}}"
                  {{#isEqual specialType 'FeaturedSlot'}} data-campaign-name="{{campaignName}}"{{/isEqual}}>
                  {{#isEqual specialType 'FeaturedSlot'}}
                      <div data-campaign-name="{{campaignName}}">
                        {{#hasVideoPreview}}
                          <a href="{{actualSliderLink}}" class="adURL" data-campaign-name="{{campaignName}}" target="_blank" style="display: block">
                              <img src="{{actualSliderImage}}" data-used-src="{{actualSliderImage}}" style="width: 100%;position: relative; z-index: 900;">
                              <div class="videoContainer" style="position: absolute; width: 47%; top: 15%; right: 1%; z-index: 1000; cursor: pointer;">
                                <video class="adVideo active" controls style="opacity: 0; cursor: pointer; pointer-events: none; width: 100%; height: 100%;">
                                  <source src="{{actualSliderVideoLink}}" type="video/mp4">
                                </video>
                              </div>
                          </a>
                        {{/hasVideoPreview}}
                        {{^hasVideoPreview}}
                          <a href="{{actualSliderLink}}" class="adURL" data-campaign-name="{{campaignName}}" target="_blank" style="display: block">
                              <img src="{{actualSliderImage}}" data-used-src="{{actualSliderImage}}" style="width: 100%;position: relative; z-index: 900;">
                              {{#actualSliderScriptSrc}}<script type="application/javascript" src="{{applyGDPR . tcfState}}"></script>{{/actualSliderScriptSrc}}
                              {{#actualSliderIframeSrc}}<iframe type="application/javascript" src="{{applyGDPR . tcfState}}" WIDTH="1" HEIGHT="1" MARGINWIDTH="0" MARGINHEIGHT="0" HSPACE="0" VSPACE="0" FRAMEBORDER="0" SCROLLING="no" BORDERCOLOR="#000000"></script>{{/actualSliderIframeSrc}}
                          </a>
                        {{/hasVideoPreview}}
                        {{#actualSliderTracker}}
                          <IMG SRC="{{.}}" style="position: absolute; z-index: 800; top:0; left:0;" BORDER="0" HEIGHT="1" WIDTH="1">
                        {{/actualSliderTracker}}
                      </div>
                  {{/isEqual}}
                  {{#isNotEqual specialType 'FeaturedSlot'}}
                    {{#ifAll movie.hasYoutubePreview notIsMobile}}
                        <youtube-cover allow-unmute="true" use-single-loop="true" youtube-id="{{movie.custom_fields.Youtube_Delivery_Id.0}}" playback-start="{{movie.custom_fields.Youtube_Delivery_Preview_Start.0}}" playback-end="{{movie.custom_fields.Youtube_Delivery_Preview_End.0}}" class="youtubeCover {{classes}}" href="{{actualSliderLink}}" {{#getWithFallback movie actualSliderImagePath 'custom_fields.featured_img_all.0' 'custom_fields.featured_img_all_small.0'}}placeholder-image="{{.}}"{{/getWithFallback}} />
                    {{/ifAll}}
                    {{^ifAll movie.hasYoutubePreview notIsMobile}}
                        <a href="{{actualSliderLink}}" style="display: block">
                            {{#ifAny useBackgroundImage movie.hasHTML5VideoPreview}}
                                <div class="sliderBackgroundItem" style="background-image:
                                    {{#actualSliderImage}}
                                        url('{{actualSliderImage}}')
                                    {{/actualSliderImage}}
                                    {{#actualSliderImagePath}}
                                        {{#getWithFallback movie actualSliderImagePath 'custom_fields.featured_img_all.0' 'custom_fields.featured_img_all_small.0' 'custom_fields.Featured-Img.0'}}
                                            url('{{.}}')
                                        {{/getWithFallback}}
                                    {{/actualSliderImagePath}}">
                                    {{#ifAll movie.hasHTML5VideoPreview notIsMobile}}
                                        <html5-video-preview video-url="{{movie.getHtml5VideoPreviewUrl}}" />
                                    {{/if}}
                                    <div class="hover-play-button" {{#if movie.hasHTML5VideoPreview}}style="position: absolute; top: 0;"{{/if}}>
                                        <img src="{{imagePath 'playbutton.png'}}" />
                                    </div>
                                </div>
                            {{else}}
                            <img {{#actualSliderImage}}
                                    src="{{actualSliderImage}}"
                                {{/actualSliderImage}}
                                data-used-src="{{actualSliderImagePath}}"
                                {{#actualSliderImagePath}}
                                    {{#getWithFallback movie actualSliderImagePath 'custom_fields.featured_img_all.0' 'custom_fields.featured_img_all_small.0' 'custom_fields.Featured-Img.0'}}
                                        src="{{.}}"
                                    {{/getWithFallback}}
                                {{/actualSliderImagePath}}
                                style="width:100%;">
                            {{/ifAny}}

                            {{#showRegisterButton movie}}

                                {{#unless state.currentUser.isPlus}}
                                    <div class="slider-register">
                                        <a href="#!/my/payment" alt="Jetzt abonnieren" class="register-now highlight-shadow"></a>
                                    </div>
                                {{/unless}}
                            {{/showRegisterButton}}
                        </a>
                    {{/ifAll}}
                  {{/isNotEqual}}
              </div>

            {{/isEqual}}
          {{/each}}
      </div>

      <!-- Controls -->
      <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
        {{^isMobile}}
          <img class="mainSliderControl" src="{{imagePath 'webseite/mainSlider/prev.png'}}">
        {{/isMobile}}
      </a>
      <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
        {{^isMobile}}
          <img class="mainSliderControl" src="{{imagePath 'webseite/mainSlider/next.png'}}">
        {{/isMobile}}
      </a>
    </div>
</div>
