{{^state.currentUser.isPlus}}
{{#allowedViaConfig}}

{{#isEqual identifier 'na-superbanner'}}
<div class="row">
    <div class="col-xs-12">
        <span class="ad-headline">WERBUNG</span>
    </div>
</div>
<div class="row">
    <div class="col-xs-12">
        <div data-nx-container="top" class="na-superbanner"
            style="background-image: url('{{config.ads.selfAdBanner.banner.0.imageURL}}');"></div>
        {{#selfAd}}
        <a href="{{clickURL}}" title="{{title}}" target="_blank" class="category-slider-banner-self"><img
                class="img-responsive" alt="{{title}}" src="{{imageURL}}"></a>
        {{/selfAd}}
    </div>
</div>
{{/isEqual}}

{{#isEqual identifier 'googlePublisherTag'}}
<!-- <div id='div-gpt-ad-1407836009820-0' style="position:fixed; right:calc((100% - 1560px)/2);z-index:100;top:74px;">
    </div> -->
<!--<div id='banner-ad' style="position:fixed; right:calc((100% - 1560px)/2);z-index:100;top:74px; border: solid 1px black;">-->

<!--  <div id='banner-ad' style="position:relative;">
            <div id='banner-ad2' style="position:fixed; width: 780px; height: 280px; right:calc((100% - 1560px)/2); top:74px;"> -->



<!-- <div id="content_wrapper" style="width: 900px; margin: 0 auto; position: relative;"> <div id="banner-ad" style="height: 600px; width: 160px; position: absolute; left: 910px; top: 0px;"></div> </div> -->

</div>
{{/isEqual}}

{{#isEqual identifier 'googlePublisherTag-mainpage-skyscraper-left'}}
<div class="gpt-skyscraper-left" id='gpt-mainpage-skyscraper-left'></div>

{{/isEqual}}

{{#isEqual identifier 'googlePublisherTag-mainpage-skyscraper-right'}}
<!-- 1190px for 20px margin (content width is 1170px)-->
<div class="gpt-skyscraper-right" id='gpt-mainpage-skyscraper-right'></div>
{{/isEqual}}

{{#isEqual identifier 'googlePublisherTag-moviepage-skyscraper-left'}}
<!-- 1190px for 20px margin (content width is 1170px)-->
<div id='gpt-moviepage-skyscraper-left' style="position:fixed; right:calc((100% - 1170px)/2 + 1190px); top:76px;"></div>
{{/isEqual}}

{{#isEqual identifier 'googlePublisherTag-moviepage-skyscraper-right'}}
<!-- 1190px for 20px margin (content width is 1170px)-->
<div id='gpt-moviepage-skyscraper-right' style="position:fixed; left:calc((100% - 1170px)/2 + 1190px); top:76px;"></div>
{{/isEqual}}

{{#isEqual identifier 'googlePublisherTag-moviepage-medium-rectangle'}}
<div id='gpt-moviepage-medium-rectangle' style="text-align: center;"></div>
{{/isEqual}}

{{#isEqual identifier 'googlePublisherTag-mainpage-billboard-4'}}
<div class="gpt-billboard" id='gpt-mainpage-billboard-4'>
</div>
{{/isEqual}}

{{#isEqual identifier 'googlePublisherTag-mainpage-billboard-3'}}
<div class="gpt-billboard" id='gpt-mainpage-billboard-3'>
</div>
{{/isEqual}}

{{#isEqual identifier 'googlePublisherTag-mainpage-billboard-2'}}
<div class="gpt-billboard" id='gpt-mainpage-billboard-2'>
</div>
{{/isEqual}}

{{#isEqual identifier 'googlePublisherTag-mainpage-billboard-1'}}
<div class="gpt-billboard" id='gpt-mainpage-billboard-1'>
</div>
{{/isEqual}}

{{#isEqual identifier 'googlePublisherTag-mainpage-mobile-billboard'}}
<div id='gpt-mobile-billboard'></div>
{{/isEqual}}

{{#isEqual identifier 'na-superbanner-only'}}
<div class="container na-superbanner-only-wrap">
    <div class="row">
        <div class="col-xs-12">
            <span class="ad-headline">WERBUNG</span>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div data-nx-container="top" class="na-superbanner only"></div>
        </div>
    </div>
</div>
{{/isEqual}}

{{#isEqual identifier 'na-skyscraper'}}
<div class="container">
    <div class="row">
        <div class="col-xs-12 na-skyscraper-wrap" style="margin-right: -187px; width: 160px; float: right;">
            <div class="na-skyscraper">
                <div data-nx-container="right"></div>
            </div>
        </div>
    </div>
</div>
{{/isEqual}}

{{#isEqual identifier 'na-content'}}
<div data-nx-container="content" class="na-content" style="background-image: url('{{selfAd.imageURL}}');"></div>
<span class="ad-headline">WERBUNG</span>
{{/isEqual}}

{{#isEqual identifier 'interstitialAd'}}
{{#if shouldDisplayInterstitial}}
<div data-visx data-ad-unit="905033" data-is-mobile="1" data-page-url=""></div>
<script async="async" src="https://s.visx.net/tag.js"></script>
{{/if}}
{{/isEqual}}

{{#isEqual identifier 'coverAdRow'}}
{{#unless preventRender}}
<netzkino-category-slider cat="{coverAdRowFakeCat}" id="coverAdRow" title="Werbung: Sky Ticket"
    slider-type-override="sliderDefaultVertical" shuffle="false" hide-nav="false" allow-arrows-mobile="true" />
{{/unless}}
{{/isEqual}}

{{#isEqual identifier 'inread'}}
<div class="container {{containerClass}}">
    <!-- <div class="row adLabelRow">
            <div class="col-xs-12">
                <span class="ad-headline">WERBUNG</span>
            </div>
        </div> -->
    <div class="row">
        <div class="col-xs-12">
            <div data-nx-container="inread"></div>
        </div>
    </div>
</div>
{{/isEqual}}

{{#isEqual identifier 'layer'}}
<div data-nx-container="layer"></div>
{{/isEqual}}

{{#isEqual identifier 'teads'}}
{{#if isMobile}}
<script type="text/javascript" class="teads" async="true" src="//a.teads.tv/page/88764/tag"></script>
{{/if}}
{{/isEqual}}

{{#isEqual identifier 'vidoomy'}}
<!-- loaded via code, see vidoomy.js -->
{{/isEqual}}
{{#isEqual identifier 'vidoomy-2'}}
<div id="vidoomy"></div>
{{/isEqual}}
{{#isEqual platform 'netzkino'}}
{{#isEqual identifier 'parallax'}}
<!--<div class="parollerBackgroundImage" data-paroller-factor="-0.3" data-paroller-type="background" data-paroller-direction="vertical" style="background: url('https://creatives.sascdn.com/diff/2957/8632834/TVN_HQ_640x960_01.jpg') no-repeat center center; background-size: cover;">
        </div>-->
<div style="font-weight: bold;margin: auto; color: white;" class="text-center">Werbung</div>
<a target="_blank" href="{{config.ads.parallax.clickURL}}" style="display: block;">
    {{#if supportsParallax}}
    <div
        style="height: 400px; background: url('{{config.ads.parallax.imageURL}}') center center no-repeat; background-attachment:fixed;">
    </div>
    {{else}}
    <img class="img-responsive" src="{{config.ads.parallax.imageURL}}"
        style="max-width: 90%; object-fit: contain;margin: auto;" />
    {{/if}}
    {{#each trackingPixels}}
    <img src="{{.}}" border="0" height="1" width="1" alt="Advertisement">
    {{/each}}
</a>

{{/isEqual}}
{{/isEqual}}

{{! Smart-Ad below - ads need provider to be set. FormatID mapping from config.}}

{{#isEqual provider 'smartAd'}}
{{#unless preventRender}}
<div id="sas_{{smartAdFormatId}}" class="generic-{{identifier}}"></div>
{{/unless}}
{{/isEqual}}

{{/allowedViaConfig}}
{{/state.currentUser.isPlus}}
