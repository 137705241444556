import BaseControl from "../../../lib/can.basecontrol.js";
import View from "./view.stache";

export default BaseControl.extend(
    "LoadingPage",
    {
        defaults: {
            template: View,
        },
    },
    {
        init: function (el, options) {
            return this._super.apply(this, arguments);
        },
    }
);
