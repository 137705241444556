import can from "can";
import swal from "sweetalert";
import BaseControl from "../../../lib/can.basecontrol.js";
import Cookie from "../../../lib/cookie-handler";
import netletixRefresh from "../../../lib/netletix-refresh";
import utils from "../../../lib/utils";
import config from "../../config";
import Category from "../../models/category";
import Movie from "../../models/movie";
import User from "../../models/user";
import watchlistAPI from "../../modules/watchlist";
import View from "./view.stache";

export default BaseControl.extend(
    "My",
    {
        defaults: {
            template: View,
        },
    },
    {
        init: function (el, options) {
            var self = this,
                state = this.options.state;
            var routeId = can.route.attr("id");

            var paymentDataPending =
                utils.getParameterByName(
                    "isPaymentDataChange",
                    window.location.href
                ) === "true"; // wirecard callback contains this
            var changePaymentData =
                routeId === "changePaymentData" || paymentDataPending;

            this.options.viewOptions.attr({
                state: state,
                config: config,
                wishlistCategory: null,
                watchlistCategory: null,
                register: ["register", "payment"].indexOf(routeId) >= 0,
                registrationSelection:
                    routeId === "payment" ||
                    config.plus.paywall.enabled ||
                    window.platform === "kixi"
                        ? "plus"
                        : null,
                directlyOfferPayment:
                    routeId === "plus" || changePaymentData ? true : false,
                changePaymentData: changePaymentData,
                hidePayment: false,
                useImageBackdrop: window.platform === "loveisawonder",
                showSocialBar: true,
                isLoggedIn: "pending",
                redirectToSmartlogin: can.route.current({
                    control: "my",
                    redirectToSmartlogin: true,
                }),
            });

            config.log("login - check status of login");
            state.currentUserPromise = User.getCurrentUser(true);
            state.currentUserPromise.then(
                function (user) {
                    config.log("login - loggedIn");
                    if (config.my.accountPageOnly) {
                        var status = utils.getParameterByName(
                            "status",
                            window.location.href
                        );

                        // payment status checks are implemented in the plus-payment component, we need to pass it down
                        var skipRedirect =
                            status === "cancel" ||
                            status === "fail" ||
                            status === "pending";

                        if (skipRedirect) {
                            self.options.viewOptions.attr(
                                "paymentStateToStartFrom",
                                "pending"
                            ); // loading animation
                        }
                        if (
                            !skipRedirect &&
                            !user.isPlus &&
                            config.my.accountPageOnly &&
                            !self.options.viewOptions.attr(
                                "directlyOfferPayment"
                            )
                        ) {
                            state.goTo("konto");
                            return;
                        } else if (!skipRedirect && user.isPlus) {
                            state.goTo("konto"); // no my page for logged in users
                            return;
                        }
                    }

                    var recommendationSlug = user.isPlus
                        ? config.plus.recommendationCategory
                        : config.my.recommendationCategory;
                    self.options.viewOptions.attr(
                        "recommendationCategory",
                        state.data.categoriesBySlug[recommendationSlug]
                    );

                    self.options.viewOptions.attr("isLoggedIn", "true");
                    User.getWishlist().then(function (wishlist) {
                        self.requestMissingMoviesAndReturnMovieList(
                            wishlist
                        ).then(function (movieList) {
                            config.log(
                                "Loaded wishlist with #movies",
                                movieList.length
                            );
                            var wishlistCategory = {
                                sliderType: config.my.wishlist.sliderType,
                                title: config.my.wishlist.name, // yep, the wishlist is meant to be displayed as the "Watchlist" in the frontend
                                slug: "search",
                                posts: movieList,
                            };
                            setTimeout(function () {
                                //FF seems to need this
                                can.batch.start();
                                self.options.viewOptions.attr(
                                    "wishlistCategory",
                                    Category.model(wishlistCategory)
                                );
                                can.batch.stop();
                            }, 1);
                        });
                    });
                    watchlistAPI.load().then(function (watchlist) {
                        var list = [];
                        var dataById = {};
                        watchlist.forEach(function (listItem) {
                            var movieId = listItem.movieId;
                            list.push(listItem.movieId);
                            dataById[movieId] = listItem;
                        });
                        self.requestMissingMoviesAndReturnMovieList(list).then(
                            function (movieList) {
                                config.log(
                                    "Loaded watchlist with #movies",
                                    movieList.length
                                );
                                var watchlistCategory = {
                                    sliderType: "sliderDefaultVertical",
                                    title: "Weiterschauen",
                                    slug: "search",
                                    posts: movieList,
                                };
                                setTimeout(function () {
                                    //FF seems to need this
                                    can.batch.start();
                                    self.options.viewOptions.attr({
                                        watchlistCategory: Category.model(
                                            watchlistCategory
                                        ),
                                        // watchlistLinkGenerator: function () {
                                        //     return function (movie) {
                                        //         var _link = movie.attr('_simpleLink');
                                        //         var watchlistData = dataById[movie.id];
                                        //         if(watchlistData && watchlistData.part) {
                                        //             _link += '/' + watchlistData.part;
                                        //         }
                                        //         return _link;
                                        //     };
                                        // }
                                    });
                                    can.batch.stop();
                                }, 1);
                            }
                        );
                    });
                },
                function () {
                    config.log("login - not loggedIn");
                    self.options.viewOptions.attr("isLoggedIn", "false");
                }
            );

            // only offer payment after first registration
            if (
                Cookie.readCookie("registeredPlus") === "1" ||
                Cookie.readCookie("directPaymentOffer") === "1"
            ) {
                self.options.viewOptions.attr("directlyOfferPayment", true);

                Cookie.clearCookie("directPaymentOffer");
                Cookie.clearCookie("registeredPlus");
            }

            if (can.route.attr("id") === "loginerror") {
                swal(
                    "Die Anmeldeinformationen waren nicht korrekt. Bitte überprüfe deine Emailadresse und dein Passwort und probiere es erneut."
                );
            }
            if (can.route.attr("id") === "registrationerror") {
                swal(
                    "Die Registrierung war nicht erfolgreich. Eventuell ist die Emailadresse bereits vorhanden. Bitte überprüfe deine Informationen und probiere es erneut."
                );
            }

            netletixRefresh.refresh();

            return this._super.apply(this, arguments);
        },
        "#logout click": function ($el, ev) {
            if (ev && ev.preventDefault) {
                ev.preventDefault();
            }
            User.logout();
        },
        "my-user-login switchLoginComponent": function () {
            config.log("switched to register");
            this.options.viewOptions.attr("register", true);
        },
        "my-user-register switchLoginComponent": function () {
            config.log("switched to login");
            this.options.viewOptions.attr("register", false);
        },
        requestMissingMoviesAndReturnMovieList: function (movieList) {
            var movieIdsToRequest = [],
                moviesById = this.options.state.data.moviesById,
                returnMovies = [];

            var movieSort = {};
            movieList.forEach(function (movieId, index) {
                movieSort[movieId] = index;
                if (!moviesById[movieId]) {
                    movieIdsToRequest.push(movieId);
                } else {
                    returnMovies.push(moviesById[movieId]);
                }
            });

            function sort(list) {
                return list.sort(function (a, b) {
                    return movieSort[b.id] - movieSort[a.id];
                });
            }
            if (movieIdsToRequest.length !== 0) {
                return Movie.findMultipleMoviesByIds(movieIdsToRequest).then(
                    function (movies) {
                        movies.forEach(function (movie) {
                            if (movie) {
                                // may be undefined if the film is not distributed anymore
                                moviesById[movie.id] = movie; // put in cache
                            }
                        });
                        // sort movies as they were sorted in initial movieList
                        var combinedById = {},
                            combinedReturn = [];

                        movies
                            .concat(Movie.models(returnMovies))
                            .forEach(function (movie) {
                                if (movie) {
                                    combinedById[movie.id] = movie;
                                }
                            });
                        movieList.forEach(function (movieId) {
                            if (combinedById[movieId]) {
                                // may be undefined because movie might not be available anymore
                                combinedReturn.push(combinedById[movieId]);
                            }
                        });
                        return sort(combinedReturn);
                    }
                );
            } else {
                return new can.Deferred().resolve(sort(returnMovies));
            }
        },
        "plus-payment startPayment": function () {
            config.log(
                "starting payment in plus-payment component, going to hide other stuff"
            );
            this.options.viewOptions.attr("hidePayment", true);
            can.$("netzkino-category-slider, .payment-hide").hide();
            this.options.viewOptions.attr("showSocialBar", false);
        },
    }
);
