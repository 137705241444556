import can from "can";
import utils from "../../lib/utils";
/**
 * data of a series:
 * id: string
 * slug: string
 * title: string
 * description: Optional<string>
 * post_count: number -> number of movies within this series
 * part: number -> first a string, but converted! Up to three digits, first digit is the season, other two declare episode
 *  if there are less than 3 digits, we assume season 1
 */

var Season = can.Map.extend({
    init: function () {
        this.attr({
            postByEpisode: new can.Map(),
        });
    },
    type: "season",

    partList: can.compute(function () {
        var posts = this.attr("posts");

        return can.$.map(posts, function (post) {
            // posts is a can.Construct, not sure why not for filter
            return {
                title: post.attr("title"),
                duration: post.attr("custom_fields.Duration.0"),
                description: post.attr("content"),
                number: post.attr("episodeInSeason"),
                identifier: post.attr("normalizedPart"),
                source: post, // not fully qualified
            };
        });
    }),
    addPost: function (seriesContent) {
        // we just put it at the array index it belongs, sorts automatically!
        var episodeIndex = seriesContent.attr("episodeInSeason") - 1;
        this.attr("postByEpisode." + episodeIndex, seriesContent); // parts are beginning with 1!
    },
    posts: can.compute(function () {
        var list = [];

        this.attr("postByEpisode").each(function (value, key) {
            list[parseInt(key)] = value;
        });
        return list
            .filter(function (p) {
                return !!p;
            }) // sometimes episode might be missing, e.g. Trotro 209
            .sort(function (a, b) {
                return a.attr("episodeInSeason") - b.attr("episodeInSeason");
            });
    }),
    firstEpisode: can.compute(function () {
        return this.attr("posts")[0];
    }),
});

// Series are not entities delivered by the API instead Seasons are defined by posts that declare a series id/slug they belong to
var Series = can.Model.extend(
    "Series",
    {
        model: function (data) {
            // TODO: only copy whats needed
            if (!data.id) {
                console.error("cannot determine id of series", data);
                return;
            }
            if (data.title) {
                data.title = data.title.replace("&amp;", "&");
            }
            var instance = can.Model.model.call(this, data);
            instance.attr("posts", new can.List());
            instance.attr("seasons", new can.List());
            return instance;
        },
    },
    {
        init: function (data) {
            this.attr("title", data.title && data.title.replace("&amp;", "&"));
            return this;
        },
        type: "series",

        addPost: function (seriesContent) {
            can.batch.start();
            // TODO: add season
            // add movie to season
            this.attr("posts").push(seriesContent);
            var seasonNumber = seriesContent.attr("inSeason");
            var seasonIndex = seasonNumber - 1;
            var season = this.attr("seasons." + seasonIndex);
            if (!season) {
                season = new Season({
                    series: this,
                    number: seasonNumber,
                }); // we just ignore the part info in there
                this.attr("seasons." + seasonIndex, season); // parts are beginning with 1!
            }

            season.addPost(seriesContent);
            seriesContent.setSeriesModel(this);
            can.batch.stop();
        },
        getPartByNumber: function (part) {
            var normalizedPart = utils.normalizePart(part);
            var seasonNumber = (normalizedPart - (normalizedPart % 100)) / 100;
            var seasonIndex = seasonNumber - 1;
            var season = this.attr("seasons." + seasonIndex);
            if (!season) {
                return;
            }
            return season.attr("partList").filter(function (part) {
                return part.identifier === normalizedPart;
            })[0];
        },
        firstEpisode: can.compute(function () {
            return this.attr("seasons.0.firstEpisode");
        }),
        allParts: can.compute(function () {
            var list = [];
            this.attr("seasons").forEach(function (season) {
                season.attr("partList").forEach(function (part) {
                    // already sorted
                    list.push(part);
                });
            });
            return new can.List(list);
        }),
    }
);

export default Series;
