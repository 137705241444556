<div class="login-container">
    {{#if error}}
        <div class="alert alert-danger">
            Das hat leider nicht geklappt. Probiere es bitte erneut.
        </div>
    {{/if}}

    <h3 class="login-headline">LOGIN</h3>

    <form class="form-horizontal" method="POST" action="{{config.my.localLoginUrl}}">
        <div class="form-group">
            <div class="col-xs-12">
                <label for="loginEmail" class="login-label">Emailadresse</label>
                <input type="text" class="form-control input-big login-input" id="loginEmail" name="username" placeholder="E-Mail" required>
            </div>
        </div>
        <div class="form-group">
            <div class="col-xs-12">
                <label for="loginPassword" class="login-label">Passwort</label>
                <input type="password" class="form-control input-big login-input" id="loginPassword" name="password" placeholder="Passwort" required>
            </div>
        </div>
        <div class="form-group">
            <div class="col-xs-12">
                <a href="{{config.my.forgotPasswordUrl}}" class="login-link" target="_blank">Passwort vergessen?</a>
            </div>
        </div>
        <div class="form-group">
            <div class="col-xs-12">
                <button type="submit" class="btn btn-primary btn-big login-btn">Einloggen</button>
            </div>
        </div>

        {{#if config.my.loginProviders.length}}
        <div class="form-group">
            <div class="col-xs-12">
                <span class="login-span">oder</a>
            </div>
        </div>
        {{/if}}

        <my-user-login-social/>

        <div class="form-group">
            <div class="col-xs-12">
                <span class="login-span bold">Mit dem Login stimmst du den <a class="login-link bold" href="#!/agb">AGB</a> und <a class="login-link bold" href="#!/datenschutz">Datenschutzbestimmungen</a> zu.</span>
            </div>
        </div>

        <div class="form-group login-register-section">
            <div class="col-xs-12">
                <span class="login-span bold">Du hast noch keinen Account?</span>
                <a href="#!/my/register" id="register" class="login-link bold">{{config.my.registerLabel}}</a>
            </div>
        </div>
    </form>
</div>