import can from "can";
import Cookie from "../../../lib/cookie-handler";
import ogury from "../../../lib/ogury";
import config from "../../config";
import template from "./view.stache";
import state from "../../state"

can.Component.extend({
    tag: "netzkino-footer",
    template: template,
    scope: {
        state: state,
        config: config,
        // TODO: clean up Cookie policy information. The current solution will be replaced by Ogury.
        // As a work-around we disable the custom Cookie Banner by setting this to always false.
        cookieNotAccepted: false, //!document.cookie.match(/(cookieconsent=[^;]+)/),
        editOgury: function () {
            config.log("Ogury CMP Settings-Menu Called");
            ogury.editUserConsent();
        },
    },
    events: {
        ".js-btn-cookie click": function ($el, ev) {
            can.$(".cookie-notification").fadeOut("fast");
            Cookie.createCookie(
                "cookieconsent",
                "true",
                "Thu, 01 Jan 2030 12:00:00 UTC"
            );
            ev.preventDefault();
            ev.stopPropagation();
            return false;
        },
        "#pciCompliance click": function ($el, ev) {
            // only used for netzkino compliance symbol popup open
            window.open(
                $el.attr("href"),
                "",
                "width = 600, height = 615, left = 100, top = 200"
            );
            ev.preventDefault();
            return false;
        },
    },
    helpers: {
        unlessLast: function name(index, max, options) {
            if (index() < max() - 1) {
                return options.fn();
            } else {
                return options.inverse();
            }
        },
    },
});
