import can from "can";
var running = 0,
    total = 0,
    callbacks = [],
    _runCallback = function (cb) {
        cb();
    },
    $el = $(
        '<div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>'
    )
        .appendTo("body")
        .hide(),
    refresh = function (decrease, title, id) {
        var id = id || total + 1;
        if (decrease) {
            running--;
        } else {
            running++;
            total++;
        }

        //console.log('[Loader] #' + id + ' refresh called, running: ' + running + ' total: ' + total);
        if (running > 0) {
            // console.time('[Loader] #' + id + (title?' - '+title:''));
            $el.show();
            callbacks.map(_runCallback);
        } else {
            // console.timeEnd('[Loader] #' + id + (title?' - '+title:''));
            $el.hide();
        }

        return total;
    },
    loader = function (deferred, modal, title, hideAfter) {
        var id = refresh(false, title),
            hide = function () {
                setTimeout(function () {
                    refresh(true, title, id);
                }, hideAfter || 100);

                return can.Deferred().resolve.apply(this, arguments);
            };

        return deferred.then(hide, hide);
    };

loader.onLoad = function (callback) {
    callbacks.push(callback);
};

export default loader;
