import can from "can";
import swal from "sweetalert";
import Cookie from "../../../lib/cookie-handler";
import utils from "../../../lib/utils";
import API from "../../api";
import config from "../../config";
import User from "../../models/user";
import ga from "../../modules/googleanalytics";
import state from "../../state";
import viewHeader from "./view.stache";

var stateSteps = [
    "information",
    "getProducts",
    config.plus.packages.supported ? "chooseType" : null, // referenced by index below
    "choosePackage", // maybe skipped --- referenced by index below
    "choosePayment",
    "submitPaymentMethod",
    "initialized",
    "pending",
    "completed",
].filter(function (e) {
    return !!e;
});

let getTransactionId;

let paypalButtons;
let paypalButtonhasRendered = false;

function isEqualPackage(p, prod) {
    var isSame =
        p.validFor === prod.validFor &&
        p.price === prod.price &&
        p.currencyCode === prod.currencyCode;
    if (isSame && !p.image) {
        p.image = prod.image;
    }
    return isSame;
}

can.Component.extend({
    tag: "plus-payment",
    template: viewHeader,
    scope: {
        directlyOfferPayment: "@", // used for skipping the selection screen
        changePaymentData: "@",
        isPaymentDataChange: can.compute(function () {
            return (
                this.attr("changePaymentData") === "true" ||
                this.attr("changePaymentData") === true
            );
        }),
        selectedType: false,
        isPaypalScriptLoaded: false,
        supportedTypes: can.compute(function () {
            var products = this.attr("paymentOptions");

            var types = [];
            if (products) {
                products.forEach(function (product) {
                    if (types.indexOf(product.type) < 0) {
                        types.push(product.type);
                    }
                });
            }
            return types.map(function (type) {
                return {
                    type: type,
                    image: config.paths.images.get(
                        "webseite/plus/payment/types/" +
                            type.toLowerCase() +
                            ".png"
                    ),
                };
            });
        }),
        paymentOptionsForSelectedType: can.compute(function () {
            var selectedType = this.attr("selectedType");
            var selectedPackage = this.attr("selectedPackage");
            var products = this.attr("paymentOptions");
            if (!config.plus.supportsPackages) {
                return products;
            }
            if (!products || !selectedType) {
                return null;
            }
            var forType = products.filter(function (prod) {
                return prod.type === selectedType;
            });
            if (selectedPackage) {
                return forType.filter(function (t) {
                    return isEqualPackage(t, selectedPackage);
                });
            }
            return forType;
        }),
        packages: can.compute(function () {
            var selectedType = this.attr("selectedType");
            var products = this.attr("paymentOptionsForSelectedType");
            if (!products || String(selectedType).toUpperCase() !== "PACKAGE") {
                return null;
            }
            var withoutDuplicates = [];
            products.forEach(function (prod) {
                var contains = withoutDuplicates.some(function (p) {
                    return isEqualPackage(p, prod);
                });

                if (!contains) {
                    withoutDuplicates.push(prod);
                }
            });
            return withoutDuplicates.sort(function (a, b) {
                return a.validFor - b.validFor;
            });
        }),
        selectedPackage: false,
        stateToStartFrom: "@",
        config: config,
        resetState: function () {
            this.attr("selectedType", false);
            this.attr("selectedPackage", false);
        },
        resetProgress: function () {
            this.resetState();

            this.attr("paymentState", stateSteps[0]);
            utils.forceReloadAtPath("my"); // using a full page reload will skip any payment interactions, like the redirect to PayPal
            return;
        },
        resetToTypeSelection: function () {
            this.resetState();
            this.attr("paymentState", stateSteps[2]);
        },
        resetToPackageSelection: function () {
            this.attr("selectedPackage", false);
            this.attr("paymentState", "choosePackage");
        },
        confirmType: function (typeContainer) {
            this.attr("selectedType", typeContainer.type);
            this.advanceState();
        },
        confirmPackage: function (pkg) {
            this.attr("selectedPackage", pkg);
            this.advanceState();
        },
        orderProduct: function (product) {
            var self = this;
            swal(
                {
                    title: document.getElementById("paymentFootnote").innerHTML,
                    html: true,
                    showCancelButton: true,
                    showConfirmButton: true,
                    cancelButtonText: "Abbrechen",
                    confirmButtonText: "Jetzt zahlungspflichtig bestellen",
                },
                function () {
                    self.attr("chosenProductId", product.attr("id"));
                    self.advanceState();
                }
            );
        },
        formatPrice: function (price) {
            var p = utils.resolveCompute(price);
            return (p / 100).toFixed(2) + "&nbsp;€";
        },
        formatValidFor: function (validForComp) {
            var validFor = utils.resolveCompute(validForComp);
            var daysInAMonth = 31;
            var months = Math.round(validFor / daysInAMonth);
            if (months === 1) {
                return months + "&nbsp;Monat";
            } else {
                return months + "&nbsp;Monate";
            }
        },
        advanceState: function () {
            var self = this,
                currentStep = this.attr("paymentState"),
                nextStep = stateSteps[stateSteps.indexOf(currentStep) + 1],
                isPaymentDataChange = self.attr("isPaymentDataChange");

            if (
                String(self.attr("selectedType")).toUpperCase() !== "PACKAGE" &&
                nextStep === "choosePackage"
            ) {
                // if the user wants to have a subscription, we skip the package selection
                nextStep = stateSteps[stateSteps.indexOf(currentStep) + 2];
            }
            self.attr("paymentState", nextStep);

            if (typeof event !== "undefined") {
                // TODO: Why, Malte?
                event.preventDefault();
            }
            // as this view can be shown without being loggedIn,
            // as soon as a user who is not loggedIn wants to advance from "information" to the next state,
            // we instead redirect to register view,
            // else we want to proceed to payment on my page, directly offering payment selection
            if (!state.currentUser) {
                state.goTo("my", "register");
            } else if (can.route.attr("control") !== "my") {
                state.goTo("my", "plus");
            } else {
                config.log("step advance", currentStep, nextStep);

                switch (nextStep) {
                    case "getProducts":
                        // hide all other stuff on my-page by emitting event to be listened to on including control
                        can.$("plus-payment").trigger("startPayment", "1"); //TODO refer to this.element inside scope?
                        var fetchProductsUrl =
                            config.plus.api.getUrl("getProducts");
                        // LIAW-only. Check for potential customer parameter, indicating free trial for NK+ user
                        if (window.platform === "loveisawonder") {
                            var customerParam = utils.getParameterByName(
                                "customer",
                                window.location.href
                            );
                            if (customerParam && customerParam === "netzkino") {
                                fetchProductsUrl =
                                    fetchProductsUrl + "?customer=netzkino";
                                self.attr("isLiawFreeTrial", true);
                            }
                        }
                        API.get(fetchProductsUrl).then(function (response) {
                            if (response.products) {
                                self.attr(
                                    "paymentOptionsLength",
                                    response.products.length
                                );
                                self.attr("paymentOptions", response.products);
                                self.advanceState();
                            } else {
                                swal(
                                    "Leider konnten keine Bezahlmöglichkeiten angeboten werden. Bitte wenden Sie sich an den Support."
                                );
                            }
                        });
                        break;
                    case "chooseType":
                        ga.sendEvent("Payment", "DisplayPaymentTypes");
                        break;
                    case "choosePackage":
                        ga.sendEvent("Payment", "DisplayPaymentPackages");
                        break;
                    case "choosePayment":
                        ga.sendEvent("Payment", "DisplayPaymentMethods");
                        break;

                    case "submitPaymentMethod":
                        var chosenProductId = self.attr("chosenProductId");
                        if (!chosenProductId) {
                            // just to make sure we did not get here by accident
                            swal("Bitte Bezahlmethode auswählen.");
                            self.resetProgress();
                        }
                        var errorUserNotification =
                            "Leider konnte die Bezahlung nicht fortgesetzt werden. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.";
                        API.post(config.plus.api.getUrl("preparePayment"), {
                            productId: chosenProductId,
                            isPaymentDataChange: isPaymentDataChange,
                        }).then(
                            function (response) {
                                // we are getting a redirectUrl in response, go there or show error if not present

                                if (response && response.redirectUrl) {
                                    window.location.href = response.redirectUrl;
                                    self.advanceState();
                                }
                                // Non Wirecard Paypal
                                else if (response && response.transactionId) {
                                    // we just swallot the event
                                } else {
                                    // this might happen if the input data was malformed or backend / database data is not setup correctly
                                    swal(errorUserNotification);
                                }
                            },
                            function () {
                                // this might happen on unexpected backend behaviour
                                swal(errorUserNotification);
                            }
                        );
                        break;
                    case "initialized":
                        // this page is now closed. User action should be within adyen or bank context now
                        // "wait" for redirect to this component with payment information ...
                        break;
                }
            }
        },
        successHandler: function (status) {
            // payment was successful
            ga.sendEvent("Payment", "Authorized");

            // let's reload the page and hope, that the backend has already found payment information
            // else it might be pending and we will show pending state
            Cookie.clearCookie("registeredPlus"); // in case this was set before

            var now = new Date();
            var time = now.getTime();
            time += 60 * 1000;
            now.setTime(time);
            Cookie.createCookie(
                "firstPlus",
                this.attr("isPaymentDataChange") ? "2" : "1",
                now.toUTCString()
            );
            // directly redirect to mainpage if paid successfully
            // if we tried to watch a movie before, redirect to that movie page
            var targetRedirectHash = "#!/mainpage";
            var previouslyWatchedMovie = Cookie.readCookie(
                "previouslyWatchedMovie"
            );
            if (
                previouslyWatchedMovie !== null &&
                previouslyWatchedMovie.length > 0
            ) {
                Cookie.clearCookie("previouslyWatchedMovie");
                targetRedirectHash = "#!/search/" + previouslyWatchedMovie;
            }

            // needs to completely reload the page to get the currentUser including the plus information
            document.location.href =
                document.location.href.split("?")[0] + targetRedirectHash;
        },
        failHandler: function (status) {
            ga.sendEvent("Payment", "Failed");
            swal(
                {
                    title: "Ihr Bezahlvorgang ist leider fehlgeschlagen. Bitte kontaktieren Sie den Support unter Angabe Ihres Nutzernamens.",
                },
                function () {
                    window.location.reload();
                }
            );
            utils.removeParamFromURL("status", status);
        },
        initStripe: function (product) {
            var self = this;
            var productInfo = product;
            var stripe = Stripe(product.paymentMethod.clientId);
            config.log("Stripe Init Called");
            // timeout required for fetching the button via id and registering the event-listener
            setTimeout(function () {
                var checkoutButton = document.getElementById(
                    "stripe_" + product.id
                );
                var paymentRequestButton = document.getElementById(
                    "stripe_payment_request_button_" + product.id
                );
                if (checkoutButton) {
                    checkoutButton.addEventListener("click", function () {
                        self.attr("paymentState", stateSteps[5]);
                        self.advanceState();
                        getTransactionId = new Promise(function (
                            resolve,
                            reject
                        ) {
                            API.post(config.plus.api.getUrl("preparePayment"), {
                                productId: productInfo.attr("id"),
                                isPaymentDataChange: self.attr(
                                    "isPaymentDataChange"
                                ),
                                redirect: {
                                    returnUrl: `${location.origin}/?status=pending#!/my/plus`,
                                    // TODO; shouldn't that be "cancel" instead of "fail"?
                                    cancelUrl: `${location.origin}/?status=fail#!/my/plus`, //
                                },
                            }).then(
                                function (response) {
                                    if (response) {
                                        resolve(response);
                                    } else {
                                        // this might happen if the input data was malformed or backend / database data is not setup correctly
                                        reject();
                                    }
                                },
                                function () {
                                    // this might happen on unexpected backend behaviour
                                    reject();
                                }
                            );
                        });
                        getTransactionId.then(
                            function (session) {
                                window.location.assign(session.redirectUrl);
                            },
                            function (err) {
                                config.error(
                                    "could not redirect to checkout with Stripe",
                                    err
                                );
                            }
                        );
                    });
                }
                // Payment requests in order to support ApplePay and GooglePay
                if (paymentRequestButton && window.platform === "netzkino") {
                    var paymentRequest = stripe.paymentRequest({
                        // INFO this is just to show some info to user, we will not really use the information passed here
                        country: "DE",
                        currency: productInfo.currencyCode.toLowerCase(), // expect 'usd', 'eur' (lower case only)
                        total: {
                            label: productInfo.name,
                            amount: productInfo.price,
                        },
                        requestPayerName: true,
                        requestPayerEmail: true, // needed for backend to create customer
                    });
                    var elements = stripe.elements();

                    var prButton = elements.create("paymentRequestButton", {
                        paymentRequest: paymentRequest,
                        style: {
                            paymentRequestButton: {
                                type: "default",
                                // One of 'default', 'book', 'buy', or 'donate'
                                // Defaults to 'default'

                                theme: "dark",
                                // One of 'dark', 'light', or 'light-outline'
                                // Defaults to 'dark'

                                height: "34px",
                                // Defaults to '40px'. The width is always '100%'.
                            },
                        },
                    });

                    // Check the availability of the Payment Request API first.
                    paymentRequest.canMakePayment().then(function (result) {
                        // Payment-Tile is initially set to display:none, only show it if payment is supported.
                        if (result && (result.applePay || result.googlePay)) {
                            try {
                                // set the respective brand image and show the tile
                                const paymentImageContainer =
                                    document.getElementById(
                                        "stripe_payment_request_img_container_" +
                                            productInfo.id
                                    );
                                const img = document.createElement("img");
                                if (result.applePay) {
                                    img.src =
                                        "https://pmd.bilder.netzkino.de/bilder/payment/applepay.png";
                                    img.className =
                                        "stripe-payment-request-apple";
                                } else {
                                    img.src =
                                        "https://pmd.bilder.netzkino.de/bilder/payment/googlepay.png";
                                    img.className =
                                        "stripe-payment-request-google";
                                }
                                paymentImageContainer.appendChild(img);

                                document.getElementById(
                                    "stripe_payment_request_button_container_" +
                                        productInfo.id
                                ).style.display = "block";
                                prButton.mount(
                                    "#" +
                                        "stripe_payment_request_button_" +
                                        productInfo.id
                                );
                            } catch (err) {
                                console.error(
                                    "could not render GooglePay/ApplePay-Button "
                                );
                            }
                        }
                    });
                    paymentRequest.on("paymentmethod", function (ev) {
                        console.log(ev);
                        console.log(JSON.stringify(ev));
                        const paymentMethod = ev.paymentMethod;
                        if (paymentMethod) {
                            // TODO: call stripeWalletPayment
                            API.post(
                                config.plus.api.getUrl("stripeWalletPayment"),
                                {
                                    productId: productInfo.attr("id"),
                                    isPaymentDataChange: self.attr(
                                        "isPaymentDataChange"
                                    ),
                                    paymentRequestMethod: paymentMethod,
                                }
                            ).then(
                                (res) => {
                                    // Payment Successful
                                    if (res && res.success === true) {
                                        ev.complete("success"); // Completes the transaction and closes Google/Apple pay​
                                        // Check if we received a client secret from the backend which
                                        // signals a further payment confirm step
                                        if (res.clientSecret) {
                                            // Let Stripe.js handle the rest of the payment flow.
                                            stripe
                                                .confirmCardPayment(
                                                    res.clientSecret
                                                )
                                                .then(function (result) {
                                                    if (result.error) {
                                                        // The payment failed -- ask your customer for a new payment method.
                                                        location.search =
                                                            "?status=fail";
                                                    }
                                                    // The payment has succeeded.
                                                    else {
                                                        location.search =
                                                            "?status=pending";
                                                    }
                                                });
                                        }
                                        // The payment has succeeded.
                                        else {
                                            // redirect to waiting for activation page
                                            location.search = "?status=pending";
                                        }
                                    }
                                    // Server returned with an error
                                    else {
                                        ev.complete("fail");
                                        location.search = "?status=fail";
                                    }
                                },
                                (err) => {
                                    config.error(
                                        "Google/Apple-Payment request failed",
                                        err
                                    );
                                    ev.complete("fail");
                                    location.search = "?status=fail";
                                }
                            );
                        }
                    });
                }
            }, 100);
        },
        initPaypal: function (product) {
            var self = this;
            if (product && window.paypalLoadScript) {
                config.log("Paypal Init Called");
                loadPaypalConfig();
            } else {
                swal(
                    "Leider konnten keine Bezahlmöglichkeiten angeboten werden. Bitte wenden Sie sich an den Support."
                );
            }
            function loadPaypalConfig() {
                // Handle respective state and user notification
                function paypalStatus(status) {
                    self.attr("paymentState", stateSteps[5]);
                    self.advanceState();
                    if (status === "success") {
                        location.search = "?status=pending";
                    } else {
                        // Provide error notification for  user
                        location.search = "?status=fail";
                    }
                }
                var productInfo = product;

                // Prepare paypal subscription or package payment
                function createSubscriptionOrOrder(data, actions) {
                    var errorUserNotification =
                        "Leider konnte die Bezahlung nicht fortgesetzt werden. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.";
                    self.attr("chosenProductId", productInfo.id);
                    getTransactionId = new Promise(function (resolve, reject) {
                        API.post(config.plus.api.getUrl("preparePayment"), {
                            productId: productInfo.id,
                            isPaymentDataChange: self.attr(
                                "isPaymentDataChange"
                            ),
                        }).then(
                            function (response) {
                                if (response && response.transactionId) {
                                    config.log(
                                        "Transaction Id received in order to initialize Subscription or Package"
                                    );
                                    resolve(response.transactionId);
                                } else {
                                    // this might happen if the input data was malformed or backend / database data is not setup correctly
                                    console.error(
                                        "Failed to receive Paypal Transcaction ID. This might happen if the input data was malformed or backend / database data is not setup correctly"
                                    );
                                    reject();
                                }
                            },
                            function () {
                                // this might happen on unexpected backend behaviour
                                console.error(
                                    "unexpected backend behaviour while preparing paypal payment"
                                );
                                reject();
                            }
                        ); // nur weil prepare vermutlich jQuery promise ist...?
                    });
                    return getTransactionId.then(
                        function transactionIdResolved(transactionId) {
                            // Paypal Package
                            if (productInfo.type === "PACKAGE") {
                                // We use our custom backend call for creating a paypal order (package) in order to avoid
                                // users being able to manipulate the price via frontend code
                                var err = new Error(
                                    "could not retrieve orderId"
                                );
                                return API.post(
                                    config.plus.api.getUrl("postPaypalOrder"),
                                    {
                                        transactionId: transactionId,
                                    }
                                ).then(
                                    function (response) {
                                        if (response && response.orderId) {
                                            return response.orderId;
                                        } else {
                                            // this might happen on unexpected backend behaviour
                                            return err;
                                        }
                                    },
                                    function () {
                                        // this might happen on unexpected backend behaviour
                                        return err;
                                    }
                                );
                            }
                            // Paypal Subscription
                            else {
                                return actions.subscription.create({
                                    plan_id: productInfo.paymentMethod.planId,
                                    custom_id: transactionId,
                                });
                            }
                        },
                        function errorHandler() {
                            swal(errorUserNotification);
                        }
                    );
                }
                // handle custom callback if payment was approved for subscription/package
                function onApprove(data, actions) {
                    // At this point we finished the state "submitPaymentMethod" and "initialiazed" and are currently in state "pending"
                    // waiting for a redirect to the page.

                    // Package
                    if (productInfo.type === "PACKAGE") {
                        actions.order.capture().then(
                            // We retrieved detailed subscription data from Paypal
                            function (detailedSubscriptionData) {
                                // Notify Backend to create a valid subscription
                                API.post(
                                    config.plus.api.getUrl("postPaypalPayment"),
                                    {
                                        data: detailedSubscriptionData,
                                        type: "PACKAGE",
                                    }
                                ).then(
                                    // Subscription should be created at this point
                                    function (response) {
                                        if (
                                            response &&
                                            response.status === "success"
                                        ) {
                                            // Provide pending notification for user
                                            paypalStatus("success");
                                        } else {
                                            // this might happen if the input data was malformed or backend / database data is not setup correctly
                                            paypalStatus("failed");
                                        }
                                    },
                                    // this might happen on unexpected backend behaviour
                                    function (errorResponse) {
                                        config.error(
                                            "Could not post paypal payment",
                                            errorResponse
                                        );
                                        paypalStatus("failed");
                                    }
                                );
                            },
                            // We couldn't retrieve subscription details from Paypal and therefore can't enable a subscription on the backend.
                            // The pending status won't find a valid subscription and will eventually timeout with an error-notification for the user
                            function () {
                                // Provide error notification for  user
                                paypalStatus("failed");
                            }
                        );
                    }
                    // Subscription
                    else {
                        actions.subscription.get().then(
                            // We retrieved detailed subscription data from Paypal
                            function (detailedSubscriptionData) {
                                // Notify Backend to create a valid subscription
                                API.post(
                                    config.plus.api.getUrl("postPaypalPayment"),
                                    {
                                        data: detailedSubscriptionData,
                                        type: "SUBSCRIPTION",
                                    }
                                ).then(
                                    // Subscription should be created at this point
                                    function (response) {
                                        if (
                                            response &&
                                            response.status === "success"
                                        ) {
                                            // Provide pending notification for user
                                            paypalStatus("success");
                                        } else {
                                            // this might happen if the input data was malformed or backend / database data is not setup correctly
                                            paypalStatus("failed");
                                        }
                                    },
                                    // this might happen on unexpected backend behaviour
                                    function (errorResponse) {
                                        config.error(
                                            "Could not post paypal payment",
                                            errorResponse
                                        );
                                        paypalStatus("failed");
                                    }
                                );
                            },
                            // We couldn't retrieve subscription details from Paypal and therefore can't enable a subscription on the backend.
                            // The pending status won't find a valid subscription and will eventually timeout with an error-notification for the user
                            function () {
                                // Provide error notification for  user
                                paypalStatus("failed");
                            }
                        );
                    }
                }

                function render(options, productInfo) {
                    if (
                        paypalButtons &&
                        paypalButtons.close &&
                        paypalButtonhasRendered
                    ) {
                        paypalButtons.close();
                        paypalButtonhasRendered = false;
                    }
                    var element_id = "#paypal_" + productInfo.id;
                    paypalButtons = paypal.Buttons(options);
                    paypalButtons.render(element_id).then(
                        function () {
                            paypalButtonhasRendered = true;
                        },
                        // check if the button component has been destroyed before it finished rendering (via back navigation) and swallow the error
                        function (err) {
                            var selector = document.querySelector(element_id);

                            // button failed to render, possibly because it was closed or destroyed.
                            if (selector && selector.children.length > 0) {
                                // still mounted so throw an error
                                config.error(
                                    "button failed to render, possibly because it was closed or destroyed",
                                    err
                                );
                            }
                            // not mounted anymore, we can safely ignore the error
                            return;
                        }
                    );
                }

                // Without a timeout the Paypal-Script can't seem to find the respective dom element (paypal-button-container) required for rendering its buttons
                // We need to toggle between paypal scripts for package and subscription
                // via paypalScriptLoader. See: https://github.com/paypal/paypal-checkout-components/issues/1463#issuecomment-729241771
                setTimeout(function () {
                    // check if buttons exist, due to render being called on hitting the back button from package selection
                    if (document.getElementById("paypal_" + productInfo.id)) {
                        if (productInfo.type === "PACKAGE") {
                            window
                                .paypalLoadScript({
                                    "client-id":
                                        productInfo.paymentMethod.clientId,
                                    vault: false,
                                    currency: "EUR",
                                    "disable-funding":
                                        "giropay,card,sepa,sofort",
                                    locale: "de_DE",
                                    components: "buttons",
                                })
                                .then(function () {
                                    render(
                                        {
                                            style: {
                                                shape: "rect",
                                                color: "blue",
                                                layout: "vertical",
                                                label: "pay",
                                            },
                                            createOrder: function (
                                                data,
                                                actions
                                            ) {
                                                return createSubscriptionOrOrder(
                                                    data,
                                                    actions
                                                );
                                            },
                                            onApprove,
                                            onError(err) {
                                                paypalStatus("failed");
                                            },
                                        },
                                        productInfo
                                    );
                                });
                        } else {
                            window
                                .paypalLoadScript({
                                    "client-id":
                                        productInfo.paymentMethod.clientId,
                                    vault: true,
                                    intent: "subscription",
                                    currency: "EUR",
                                    "disable-funding":
                                        window.platform === "netzkino"
                                            ? "card"
                                            : "",
                                    components: "buttons",
                                    locale: "de_DE",
                                })
                                .then(function () {
                                    render(
                                        {
                                            style: {
                                                shape: "rect",
                                                color: "blue",
                                                layout: "vertical",
                                                label: "pay",
                                            },
                                            createSubscription: function (
                                                data,
                                                actions
                                            ) {
                                                return createSubscriptionOrOrder(
                                                    data,
                                                    actions
                                                );
                                            },
                                            onApprove: function (
                                                data,
                                                actions
                                            ) {
                                                onApprove(data, actions);
                                            },
                                            onError: function (err) {
                                                paypalStatus("failed");
                                            },
                                        },
                                        productInfo
                                    );
                                });
                        }
                    }
                }, 50);
            }
        },
    },
    events: {
        init: function (el, options) {
            var self = this;
            // Platforms which currently support Paypal
            if (
                window.platform === "kixi" ||
                window.platform === "loveisawonder" ||
                window.platform === "netzkino"
            ) {
                self.options.scope.attr("isPaypalSupported", true);
                self.options.scope.attr("isPaypalScriptLoaded", false);
                // Wait for the paypalScriptLoader to be ready before rendering Paypal Buttons.
                // We need to dynamically reload the paypal script in order to support orders and subscriptions
                can.$.getScript(
                    "https://unpkg.com/@paypal/paypal-js@5.1.4/dist/iife/paypal-js.legacy.min.js"
                ).then(() => {
                    self.options.scope.attr("isPaypalScriptLoaded", true);
                });
            }
        },
        inserted: function (el, ev) {
            // this is triggered on coming into view / being loaded
            var self = this,
                paymentStateToStartFrom =
                    self.options.scope.attr("stateToStartFrom") ||
                    "information",
                advanceOnFinish = false;

            state.currentUserPromise.then(
                function (user) {
                    // check for potential status parameter (coming from a payment-service redirect / payment provider cancel)
                    var status = utils.getParameterByName(
                        "status",
                        window.location.href
                    );
                    if (
                        !self.options.scope.attr("isPaymentDataChange") &&
                        user.isPlus &&
                        !status
                    ) {
                        // we did not just payed, but are a long term customer already (no status set in url from redirect)
                        // -> set paymentState to completed to hide this component completely
                        paymentStateToStartFrom = "completed";
                    } else if (status) {
                        config.log("Status handling for status value", status);
                        switch (status) {
                            case "fail":
                                self.scope.failHandler(status);
                                break;
                            case "success":
                                self.scope.successHandler(status);
                                break;
                            case "cancel":
                                ga.sendEvent("Payment", "Cancelled");
                                swal(
                                    {
                                        title: "Sie haben den Bezahlvorgang erfolgreich abgebrochen und können diesen nun von Neuem beginnen.",
                                    },
                                    function () {
                                        if (config.plus.paywall.enabled) {
                                            // if there is a paywall, redirect the user to the payment process! Use location to force full reload
                                            // this is necessary as it resets the state of the plus-payment component.
                                            window.location.href =
                                                window.location.href.split(
                                                    "?"
                                                )[0] + "#!/my/plus";
                                        }
                                    }
                                );
                                utils.removeParamFromURL("status", status);
                                break;
                            case "pending":
                                // pending should only indicate a minor delay of successas both, the redirection and the payment notification are sent at the same time
                                // for the process and the possible unsuccessful event after a short time, we want to request users/me every few seconds
                                // and declare this a failure if there is no valid subscription (user.isPlus) after 10 seconds
                                paymentStateToStartFrom = "pending";
                                // start checking
                                swal({
                                    title: "Wir warten gerade auf die Antwort des Zahlungsanbieters. Bitte bleiben sie auf dieser Seite und benutzen nicht den Zurück-Knopf des Browsers, vielen Dank.",
                                    closeOnClickOutside: false,
                                    showCloseButton: false,
                                    showConfirmButton: false,
                                    showCancelButton: false,
                                });
                                var startTime = Date.now();
                                var intervalRuns = 0;
                                var pendingInterval = setInterval(function () {
                                    User.getCurrentUser().then(function (user) {
                                        if (user.isPlus) {
                                            self.scope.successHandler(status);
                                            clearInterval(pendingInterval);
                                        } else if (
                                            startTime + 1 * 60 * 1000 <
                                            Date.now()
                                        ) {
                                            self.scope.failHandler(status);
                                            clearInterval(pendingInterval);
                                        }
                                        intervalRuns++;
                                    });
                                }, 2000);
                                break;
                            case "plus":
                                advanceOnFinish = true;
                                break;
                        }
                    } else if (
                        self.options.scope.attr("directlyOfferPayment") ===
                        "true"
                    ) {
                        advanceOnFinish = true;
                    }

                    config.log(
                        "inserted plus-payment with state",
                        paymentStateToStartFrom
                    );
                    self.options.scope.attr(
                        "paymentState",
                        paymentStateToStartFrom
                    );
                    if (advanceOnFinish) {
                        self.scope.advanceState();
                    }
                },
                function () {
                    // the user is not loggedIn yet
                    // just show information
                    self.options.scope.attr("paymentState", "information");
                }
            );
        },
        'input[name="brandCode"] click': function () {
            can.$("#submitPaymentButton").prop("disabled", false);
        },
        ".goToOffer click": function ($el, ev) {
            ev.preventDefault();
            this.scope.advanceState();
        },
        "#submitPaymentButton click": function ($el, ev) {
            if ($el.is(":disabled")) {
                ev.preventDefault();
                ev.stopPropagation();
                return false;
            } else {
                this.scope.attr(
                    "chosenProductId",
                    can.$('input[name="brandCode"]:checked').val()
                );
                this.scope.advanceState();
            }
        },
    },
});
