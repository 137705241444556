import can from "can";
import utils from "../../lib/utils";
import state from "../state";
import AudioContent from "./audioContent";
import Category from "./category";
import Movie from "./movie";
import Series from "./series";
import SeriesContent from "./seriesContent";

function getSeriesForSeriesContent(model) {
    var seriesData = model.attr("series");
    var id = seriesData.id;
    var seriesById = state.data.seriesById;

    var existingInstance = seriesById[id];
    if (existingInstance) {
        return existingInstance;
    } else {
        var newInstance = Series.model(seriesData);
        seriesById[id] = newInstance; // cache in state
        return newInstance;
    }
}

function parseMultimediaPost(post) {
    // if there is already an instance, we reuse it. We would also get an Exception otherwise.
    // the previously used can.Model.model() function also does this.
    if (post instanceof Movie) {
        // config.log('[parseMultimediaPost] post is already a model', post);
        return post; // do no reparse
    }
    var trackData = utils.getDeep(post, "custom_fields.trackData");
    // if the wordpress post had trackData once, it will keep it as an empty array
    var isAudioContent = !!(trackData && trackData.length);
    var isSeriesContent = !!utils.getDeep(post, "series.id");

    if (isAudioContent) {
        return new AudioContent(post);
    } else if (isSeriesContent) {
        var seriesContent = new SeriesContent(post);
        var series = getSeriesForSeriesContent(seriesContent);
        series.addPost(seriesContent);

        return seriesContent;
    } else {
        return new Movie(post);
    }
}

function parseMultimediaList(posts) {
    if (!posts) {
        throw new Error("trying to parse non-existing posts");
    }
    var models = [];
    can.batch.start();
    posts.forEach(function (post) {
        // can be a can.List which does not provide .map....
        if (!post) return;
        models.push(parseMultimediaPost(post));
    });
    can.batch.stop();

    return new can.Model.List(models);
}

var parsePost =
    window.platform === "kixi"
        ? parseMultimediaPost
        : function parsePost(post) {
              if (post instanceof Movie) {
                  // same behaviour as Movie.model()
                  return post;
              }
              return new Movie(post);
          };

var parsePostList =
    window.platform === "kixi"
        ? parseMultimediaList
        : function parsePostList(posts) {
              return new Movie.List(posts);
          };

function parseCategory(data) {
    var instance = data;
    // if there is already an instance, we reuse it. We would also get an Exception otherwise.
    // the previously used can.Model.model() function also does this.
    if (!(instance instanceof Category)) {
        instance = new Category(data);
    }
    if (data.posts) {
        // m.attr('posts', data.posts);
        //debugger;
        var models = parsePostList(data.posts);
        instance.attr("posts", models);
    }
    if (!instance.attr("subcategories")) {
        instance.attr("subcategories", []); // some categories could not appear on the index, but still get called directly, avoid exceptions
    }
    return instance;
}

function parseCategoryList(data) {
    var arr = data.categories || data;
    var models = [];
    arr.forEach(function (cat) {
        models.push(parseCategory(cat));
    });
    return new can.Model.List(models);
}

export default {
    parsePost: parsePost,
    parsePostList: parsePostList,
    parseCategory: parseCategory,
    parseCategoryList: parseCategoryList,
};
