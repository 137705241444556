{{#if movie}}
    <div class="infobox">
        {{#unless movie.hasSimpleOverlay}}
            <span class="infobox-title text-overflow-lines-2">
                <span>{{useFallback title movie.title}}</span>
            </span>
        {{/unless}}
            <span class="infobox-play" {{#if overlayURL}}style="background-size: cover;background-image: url('{{overlayURL}}');"{{/if overlayURL}}></span>
        {{#unless movie.hasSimpleOverlay}}
            <span class="infobox-data-bar {{#if function}}function movie-remove-link{{/if}}" {{#if function}} data-function="{{function}}" {{/if}}>
                {{#if function}} {{! defined at category-slider level - used for Watchlist and Wishlist additional functionality}}
                    <a alt="Film von Liste entfernen">Entfernen</a>
                {{else}}
                    {{#if movie.custom_fields.Jahr.0}}
                        <span class="va-middle">{{movie.custom_fields.Jahr.0}}{{#ifAny movie.custom_fields.IMDb-Bewertung.0 movie.custom_fields.FSK.0}}&nbsp;|{{/ifAny}}</span>
                    {{/if}}
                    {{#if movie.custom_fields.IMDb-Bewertung.0}}
                        <span class="rating-bar va-middle">
                            <span class="rating" style="width: calc({{commaReplace movie.custom_fields.IMDb-Bewertung.0}} * 10%);"></span>
                        </span>{{#if movie.custom_fields.FSK.0}}&nbsp;|{{/if}}
                    {{/if}}
                    {{#if movie.custom_fields.FSK.0}}
                        <span class="va-middle">FSK {{movie.custom_fields.FSK.0}}</span>
                    {{/if}}
                {{/if}}
            </span>
        {{/unless}}
    </div>
{{/if}}