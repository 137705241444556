import can from "can";
import config from "../js/config";
import state from "../js/state";

export default {
    refresh: function () {
        if (
            window.platform === "netzkino" &&
            !(state.currentUser && state.currentUser.isPlus)
        ) {
            // NS-795 - trigger ad placement after DOM is built
            can.defer(function () {
                var nx = window.nx || { cmd: [] };
                nx.cmd.push(function () {
                    nx.trigger("rescan");
                    nx.trigger("refresh");
                });
                config.log("[nx] trigger nx refresh");
            });
        }
    },
};
