<div class="container title-social-bar">
  <div class="row">
    <div class="col-lg-6 col-md-5 col-sm-8 col-xs-12">
      <h1 class="cat-title {{^showText}}cat-title-big{{/showText}}" >
      {{#if firstCategoryLink}}<a href="{{firstCategoryLink}}">{{cat}}</a>{{else}}{{cat}}{{/if}}
      </h1>
      {{#showText}}
        <span class="claim hidden-xs">Hier kannst du kostenlos und legal Spielfilme schauen.</span>
        <span class="claim visible-xs">Kostenlos Filme schauen.</span>
      {{/showText}}
    </div>
    {{#if config.socialBar.show}}
        <div class="col-lg-6 col-md-7 col-sm-4 hidden-xs text-right">
          <ul class="followButtons">
            {{! also determined by location "showExtraLogo" attribute}}
            {{#if showExtraLogo}}
              <li>
                <a href="{{config.socialBar.extraLogo.link}}" target="_blank" title="{{config.socialBar.extraLogo.label}}" style="display: inline;">
                  <img src="{{config.socialBar.extraLogo.img}}" alt="{{config.socialBar.extraLogo.label}}" title="{{config.socialBar.extraLogo.label}}" style="margin-top: 2px;" />
                </a>
              </li>
            {{/if}}
            {{#if config.socialBar.followMotivation.show}}
            <li class="hidden-sm">
              <img src="{{imagePath config.socialBar.followMotivation.img}}" alt="{{config.socialBar.followMotivation.label}}" title="{{config.socialBar.followMotivation.label}}" style="margin-top: 5px;" />
            </li>
            {{/if}}
            {{#each config.socialBar.links}}
                <li class="hidden-sm">
                  <a href="{{link}}" alt="{{label}}" title="{{label}}" {{#if config.socialBar.openInNewTab}}target="_blank" rel="noopener noreferrer"{{/if}}>
                    <img src="{{imagePath image}}" />
                  </a>
                </li>
            {{/each}}
          </ul>
        </div>
    {{/if config.socialBar.show}}
  </div>
</div>