{{#canPreviewYoutube movie}}
    <youtube-cover youtube-id="{{movie.custom_fields.Youtube_Delivery_Id.0}}" playback-start="{{movie.custom_fields.Youtube_Delivery_Preview_Start.0}}" playback-end="{{movie.custom_fields.Youtube_Delivery_Preview_End.0}}" class="youtubeCover movie-link {{classes}}" href="{{href}}" placeholder-image="{{imageSrc}}" />

{{else}}
    <a href="{{href}}" class="wideCover movie-link movie-type-{{movie.type}} {{classes}}">
       <img {{#if imageSrc}}data-original="{{imageSrc}}"{{/if}} alt="{{getTitle movie}}" title="{{getTitle movie}}" class="img-responsive" src="{{imageLoadingOverlay}}">
       {{#if displayPlusOverlay}}
            <img src="{{imagePath plusOverlayPath}}" style="position: absolute;left: 0;top: 0;"/>
       {{/if}}
       <cover-overlay-infobox cover-size="{{coverSize}}" movie="{movie}" title="{{getTitle movie}}"/>
    </a>
{{/canPreviewYoutube}}