<div class="container">
        <div class="navPlaceholder"></div>
</div>
        {{#isEqual isLoggedIn 'pending'}}
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        {{/isEqual}}

        {{#isEqual isLoggedIn 'true'}}
            <div class="container smartlogin">
                <div class="row">
                    <div class="col text-center">
                        <button class="btn btn-primary" id="btn-code">Code für SmartTV anfordern</button>
                    </div>
                </div>
                {{#isEqual showCode 'true'}}
                <div class="row">
                    <div class="col text-center">
                        Dein Code:
                    </div>
                    <div class="col text-center code">
                        {{code}}
                    </div>
                    <div class="col text-center">
                        Bitte gib diesen Code im Login Bereich der SmartTV App von {{platform}} ein um dich einzuloggen.
                    </div>
                </div>
                {{/if}}
                    {{#isEqual showCode 'pending'}}
                    <div class="spinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                {{/isEqual}}
                {{#isEqual showCode 'error'}}
                    <div class="row">
                        <div class="col text-center">
                            Etwas ist schiefgelaufen. Bitte versuche es in ein paar Sekunden nochmal.
                        </div>
                    </div>
                {{/if}}
            </div>
        {{/if}}

        {{#isEqual isLoggedIn 'false'}}
            <div class="center-wrapper">
                <my-user-login />
            </div>
        {{/if}}
    </div>
</div>
