import can from "can";
import BaseControl from "../../../lib/can.basecontrol.js";
import categoryRenderingPerformanceHACK from "../../../lib/categoryRenderingPerformanceHACK";
import loader from "../../../lib/loader";
import netletixRefresh from "../../../lib/netletix-refresh";
import lazyrefresh from "../../components/img-lazy";
import config from "../../config";
import Category from "../../models/category";
import Movie from "../../models/movie";
import View from "./view.stache";

function finishLoad(cat, catposts, catpostsads, viewOptions) {
    var posts = cat.attr("posts");
    catposts.replace(posts.slice(0, 10));
    catpostsads.replace(posts);
    viewOptions.attr("loading", false);

    // adds wide images to mix up the standard article image grid
    // for(var i = 0; i < catpostsads.length; i+=7){
    //     catpostsads[i].attr('isBig', true);
    // }

    // Show ads after every 3 rows depending on screen width
    // if(window.platform === 'netzkino') {
    //     var itemsPerRow = 6;
    //     if(window.outerWidth < 1200) {
    //         itemsPerRow = 5;
    //     }
    //     var first = true;
    //     for(var i = 0; i < catpostsads.length; i+=(itemsPerRow*3)) {
    //         if(!first) {
    //             catpostsads[i-1].attr('shouldDisplayAd', true);
    //         } else {
    //             first = false;
    //         }
    //     }
    // }

    // insert ads
    // var adconf = config.adconf[cat.attr('slug')] || config.adconf['*'];
    // NS-685 NO ad placement in single view anymore.
    // if(false && adconf && adconf.enabled) {
    //     var i = 0;
    //     adconf.itemsPerBlock = adconf.itemsPerBlock || posts.length + 1;
    //     while(i++ < Math.floor(posts.length / adconf.itemsPerBlock) && i <= (adconf.limit||9999)) {
    //         // config.log('Adding adItem at idx %d p.l: %d, i: %d, ipb: %d', i * (adconf.itemsPerBlock + 1) - 1, posts.length, i, adconf.itemsPerBlock);
    //         catpostsads.splice(i * (adconf.itemsPerBlock + 1) - 1, 0, new AdItem(adconf.ads[(i-1)%adconf.ads.length]));
    //     }
    // }
}

export default BaseControl.extend(
    "CategoryPage",
    {
        defaults: {
            template: View,
        },
    },
    {
        init: function (el, options) {
            var state = this.options.state,
                $indexLoaderDef = state.defs.indexLoader,
                thisId = can.route.attr("control"),
                cat = new can.Map({}),
                catposts = new can.List([]),
                catpostsads = new can.List([]),
                $el = this.element,
                self = this;

            window.active = {
                cat: cat,
                catposts: catposts,
                catpostsads: catpostsads,
            };

            this.options.viewOptions.attr({
                state: state,
                displayBanner: window.platform === "netzkino",
                isNetzkino: window.platform === "netzkino",
                cat: cat,
                loading: true,
                catposts: catposts,
                catpostsads: catpostsads,
                hasSubcategories: false,
                mainCategoryTitle: false,
                parentCategorySliderCategory: null,
                config: config,
                adBlacklist: thisId === "kinderkino" ? "kinderkino" : "", // subcategories not checked, because they do not use the na-superbanner
                isKixi: window.platform === "kixi",
                landingPreview: config.plus.getLandingPagePreviewURLs(thisId),
            });

            can.defer(function () {
                $indexLoaderDef.always(function (index) {
                    //thisId actually is the category-slug ...

                    var slug = thisId,
                        c = state.data.categoriesBySlug[slug],
                        handleParentViewAssignment = function (c) {
                            var allSubcategories;
                            if (
                                window.platform === "kixi" &&
                                c.attr("slug").indexOf("audio") < 0
                            ) {
                                allSubcategories = c.attr(
                                    "allNestedSubcategories"
                                );
                            } else {
                                allSubcategories = c.attr("getSubCategories");
                            }

                            allSubcategories.forEach(function (subCat, i) {
                                if (subCat.isMainSliderCategory) {
                                    self.options.viewOptions.attr(
                                        "parentCategorySliderCategory",
                                        subCat
                                    );
                                } else if (
                                    (i - 2) %
                                        config.ads.frontpagebanner
                                            .everyNthSlider ===
                                    0
                                ) {
                                    subCat.attr("shouldDisplayAd", true);
                                } else {
                                    subCat.attr("shouldDisplayAd", false);
                                }

                                var createGenreSlider = function (genreCatDef) {
                                    if (
                                        genreCatDef.enabled &&
                                        subCat.id === genreCatDef.appearAfter
                                    ) {
                                        // populate fake category
                                        var fakeFilms = [],
                                            f;
                                        genreCatDef.categoryIds.forEach(
                                            function (catId) {
                                                var category =
                                                    self.options.state.data
                                                        .categoriesById[catId];
                                                f = {};
                                                f.custom_fields = {};
                                                f.custom_fields.featured_img_all_small =
                                                    [
                                                        config.paths.images.get(
                                                            "webseite/plus/genre/" +
                                                                catId +
                                                                ".jpg"
                                                        ),
                                                    ];
                                                f.title =
                                                    category && category.slug;
                                                f.hasSimpleOverlay = true;
                                                fakeFilms.push(f);
                                            }
                                        );

                                        var fakeCat = {
                                            // TODO: how to handle in clones?
                                            sliderType: "genreDoubleRow",
                                            isGenreList: true,
                                            title: genreCatDef.title,
                                            type: "genres",
                                            slug: genreCatDef.linkTo,
                                            posts: Movie.models(fakeFilms),
                                        };

                                        return Category.model(fakeCat);
                                    }
                                };

                                subCat.attr("showPlusGenre", false);
                                subCat.attr("showPlusHighlightGenre", false);

                                // TODO: still needed? NetzkinoPlus category is not to be shown for any users ?
                                if (
                                    config.plus.enabled &&
                                    config.plus.isPlusCategory(c)
                                ) {
                                    var nkPlusSliderCategories =
                                        createGenreSlider(
                                            config.plus.genreCategories
                                        );
                                    if (nkPlusSliderCategories) {
                                        self.options.viewOptions.attr(
                                            "plusGenreCategory",
                                            nkPlusSliderCategories
                                        );
                                        subCat.attr("showPlusGenre", true);
                                    }
                                    var nkPlusHighlightCategory =
                                        createGenreSlider(
                                            config.plus.genreHighlightCategory
                                        );
                                    if (nkPlusHighlightCategory) {
                                        self.options.viewOptions.attr(
                                            "plusGenreHighlightCategory",
                                            nkPlusHighlightCategory
                                        );
                                        subCat.attr(
                                            "showPlusHighlightGenre",
                                            true
                                        );
                                    }
                                }
                            });
                            allSubcategories.sort(function (a, b) {
                                // order should be "Alle Filme", "genre-highlights", ... (rest from API)
                                return a.attr("slug").indexOf("-all") > -1
                                    ? -1
                                    : b.attr("slug").indexOf("highlights");
                            });
                            self.options.viewOptions.attr(
                                "hasSubcategories",
                                true
                            );
                            self.options.viewOptions.attr("loading", false);
                            categoryRenderingPerformanceHACK.asyncCategoryRendering(
                                self.options.viewOptions,
                                "subcategories",
                                allSubcategories
                            );
                        };

                    if (!c) {
                        console.error("*** categorypage.js cat not found!");
                    } else {
                        //check if we want to access a parent or a subcategory
                        var isParent = false;
                        var hasSubcategories =
                            config.categoryTree.enabled &&
                            c.attr("subcategories") &&
                            c.attr("subcategories").length > 0;
                        if (hasSubcategories && config.categoryTree.isRoot(c)) {
                            // display mainCategory title in header bar
                            self.options.viewOptions.attr(
                                "mainCategoryTitle",
                                c.title
                            );
                            isParent = true;
                        } else {
                            // change / add control class to show that we are on a fake subcategory
                            $("netzkino-app").addClass("subcategory");
                            $(window).scroll();
                        }
                        self.setSeoTags(c);

                        can.batch.start(); // start the batch here, let it stop when cat is done
                        var categoriesById = state.data.categoriesById;
                        var parent = categoriesById[c.parent];

                        if (
                            config.plus.enabled &&
                            config.plus.isPlusCategory(c)
                        ) {
                            // if we are on the special page, we want to manually filter the categories to be displayed
                            // and add genre slider according to config parameters

                            // do not directly show these categories as they should be displayed in genre slider
                            c.attr(
                                "subcategories",
                                c.attr("subcategories").filter(function (c) {
                                    var parsed = parseInt(c);
                                    return (
                                        config.plus.genreCategories.categoryIds.indexOf(
                                            parsed
                                        ) < 0 &&
                                        parsed !==
                                            config.plus.genreHighlightCategory
                                                .categoryId
                                    );
                                })
                            );
                        }

                        // some notes to consider when changing the loading behaviour of a categorypage:
                        // - we might need to request the category (again) if it has more than 20 (limited for frontpage categories API-side) available posts
                        // - only load categories which are parent categories - not derived categories (as they are not provided by the API, but built locally)
                        // - this page can be accessed from the homepage and from direct navigation to both the parent category and sub-categories (via slug - even though those are not directly accessible by the API)

                        // - we need to load the category, if
                        //      - we do not have the category in our cache
                        //      - the post.length does not reflect the post count (in this case it is a frontpage category which is limited to 20 posts but should be shown on a single page with all posts available)
                        // - we need to load the parent category of the currently viewed category if it is a subcategory

                        var isThisCategory = function (category) {
                            var slug = category.slug,
                                id = category.id;
                            if (category instanceof can.Map) {
                                slug = category.attr("slug");
                                id = category.attr("id");
                            }
                            return slug === c.slug || id === c.id;
                        };

                        function categoryInfo(c) {
                            var isThisCategory = function (category) {
                                var slug = category.slug,
                                    id = category.id;
                                if (category instanceof can.Map) {
                                    slug = category.attr("slug");
                                    id = category.attr("id");
                                }
                                return slug === c.slug || id === c.id;
                            };
                            var result = {
                                hasPosts: !!c.attr("posts.length"),
                                notFullyLoaded:
                                    c.attr("posts.length") <
                                    c.attr("post_count"),
                                isCategoryLoaded: c.attr("hasLoaded"),
                                isHomepageCategory:
                                    index.homepage_categories.filter(
                                        isThisCategory
                                    ).length > 0
                                        ? true
                                        : false,
                                isIndexCategory:
                                    index.categories.filter(isThisCategory)
                                        .length > 0
                                        ? true
                                        : false,
                            };

                            // defines whether we will get a response from the API for this category
                            // - only for netzkino as liaw currently has "all categories are plus but a few" enabled, thus we can not use "isPlusCategory" there
                            result.isCategoryRequestable =
                                result.isHomepageCategory ||
                                result.isIndexCategory ||
                                (config.plus.enabled &&
                                    window.platform === "netzkino" &&
                                    config.plus.isPlusCategory(c));
                            return result;
                        }

                        var currentC = c;
                        var catInfo = categoryInfo(c);

                        if (
                            !catInfo.hasPosts ||
                            catInfo.notFullyLoaded ||
                            (!catInfo.isCategoryRequestable &&
                                (!catInfo.hasPosts || catInfo.notFullyLoaded))
                        ) {
                            // var categoryToLoadSlug = catInfo.isCategoryRequestable ? c.slug : parent.slug;
                            var catToLoad = c;
                            var path = [];
                            while (
                                catToLoad &&
                                !categoryInfo(catToLoad).isCategoryRequestable
                            ) {
                                path.push(catToLoad);
                                catToLoad =
                                    state.data.categoriesById[catToLoad.parent];
                            }
                            var categoryToLoadSlug =
                                (catToLoad && catToLoad.slug) || c.slug;

                            config.log(
                                "categorypage",
                                "requesting category " +
                                    categoryToLoadSlug +
                                    " for loading categoryToLoadSlug",
                                c
                            );

                            loader(
                                loader(
                                    Category.findOne(categoryToLoadSlug),
                                    true,
                                    "Lade Kategorie..."
                                ).then(function (c) {
                                    c.attr("posts").forEach(function (m) {
                                        state.data.moviesBySlug[m.slug] = m;
                                        state.data.moviesById[m.id] = m;
                                    });
                                    if (isParent) {
                                        handleParentViewAssignment(c);
                                    } else if (
                                        currentC.subcategories.length > 0
                                    ) {
                                        c.getSubCategories(); // initialize in-between-category by getting subcategories of the root category which leads to having posts in current parent category
                                        handleParentViewAssignment(currentC);
                                    } else {
                                        // traverse the inheritance downwards, call getSubCategory to build cache and return the lowest category we expected in the beginning.
                                        // supports any number of parents
                                        var lowestParent = c;
                                        path.push(c); // getSubCategory needs to be called for every parent, because it is mutating an internal cache...
                                        path.reverse().forEach(function (
                                            parentCategory,
                                            index
                                        ) {
                                            if (index < path.length - 1) {
                                                var subcat = path[index + 1];
                                                var realSubcategory =
                                                    parentCategory.getSubCategory(
                                                        subcat.slug
                                                    );
                                                lowestParent =
                                                    realSubcategory ||
                                                    lowestParent;
                                            }
                                        });

                                        // WORKAROUND: we need to restore movie model instances explicitly as there are some cases where posts are no longer model instances (due to serialize)
                                        cat.attr(lowestParent.serialize()); // show category with movies from parents
                                        cat.attr(
                                            "posts",
                                            lowestParent.attr("posts")
                                        ); // restores model instances

                                        finishLoad(
                                            cat,
                                            catposts,
                                            catpostsads,
                                            self.options.viewOptions
                                        );
                                    }

                                    can.batch.stop();
                                    can.defer(function () {
                                        netletixRefresh.refresh(); // this is responsible for loading the skyscraper after control-initialization (category banners are taken care of in perofrmanceHACK)
                                        lazyrefresh($el);
                                    });
                                }),
                                true,
                                "Zeige Kategorie..."
                            );
                        } else {
                            config.log(
                                "Not reloading Category!",
                                c.attr("posts.length")
                            );
                            if (isParent) {
                                handleParentViewAssignment(c);
                            } else if (currentC.subcategories.length > 0) {
                                c.getSubCategories(); // initialize in-between-category by getting subcategories of the root category which leads to having posts in current parent category
                                handleParentViewAssignment(currentC);
                            } else {
                                // WORKAROUND: we need to restore movie model instances explicitly as there are some cases where posts are no longer model instances (due to serialize)
                                cat.attr(c.serialize());
                                cat.attr("posts", c.attr("posts")); // restores model instances

                                finishLoad(
                                    cat,
                                    catposts,
                                    catpostsads,
                                    self.options.viewOptions
                                );
                            }

                            can.batch.stop();
                            can.defer(function () {
                                netletixRefresh.refresh(); // this is responsible for loading the skyscraper after control-initialization (category banners are taken care of in perofrmanceHACK)
                                lazyrefresh($el);
                            });
                        }
                    }
                });
            });

            return this._super.apply(this, arguments);
        },
        ".kixiPromoVideoBanner click": function () {
            window.location.href = "#!/my/payment";
        },
        setSeoTags: function (c) {
            if (window.platform === "netzkino") {
                this.seo.setTitle(
                    c.title > 23
                        ? c.title
                        : c.title + " - Jetzt kostenlos ansehen | Netzkino"
                );
                this.seo.setDescription(
                    "Bei Netzkino können Sie viele " +
                        c.title +
                        " Filme jederzeit kostenlos anschauen. Jetzt online, per Android, iPhone, iPad oder SmartTV anschauen!"
                );
                this.seo.setUrl("/" + c.slug);
            } else {
                this.seo.setTitle(c.title);
                this.seo.setDescription(
                    "Viele tolle " +
                        c.title +
                        " jetzt kostenlos und jederzeit ansehen."
                );
            }
        },
    }
);
