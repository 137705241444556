import can from "can";
import utils from "../../../lib/utils";
import config from "../../config";
import state from "../../state";
import movieCoverView from "./view.stache";
can.Component.extend({
    tag: "movie-cover",
    template: movieCoverView,
    scope: {
        movie: null,
        classes: "@",
        href: "@",
        imageSrc: "@",
        allowYoutube: false,
    },
    events: {
        init: function (el, options) {
            var displayPlusOverlay = false;
            var loadingPlaceholder = config.paths.images.loadingPlaceholder;
            if (window.platform === "netzkino") {
                if (
                    (!state.currentUser || !state.currentUser.isPlus) &&
                    options.scope.attr("movie").isPlusMovie()
                ) {
                    displayPlusOverlay = true;
                }
            }
            if (window.platform === "bronco" || window.platform === "grjngo")
                loadingPlaceholder =
                    config.paths.images.base + loadingPlaceholder;

            options.scope.attr("displayPlusOverlay", displayPlusOverlay);
            options.scope.attr(
                "plusOverlayPath",
                "webseite/plus/coverOverlay/netzkinoplus_" +
                    options.scope.attr("coverSize") +
                    ".png"
            );
            options.scope.attr("imageLoadingOverlay", loadingPlaceholder);
        },
        ".movie-link click": function ($el, ev) {
            can.trigger(this.element, "clickMovie", this.scope.movie.id);
        },
    },
    helpers: {
        canPreviewYoutube: function (movieCompute, options) {
            var movie = utils.resolveCompute(movieCompute),
                rawAllowYoutube = this.attr("allowYoutube"),
                allowYoutube =
                    rawAllowYoutube === "true" || rawAllowYoutube === true,
                isMobile = window.innerWidth <= 468;
            if (
                isMobile ||
                !allowYoutube ||
                !movie ||
                !movie.hasYoutubePreview ||
                !movie.hasYoutubePreview()
            ) {
                return options.inverse();
            } else {
                return options.fn();
            }
        },
        getTitle: function (movieCompute, options) {
            var movie = utils.resolveCompute(movieCompute);
            var title = movie.attr("title");
            if (movie.isMoreLink) {
                return options.scope.attr("cat.title") || title;
            } else if (movie.type === "seriesContent") {
                return movie.attr("seriesModel.title");
            } else {
                return title;
            }
        },
    },
});
