import can from "can";
import config from "../config";
import state from "../state";

function init_ga() {
    try {
        (function (i, s, o, g, r, a, m) {
            i["GoogleAnalyticsObject"] = r;
            (i[r] =
                i[r] ||
                function () {
                    (i[r].q = i[r].q || []).push(arguments);
                }),
                (i[r].l = 1 * new Date());
            (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        })(
            window,
            document,
            "script",
            (document.location.protocol === "file:" ? "https://" : "") +
                "//www.google-analytics.com/analytics.js",
            "ga"
        );

        ga("create", config.google_analytics.id, "auto");
    } catch (e) {
        // this could be seen in the firefox console every once in a while. Maybe there is a privacy blocker (extension) that causes this.
        console.error("google analytics init crashed", e);
        if (!window.ga) {
            window.ga = function () {}; // make sure there is at least something so that the other events don't break
        }
    }
}
var currentlySetUserType = undefined;
var GATracker = can.Construct.extend(
    {},
    {
        init: function () {
            if (config.general.isLanding()) {
                window.ga = function () {};
            } else {
                init_ga();
            }
        },

        onNavigate: function (newPage) {
            this.checkAndSetCurrentUser();

            config.log("[GATracker]: onNavigate %s called", newPage);
            // see https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
            ga.apply(ga, ["set", "page", newPage]);
            ga.apply(ga, ["send", "pageView"]);
        },

        sendEvent: function (category, action, label, value) {
            this.checkAndSetCurrentUser();

            var args = ["send", "event", category, action, label];
            if (value) {
                args.push(value);
            }
            config.log("[GATracker]: sendEvent called:", args);
            ga.apply(ga, args);
        },
        // this function checks current user state before each event that should be send to GA
        // - it will change the user cookie (if we have a user that is loggedIn) or unset it, if the user has loggedOut in the meantime
        // - it will add a user dimension depending on the user being a plus user or not, else 'guest' will be used, as we can not filter for unset dimensions in GA
        // - if it has already been set in current context, no action needs to be taken (ga-set calls are not based on cookies, but on currentState)
        checkAndSetCurrentUser: function () {
            var newUserTypeToSet = "guest";
            if (state.currentUser) {
                config.log("[GATracker]: set userId", state.currentUser.id);
                ga("set", "userId", state.currentUser.id);

                newUserTypeToSet = state.currentUser.isPlus ? "plus" : "user";
            }
            if (currentlySetUserType !== newUserTypeToSet) {
                config.log("[GATracker]: set dimension1", newUserTypeToSet);
                ga("set", "dimension1", newUserTypeToSet);
                currentlySetUserType = newUserTypeToSet;
            }
        },
    }
);

export default new GATracker();
