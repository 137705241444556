import can from "can";
import "jquery-lazyload";
import ytAPI from "../../../lib/youtube-api";
import config from "../../config";
import youtubeCoverView from "./view.stache";
var ytReady = ytAPI.load(),
    DEFAULT_START = config.youtube.timeDefaults.start,
    DEFAULT_END = config.youtube.timeDefaults.end,
    MAX_IFRAMES = config.youtube.covers.max,
    visibleVideos = 0;

function verifyTime(t, def) {
    var _isNaN = Number.isNaN || isNaN;
    if (!t || t.length < 1 || isNaN(Number(t)) || t < 0) {
        return Number(def);
    } else {
        return Number(t);
    }
}

var globalMute = can.compute(true); // user pressed mute once

can.Component.extend({
    tag: "youtube-cover",
    template: youtubeCoverView,
    scope: {
        youtubeId: "@",
        playbackStart: "@",
        playbackEnd: "@",
        classes: "@",
        href: "@",
        placeholderImage: "@",
        useSingleLoop: "@",
        _removed: false,
        isMuted: can.compute(function () {
            // cannot directly pass the computed
            if (this.attr("_allowUnmuteBoolean")) {
                return globalMute(); // will subscribe to changes
            } else {
                return true;
            }
        }),
        allowUnmute: "@",
        _allowUnmuteBoolean: can.compute(function () {
            return (
                this.attr("allowUnmute") === true ||
                this.attr("allowUnmute") === "true"
            );
        }),
        _showOrHideIfInViewport: function _showOrHideIfInViewport(el, force) {
            var $cover = el.find(".wideCover:first"),
                isInViewport = $cover.is(":in-viewport"),
                $slider = el.closest(".inner-slider,.carousel"),
                visibleInSlider = true;

            if ($slider && $slider.length) {
                // optional slider
                var pos = $cover.position();
                visibleInSlider =
                    pos.left + $cover.width() > 0 && pos.left < $slider.width();
            }

            if (!force && (!isInViewport || !visibleInSlider)) {
                if (this._initialized) {
                    this._destroyPlayer();
                }
            } else if (
                !this._player &&
                (force ||
                    (isInViewport &&
                        visibleInSlider &&
                        visibleVideos < MAX_IFRAMES))
            ) {
                setTimeout(function () {
                    // this is needed for lazyLoading the current mainpage-slider cover correctly
                    $(".lazy", el).lazyload({});
                }, 0);
                this._initPlayer(el, force);
            }
        },
        _destroyPlayer: function _destroyPlayer() {
            this._el.find(".outOfView").removeClass("fadeInView");
            if (this._player && this._player.destroy) {
                // user can scroll faster than the youtube API can setup the player
                this._player.destroy();
                this._initialized = false;
            }
            this._removed = true;
            clearTimeout(this._stopVideoTimeout);
            visibleVideos = Math.max(0, visibleVideos - 1);
            config.log(
                "[youtube-cover] destroying player instance with video id " +
                    this.attr("youtubeId"),
                visibleVideos
            );

            this._player = null;
        },
        _initPlayer: function _initPlayer(el, force) {
            var scope = this;
            if (scope._player) {
                return;
            }
            // if(!force) {
            visibleVideos++;
            if (visibleVideos >= MAX_IFRAMES) {
                config.log(
                    "[youtube-cover] maximum count of youtube player elements reached!",
                    visibleVideos,
                    MAX_IFRAMES
                );
            }
            // }

            scope._el = el;
            scope._initialized = true;
            scope._removed = false;
            scope._playbackStarted = false;
            scope._player = null;

            config.log(
                "[youtube-cover] initializing player instance for video id " +
                    this.attr("youtubeId"),
                visibleVideos
            );

            var hookElement = el.find(".youtube-player:first")[0],
                videoID = scope.attr("youtubeId"),
                start = verifyTime(scope.attr("playbackStart"), DEFAULT_START),
                end = verifyTime(scope.attr("playbackEnd"), DEFAULT_END),
                duration = Math.abs(end - start) * 1000;

            if (!videoID) {
                throw new Error("video ID missing!");
            }

            ytReady.then(function (YT) {
                if (scope._removed) return; // do not render for already destroyed component

                new YT.Player(hookElement, {
                    videoId: videoID,
                    suggestedQuality: "small",
                    playerVars: {
                        rel: 0, // no related videos - no longer works, just shows from same channel
                        autoplay: 0, // manually trigger autoplay, as the video sometimes starts before .mute() is triggered
                        controls: 0, // no playback controls
                        // showinfo: 0,
                        disablekb: 1, // no keyboard
                        iv_load_policy: 3, // no annotations
                        modestbranding: 1, // minimal branding (no youtube logo)
                        enablejsapi: true,
                        // only preloads the necessary part of the video
                        start: start,
                        end: end + 1, // allow an additional second, because the end screen mit be triggered before the state change event is fired
                    },
                    events: {
                        onReady: onYoutubePlayerReady,
                        onStateChange: onPlayerStateChange,
                    },
                });
            });

            function stopVideo(player) {
                if (scope._removed || !scope._player || scope._playbackFinished)
                    return;
                if (scope.useSingleLoop === "true") {
                    config.log(
                        "youtube cover is stopping playback because useSingleLoop is set"
                    );
                    can.trigger(scope._el, "requestNext");
                    var e = scope._el
                        .find(".fadeInView")
                        .addClass("playbackFinished");
                    scope._playbackFinished = true;
                    player.pauseVideo();
                    return;
                }

                player.seekTo(start);
                scope._playbackStarted = false;
                player.playVideo();
            }

            function onYoutubePlayerReady(ev) {
                var player = ev.target;
                scope._player = player;

                if (scope.attr("isMuted")) {
                    player.mute();
                }

                if (!scope._removed) {
                    player.playVideo();
                } else {
                    player.stopVideo();
                }
            }

            function onPlayerStateChange(ev) {
                var player = scope._player;
                if (scope._removed || !player) {
                    return;
                }

                if (
                    ev.data == YT.PlayerState.PLAYING &&
                    !scope._playbackStarted
                ) {
                    // we want to see the actual youtube element when the video is starting to play
                    scope._el
                        .find(".outOfView")
                        .addClass("fadeInView")
                        .removeClass("playbackFinished");
                    scope._stopVideoTimeout = setTimeout(
                        stopVideo,
                        duration,
                        player
                    );
                    scope._playbackStarted = true;
                    scope._playbackFinished = false;
                } else if (ev.data == YT.PlayerState.ENDED) {
                    scope._el.find(".outOfView").removeClass("fadeInView");
                    can.trigger(scope._el, "requestNext");
                    stopVideo(player);
                }
            }
        },
    },
    events: {
        inserted: function (el, ev) {
            var wait =
                    window.requestAnimationFrame || can.defer.bind(can.defer),
                scope = this.scope;
            wait(
                function () {
                    scope._showOrHideIfInViewport(el);
                }.bind(this)
            );

            scope._scrollListener = can.debounce(
                function (ev) {
                    scope._showOrHideIfInViewport(el);
                }.bind(this),
                100
            );
            window.addEventListener("scroll", scope._scrollListener);
        },
        removed: function () {
            this.scope._removed = true;
            window.removeEventListener("scroll", this.scope._scrollListener);
        },
        ".wideCover click": function (el, ev) {
            // emulate a links
            var href = this.scope.attr("href");
            if (href) {
                location.href = href;
                ev.preventDefault();
            }
        },
        // empty space is necessary so that can.js notices the string as a jquery Event, thrown by lemon slider
        " sliderScrollingDone": function (el, ev) {
            this.scope._showOrHideIfInViewport(el);
        },
        " forceVisible": function (el, ev) {
            // triggered by carousel
            this.scope._showOrHideIfInViewport(el, true);
        },
        " forceInvisible": function (el, ev) {
            // triggered by carousel
            this.scope._showOrHideIfInViewport(el);
        },
        ".youtubePlayer-muter click": function ($el, ev) {
            var willMute = !globalMute();
            globalMute(willMute);
            ev.stopPropagation();
            ev.preventDefault();

            var player = this.scope._player;
            if (player) {
                if (willMute) {
                    player.mute();
                } else {
                    player.unMute();
                }
            }
        },
    },
});
