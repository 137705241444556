/*
 * @Author: Malte
 * @Date:   2015-05-15 17:40:39
 * @Last Modified by:   Mathis
 * @Last Modified time: 2016-12-13 12:41:39
 */
/*
 * @Author: Malte
 * @Date:   2015-05-15 17:40:39
 * @Last Modified by:   Mathis
 * @Last Modified time: 2016-12-13 12:41:39
 */
import can from "can";
import config from "../../config";
import state from "../../state";
import View from "./view.stache";

can.Component.extend({
    tag: "netzkino-title-social-bar",
    template: View,
    scope: {},
    events: {
        init: function (el, options) {
            var showExtraLogo =
                config.socialBar.extraLogo.show &&
                !(state.currentUser && state.currentUser.isPlus);
            if (
                config.frontpage.categorySlider.useSocialTitleAsCategoryLink ===
                    true &&
                options.scope.mainpage === "true"
            ) {
                var url = "#!/westernfilme-bronco";
                this.options.scope.attr("firstCategoryLink", url);
            }
            this.options.scope.attr("showExtraLogo", showExtraLogo);
            this.options.scope.attr(
                "showText",
                this.options.scope.attr("showText") == "true" &&
                    window.platform === "netzkino"
                    ? true
                    : false
            );
        },
    },
});
