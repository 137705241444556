<!-- notification.stache -->
{{#if visible}}
<div class="sticky-header">
  <div class="notification">
    <p>Liebe Nutzer, "Love is a wonder" wird am 31.03.2024 eingestellt.<br><br> Alle aktiven Abonnements werden automatisch zu diesem Datum beendet,<br>
    und es erfolgt keine weitere Abbuchung von Ihrem Konto.<br><br> Ihre Kundendaten werden gemäß den Datenschutzbestimmungen gelöscht werden.<br></p>
    <button can-click="hideNotification">Schließen</button>
  </div>
<div>
{{/if}}
