export default function shuffle(o) {
    //v1.0
    if (!o || !o.length) return o;
    for (
        var j, x, i = o.length;
        i;
        j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x
    );
    return o;
}
