import can from "can";
import utils from "../../lib/utils";
import config from "../config";
import state from "../state";
import analytics from "./googleanalytics";
import watchlist from "./watchlist";

var SEND_TIMEUPDATE_INTERVAL = 60 * 1000; // every minute

export default can.Construct.extend(
    "ContentTracker",
    {},
    {
        init: function (content, part) {
            this.content = content;
            this.part = part; // for audioContent
            this.userIsPlus = null; // see asyncPrepare

            this.lastTrackedCurrentTime = undefined;
            this.currentTimeSumUntracked = 0;
            this.watchdurationInterval = undefined;
            this.mainContentHasStarted = false;
            this.isDestroyed = false;
            this.hasEnded = false;

            var userDeferred = $.Deferred();
            var asyncPrepareHandler = function (user) {
                this.userIsPlus = !!(user && user.isPlus);
                userDeferred.resolve();
            }.bind(this);

            /*
             * just passing the promise returned by .then() does not work when the user is logged out.
             * We explicitly handle this with an additional deferred.
             * If something happens, it falls back to a timeout of a second.
             */
            this.asyncPrepare = userDeferred.promise();

            state.currentUserPromise.then(
                asyncPrepareHandler,
                asyncPrepareHandler
            );

            setTimeout(
                function () {
                    if (
                        userDeferred.state() !== "resolved" &&
                        this.userIsPlus === null
                    ) {
                        var mightBePlus = utils.mightBePlusUser();
                        console.error(
                            "currentUserPromise got a timeout, assuming plus state as %s based on localstorage",
                            mightBePlus
                        );
                        this.userIsPlus = mightBePlus;
                        userDeferred.resolve();
                    }
                }.bind(this),
                1000
            );

            this.handleUnload = function () {
                this._trackTimeupdateInterval({
                    async: false,
                });
            }.bind(this);
            $(window).on("beforeunload", this.handleUnload);
        },

        destroy: function () {
            this.asyncPrepare.then(
                function () {
                    clearInterval(this.watchdurationInterval);
                    this.isDestroyed = true;
                    this.playbackStopped(true);
                    $(window).off("beforeunload", this.handleUnload);
                }.bind(this)
            );
        },

        contentDisplayed: function contentDisplayed() {
            this.asyncPrepare.then(
                function () {
                    analytics.sendEvent("Filmview", this.content.attr("title"));
                }.bind(this)
            );
        },

        // called when something is playing, could be an ad!
        // we support send a filmplay before the ads are showing for legacy reasons!
        // this function basically only initializes the time update interval, can be called multiple times!
        playbackStarted: function playbackStarted(isAd) {
            this.asyncPrepare.then(
                function () {
                    if (!isAd) {
                        this.mainContentHasStarted = true;
                    } else if (this.mainContentHasStarted) {
                        // everything is initialized
                        return;
                    }

                    if (this.isDestroyed) {
                        console.error(
                            "ContentTracker: trying to call playbackStart, but the tracker is already destroyed!"
                        );
                        return;
                    }

                    // - we want to send a filmplay-avod always if the user is not a plus user
                    // - if the user is a plus user, depending on the film custom field properties "Is_AVOD"/"Is_SVOD", we need to show ads and track filmplay-avod
                    //   if the movie is not "Is_SVOD". Only "Is_SVOD" movies are to be tracked as filmplay-svod IF the user is a plus user
                    // - this is tightly coupled with adconf-selection logic.

                    if (!this.watchdurationInterval) {
                        // undefined if the video is an advertisement
                        this._sendFirstPlayEvent();

                        config.log("watchlistduration interval started");
                        this.watchdurationInterval = setInterval(
                            function () {
                                config.log("watchlistduration interval");
                                this._trackTimeupdateInterval();
                            }.bind(this),
                            SEND_TIMEUPDATE_INTERVAL
                        ); // interval is cleared on player destroy (cleanuppotentialOldPlayer)
                    }
                }.bind(this)
            );
        },

        playbackStopped: function playbackStopped() {
            this.asyncPrepare.then(
                function () {
                    /**
                     * Track now the rest of the current trackTime and then skip future
                     * only if we have already started the playback (after pre-rolls).
                     *
                     * Note that this method can get called multiple times at the end, because .destroy() also triggers this.
                     * This is fine as long trackTimeupdateInterval can handle this.
                     */

                    if (this.mainContentHasStarted) {
                        this._trackTimeupdateInterval();
                    }
                }.bind(this)
            );
        },
        playbackEnded: function () {
            this.hasEnded = true;
            this.playbackStopped();
        },

        playbackTimeupdate: function playbackTimeupdate(currentTime) {
            this.asyncPrepare.then(
                function () {
                    if (currentTime < this.lastTrackedCurrentTime) {
                        // user seeked backwards, ignore this second and begin tracking again from new position
                    } else if (currentTime > this.lastTrackedCurrentTime + 10) {
                        // add 10 seconds as a maximum interval that our onTimeUpdate might not have occured
                        //                     minimum interval that we think of as a seek-action by the user
                        // user seeked forwards, we do not track the complete difference but start again from new position
                    } else if (this.lastTrackedCurrentTime) {
                        // not undefined in first initialization
                        this.currentTimeSumUntracked +=
                            currentTime - this.lastTrackedCurrentTime;
                    }
                    this.lastTrackedCurrentTime = currentTime;
                }.bind(this)
            );
        },

        _trackTimeupdateInterval: function trackTimeupdateInterval(
            ajaxOptions
        ) {
            // no need for asyncPrepare
            if (!this.mainContentHasStarted) {
                console.error(
                    "ContentTracker called trackTimeupdateInterval without playing content"
                );
                return;
            }

            var timeToTrack = Math.round(this.currentTimeSumUntracked);
            var movie = this.content;
            this.currentTimeSumUntracked = 0; // reset counter
            if (timeToTrack > 0) {
                config.log(
                    "tracking watchduration",
                    timeToTrack,
                    this._getEventLabel()
                );
                var eventToTrack = "Watchduration";
                if (this.userIsPlus && movie.hasMovieProperty("svod")) {
                    // is svod
                    eventToTrack = "Watchduration-SVOD";
                }
                analytics.sendEvent(
                    eventToTrack,
                    "track",
                    this._getEventLabel(),
                    timeToTrack
                );
                if (window.platform === "kixi") {
                    var category = "Media Play 1.0";
                    if (movie.type === "audioContent") {
                        category = "Audio Play 1.0";
                    }
                    analytics.sendEvent(
                        category,
                        movie.attr("custom_fields.Lizenzgeber.0"),
                        this._getEventLabel(),
                        timeToTrack
                    );
                }
                this._updateWatchlist(ajaxOptions);
            } else if (this.hasEnded) {
                this._updateWatchlist(ajaxOptions); // when a track has ended, the track should start from the beginning
            }
        },

        _sendFirstPlayEvent: function () {
            // no need for async prepare
            if (this.didSendFirstPlayEvent) return;

            var eventToTrack = "Filmplay";
            if (this.userIsPlus && this.content.hasMovieProperty("svod")) {
                // is svod
                eventToTrack = "Filmplay-SVOD";
            }

            analytics.sendEvent(eventToTrack, this._getEventLabel());
            this.didSendFirstPlayEvent = true;
        },
        _getEventLabel: function () {
            var content = this.content;
            var label = content.attr("title");
            if (content.attr("type") === "seriesContent") {
                label += "@" + content.attr("seriesModel.title");
            } else if (content.attr("type") === "audioContent" && this.part) {
                label = this.part.title + "@" + content.attr("title");
            }
            return label;
        },
        _updateWatchlist: function _updateWatchlist(ajaxOptions) {
            var currentTime = Math.floor(this.lastTrackedCurrentTime); // we only need the value as an integer
            if (this.hasEnded) {
                currentTime = 0; // next playback will start from the beginning
            }
            var content = this.content;
            if (content.type === "audioContent") {
                watchlist.updateWatchlistForAudio(
                    content,
                    this.part.number,
                    currentTime,
                    ajaxOptions
                );
            } else {
                watchlist.updateWatchlistForVideo(
                    content,
                    currentTime,
                    ajaxOptions
                );
            }
        },
    }
);
