import can from "can";
import config from "../js/config";
import utils from "../lib/utils";

can.stache.registerHelper("commaReplace", function (compute) {
    var str = utils.resolveCompute(compute);
    return typeof str === "string" ? str.replace(",", ".") : str;
});

can.stache.registerHelper("imagePath", function (compute) {
    var path = utils.resolveCompute(compute);
    return config.paths.images.get(path);
});

/*
 * Call as a block with a can.Map as first parameter and path strings as any additional.
 * {{#getWithFallback movie 'coverImage' 'thumbnail'}}{{.}}{{/getWithFallback}}
 */
can.stache.registerHelper("getWithFallback", function (/* arguments */) {
    if (arguments.length < 3) {
        // options is always the last argument
        throw new Error("getWithFallback requires at least two parameters");
    }
    var movie = utils.resolveCompute(arguments[0]);
    var options = arguments[arguments.length - 1];
    var sources = Array.prototype.slice.call(
        arguments,
        1,
        arguments.length - 1
    );
    if (!movie) return options.inverse();
    var src;
    sources.some(function (path) {
        src = movie.attr(utils.resolveCompute(path));
        return src;
    });
    if (src) {
        src = config.paths.images.get(src);
        return options.fn(src);
    } else {
        return options.inverse();
    }
});

/*
 * check if any of the provided expressions is valid
 */
can.stache.registerHelper("ifAny", function (/* arguments */) {
    var expressions = Array.prototype.slice.call(
        arguments,
        0,
        arguments.length - 1
    );
    var options = arguments[arguments.length - 1];
    var resolved = expressions.some(function (exp) {
        var value = utils.resolveCompute(exp);
        return value !== undefined && value !== null && value;
    });
    if (resolved) {
        return options.fn();
    } else {
        return options.inverse();
    }
});

/*
 * check if all of the provided expressions is valid
 */
can.stache.registerHelper("ifAll", function (/* arguments */) {
    var expressions = Array.prototype.slice.call(
        arguments,
        0,
        arguments.length - 1
    );
    var options = arguments[arguments.length - 1];
    var invalid = expressions.some(function (exp) {
        var value = utils.resolveCompute(exp);
        return !(value === undefined || value === null ? false : value);
    });
    if (invalid) {
        return options.inverse();
    } else {
        return options.fn();
    }
});

/*
 * check if all of the provided expressions is valid
 */
can.stache.registerHelper("isEqual", function (expr1, expr2, options) {
    if (utils.resolveCompute(expr1) === utils.resolveCompute(expr2)) {
        return options.fn();
    } else {
        return options.inverse();
    }
});

can.stache.registerHelper("isNotEqual", function (expr1, expr2, options) {
    if (utils.resolveCompute(expr1) !== utils.resolveCompute(expr2)) {
        return options.fn();
    } else {
        return options.inverse();
    }
});

/*
 * hide plus categories that should disappear when a plus user is logged in
 */
can.stache.registerHelper("unlessPlusCategory", function (cat, user, options) {
    var category = utils.resolveCompute(cat);
    var currentUser = utils.resolveCompute(user);

    if (
        category &&
        config.plus.enabled &&
        currentUser &&
        currentUser.attr("isPlus") &&
        config.plus.plusCategoryToHide(category)
    ) {
        return options.inverse();
    } else {
        return options.fn();
    }
});

can.stache.registerHelper("permalink", function (movieCompute) {
    var movie = utils.resolveCompute(movieCompute);
    return movie._permalink();
});

can.stache.registerHelper("timeToRelativeHours", function (dateCompute) {
    var date = utils.resolveCompute(dateCompute);
    if (!date) {
        return null;
    }
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    var timestamp = date.getTime();
    var timeLeft = timestamp - Date.now();
    var numberOfHours = timeLeft / 1000 / 60 / 60;
    if (numberOfHours < 1) {
        return "weniger als 1 Stunde";
    }
    var rounded = Math.round(numberOfHours);
    if (rounded === 1) {
        return rounded + " Stunde";
    }
    return rounded + " Stunden";
});

// {{useFallback prop1 prop2}} is equivalent to "return prop1 || prop2"
can.stache.registerHelper("useFallback", function (/* arguments */) {
    var args = Array.prototype.slice.call(arguments);
    var resolved;
    for (var i = 0; i < args.length; i++) {
        resolved = utils.resolveCompute(args[i]);
        if (resolved) {
            return resolved;
        }
    }
    return resolved;
});

// {{ifAbove 3 2}}
can.stache.registerHelper(
    "ifAbove",
    function (valueCompute, numberCompute, options) {
        var value = utils.resolveCompute(valueCompute);
        var number = utils.resolveCompute(numberCompute);
        if (value > number) {
            return options.fn();
        } else {
            return options.inverse();
        }
    }
);

// {{ifBelow 2 3}}
can.stache.registerHelper(
    "ifBelow",
    function (valueCompute, numberCompute, options) {
        var value = utils.resolveCompute(valueCompute);
        var number = utils.resolveCompute(numberCompute);
        if (value < number) {
            return options.fn();
        } else {
            return options.inverse();
        }
    }
);

/*
    Adapted from: http://jsfiddle.net/prometh/c71L3hzn/
    {{#switch attr}}
        {{#case "value1"}}...{{/case}}
        {{#case "value2" "value3" ... "valueN"}}...{{/case}}
        {{#default}}....{{/default}} MUST BE AT THE END!
    {{/switch}}

    Note: nested switches probably won't work.
*/

can.stache.registerHelper("switch", function (expression, options) {
    if (options.scope._switch) {
        throw new Error(
            '"switch" helper cannot be nested in other switch block!'
        );
    }
    options.scope._switch = {
        is: false,
        expression: utils.resolveCompute(expression),
    };

    var fragment = options.fn(options.scope, options.options);
    delete options.scope._switch;
    return fragment;
});

can.stache.registerHelper("case", function () {
    var containsParam = false;
    var options = Array.prototype.pop.apply(arguments);

    if (options.scope._switch) {
        for (var i = 0, numArgs = arguments.length; i < numArgs; i++) {
            if (
                utils.resolveCompute(arguments[i]) ==
                options.scope._switch.expression
            ) {
                containsParam = true;
                break;
            }
        }

        if (containsParam) {
            return options.fn(options.scope, options.options);
        }
    } else {
        throw new Error('only use "case" stache helper within switch case!');
    }
});

can.stache.registerHelper("default", function () {
    throw new Error('"default" helper is not supported!'); // avoid usage of default, might result in error that is never cached
});
