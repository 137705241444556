import BaseControl from "../../../lib/can.basecontrol.js";
import config from "../../config";
import View from "./view.stache";

export default BaseControl.extend(
    "Jugendschutz",
    {
        defaults: {
            template: View,
        },
    },
    {
        init: function (el, options) {
            this.options.viewOptions.attr("config", config);

            var kinderkinoLink =
                window.platform === "netzkino"
                    ? "#!/kinderkino"
                    : "https://www.netzkino.de/#!/kinderkino";
            this.options.viewOptions.attr("kinderkinoLink", kinderkinoLink);

            var youtubeAndTitle =
                window.platform === "netzkino" ? "Netzkino.de" : "Webseite";
            this.options.viewOptions.attr("youtubeAndTitle", youtubeAndTitle);
            return this._super.apply(this, arguments);
        },
    }
);
