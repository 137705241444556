import can from "can";
import utils from "../../../lib/utils";
import view from "./view.stache";
can.Component.extend({
    tag: "part-selector",
    template: view,
    scope: {
        partList: [],
        selected: 1, // first episode!
        selectedPart: null,
        partNumberPrefix: "",
    },
    events: {},
    helpers: {
        isSelected: function (partCompute, options) {
            var part = utils.resolveCompute(partCompute);
            var selected = this.attr("selectedPart");
            if (
                selected &&
                part.attr("identifier") === selected.attr("identifier")
            ) {
                return options.fn();
            } else {
                return options.inverse();
            }
        },
        displayDuration: function (durationCompute) {
            var duration = utils.resolveCompute(durationCompute);
            if (!duration) {
                return;
            } else {
                return duration + " Min";
            }
        },
    },
});
