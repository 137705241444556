import can from "can";
import config from "../js/config";
import state from "../js/state";
import utils from "../lib/utils";

const leftSkyscraperTag =
    window.platform === "grjngo"
        ? "/27665794/DisplayGrjngo/DisplayGrjngoWeb/DisplayGrjngoWebStartseite/DisplayGrjngoWebStartseiteSkylinks"
        : "/27665794/DisplayNetzkino/DisplayNetzkinoWebStartseite/DisplayNetzkinoWebStartseiteSkyscraperLinks";
const rightSkyscraperTag =
    window.platform === "grjngo"
        ? "/27665794/DisplayGrjngo/DisplayGrjngoWeb/DisplayGrjngoWebStartseite/DisplayGrjngoWebStartseiteSkyrechts"
        : "/27665794/DisplayNetzkino/DisplayNetzkinoWebStartseite/DisplayNetzkinoWebStartseiteSkyscraperRechts";
const billboardsTag =
    window.platform === "grjngo"
        ? "/27665794/DisplayGrjngo/DisplayGrjngoWeb/DisplayGrjngoWebStartseite/DisplayGrjngoWebStartseiteContentBillboard"
        : "/27665794/DisplayNetzkino/DisplayNetzkinoWebStartseite/DisplayNetzkinoWebStartseiteContentBillboard";

let billboards = [],
    skyscrapers = [],
    mobileSwipeAd = [],
    billboardsLoaded = false,
    skyscraperLoaded = false,
    mobileSwipeAdLoaded = false,
    isGooglePublisherTagServicesLoaded = false,
    adSlotMainpageLeftSkyscraper,
    adSlotMainpageRightSkyscraper;

let consentDeferred = new can.Deferred();

const isNetzkinoMobileWeb = window.platform === "netzkino" && utils.isMobile();

function destroyAllAds() {
    googletag.cmd.push(function () {
        if (skyscrapers.length > 0) {
            for (var i = 0; i < skyscrapers.length; i++) {
                skyscrapers[i] = googletag.destroySlots([skyscrapers[i]]);
                skyscrapers[i] = null;
            }
            skyscrapers = [];
            skyscraperLoaded = false;
        }
        if (billboards.length > 0) {
            for (var k = 0; k < billboards.length; k++) {
                billboards[k] = googletag.destroySlots([billboards[k]]);
                billboards[k] = null;
            }
            billboards = [];
            billboardsLoaded = false;
        }
        if (mobileSwipeAd.length > 0) {
            for (var l = 0; l < billboards.length; l++) {
                mobileSwipeAd[l] = googletag.destroySlots([mobileSwipeAd[l]]);
                mobileSwipeAd[l] = null;
            }
            mobileSwipeAd = [];
            mobileSwipeAdLoaded = false;
        }
        config.log("[googlePublisherTag] all Ad slots destroyed");
    });
}
function destroyBillboards() {
    googletag.cmd.push(function () {
        if (billboards.length > 0) {
            for (var i = 0; i < billboards.length; i++) {
                billboards[i] = googletag.destroySlots([billboards[i]]);
                billboards[i] = null;
            }
        }
        billboards = [];
        billboardsLoaded = false;
        config.log("[googlePublisherTag] all billboard slots destroyed");
    });
}

function shouldAllowAd() {
    return (
        (window.platform === "netzkino" || window.platform === "grjngo") &&
        !(state.currentUser && state.currentUser.isPlus)
    );
}

function loadAndRefreshBillboards() {
    // Load Billboards if they aren't loaded already
    if (billboards.length === 0) {
        // Responsive Mapping Billboard
        var billboardMapping = googletag
            .sizeMapping()
            .addSize(
                [1100, 0],
                [
                    [1100, 520],
                    [1100, 500],
                    [1100, 300],
                    [1100, 260],
                    [1024, 768],
                    [980, 120],
                    [980, 90],
                    [970, 250],
                    [970, 90],
                    [970, 66],
                    [960, 90],
                    [950, 90],
                    [930, 180],
                    [800, 250],
                    [750, 300],
                    [750, 200],
                    [750, 100],
                    [728, 90],
                ]
            )
            .addSize(
                [1000, 0],
                [
                    [1000, 250],
                    [980, 120],
                    [980, 90],
                    [970, 250],
                    [970, 90],
                    [970, 66],
                    [960, 90],
                    [950, 90],
                    [930, 180],
                    [800, 250],
                    [750, 300],
                    [750, 200],
                    [750, 100],
                    [728, 90],
                ]
            )
            .addSize(
                [800, 0],
                [
                    [800, 250],
                    [768, 1024],
                    [750, 300],
                    [750, 200],
                    [750, 100],
                    [728, 90],
                ]
            )
            .addSize([0, 0], [])
            .build();

        var billboardSizes = [
            [1100, 520],
            [1100, 500],
            [1100, 300],
            [1100, 260],
            [1024, 768],
            [1000, 250],
            [980, 120],
            [980, 90],
            [970, 250],
            [970, 90],
            [970, 66],
            [960, 90],
            [950, 90],
            [930, 180],
            [850, 250],
            [768, 1024],
            [750, 300],
            [750, 200],
            [750, 100],
            [728, 90],
        ];

        googletag.cmd.push(function () {
            // Load Billboards on the Mainpage for desktopWeb
            var maxNumberBillboards = window.platform === "grjngo" ? 3 : 4;
            for (var i = 0; i < maxNumberBillboards; i++) {
                var adSlot = googletag
                    .defineSlot(
                        billboardsTag + (i + 1),
                        billboardSizes,
                        "gpt-mainpage-billboard-" + (i + 1)
                    )
                    .setCollapseEmptyDiv(true)
                    .addService(googletag.pubads());
                adSlot.defineSizeMapping(billboardMapping);
                billboards.push(adSlot);
            }
        });
        config.log("[googlePublisherTag] billboards defined");
    }
    googletag.cmd.push(function () {
        if (!billboardsLoaded) {
            googletag.display("gpt-mainpage-billboard-1");
            googletag.display("gpt-mainpage-billboard-2");
            googletag.display("gpt-mainpage-billboard-3");
            // TODO: check programatically how many billboards can be displayed based on amount of categories
            if (window.platform !== "grjngo") {
                googletag.display("gpt-mainpage-billboard-4");
            }

            billboardsLoaded = true;
            googletag.pubads().refresh(billboards);
            config.log("[googlePublisherTag] billboards loaded");
        } else {
            // TODO this case is probably not needed anymore, since we always destroy and
            // reload the billboards (when leaving and returning to the mainpage component) in order to get lazy loading to work.
            googletag.pubads().refresh(billboards);
            config.log("[googlePublisherTag] billboards refreshed");
        }
    });
}

function loadAndRefreshMobileSwipeAd() {
    //  Load mobile swipe ad, only display the ad on every fifth page
    if (isNetzkinoMobileWeb && utils.getPageCounter() % 5 == 0) {
        if (mobileSwipeAd.length === 0) {
            googletag.cmd.push(function () {
                googletag.cmd.push(function () {
                    var mobileAdSlot = googletag
                        .defineSlot(
                            "/27763518/Weischer_Online_GmbH/po_netzkino/mobile_superbanner/mobile_superbanner_1",
                            [[480, 240]],
                            "gpt-mobile-billboard"
                        )
                        .addService(googletag.pubads());
                    mobileSwipeAd.push(mobileAdSlot);
                    config.log("[googlePublisherTag] mobileSwipeAd defined");
                });
            });
        }

        googletag.cmd.push(function () {
            if (!mobileSwipeAdLoaded) {
                googletag.display("gpt-mobile-billboard");
                mobileSwipeAdLoaded = true;
                googletag.pubads().refresh(mobileSwipeAd);
                config.log("[googlePublisherTag] mobileSwipeAd loaded");
            } else {
                googletag.pubads().refresh(mobileSwipeAd);
                config.log("[googlePublisherTag] mobilSwipeAd refreshed");
            }
        });
    }
}

function loadAndRefreshSkyscrapers() {
    // Mainpage Skyscrapers.
    // Only define slots if they aren't defined already
    if (skyscrapers.length === 0) {
        // Skyscraper Ad sizes
        var skyScraperSizes = [
            [120, 600],
            [160, 600],
            [300, 600],
            [300, 700],
            [300, 800],
            [300, 900],
            [300, 1000],
            [300, 1050],
        ];
        // Responsive Mapping Skyscrapers
        var skyScraperMapping = googletag
            .sizeMapping()
            .addSize(
                [1900, 0],
                [
                    [300, 1050],
                    [300, 1000],
                    [300, 900],
                    [300, 800],
                    [300, 700],
                    [300, 600],
                    [160, 600],
                    [120, 600],
                ]
            )
            .addSize(
                [1600, 0],
                [
                    [160, 600],
                    [120, 600],
                ]
            )
            .addSize([0, 0], [])
            .build();
        // Mainpage Skyscraper Left
        adSlotMainpageLeftSkyscraper = googletag
            .defineSlot(
                leftSkyscraperTag,
                skyScraperSizes,
                "gpt-mainpage-skyscraper-left"
            )
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads());

        adSlotMainpageLeftSkyscraper.defineSizeMapping(skyScraperMapping);

        // Mainpage Skyscraper Right
        adSlotMainpageRightSkyscraper = googletag
            .defineSlot(
                rightSkyscraperTag,
                skyScraperSizes,
                "gpt-mainpage-skyscraper-right"
            )
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads());

        adSlotMainpageRightSkyscraper.defineSizeMapping(skyScraperMapping);

        // Keep reference lists for the respective Adslots in order to clean them up if neccessary
        skyscrapers.push(
            adSlotMainpageLeftSkyscraper,
            adSlotMainpageRightSkyscraper
        );
        config.log("[googlePublisherTag] skyscrapers defined");
    }
    googletag.cmd.push(function () {
        if (!skyscraperLoaded) {
            googletag.display("gpt-mainpage-skyscraper-left");
            googletag.display("gpt-mainpage-skyscraper-right");

            skyscraperLoaded = true;
            googletag.pubads().refresh(skyscrapers);
            config.log("[googlePublisherTag] skyscrapers loaded");
        } else {
            googletag.pubads().refresh(skyscrapers);
            config.log("[googlePublisherTag] skyscrapers refreshed");
        }
    });
}
function loadServices() {
    googletag.cmd.push(function () {
        config.log("[googlePublisherTag] services loaded");

        // Register event handlers to observe lazy loading behavior.
        if (!isGooglePublisherTagServicesLoaded) {
            // only enable lazy load for desktop web
            if (!isNetzkinoMobileWeb) {
                // Enable Lazy-Loading with defaults.
                googletag.pubads().enableLazyLoad({
                    // Fetch slots within 1 viewports.
                    fetchMarginPercent: 100,
                    // Render slots within 1 viewports.
                    renderMarginPercent: 100,
                    // We dot not scale the values up for mobile
                    mobileScaling: 1.0,
                });
            }
            googletag
                .pubads()
                .addEventListener("slotRequested", function (event) {
                    config.log(
                        "[googlePublisherTag] slot requested",
                        event.slot.getSlotElementId()
                    );
                });

            googletag.pubads().addEventListener("slotOnload", function (event) {
                config.log(
                    "[googlePublisherTag] slot rendered",
                    event.slot.getSlotElementId()
                );
            });
            isGooglePublisherTagServicesLoaded = true;
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
        }
    });
}

// Apparently either Googles GTP does not wait for the CMP to be loaded or our CMP does still have issues with responding fast enough.
// As a work around we always check if tcData is available before initializing the GPT script
function initGPT() {
    if (shouldAllowAd()) {
        config.log("[googlePublisherTag] preparing gtp-script load");

        // eslint-disable-next-line no-inner-declarations
        function loadGPTScript() {
            var googleScript =
                "https://securepubads.g.doubleclick.net/tag/js/gpt.js";

            window.googletag = window.googletag || {
                cmd: [],
            };
            can.$.getScript(googleScript).then(() => {
                loadServices();
                // resolve the deferred for ad calls when the google api is ready and resolve the queue (google.cmd)
                consentDeferred.resolve();
                // Skyscrapers and mobile swipe ads are loaded on init since there respective divs already exist at the point of calling the init function
                loadAndRefreshAds("skyscraper");
                loadAndRefreshAds("mobile-swipe-ad");
            });
        }
        loadGPTScript();
    }
}

function loadAndRefreshAds(adType) {
    var allow = shouldAllowAd();
    if (!allow && (skyscrapers.length > 0 || billboards.length > 0)) {
        destroyAllAds();
    } else if (allow) {
        can.defer(function () {
            googletag.cmd.push(function () {
                if (adType === "skyscraper") {
                    loadAndRefreshSkyscrapers();
                } else if (adType === "billboard") {
                    loadAndRefreshBillboards();
                } else if (adType === "mobile-swipe-ad") {
                    loadAndRefreshMobileSwipeAd();
                }
            });
        });
    }
}

export default {
    init: function () {
        initGPT();
    },
    loadAndRefreshSkyscrapers: function () {
        consentDeferred.then(function () {
            loadAndRefreshAds("skyscraper");
        }, console.error.bind(
            console,
            "Error occurred while trying to load GPT skyscrapers"
        ));
    },
    loadAndRefreshBillboards: function () {
        consentDeferred.then(function () {
            // We set a timeout to wait for rendering the billboard divs,
            // in order to support googles lazy loading based on viewport settings
            setTimeout(function () {
                loadAndRefreshAds("billboard");
            }, 1000);
        }, console.error.bind(
            console,
            "Error occurred while trying to load GPT billboards"
        ));
    },
    loadAndRefreshMobileSwipeAd: function () {
        consentDeferred.then(function () {
            loadAndRefreshAds("mobile-swipe-ad");
        }, console.error.bind(
            console,
            "Error occurred while trying to load GPT mobile swipe ad"
        ));
    },
    destroyBillboards: function () {
        consentDeferred.then(function () {
            destroyBillboards();
        }, console.error.bind(
            console,
            "Error occurred while trying to destroy Billboard-Ads"
        ));
    },
};
