import can from "can";
import config from "../../config";
import state from "../../state";
import viewHeader from "./view.stache";
can.Component.extend({
    tag: "netzkino-header",
    template: viewHeader,
    scope: {
        state: state,
    },
    events: {
        init: function (el, options) {
            var self = this;

            // add the isKixi-scope for conditional rendering of the fragFinn-Button
            self.options.scope.attr("isKixi", window.platform === "kixi");
            // add the isLoveisawonder-scope for conditional rendering of the register button
            self.options.scope.attr(
                "isLoveisawonder",
                window.platform === "loveisawonder"
            );

            // we do not want to show the plus category in genre dropdown,
            // but need to keep it in index.categories
            // TODO: is this still true? We *could* remove NetzkinoPlus from index-category in the API as it is not used as a top level category...
            state.defs.indexLoader.then(function () {
                var categories = state.data.index.categories;
                var filterDeferred = can.Deferred();
                if (window.platform === "netzkino") {
                    filterDeferred.resolve(
                        categories.filter(function (category) {
                            return !config.plus.isPlusCategory(category);
                        })
                    );
                } else if (window.platform === "loveisawonder") {
                    state.currentUserPromise.always(function (user) {
                        // some categories should not be displayed on the landingpage (not-logged in) but be shown on the frontpage (logged in)
                        filterDeferred.resolve(
                            categories.filter(function (category) {
                                if (user && user.isPlus) {
                                    return true;
                                }
                                return (
                                    config.plus.excludedHomepageCategoriesLanding.indexOf(
                                        category.id
                                    ) < 0
                                );
                            })
                        );
                    });
                } else {
                    /* else if(window.platform === 'kixi') {
                                            filterDeferred.resolve(can.$.map(categories, function(cat) {
                                                if(cat.slug === 'audio') {
                                                    return cat;
                                                }
                                                var allPostsCategory = state.data.categoriesById[cat.id * -1];
                                                var dummyCategory = can.extend({}, allPostsCategory.serialize(), { title: cat.title });
                                                return dummyCategory;
                                            }));
                                        } */
                    filterDeferred.resolve(categories);
                }
                filterDeferred.then(function (genreCategories) {
                    self.options.scope.attr("genreCategories", genreCategories);
                }, console.error.bind(
                    console,
                    "Error occurred while filtering which categories should be shown"
                ));
            });

            self.options.scope.attr(
                "enableSearch",
                window.platform !== "loveisawonder"
            ); // disable search for non plus users on liaw
            self.options.scope.attr("isPlus", false);

            state.currentUserPromise.then(
                function userResolved(user) {
                    can.batch.start();
                    if (user.isPlus) {
                        self.options.scope.attr("isPlus", true);
                        self.options.scope.attr("enableSearch", true);
                    } else {
                        self.options.scope.attr("isPlus", false);
                    }
                    self.options.scope.attr(
                        "shouldUsePaywallPlusCategoryLinks",
                        config.plus.paywall.enabled && !user.isPlus
                    );
                    can.batch.stop();
                },
                function handleMissingUser() {
                    self.options.scope.attr(
                        "shouldUsePaywallPlusCategoryLinks",
                        config.plus.paywall.enabled
                    );
                }
            );
            // Apparently a timeout is needed to access the Html-Elements..
            setTimeout(function () {
                can.$("#collapsable-search").on(
                    "shown.bs.collapse",
                    function () {
                        can.$(arguments[0].target).find("input").focus();
                    }
                );
                // The default bootstrap navbar collaps happens when < 768px.
                // We customize this value for Kixi since the navigation includes more items then on the other platforms.
                if (window.platform === "kixi") {
                    can.addClass(can.$("#top"), "kixi-navbar");
                }
            }, 1);
        },
        "shown.bs.collapse": function ($el, ev) {
            can.$(".navbar-header").addClass("open");
        },
        "hide.bs.collapse": function ($el, ev) {
            can.$(".navbar-header").removeClass("open");
        },
        "a[role=menuitem] click": function ($el, ev) {
            can.$(".bs-navbar-collapse.in").collapse("hide");
        },
    },
    scope: {
        navigateToMy: function () {
            var currentRoute = can.route.attr();
            if (
                currentRoute &&
                currentRoute.control === "my" &&
                !currentRoute.id
            ) {
                // my is also the payment page
                window.location.reload();
            } else {
                this.state.goTo("my");
            }
            can.$(".bs-navbar-collapse.in").collapse("hide");
        },
        navigateToRegister: function () {
            this.state.goTo("my", "register");
            can.$(".bs-navbar-collapse.in").collapse("hide");
        },
        navigateToSmartlogin: function () {
            var currentRoute = can.route.attr();
            if (currentRoute && currentRoute.control === "smartlogin") {
                window.location.reload();
            } else {
                this.state.goTo("smartlogin");
            }
            can.$(".bs-navbar-collapse.in").collapse("hide");
        },
        search: function (context, $el, ev) {
            var s = "",
                $inputElement = $el; // assume direct input
            if ($el.is("input")) {
                // this came directly from the input element (can-enter)
                s = $el.val();
            } else {
                // this was submitted by clicking on "Suche" or the search-icon
                $inputElement = $el.parent().find("input"); // correct assumption
                s = $inputElement.val();
            }

            // we only want to navigate if we have sth to search for
            // empty string not working well
            if (s.length <= 0) {
                return;
            }

            can.$("#collapsable-search").collapse("hide");
            $inputElement.val("");

            can.route.attr({}, true);
            can.route.attr(
                {
                    control: "suche",
                    id: s,
                },
                true
            );
            can.$(".bs-navbar-collapse.in").collapse("hide");
        },
        config: config,
    },
});
