{{#if isLoveisawonder}}
    <notification-component></notification-component>
{{/if}}
{{^if state.hideHeader}}
    <netzkino-header state="{state}"></netzkino-header>
{{/if}}
<!-- add skyscrapers to base stache in order to keep them on every page -->
<generic-ad identifier="googlePublisherTag-mainpage-skyscraper-left"></generic-ad>
<generic-ad identifier="googlePublisherTag-mainpage-skyscraper-right"></generic-ad>
<netzkino-content></netzkino-content>
<!-- Mobile Swipe Ad. Will position at the bottom of the currently viewable screen.
This slot will only be filled on mobile devices (since we wont have enough space to load the regular billboards) -->
<generic-ad identifier="googlePublisherTag-mainpage-mobile-billboard"></generic-ad>
<netzkino-footer></netzkino-footer>
<generic-ad identifier="vidoomy"></generic-ad>
