<footer>
  {{#unless state.currentUser.isPlus}}
    <div class="container benefit-container">
        <div class="row">
          <div class="col-xs-12">
            <h3>Pädagogisch wertvolle Kinderfilme, Hörspiele, Serien und Kinderlieder</h3>
            <p>
              Kixi ist Deutschlands führender Streamingdienst für pädagogisch wertvolle Kinderfilme und Jugendfilme, Kinderserien und Audioprogramme für Kinder.
              Zu den Kinderfilmen gehören Spielfilme für Kinder, Kinderfilm-Klassiker, Deutscher Kinderfilm, Kinderkino, Zeichentrickfilme für Kinder, Kinder-Cartoons und vor allem auch Bildungsprogramme für Kinder (Kinder-Lehrfilme, Lernfilme, Lernvideos, Schulfilme, Erklärfilme für Kinder, Ratgebervideos für Kinder und Unterrichtsmedien).
              Zu unsere Kinderserien gehören Kinderserien-Klassiker sowie neue Zeichentrickserien, Lehrserien / Lernserien und Bildungsserien für Kinder.
              Zu den Audioprogrammen gehören Hörspiele für Kinder, Audiobücher (Audiobooks) für Kinder, Kinderlieder und vieles mehr.
              Das besondere an Kixi ist, dass wir uns auf pädagogisch wertvolle Kinderprogramme spezialisiert haben.
              Bei Kixi gibt es kaum "Mainstream" sondern primär die "guten" Kindermedien, herausgesucht und empfohlen von unserer Kixi-Redaktion.
            </p>
          </div>
        </div>
        <div class="row benefits">
            <div class="col-xs-12">
                <ul style="padding: 0px;">
                    <li class="benefit-element">
                        <div>
                            <img src="{{imagePath 'webseite/plus/icon_nospam.png'}}" class="img-responsive" />
                        </div>
                        <h4>Werbefrei</h4>
                    </li>
                    <li class="benefit-element">
                        <div>
                            <img src="{{imagePath 'webseite/plus/icon_lock.png'}}" class="img-responsive" />
                        </div>
                        <h4>Sicher &amp; legal</h4>
                    </li>
                    <li class="benefit-element">
                        <div>
                            <img src="{{imagePath 'webseite/plus/icon_pencil.png'}}" class="img-responsive" />
                        </div>
                        <h4>Pädagogisch wertvoll</h4>
                    </li>
                    {{^if state.isHospital}}
                        <li class="benefit-element">
                            <div>
                                <img src="{{imagePath 'webseite/plus/icon_pig.png'}}" class="img-responsive" />
                            </div>
                            <h4>Für nur {{config.plus.price}}</h4>
                        </li>
                    {{/if}}
                </ul>
            </div>
        </div>
        {{^if state.isHospital}}
            <div class="row">
                <div class="col-xs-12 text-center">
                    <a href="#!/my/payment" alt="Jetzt abonnieren" class="register-now"></a>
                </div>
            </div>
        {{else}}
            {{! SPACING ELEMENT }}
            <div style="height: 80px"></div>
        {{/if}}
    </div>
  {{/unless}}

  <div class="container" style="padding-left: 30px;padding-right: 30px; text-align: center;">
    {{#if config.footer.buttons.show}}
        <div class="row">
          <div class="col-xs-12 appstore-buttons">
            {{#each config.footer.buttons.buttons}}
                <a target="_blank" href="{{link}}">
                  <img src="{{imagePath img}}" />
                </a>
            {{/each}}
          </div>
        </div>
    {{/if}}
    <div class="row">
      <div class="col-xs-12">
        <ul>
            {{#each config.footer.links}}
                <li>
                    <!-- Add custom link for Ogury CMP Policy -->
                    {{#isEqual label 'Cookie Policy'}}
                        <a can-click="editOgury" {{#if blank}}target="_blank"{{/if}} style="cursor:pointer;">{{label}}</a>
                         {{#if config.footer.copyright}}
                          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                        {{else}}
                          {{#unlessLast @index config.footer.links.length}}
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                          {{/unlessLast}}
                        {{/if}}
                    <!-- Regular Footer Links-->
                    {{/else}}
                        <a href="{{link}}" {{#if blank}}target="_blank"{{/if}}>{{label}}</a>
                        {{#if config.footer.copyright}}
                          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                        {{else}}
                          {{#unlessLast @index config.footer.links.length}}
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                          {{/unlessLast}}
                        {{/if}}
                    {{/isEqual}}
                </li>
            {{/each}}
          <li>
            {{#if config.footer.copyrightLink}}
              <a target='_blank' href={{config.footer.copyrightLink}}>{{config.footer.copyright}}</a>
            {{else}}
              {{config.footer.copyright}}
            {{/if}}
          </li>
        </ul>
      </div>
    </div>
    {{#if config.footer.info}}
    <div class="row">
      <div class="col-xs-12">
        <p>
          {{config.footer.info}}
        </p>
      </div>
    </div>
    {{/if}}
    {{#config.footer.social.show}}
    <div class="row" style="margin-top: 40px;padding-bottom: 40px;">
      <div class="col-xs-12 col-md-6 vcenter left-social-wrap">
        {{#each config.footer.social.images}}
          <a href="{{link}}" target="_blank" class="image-item">
            <img src="{{src}}" alt="{{alt}}" title="{{alt}}" class="vcenter" />
          </a>
        {{/each}}
      </div><!--
      --><div class="col-xs-12 col-md-6 vcenter right-social-wrap">
        {{#each config.socialBar.links}}
          <a href="{{link}}" alt="{{label}}" title="{{label}}" class="social-item" >
            <img src="{{imagePath imageWhite}}" />
          </a>
        {{/each}}
      </div>
    </div>
    {{/config.footer.social.show}}
  </div>
</footer>
{{#cookieNotAccepted}}
  <div class="cookie-notification" style="display: block;">
      <div class="container">
          <div class="row">
              <div class="col-sm-10"><p>Diese Webseite verwendet Cookies. Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben. Sie geben Einwilligung zu unseren Cookies, wenn Sie unsere Webseite weiterhin nutzen. ( <a href="#!/datenschutz">Zur Datenschutzvereinbarung</a> )</p></div>
              <div class="col-sm-2"><a class="btn-cookie js-btn-cookie" href="#">Einverstanden</a></div>
          </div>
      </div>
  </div>
{{/cookieNotAccepted}}
