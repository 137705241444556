<div class="navPlaceholder"></div>

<netzkino-title-social-bar cat="Impressum" show-text="false"></netzkino-title-social-bar>

<div class="container content-page">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <p>
                <a href="https://www.kixi-entertainment.com/">Kixi Entertainment GmbH</a> <br>
                August-Bebel-Strasse 27 <br>
				D-14482 Potsdam <br>
				Tel.: 0331-23220021 (Mo.-Fr.: 09:30-16:00) <br>
				<br>
				<a href="mailto:info@kixi.de">info@kixi.de</a> <br>
				<a href="https://www.kixi-entertainment.com/">https://www.kixi-entertainment.com/</a> <br>
				<br>
                Geschäftsführer: Peter von Ondarza <br>
                Handelsregister: Amtsgericht Potsdam HRB 30193 P <br>
				Ust-ID: DE279928007 <br>
                <br>
                Die Kixi Entertainment GmbH ist Mitglied der Unterhaltungssoftware Selbstkontrolle <br>
                <br>
                <a href="http://www.usk.de/Mitglieder" target="_blank"><img class="alignnone  wp-image-404501" alt="USK_Logo_kurz_RGB" src="{{imagePath 'webseite/usk_guetesiegel.png'}}" width="230" height="125" /></a><br>
                <br>
                Jugendschutzbeauftragter <br>
                USK Unterhaltungssoftware Selbstkontrolle <br>
                Torstr. 6, 10119 Berlin <br>
                <a href="http://www.usk.de/" target="_blank">www.usk.de</a> | <a title="Zum Kontaktformular" href=" http://www.usk.de/Jugendschutzbeauftragter" target="_blank">Zum Kontaktformular</a>
            </p>
        </div>
    </div>
</div>
