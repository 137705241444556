import can from "can";
import $ from "jquery";
import "jquery-lazyload";
import config from "../config";

var els = [],
    _lazyrefresh = function (el, options) {
        config.warn("lazyrefresh!", el);
        $("img[data-original], .lazy", $(el)).lazyload(options || {});
    },
    lazyrefresh = can.debounce(_lazyrefresh, 500);

// loader.onLoad(lazyrefresh);
// can.Component.extend({
//     tag: 'img-lazy',
//     scope: {
//         'class': '@',
//         'src': '@'
//     },
//     template: '<img class="{{class}}" data-original="{{src}}"/>',
//     events: {
//         init: function(el, options) {
//             els.push.apply(els, el);
//             refresh();
//         }
//     }
// });
can.stache.registerHelper("lazyImage", function () {
    return function (el) {
        // els.push.apply(els, el);
        lazyrefresh($(el).parent());
    };
});
export default _lazyrefresh;
