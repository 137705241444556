import { nanoid } from 'nanoid'
import BaseControl from '../../../lib/can.basecontrol.js'
import config from '../../config.js'
import User from '../../models/user.js'
import API from '../../api.js'
import View from './view.stache'

var GENERATED_ACCOUNT_SUFFIX = 'generated.smpl.services'
var marketingSource = 'netopsie'

export default BaseControl.extend(
  'HospitalLandingPage',
  {
    defaults: {
      template: View,
    },
  },
  {
    init: function () {
      let errorPopupTimeout
      function showError(errorText) {
        const errorPopup = document.getElementById('hospitalLandingPageError')
        errorPopup.textContent = errorText

        // Show the error message
        errorPopup.style.display = 'block'

        if (errorPopupTimeout) {
          // If a timeout is already set (error popup is displayed), clear it
          clearTimeout(errorPopupTimeout)
        }

        // Set a new timeout to hide the error message after 2 seconds
        errorPopupTimeout = setTimeout(function () {
          errorPopup.style.opacity = 0
          setTimeout(function () {
            errorPopup.style.display = 'none'
            errorPopup.style.opacity = 1
          }, 500) // Delay for the fade-out animation
        }, 2000) // 2 seconds
      }

      var state = this.options.state
      this.options.viewOptions.attr({
        state: state,
        config: config,
        initNetopsieLogin: function () {
          try {
            var id = nanoid(10)
            var password = nanoid(12)
            var username = `${marketingSource}-${id}@${GENERATED_ACCOUNT_SUFFIX}`
            // User.register receives the following variables -> username, password, email, name
            User.register(username, password).then(
              // success
              function () {
                console.log('user register successful, starting login')
                API.login(username, password, { disableRedirect: true }).then(
                  function () {
                    console.log('user login successful')
                    state.currentUserPromise = User.getCurrentUser(true)
                    setTimeout(() => {
                      state.goTo('mainpage')
                      setTimeout(() => {
                        window.location.reload()
                      }, 10)
                    }, 500)
                  },
                  function (err) {
                    console.error('user login failed', err)
                    showError(
                      'Es tut uns leid, aber ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
                    )
                  }
                )
              },
              // failure
              function (err) {
                console.error('user register failed', err)
                showError(
                  'Es tut uns leid, aber ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
                )
              }
            )
          } catch (err) {
            console.error(err)
          }
        },
      })

      return this._super.apply(this, arguments)
    },
  }
)
