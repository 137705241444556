<div class="paymentPanel">
    {{!no switch case yet in canJS 2.1}}
    {{#isEqual paymentState 'information'}}
        {{! we need to handle .goToOffer click, because we cannot pass down callback functions without maximum call stack exceptions }}
        <plus-information />
    {{/isEqual}}

    {{#isEqual paymentState 'getProducts'}}
        <div class="choose-method-wrap text-center">
            <p>Lade ...</p>

            <button type="submit" class="btn btn-big btn-dark" can-click="resetProgress" style="width: 100%; margin-top: 25px;">Zurück</button>
        </div>
    {{/isEqual}}

    {{#isEqual paymentState 'chooseType'}}
        <div class="choose-method-wrap">
            <div class="row">
                <div class="col-md-12 chooseType">
                    <h2 class="text-center">Bitte wähle eine Zahlungsmethode</h2>
                    <br />
                    <br />
                    <div class="chooseType-container">
                        {{#each supportedTypes}}
                            <div class="paymentType" can-click="confirmType">
                                <!-- must be span, canJS does not seem to parse divs here -->
                                <span class="paymentMethodImg {{#isEqual type selectedTypeTemp}}selectedPaymentType{{/isEqual}}">
                                    <img alt="{{type}}" src="{{image}}" class="img-responsive vcenter" ></img>
                                </span>
                            </div>
                        {{/each}}
                    </div>
                    <br />
                    <br />

                    <p class="footnote" style="text-align: center; font-size: 18px; margin-top: 25px;">{{{config.plus.packages.typeSelectionfootnoteHTML}}}</p>
                </div>
            </div>
       </div>
    {{/isEqual}}

    {{#isEqual paymentState 'choosePackage'}}
        <div class="choose-method-wrap">
            <div class="row">
                <div class="col-md-12 chooseType" style="position: relative">
                    <button class="btn btn-big btn-default paymentBackButton" can-click="resetToTypeSelection">Zurück</button>

                    <h2 class="text-center">Jetzt Laufzeit wählen</h2>
                    <br />
                    <br />
                    <div class="chooseType-container">
                        {{#each packages}}
                            <div class="paymentType" can-click="confirmPackage">
                                <!-- must be span, canJS does not seem to parse divs here -->
                                <span class="paymentMethodImg {{#isEqual . selectedPackageTemp}}selectedPaymentType{{/isEqual}}">
                                    <img alt="{{type}}" src="{{image}}" class="img-responsive vcenter" ></img>
                                </span>
                            </div>
                        {{/each}}
                    </div>
                    <br />
                    <br />
                        <p class="footnote" style="text-align: center; font-size: 18px; margin-top: 25px;">{{{config.plus.packages.packagesFootnoteHTML}}}</p>
                </div>
            </div>
       </div>
    {{/isEqual}}

    {{#isEqual paymentState 'choosePayment'}}
        <div class="choose-method-wrap">
            <div class="row">
                <div class="col-md-12 chooseType" style="position: relative;">
                    {{#if config.plus.supportsPackages}}
                        {{#if selectedPackage}}
                            <button class="btn btn-big btn-default paymentBackButton" can-click="resetToPackageSelection">Zurück</button>
                        {{else}}
                            <button class="btn btn-big btn-default paymentBackButton" can-click="resetToTypeSelection">Zurück</button>
                        {{/if}}
                    {{/if}}
                    {{#if selectedPackage}}
                        <h2 class="text-center">Wähle im nächsten Schritt Deine Zahlungsmethode aus</h2>
                    {{else}}
                        <h2 class="text-center">Bitte wähle eine Zahlungsmethode</h2>
                    {{/if}}
                    <br />
                    <br />
                    <div class="chooseType-container">
                        {{#each paymentOptionsForSelectedType}}
                            {{#if isPaypalSupported}}
                                {{#isEqual paymentMethod.paymentProvider "paypal"}}
                                    {{#if isPaypalScriptLoaded}}
                                        <div class="payment-method-tile">
                                                <div style="height: 100%; display: flex; justify-content: center; align-items: center;">
                                                    <img style="width: 60px; height: 60px;" src="https://pmd.bilder.netzkino.de/bilder/payment/paypal.png">
                                                </div>
                                                <div><div id="paypal_{{id}}"></div></div>
                                        </div>
                                        {{ initPaypal .}}
                                    {{/if}}
                                {{else}}
                                    {{#isEqual paymentMethod.paymentProvider "stripe"}}
                                                <div class="payment-method-tile">
                                                    {{#isEqual type "PACKAGE"}}
                                                        <div style="height: 100%; display: flex; justify-content: center; flex-direction: column; width: 90%;">
                                                            <div style="width: 100%; display: flex; justify-content: space-around;">
                                                                <div>
                                                                    <img style="width: 50px; height: 50px;" src="https://pmd.bilder.netzkino.de/bilder/payment/visa.png">
                                                                </div>
                                                                <div>
                                                                    <img style="width: 50px; height: 50px;" src="https://pmd.bilder.netzkino.de/bilder/payment/mastercard2.png">
                                                                </div>
                                                                <div>
                                                                    <img style="width: 50px; height: 50px;" src="https://pmd.bilder.netzkino.de/bilder/payment/american_express.png">
                                                                </div>
                                                            </div>
                                                            <div style="width: 100%; display: flex; justify-content: space-around;">
                                                                <div>
                                                                    <img style="width: 40px; height: 40px;" src="https://pmd.bilder.netzkino.de/bilder/payment/giropay.svg">
                                                                </div>
                                                                 <div style="width: 40px; height: 40px;">
                                                                    <!-- placeholder div -->
                                                                </div>
                                                                 <div style="width: 40px; height: 40px;">
                                                                 <!-- placeholder div -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    {{else}}
                                                        <div class="paymentStribeAbo">
                                                            <div class="paymentStribeAboIcons">
                                                                <div>
                                                                    <img style="width: 50px; height: 50px;" src="https://pmd.bilder.netzkino.de/bilder/payment/visa.png">
                                                                </div>
                                                                <div>
                                                                    <img style="width: 50px; height: 50px;" src="https://pmd.bilder.netzkino.de/bilder/payment/mastercard2.png">
                                                                </div>
                                                                <div>
                                                                    <img style="width: 50px; height: 50px;" src="https://pmd.bilder.netzkino.de/bilder/payment/american_express.png">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    {{/isEqual}}
                                                    <div class="paymentStribeButtonContainer">
                                                        <button type="button" class="stripe-button" id="stripe_{{id}}" >Jetzt zahlen</button>
                                                    </div>

                                                </div>
                                                <!-- Extra Tile for potentially supporting GooglePay/ApplePay if enabled for the current Browser -->
                                                <div class="payment-method-tile" id="stripe_payment_request_button_container_{{id}}" style="display:none">
                                                    <div style="height: 100%; display: flex; justify-content: center; flex-direction: column; align-items: center;">
                                                        <div style="height: 80%; display: flex; justify-content: center; align-items: center;" id="stripe_payment_request_img_container_{{id}}">
                                                        </div>
                                                        <div class="stripe-payment-request-button" id="stripe_payment_request_button_{{id}}"></div>
                                                    </div>
                                                </div>
                                                {{ initStripe .}}
                                    {{else}}
                                        <div class="paymentType" can-click="orderProduct">
                                            <!-- must be span, canJS does not seem to parse divs here -->
                                            <span class="paymentMethodImg {{#isEqual . selectedPackageTemp}}selectedPaymentType{{/isEqual}}">
                                                <img alt="Zahlungsmethode: {{name}}" src="{{useFallback paymentMethod.image image}}" class="img-responsive vcenter" ></img>
                                            </span>
                                        </div>
                                    {{ /isEqual}}
                                {{/isEqual}}
                            {{else}}
                                <div class="paymentType" can-click="orderProduct">
                                    <!-- must be span, canJS does not seem to parse divs here -->
                                    <span class="paymentMethodImg {{#isEqual . selectedPackageTemp}}selectedPaymentType{{/isEqual}}">
                                        <img alt="Zahlungsmethode: {{name}}" src="{{useFallback paymentMethod.image image}}" class="img-responsive vcenter" ></img>
                                    </span>
                                </div>
                            {{/if}}

                        {{/each}}
                        <!-- Paypal Integration -->


                    </div>
                    <br />
                    <br />

                        <p id="paymentFootnote" class="footnote" style="text-align: center; font-size: 18px; margin-top: 25px;">
                            {{#if selectedPackage}}
                                Bei der Option Paketkauf bestellst Du einmalig ein auf {{{formatValidFor selectedPackage.validFor}}} befristetes {{config.plus.title}} Abonnement für {{{formatPrice selectedPackage.price}}} (inkl. gesetzlicher MwSt), welches sich nicht verlängert.
                            {{else}}
                                {{#if isLiawFreeTrial}}
                                    {{{config.plus.freeTrialFootnoteHTML}}}
                                {{else}}
                                    {{{config.plus.paymentFootnoteHTML}}}
                                {{/if}}
                            {{/if}}
                        </p>
                </div>
            </div>
       </div>
    {{/isEqual}}

    {{#isEqual paymentState 'initialized'}}
        <div class="choose-method-wrap text-center">
            <h2>Bezahlvorgang gestartet...</h2>
            <p>Du wirst zum Zahlungsanbieter weitergeleitet ...</p>
        </div>
        <p>{{!actually this should never be present due to navigation to payment service}}</p>
    {{/isEqual}}

    {{#isEqual paymentState 'submitPaymentMethod'}}
        <div class="choose-method-wrap text-center">
            <h2>Bezahlvorgang gestartet...</h2>
            <p>Bearbeitung wurde gestartet... Einen Moment Geduld, du wirst gleich weitergeleitet.</p>
        </div>
        <p>{{!actually this should never be present due to navigation to payment service}}</p>
    {{/isEqual}}

    {{#isEqual paymentState 'pending'}}
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <br />
        <p class="text-center">Bitte warten während der Bezahlvorgang verifiziert wird.</p>
    {{/isEqual}}

    {{#isEqual paymentState 'completed'}}
        {{!This is a valid subscription, do not show anything ... for now ...}}
    {{/isEqual}}
</div>
