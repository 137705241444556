import can from "can";
import config from "../js/config";
import state from "../js/state";
import publisherTag from "./googlePublisherTag";
import vidoomy from "./vidoomy";

function shouldAllowAd() {
    return (
        window.platform === "netzkino" &&
        !(state.currentUser && state.currentUser.isPlus)
    );
}

function wrap(fn) {
    try {
        return fn();
    } catch (e) {
        console.error("error while initializing ad", e);
    }
}

export default {
    init: function (what) {
        // "pageload" | "indexload"
        if (shouldAllowAd()) {
            if (what === "pageload") {
                // wrap(function () {
                //     publisherTag.init();
                // });
            } else if (what === "indexload") {
                // Load GPT script on indexload
                wrap(function () {
                    publisherTag.init();
                });
                if (config.ads.isAllowed("vidoomy")) {
                    wrap(function () {
                        vidoomy.init();
                    });
                }
            }
        }
    },
    refresh: function () {
        var allow = shouldAllowAd();
        if (allow) {
            can.defer(function () {
                if (window.googletag) {
                    wrap(function () {
                        googletag.cmd.push(function () {
                            publisherTag.loadAndRefreshSkyscrapers();
                            publisherTag.loadAndRefreshMobileSwipeAd();
                        });
                    });
                }

                if (config.ads.isAllowed("vidoomy")) {
                    wrap(function () {
                        vidoomy.refresh();
                    });
                }
            });
            console.log("[externalAdvertisers] refresh");
        }
        // wrap(function () {
        //     publisherTag.refresh();
        // });
    },
};
