import can from "can";
import Movie from "./movie";

export default Movie.extend(
    "AudioContent",
    {
        // not called audio because CanJS throws a warning that "Audio" is already
    },
    {
        type: "audioContent",
        partList: can.compute(function () {
            var trackData = this.attr("custom_fields.trackData");

            return can.$.map(trackData, function (track) {
                // track is a can.Construct, not sure why not for filter
                return {
                    title: track.attr("name"),
                    duration: track.attr("length"),
                    description: null,
                    number: track.attr("track"),
                    identifier: track.attr("track"),
                    source: track.attr("url"), // not fully qualified
                };
            });
        }),
        getPartForPartNumber: function (partNumber) {
            return this.attr("partList").filter(function (part) {
                // plain JS Object
                return part.number === partNumber;
            })[0];
        },
        getSourceForPartNumber: function getSourceForPartNumber(partNumber) {
            var activePart = this.getPartForPartNumber(partNumber);
            if (activePart) {
                return activePart.source;
            } else {
                return null;
            }
        },
    }
);
