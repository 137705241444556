import can from "can";
import config from "../../config";
import view from "./view.stache";
function verifyTime(t, def) {
    var _isNaN = Number.isNaN || isNaN;
    if (!t || t.length < 1 || isNaN(Number(t)) || t < 0) {
        return Number(def);
    } else {
        return Number(t);
    }
}

can.Component.extend({
    tag: "html5-video-preview",
    template: view,
    scope: {
        videoUrl: "@",
        poster: "@",
    },
    events: {
        inserted: function (el, ev) {
            this.$video = el.find("video:first");
            this.video = this.$video[0];
            config.log(
                "[html5-video-preview] initializing video",
                this.videoUrl,
                el
            );
            this.$video.on(
                "ended",
                function endHandler(params) {
                    config.log(
                        "[html5-video-preview] video ended",
                        this.videoUrl
                    );
                    can.trigger(el, "requestNext");
                }.bind(this)
            );
        },
        removed: function () {},
        " forceVisible": function () {
            this.video.play();
        },
        " forceInvisible": function () {
            this.video.pause();
            this.video.currentTime = 0; // seek to the start
        },
    },
});
