export default {
    general: {
        support: "info@amogo.de",
        privacy: "info@amogo.de",
        company: "Amogo Networx UG (haftungsbeschränkt)",
        companyShort: "Amogo",
        newsletter: "info@amogo.de",
        newsletterUnsubscribe: "info@amogo.de",
        websiteShort: window.location.hostname.replace("www.", ""),
        address:
            "Amogo Networx UG (haftungsbeschränkt)<br />" +
            "August-Bebel-Straße 27<br />" +
            "14482 Potsdam<br />" +
            "Telefon: 0331-23220021<br />" +
            'E-Mail: <a href="mailto:info@amogo.de">info@amogo.de</a><br />',
        privacyContact:
            "Amogo Networx UG (haftungsbeschränkt)<br />" +
            "Peter von Ondarza<br />" +
            '<a href="mailto:info@amogo.de">info@amogo.de</a><br />' +
            "August-Bebel-Straße 27<br />" +
            "14482 Potsdam",
    },
    paths: {
        images: {
            base:
                process.env.NODE_ENV === "production"
                    ? "website/latest/dist/imgs/" + window.platform + "/"
                    : "dist/imgs/" + window.platform + "/", // trailing slash!
        },
        api: {
            base:
                process.env.NODE_ENV === "production"
                    ? "https://api-amogo-de.simplecache.net/capi-2.0a"
                    : "https://amogo-tapi.herokuapp.com/capi-2.0a",
            baseParams: {},
        },
    },
    categoryTree: {
        enabled: false,
    },
    my: {
        enabled: false,
        smartLogin: {
            enabled: false,
            showHeaderButton: false,
            showMyButton: false,
        },
    },
    plus: {
        enabled: false,
        supportsPackages: false,
        recommendationCategory: "empfehlungen_woche",
        packages: {
            supported: false,
        },
    },
    geoBlocking: {
        enabled: false,
    },
    frontpage: {
        additionalSliderImages: function (category, userisPlus) {
            return [];
        },
        sliderTypeForCategory: function (cat) {
            return "dynamicDoubleRowSlider";
        },
        categorySlider: {
            show: false,
        },
    },
    ads: {},
    moviepage: {
        facebookComments: {
            show: false,
        },
        sharing: {},
        akamaiOptionsForMovie: function () {
            return null;
        },
    },
    header: {
        apps: {
            show: false,
        },
        info: {
            show: true,
            label: "Informationen",
            links: [
                {
                    link: "#!/impressum",
                    label: "Impressum",
                },
                {
                    link: "#!/datenschutz",
                    label: "Datenschutz",
                },
                {
                    link: "#!/agb",
                    label: "AGB",
                },
            ],
        },
        categories: {
            enabled: true,
            label: "Kategorien",
            icons: false,
        },
    },
    google_analytics: {
        id: "UA-51035686-14",
        avo_id: "UA-51035686-14",
    },
    socialBar: {
        followMotivation: {
            show: true,
            img: "/follow-us-on.png",
            label: "Folge uns auf",
        },
    },
    footer: {
        buttons: {
            show: false,
        },
        links: [
            {
                link: "#!/",
                label: "Startseite",
            },
            {
                link: "#!/impressum", //yep, that's correct
                label: "Jugendschutz",
            },
            {
                link: "#!/agb",
                label: "AGB",
            },
            {
                link: "#!/datenschutz",
                label: "Datenschutz",
            },
            {
                link: "#!/impressum",
                label: "Impressum",
            },
        ],
        copyright:
            "Amogo Networx UG (haftungsbeschränkt) " + new Date().getFullYear(),
        copyrightLink: null,
        social: {
            show: false,
        },
    },
};
