import can from "can";
import config from "../../config";
import View from "./view.stache";

export default can.Component.extend({
    tag: "plus-information",
    template: View,
    scope: {
        identifier: "@",
        // onClick: '@'
    },
    events: {
        init: function (el, options) {
            this.options.scope.attr("config", config);
        },
        ".goToOffer click": function () {
            // needs to be handled by wrapping component....
        },
    },
});
