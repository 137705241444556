var VOUCHER_STATE_KEY = "vc";

/**
 * Structure in localstorage
 * vc: { [$MovieID]: {exp: Date}}
 */
function getAllVoucherStates() {
    var allVouchersString = window.localStorage.getItem(VOUCHER_STATE_KEY);
    if (!allVouchersString) {
        return null;
    } else {
        try {
            return JSON.parse(allVouchersString);
        } catch (e) {
            console.error("could not get voucher states", e);
            return null;
        }
    }
}

function setAllVoucherStates(states) {
    try {
        var statesWithoutInvalid = Object.keys(states);
        window.localStorage.setItem(VOUCHER_STATE_KEY, JSON.stringify(states));
    } catch (e) {
        console.error("could not update voucher states", e);
    }
}

function setVoucherStateById(id, state) {
    try {
        var states = getAllVoucherStates() || {};
        states[id] = state;
        setAllVoucherStates(states);
    } catch (e) {
        console.error("could not store voucher update", e);
    }
}

function getVoucherInfoById(id) {
    var state = (getAllVoucherStates() || {})[id] || null;
    if (!state) {
        return {
            valid: false,
        };
    }

    if (state.exp) {
        var d = new Date(state.exp);
        if (d.getTime() < Date.now()) {
            // coupon is no longer valid!
            return {
                valid: false,
            };
        }
        return {
            valid: true,
            alreadyPlayed: true,
            validUntil: d,
        };
    } else {
        return {
            valid: true,
            alreadyPlayed: false,
        };
    }
}

function removeAllInvalidVouchers() {
    var states = getAllVoucherStates();
    if (states) {
        // remove movie state if the voucher is no longer valid
        Object.keys(states).forEach(function (id) {
            if (!getVoucherInfoById(id).valid) {
                delete states[id];
            }
        });
        setAllVoucherStates(states);
    }
}

function getVoucherInfoAndRemoveIfInvalid(movie) {
    var id = movie.attr("id");
    var state = getVoucherInfoById(id);
    removeAllInvalidVouchers(); // refresh localstorage
    return state;
}

function setExpiration(movie, hours) {
    var id = typeof movie === "object" && movie.attr ? movie.attr("id") : movie;
    var voucherState = (getAllVoucherStates() || {})[id];
    if (voucherState) {
        voucherState.exp = new Date(Date.now() + hours * 60 * 60 * 1000);
    }
    setVoucherStateById(id, voucherState);
    removeAllInvalidVouchers(); // refresh localstorage
}

removeAllInvalidVouchers(); // refresh localstorage

export default {
    getInfo: getVoucherInfoAndRemoveIfInvalid,
    setExpirationInHours: setExpiration,
    getAllMovieIds: function getAllMovieIds() {
        // required on SmartTV to show movies
        removeAllInvalidVouchers(); // remove first, updates localstorage
        var voucherStates = getAllVoucherStates() || {};
        return Object.keys(voucherStates).map(function (id) {
            return id;
        });
    },
    addMovieById: function (movieId) {
        var states = getAllVoucherStates() || {};
        states[movieId] = {};
        setAllVoucherStates(states);
        removeAllInvalidVouchers();
    },
};
