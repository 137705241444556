<!-- TODO: Dynamisch machen -->
<div class="navPlaceholder"></div>

<netzkino-title-social-bar cat="Jugendschutz" show-text="false"></netzkino-title-social-bar>

<div class="container subpage-container">
	<div class="row">
		<div class="col-xs-12 col-md-offset-1 col-md-10">
			<h2 class="main-section-headline">Jugendschutz bei {{config.general.name}}</h2>
			<p>
				Viele Filme auf {{config.general.name}} sind für Kinder und Jugendliche geeignet. Einige Inhalte richten sich dagegen an bestimmte Altersgruppen oder sind eher für Erwachsene.
				<br/>
				Weil wir unsere Verantwortung als Anbieter gegenüber Kindern und Jugendlichen sehr ernst nehmen, haben wir uns dazu entschlossen, entsprechende technische Schutzmaßnahmen umzusetzen. Diese sind an die unterschiedlichen Geräte angepasst, mit denen Sie das Angebot von {{config.general.name}} nutzen können.
				<br/>
				Spannende Filme, die für Kinder von 0 bis 12 Jahren geeignet sind, finden Sie in unserem <a href="{{kinderkinoLink}}">Kinderkino</a>. Für reine Kinderunterhaltung empfehlen wir die <a href="http://www.kixi.de" target="_blank">Kixi-App</a> und <a href="http://www.kinderkino.de" target="_blank">Kinderkino.de</a>.
			</p>

			<h2 class="main-section-headline">Wie funktioniert das?</h2>

			<h3>YouTube und {{youtubeAndTitle}}</h3>
			<p>
				Auf unserer Webseite und bei YouTube nutzen wir den gesetzlich unterstützen Standard für die freiwillige Alterskennzeichnung von Online-Inhalten (auch „Age-de.xml-Labeling“ genannt). Diese technische Alterskennzeichnung kann von Jugendschutzsoftware ausgelesen werden, sodass ungeeignete Inhalte nicht angezeigt werden.
				<br/>
				Während erwachsene Besucher das Angebot frei nutzen können, haben wir so für Eltern die technische Voraussetzung für den Einsatz kostenloser Jugendschutzsoftware geschaffen. Erziehungsberechtigte können also selbst entscheiden, welche Inhalte für ihre Kinder zugänglich sein sollen. Weitere Informationen über die freiwillige Alterskennzeichnung, die Installation und/oder Konfiguration kostenloser Jugendschutzsoftware erhalten Sie bei der Bund-Länder-Initative <a href="http://www.sicher-online-gehen.de" target="_blank">Sicher online gehen</a> oder dem gemeinnützigen Verein <a href="http://www.jugendschutzprogramm.de" target="_blank">JusProg e.V</a>.
			</p>

			<h3>Mobile Apps</h3>
			<p>
				Unsere mobilen Apps tragen ein USK-Kennzeichen. Mehr Informationen dazu erhalten Sie <a href="http://usk.de/iarc" target="_blank">hier</a>. Möchten Sie für Ihre Kinder den Zugriff im mobilen Bereich beschränken, können Sie dies über die Parental Controls von Google, Apple oder Microsoft tun.
			</p>

			<h3>{{config.general.name}} auf Fernsehgeräten</h3>
			<p>
				SmartTV-Geräte unterstützen den technischen Standard der gesetzlichen Alterskennzeichnung derzeit nicht. Um dem Jugendschutz auch bei diesen Geräten zu gewährleisten, haben wir uns entschlossen, jugendschutzrelevante Inhalte dort nur im Rahmen einer für das TV üblichen Sendezeitbeschränkung anzubieten. Damit sind Inhalte für ältere Jugendliche und Erwachsene erst nachts verfügbar.
				<br/>
				Alternativ können bei TV-Geräten des Herstellers Samsung auch tagsüber Filme mit einem PersoCheck-Verfahren freigeschaltet werden. Dabei müssen Sie einmalig ihr Alter mittels Personalausweis verifizieren und im Anschluss eine Jugendschutz-Pin vergeben. {{config.general.websiteShort}} speichert dabei keine personenbezogenen Daten.
				Wir arbeiten daran, das PersoCheck-Verfahren so bald wie möglich für weitere TV-Geräte anzubieten.
			</p>
			<p>
				Die {{config.general.company}} ist Mitglied der Unterhaltungssoftware Selbstkontrolle (USK). Alle Jugendschutzlösungen wurden in enger Zusammenarbeit mit der USK umgesetzt. Für die Umsetzung eines gesetzeskonformen und sinnvollen Jugendschutzes wurden die Angebote von {{config.general.name}} mit dem Gütesiegel Jugendschutz der USK ausgezeichnet.
			</p>

			<h2 class="main-section-headline">Sie haben Fragen oder Anmerkungen?</h2>
			<p>
				Die USK übernimmt die Aufgaben des Jugendschutzbeauftragten für {{config.general.name}}. Gerne können Sie sich mit allen Fragen zum Jugendschutz an die USK wenden:
			</p>
		</div>
	</div>

	<div class="row">
		<div class="col-lg-12 text-center">
			<img src="{{imagePath 'webseite/usk_guetesiegel.png'}}" style="height: 125px; display: inline-block; margin-bottom: 15px;" class="img-responsive" />
		</div>
	</div>

	<div class="row">
		<div class="col-lg-12 text-center">
			<p>
				USK Unterhaltungssoftware Selbstkontrolle<br/>
				Torstr. 6, 10119 Berlin<br/>
				<a href="mailto:{{config.general.jugendschutzEmail}}">{{config.general.jugendschutzEmail}}</a>
			</p>
		</div>
	</div>
	<br>
	<br>
</div>

