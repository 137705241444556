const $ = require("./jquery");

/**
 * According to the comment within the file, this includes:
 * can/component
 * can/construct
 * can/map
 * can/list
 * can/observe
 * can/compute
 * can/model
 * can/view
 * can/control
 * can/route
 * can/control/route
 * can/view/mustache
 * can/view/bindings
 * can/view/live
 * can/view/scope
 * can/util/string
 * can/util/attr
 */
require("../../../bower_components/canjs/can.jquery.js");
/**
 * Additonally required in Netzkino:
 * can/view/stache
 * can/construct/super
 * can/construct/proxy
 *
 * can/component <- already in can.jquery
 * can/map/sort
 */

require("../../../bower_components/canjs/can.stache.js");
require("../../../bower_components/canjs/can.construct.super");
require("../../../bower_components/canjs/can.construct.proxy");

require("../../../bower_components/canjs/can.list.sort.js"); // TODO: not sure if necessary

// previously: require("../../lib/canjs-plugins/src/can.function/can.function.js");
can.extend(can, {
    debounce: function (fn, time, context) {
        var timeout;
        return function () {
            var args = arguments;
            clearTimeout(timeout);
            timeout = setTimeout(
                can.proxy(function () {
                    fn.apply(this, args);
                }, context || this),
                time
            );
        };
    },
    throttle: function (fn, time, context) {
        var run;
        return function () {
            var args = arguments;
            var ctx = context || this;
            if (!run) {
                run = true;
                setTimeout(function () {
                    fn.apply(ctx, args);
                    run = false;
                }, time);
            }
        };
    },
    defer: function (fn, context) {
        var args = arguments;
        var ctx = context || this;
        setTimeout(function () {
            fn.apply(ctx, args);
        }, 0);
    },
});

export default window.can;
