import can from "can";
import utils from "../../lib/utils";
import API from "../api";
import config from "../config";
import ga from "../modules/googleanalytics";
import state from "../state";

var watchlistDeferred = can.$.Deferred();
var User = can.Model.extend(
    "User",
    {
        resource: config.my.api.getUrl("users"),
        watchlistpromise: watchlistDeferred.promise(),

        login: function (username, password, rememberMe) {
            if (rememberMe) {
                API.useLocalStorage(true);
            }

            return API.post(
                config.my.api.getUrl("login"),
                { username: username, password: password },
                {}
            ).then(function (res) {
                config.log("login succeeded", res);
                return User.getCurrentUser();
            });
        },
        logout: function (skipGAEvent) {
            if (!skipGAEvent) {
                ga.sendEvent("Account Action", "Logout");
            }
            state.removeAttr("currentUser");
            API.removeCurrentUserFromCache();
            state.goTo("my");

            API.post(config.my.api.getUrl("logout"), null, {
                // no data
            }).then(
                function (res) {
                    config.log("logout succeeded", res);
                    window.location.reload();
                },
                function (err) {
                    // TODO should we notify the user - or will he realize himself after reload?
                    window.location.reload();
                    config.error("could not logout user!", err);
                }
            );
        },
        register: function (email, password) {
            ga.sendEvent("Account Action", "Register");
            return API.post(
                config.my.api.getUrl("users"),
                {
                    username: email,
                    password: password,
                    email: email,
                },
                {}
            ).then(function (res) {
                config.log("register succeeded", res);
                return res;
            });
        },
        getCurrentUser: function (skipUnauthorizedError) {
            var deferred = new can.Deferred();
            if (!API.getFromStorage("currentUser") && !skipUnauthorizedError) {
                config.log("login - localStorage not containing currentUser");
                deferred.reject();
                return deferred;
            }
            function handleUserGet(user) {
                if (user && user.id) {
                    var watchlistApi = require("../modules/watchlist").default;
                    watchlistApi.load().then(
                        function (watchlist) {
                            config.log("loaded watchlist for user", watchlist);
                            watchlistDeferred.resolve(watchlist);
                        },
                        function (err) {
                            console.error(
                                "could not load watchlist for user",
                                err
                            );
                            watchlistDeferred.reject(err);
                        }
                    );
                    User.getWishlist();

                    // make sure we have subscriptions to iterate over
                    if (config.plus.enabled) {
                        var status = utils.checkSubscriptionStatus(user);
                        user.isPlus = status.isPlus;
                        user.activeSubscriptionTerm =
                            status.activeSubscriptionTerm;
                        user.activeSubscription = status.activeSubscription;
                        user.shouldWarnAboutWirecard =
                            status.shouldWarnAboutWirecard;
                    }

                    API.storeCurrentUserInCache(user);
                    state.attr("currentUser", user);
                    currentUser = user;
                } else {
                    watchlistDeferred.reject();
                }
                return user;
            }

            function handleUserGetError(err) {
                config.error("could not get currentUser info", err);
                state.removeAttr("currentUser");
                return err; // give to next promise handler
            }

            if (skipUnauthorizedError) {
                API.getUnauthorized(
                    config.my.api.getUrl("me"),
                    skipUnauthorizedError
                )
                    .then(handleUserGet, handleUserGetError)
                    .then(
                        function (user) {
                            deferred.resolve(user);
                            return user;
                        },
                        function (err) {
                            deferred.reject(err?.responseJSON);
                            console.error(
                                "could not get user via getUnauthorized",
                                err
                            );
                        }
                    );
            } else {
                API.get(config.my.api.getUrl("me"), skipUnauthorizedError)
                    .then(handleUserGet, handleUserGetError)
                    .then(
                        function (user) {
                            deferred.resolve(user);
                            return user;
                        },
                        function (err) {
                            deferred.reject(err?.responseJSON);
                            console.error("could not get user via get", err);
                        }
                    );
            }
            return deferred;
        },
        // fire 'n forget
        removeMovieFromList: function (movieId, listType) {
            ga.sendEvent(
                listType === "wishlist" ? "Watchlist" : "Weiterschauen",
                "Remove",
                movieId
            );
            return API.post(
                config.my.api.getUrl(listType) + "remove/" + movieId
            ).then(
                function () {
                    if (listType === "wishlist") {
                        state
                            .attr("currentWishlist")
                            .splice(
                                state.attr("currentWishlist").indexOf(movieId),
                                1
                            );
                    }
                    config.log("removed movie from list", movieId, listType);
                },
                function (err) {
                    config.error("removal failed", movieId, listType, err);
                    return err;
                }
            );
        },
        getWishlist: function () {
            // shrink wishlist to simple list for easier usage
            config.log("getting wishlist for current user");
            return API.get(config.my.api.getUrl("wishlist")).then(
                function (wishlist) {
                    let wishListMovies = [];
                    wishlist.forEach(function (item) {
                        wishListMovies.push(item.movieId);
                    });
                    state.attr("currentWishlist", wishListMovies);
                    return wishListMovies;
                },
                function (err) {
                    config.error("could not get wishlist", err);
                    return err;
                }
            );
        },
        updateUser: function (userRequestObject) {
            ga.sendEvent("Account Action", "UserUpdate");
            config.log("sending user change request for currentUser");
            return API.put(
                config.my.api.getUrl("users") + state.currentUser.id,
                userRequestObject
            ).then(
                function (response) {
                    config.log("user update response", response);
                    return response;
                },
                function (err) {
                    config.error("could not change current user");
                    return err;
                }
            );
        },
        cancelSubscription: function (subscriptionId) {
            ga.sendEvent("Account Action", "CancelSubscription");
            config.log("sending cancelSubscription");
            return API.post(config.plus.api.getUrl("cancelPayment"), {
                subscriptionId: subscriptionId,
            }).then(
                function (response) {
                    config.log("cancelSubscription response", response);
                    return response;
                },
                function (err) {
                    config.error("error in cancelPayment request", err);
                    return err;
                }
            );
        },
        deleteUser: function () {
            ga.sendEvent("Account Action", "DeleteUser");
            config.log("sending user delete request for currentUser");
            return API.delete(config.my.api.getUrl("me")).then(
                function (response) {
                    User.logout(true);
                    return response;
                },
                function (err) {
                    config.error("could not delete current user");
                    return err;
                }
            );
        },
        // operates on strings rather than integers
        wishlistToggleForMovie: function (movieId) {
            if (
                state.attr("currentWishlist") &&
                state.attr("currentWishlist").indexOf(movieId) > -1
            ) {
                return User.removeMovieFromList(movieId, "wishlist");
            } else {
                ga.sendEvent("Watchlist", "Add", movieId);
                return API.post(
                    config.my.api.getUrl("wishlist") + "add/" + movieId,
                    {}
                ).then(
                    function (response) {
                        if (response && response.success) {
                            state.attr("currentWishlist").push(movieId);
                            config.log("added to wishlist", response);
                            return true;
                        } else {
                            config.log("error adding to wishlist", response);
                            return false;
                        }
                    },
                    function (err) {
                        config.log("error adding to wishlist", err);
                        return false;
                    }
                );
            }
        },
        createTokens: function (acls) {
            return state.currentUserPromise.then(function () {
                return API.post(config.plus.api.getUrl("createToken"), {
                    acls: acls,
                }).then(
                    function (response) {
                        if (response) {
                            return response;
                        } else {
                            return new can.Deferred().reject("no token");
                        }
                    },
                    function () {
                        return new can.Deferred().reject("no token");
                    }
                );
            });
        },
        createLoginToken: function () {
            return state.currentUserPromise.then(function () {
                return API.post(config.my.api.getUrl("createLoginToken")).then(
                    function (response) {
                        if (response) {
                            return response;
                        } else {
                            return new can.Deferred().reject("no token");
                        }
                    },
                    function () {
                        return new can.Deferred().reject("no token");
                    }
                );
            });
        },
    },
    {}
);

// try user login to check whether active session exists
if (API.getFromStorage("currentUser") || API.getFromStorage("loggedIn")) {
    config.log("login - no currentUser found");

    User.getCurrentUser(true).then(
        function (user) {
            if (user && user.id) {
                state.currentUserPromise.resolve(user);
            } else {
                state.currentUserPromise.reject();
            }
        },
        function (err) {
            state.currentUserPromise.reject(err);
        }
    );
} else {
  // we are not loggedIn until we visit the login page at least once.
  // var deferred = new can.Deferred();
  // deferred.reject();
  // state.currentUserPromise = deferred;

  // KIXI-49:
  // Fetch the user, even if there is no user to fetch, we will receive information from the backend 
  // if the current client request is made from an ip-address which belongs to a special institution
  state.currentUserPromise = User.getCurrentUser(true)
}
state.currentUserPromise.then(null, function () {
    watchlistDeferred.reject();
    API.removeCurrentUserFromCache();
});

export default User;
