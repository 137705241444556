<div class="container information-wrapper">
    <div class="row">
        <div class="col-xs-12 col-sm-offset-6 col-sm-6 information-content-container">
            <h4>Mehr Filme für Alle mit {{config.plus.registrationOptionHeader.secondOptionTitle}}</h4>
            <h5>{{config.plus.registrationOptionHeader.secondOptionPrice}}</h5>
            {{#each config.plus.registrationOptions}}
                {{^firstOption}}
                    <div class="offer-option"><i class="fa fa-check checkMark"></i>&nbsp;&nbsp;<span>{{name}}</span></div>
                {{/firstOption}}
            {{/each}}
            <div class="offer-option"><i class="fa fa-check checkMark"></i>&nbsp;&nbsp;<span>Monatlich kündbar****</span></div>
            <a href="#" class="btn btn-big btn-dark goToOffer" style="margin-top: 20px;">Zum Angebot</a>
            <p class="footnote">
                {{#each config.plus.registrationOptionFootnote}}
                    {{.}}<br/>
                {{/each}}
                ****bei einem fortlaufenden {{config.plus.title}} Abonnement.
            </p>
        </div>
    </div>
</div>