<ul>
    {{#each partList}}
        <li can-click='onSelect'>
            <div class="part {{#isSelected .}}selected{{/isSelected}}">
                <div class="col-xs-2 number">
                    <span>
                        {{partNumberPrefix}} {{number}}
                    </span>
                </div>
                <div class="col-xs-7 col-md-8 title">
                    <span>{{title}}</span>
                </div>
                <div class="col-xs-3 col-md-4 duration">
                    {{displayDuration duration}}
                </div>
            </div>
        </li>
    {{/each}}
</ul>
