import can from "can";
import utils from "../../lib/utils";
import Movie from "./movie";

export default Movie.extend(
    "SeriesContent",
    {
        // not called audio because CanJS throws a warning that "Audio" is already
    },
    {
        type: "seriesContent",

        normalizedPart: can.compute(function () {
            // as integer
            return utils.normalizePart(this.attr("series.part"));
        }),
        inSeason: can.compute(function () {
            var seasonString = (this.attr("normalizedPart") + "")[0]; // first digit is the season
            return parseInt(seasonString);
        }),
        episodeInSeason: can.compute(function () {
            return this.attr("normalizedPart") % 100;
        }),

        getSourceForPartNumber: function getSourceForPartNumber(partNumber) {
            var activePart = this.attr("partList").filter(function (part) {
                // plain JS Object
                return part.number === partNumber;
            })[0];
            if (activePart) {
                return activePart.source;
            } else {
                return null;
            }
        },
        setSeriesModel: function (series) {
            this.attr("seriesModel", series);
        },
    }
);
