import can from "can";
import BaseControl from "../../../lib/can.basecontrol.js";
import config from "../../config";
import Category from "../../models/category";
import Movie from "../../models/movie";
import state from "../../state";
import View from "./view.stache";

// creates the "Was dich erwartet" slider category for the default landingpage
// -> showing all categories liaw has to offer
function getExpectationSliderDefaultCategory(catIndex) {
    var fakeFilms = [],
        f;
    catIndex.categories
        .filter(function (cat) {
            // some categories should not be displayed on the landingpage (not-logged in) but be shown on the frontpage (logged in)
            return (
                config.plus.excludedHomepageCategoriesLanding.indexOf(cat.id) <
                0
            );
        })
        .forEach(function (cat) {
            f = {};
            f.custom_fields = {};
            f.custom_fields.featured_img_all_small = [
                config.paths.images.genreToImage(cat),
            ];
            f.title = "plus/" + cat.slug; // for this slider only we want to link to plus page to show specific video/content depending on the category
            f.hasSimpleOverlay = true;
            if (config.plus.enabled && !config.plus.isPlusCategory(cat)) {
                fakeFilms.push(f);
            }
        });

    var fakeCat = {
        sliderType: "genreDoubleRow",
        isGenreList: true,
        title: "Genre",
        type: "genres",
        slug: "genres",
        posts: Movie.models(fakeFilms),
    };
    return Category.model(fakeCat);
}

// fetches the preview "Was dich erwartet" category for the category specific landingpage
// -> showing a preview of this category
function getExpectationSliderCategory(categorySlug, cb) {
    config.log(
        '[landing] - requesting "landingpage-love-is-a-wonder" to load landingpage expectationCategory for ' +
            categorySlug
    );
    // a category landingpage
    if (!config.plus.expectationCategoryParent) {
        throw new Error(
            "config.plus.expectationCategoryParent is not configured!"
        );
    }
    Category.findOne(config.plus.expectationCategoryParent).then(function (c) {
        c.attr("posts").forEach(function (m) {
            state.data.moviesBySlug[m.slug] = m;
            state.data.moviesById[m.id] = m;
        });
        c.getSubCategories();
        // we now have also loaded all subcategories of 'landingpage-love-is-a-wonder'
        // we want to return the category that matches 'was-dich-erwartet-{categorySlug}'
        config.log(
            '[landing] - we expect to find a category "was-dich-erwartet-"' +
                categorySlug +
                '".'
        );
        cb(state.data.categoriesBySlug["was-dich-erwartet-" + categorySlug]);
    });
}

export default BaseControl.extend(
    "plus",
    {
        defaults: {
            template: View,
        },
    },
    {
        init: function (el, options) {
            var self = this,
                landingPreview = config.plus.getLandingPagePreviewURLs(),
                landingMobilePreviewImageUrl = config.plus.getLandingPageMobileImageURL();

            options.viewOptions.attr("config", config);

            // if currentUser turns out to be plus member already, show him the contents he paid for ...
            options.state.currentUserPromise.then(function (user) {
                if (user.isPlus) {
                    options.state.goTo(config.plus.plusCategorySlug);
                }
            });

            if (window.platform === "loveisawonder") {
                options.viewOptions.attr(
                    "subHeadline",
                    "Erotikfilme und Ratgeber fürs Liebesleben"
                );
                options.viewOptions.attr("categoryEnrichment", null);
                // We want to show different landingpages for each category on the frontpage...
                var landingpageCategorySlug = can.route.attr("id");
                if (landingpageCategorySlug) {
                    config.log(
                        "[landing] - category specific landing page initialized for category " +
                            landingpageCategorySlug
                    );
                    landingPreview = config.plus.getLandingPagePreviewURLs(
                        landingpageCategorySlug
                    );
                    landingMobilePreviewImageUrl = config.plus.getLandingPageMobileImageURL(
                        landingpageCategorySlug
                    );
                }

                this.options.state.defs.indexLoader.then(
                    function (catIndex) {
                        config.log("indexLoader", catIndex);
                        this.options.viewOptions.attr("index", catIndex);
                        var highlightsCategory = catIndex.homepage_categories.filter(
                            function (category) {
                                return category.slug === "liawsvod-highlights";
                            }
                        )[0];
                        if (highlightsCategory) {
                            highlightsCategory = highlightsCategory.serialize();
                            highlightsCategory.sliderType = "sliderDoubleRow";
                            this.options.viewOptions.attr(
                                "highlightsCategory",
                                highlightsCategory
                            );
                        }

                        var expectationCategory = getExpectationSliderDefaultCategory(
                            catIndex
                        );
                        if (landingpageCategorySlug) {
                            getExpectationSliderCategory(
                                landingpageCategorySlug,
                                function (cat) {
                                    // always fallback to the category-slider ... just in case some slug is wrong ...
                                    self.options.viewOptions.attr(
                                        "categories",
                                        cat || expectationCategory
                                    );
                                }
                            );
                            var enrichment =
                                config.categoryTree.categoryEnrichment[
                                    landingpageCategorySlug
                                ];
                            if (enrichment) {
                                enrichment.title =
                                    state.data.categoriesBySlug[
                                        landingpageCategorySlug
                                    ].title;
                                // enrichment.subtitle = enrichment.subtitle || enrichment.title
                                self.seo.setTitle(enrichment.subtitle);
                            }
                            self.options.viewOptions.attr(
                                "categoryEnrichment",
                                enrichment
                            );
                            // self.options.viewOptions.attr('subHeadline', state.data.categoriesBySlug[landingpageCategorySlug].title);
                        } else {
                            config.log(
                                "[landing] - default landing initialized"
                            );
                            this.options.viewOptions.attr(
                                "categories",
                                expectationCategory
                            );
                        }
                    }.bind(this)
                );
            }

            options.viewOptions.attr("landingPreview", landingPreview);
            options.viewOptions.attr(
                "landingMobilePreviewImageUrl",
                landingMobilePreviewImageUrl
            );

            return this._super.apply(this, arguments);
        },
        ".register-now click": function ($el, ev) {
            ev.preventDefault();
            if (this.options.state.attr("currentUser.id")) {
                this.options.state.goTo("my", "plus");
            } else {
                this.options.state.goTo("my", "payment");
            }
        },
    }
);
