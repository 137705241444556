  <div class="container">
    {{#unless hideHeadline}}
      <div class="row category-header-row {{#if cat.adOnly}}cover-ad-row-show-on-delivery{{/if}}">
        {{#if title}}
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h2 class="catHeadline">{{{title}}}</h2>
          </div>
        {{else}}
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h2 class="catHeadline">
              {{#if showMoreButton}}
                {{#ifAbove cat.posts.length 6}}<!-- more than one page -->
                  <a href="#!/{{cat.slug}}" class="btn btn-default pull-right sliderViewMore">
                    Mehr anzeigen
                  </a>
                {{/ifAbove}}
              {{/if}}
                <a href="#!/{{cat.slug}}">{{cat.title}}</a>
            </h2>

          </div>
        {{/if}}
      </div>
    {{/unless}}

    {{#isDynamicDoubleRowSlider}}
        <div class="row">
          <div id="{{cat.slug}}" class="slider lemmonSliderLoading sliderDynamicDoubleRow" data-size="458x254" style="max-height: 265px;">
            <div class="inner-slider">
              <div class="sliderContainer">
                {{#prepareMoviesForDynamicDoubleRow catposts}}
                    {{#each sections}}
                        {{#isEqual type 'adTileBig'}}
                            <div class="sliderItem sliderItem-big" >
                                <generic-ad posts="{catposts}" smart-ad-page-id="997107"  provider="smartAd" identifier="slider-tile-big-genre" />
                            </div>
                        {{/isEqual}}
                        {{#isEqual type 'big'}}
                            <div class="sliderItem sliderItem-big" >
                                {{#if movie}}
                                    <movie-cover cover-size="img_all" movie="{movie}" href="{{getCoverLink movie}}" image-src="{{#getWithFallback movie 'custom_fields.featured_img_all_small.0' 'custom_fields.featured_img_all.0' 'custom_fields.Artikelbild.0' 'custom_fields.featured_img_seven_small.0' 'custom_fields.featured_img_seven.0' 'custom_fields.Featured-Img.0' 'custom_fields.Wide-Cover.0' 'custom_fields.Cover.0'}}{{.}}{{/getWithFallback}}" allow-youtube="{{allowYoutubeOverride}}" />
                                {{/if}}
                            </div>
                        {{/isEqual}}
                        {{#isEqual type 'adTileSmall'}}
                            <div class="sliderVerticalBlock" >
                                {{#each movies}}
                                    {{#isEqual type 'genericAd'}}
                                        <div class="sliderItem">
                                            <generic-ad posts="{catposts}" smart-ad-page-id="997107" class="smartAdMobile" provider="smartAd" identifier="slider-tile-big-genre" />
                                        </div>
                                    {{else}}
                                        <div class="sliderItem">
                                             <movie-cover cover-size="img_all_small" movie="{.}" href="{{getCoverLink .}}" image-src="{{#getWithFallback . 'custom_fields.featured_img_all_small.0' 'custom_fields.featured_img_all.0' 'custom_fields.Artikelbild.0' 'custom_fields.featured_img_seven_small.0' 'custom_fields.featured_img_seven.0' 'custom_fields.Featured-Img.0' 'custom_fields.Wide-Cover.0' 'custom_fields.Cover.0'}}{{.}}{{/getWithFallback}}" />
                                        </div>
                                    {{/isEqual}}
                                {{/each}}
                            </div>
                        {{/isEqual}}
                        {{#isEqual type 'verticalPair'}}
                            <div class="sliderVerticalBlock">
                                {{#each movies}}
                                    <div class="sliderItem">
                                        <movie-cover cover-size="img_all_small" movie="{.}" href="{{getCoverLink .}}" image-src="{{#getWithFallback . 'custom_fields.featured_img_all_small.0' 'custom_fields.featured_img_all.0' 'custom_fields.Artikelbild.0' 'custom_fields.featured_img_seven_small.0' 'custom_fields.featured_img_seven.0' 'custom_fields.Featured-Img.0' 'custom_fields.Wide-Cover.0' 'custom_fields.Cover.0'}}{{.}}{{/getWithFallback}}" />
                                    </div>
                                {{/each}}
                            </div>
                        {{/isEqual}}
                    {{/each}}
                {{/prepareMoviesForDynamicDoubleRow}}
              </div>
            </div>
            <nav>
              <a href="#" class="prev-page"><i class="fa fa-2x fa-chevron-left"></i></a>
              <a href="#" class="next-page"><i class="fa fa-2x fa-chevron-right"></i></a>
            </nav>
          </div>
        </div>
    {{/isDynamicDoubleRowSlider}}
    {{#isSliderDoubleRow}}
    <div class="row" debug-slider-name="sliderDoubleRow">
      <div id="{{cat.slug}}" class="slider lemmonSliderLoading sliderDoubleRow {{#isEqual type 'large'}}largeSlider{{/isEqual}}" data-size="{{#isEqual type 'large'}}458x265{{else}}230x127{{/isEqual}}" data-sizefirst="{{#isEqual type 'large'}}458x265{{else}}458x265{{/isEqual}}" data-mobile-size="230x127" data-mobile-sizefirst="230x127">
        <div class="inner-slider">
          <ul class="sliderContainer">
            {{#each catposts}}
              <li class="sliderItem">
                <movie-cover cover-size="img_all_small" movie="{.}" href="{{getCoverLink .}}" image-src="{{#getWithFallback . 'custom_fields.featured_img_all_small.0' 'custom_fields.featured_img_all.0' 'custom_fields.Artikelbild.0' 'custom_fields.featured_img_seven_small.0' 'custom_fields.featured_img_seven.0' 'custom_fields.Featured-Img-Small.0' 'custom_fields.Featured-Img.0' 'custom_fields.Cover.0' }}{{.}}{{/getWithFallback}}" allow-youtube="{{cat.ytAllowed}}" />
              </li>
            {{/each}}
          </ul>
        </div>
        <nav>
          <a href="#" class="prev-page"><i class="fa fa-2x fa-chevron-left"></i></a>
          <a href="#" class="next-page"><i class="fa fa-2x fa-chevron-right"></i></a>
        </nav>
      </div>
    </div>
    {{/isSliderDoubleRow}}
    {{#isSliderDefaultVertical}}
    <div class="row" debug-slider-name="sliderDefaultVertical">
      <div id="{{cat.slug}}" class="slider lemmonSliderLoading sliderDefaultVertical" data-size="172x246" style="max-height: 246px;">
        <div class="inner-slider">
          <ul class="sliderContainer">
            {{#each catposts}}
              <li class="sliderItem" {{#ifAll cat.adOnly pageId}}id="delivery-{{identifier}}"{{/if}}>
                {{#ifAll cat.adOnly pageId}}
                  <generic-ad smart-ad-page-id="{{pageId}}" provider="smartAd" identifier="{{identifier}}" class="sliderItem sliderDefaultVertical wideCover" style="display: inline;"></generic-ad>
                {{else}}
                  <movie-cover cover-size="artikelbild" movie="{.}" href="{{getCoverLink .}}" image-src="{{#getWithFallback . 'custom_fields.Artikelbild.0' 'custom_fields.Cover.0' 'thumbnail'}}{{.}}{{/getWithFallback}}" />
                {{/if}}
              </li>
            {{/each}}
          </ul>
        </div>
        <nav {{#if allowArrowsMobile}}class="allowArrowsMobile"{{/if}} {{#isEqual hideNav 'true'}}style="visibility: hidden;"{{/isEqual}}>
          <a href="#" class="prev-page"><i class="fa fa-2x fa-chevron-left"></i></a>
          <a href="#" class="next-page"><i class="fa fa-2x fa-chevron-right"></i></a>
        </nav>
      </div>
    </div>
    {{/isSliderDefaultVertical}}
    {{#isAudioContentSlider}}
    <div class="row" debug-slider-name="audioContentSlider">
      <div id="{{cat.slug}}" class="slider lemmonSliderLoading sliderDefaultVertical audioContentSlider" data-size="172x172" style="max-height: 172px;">
        <div class="inner-slider">
          <ul class="sliderContainer">
            {{#each catposts}}
              <li class="sliderItem">
                <movie-cover cover-size="artikelbild" movie="{.}" href="{{getCoverLink .}}" image-src="{{#getWithFallback . 'custom_fields.Artikelbild.0' 'custom_fields.Cover.0' 'thumbnail'}}{{.}}{{/getWithFallback}}" />
              </li>
            {{/each}}
          </ul>
        </div>
        <nav>
          <a href="#" class="prev-page"><i class="fa fa-2x fa-chevron-left"></i></a>
          <a href="#" class="next-page"><i class="fa fa-2x fa-chevron-right"></i></a>
        </nav>
      </div>
    </div>
    {{/isAudioContentSlider}}
    {{#isSliderDefaultHorizontal}}
    <div class="row" debug-slider-name="sliderDefaultHorizontal">
      <div id="{{cat.slug}}" class="slider lemmonSliderLoading sliderDefaultHorizontal" data-size="437x175" style="max-height: 175px;">
        <div class="inner-slider">
          <ul class="sliderContainer">
            {{#each catposts}}
              <li class="sliderItem">
                <movie-cover cover-size="img_seven_small" movie="{.}" href="{{getCoverLink .}}" image-src="{{#getWithFallback . 'custom_fields.featured_img_seven_small.0' 'custom_fields.Artikelbild.0' 'thumbnail'}}{{.}}{{/getWithFallback}}" />
              </li>
            {{/each}}
          </ul>
        </div>
        <nav>
          <a href="#" class="prev-page"><i class="fa fa-2x fa-chevron-left"></i></a>
          <a href="#" class="next-page"><i class="fa fa-2x fa-chevron-right"></i></a>
        </nav>
      </div>
    </div>
    {{/isSliderDefaultHorizontal}}
    {{#isSliderYoutube}}
    <div class="row" debug-slider-name="sliderYoutube">
      <div id="{{cat.slug}}" class="slider lemmonSliderLoading sliderYoutube" data-size="458x265" style="max-height: 265px;">
        <div class="inner-slider">
          <ul class="sliderContainer">
            {{#each catposts}}
              <li class="sliderItem">
                <movie-cover cover-size="img_all_small" allow-youtube="true" movie="{.}" href="{{getCoverLink .}}" image-src="{{#getWithFallback . 'custom_fields.featured_img_all_small.0' 'custom_fields.featured_img_all.0' 'custom_fields.Artikelbild.0' 'custom_fields.featured_img_seven_small.0' 'custom_fields.featured_img_seven.0'}}{{.}}{{/getWithFallback}}" />
              </li>
            {{/each}}
          </ul>
        </div>
        <nav>
          <a href="#" class="prev-page"><i class="fa fa-2x fa-chevron-left"></i></a>
          <a href="#" class="next-page"><i class="fa fa-2x fa-chevron-right"></i></a>
        </nav>
      </div>
    </div>
    {{/isSliderYoutube}}
    {{#isSliderDefaultHorizontalSmall}}
    <div class="row" debug-slider-name="sliderDefaultHorizontalSmall">
      <div id="{{cat.slug}}" class="slider lemmonSliderLoading sliderDefaultHorizontalSmall" data-size="322x129" style="max-height: 129px;">
        <div class="inner-slider">
          <ul class="sliderContainer">
            {{#each catposts}}
              <li class="sliderItem">
                <movie-cover cover-size="img_seven_small" movie="{.}" href="{{getCoverLink .}}" image-src="{{#getWithFallback . 'custom_fields.featured_img_seven_small.0' 'custom_fields.featured_img_seven.0' 'custom_fields.Artikelbild.0' 'thumbnail'}}{{.}}{{/getWithFallback}}" />
              </li>
            {{/each}}
          </ul>
        </div>
        <nav>
          <a href="#" class="prev-page"><i class="fa fa-2x fa-chevron-left"></i></a>
          <a href="#" class="next-page"><i class="fa fa-2x fa-chevron-right"></i></a>
        </nav>
      </div>
    </div>
    {{/isSliderDefaultHorizontalSmall}}
    <div style="display: none;">
      {{!reference CSS background images for preloading}}
      <img src="{{getImage 'webseite/playbutton.png'}}" width="0" height="0" />
    </div>
  </div>
