import can from "can";
import Hammer from "hammer";
import shuffle from "../../../lib/shuffle";
import utils from "../../../lib/utils";
import config from "../../config";
import ga from "../../modules/googleanalytics";
import state from "../../state";
import View from "./view.stache";
var SLIDE_DELAY_AFTER_VIDEO = 2000; // wait 2s after the youtube preview finished playback, matches slider.scss
var SLIDER_INTERVAL = 7000;

// if the viewmodel attr hideExtraSlider is set in any way, the extra slider won't show

let date = Date.now();
can.Component.extend({
    tag: "netzkino-mainpage-slider",
    template: View,
    scope: {
        catposts: [],
        hideextraslider: false,
        useBackgroundImage: false,
    },
    events: {
        removed() {
            if (this.tcDataListenerId) {
                __tcfapi(
                    "removeEventListener",
                    2,
                    () => {},
                    this.tcDataListenerId
                );
            }
        },
        init: function (el, options) {
            let randomAdTrack = Math.random(); // calculate once in order to no boost loads
            date = Date.now(); // refresh if the mainpage gets opened again

            const callback = (tcData, success) => {
                this.tcDataListenerId = tcData.listenerId;
                options.scope.attr("tcfState", tcData);
                if (success && tcData.eventStatus === "tcloaded") {
                    // do something with tcData.tcString

                    // remove the ourself to not get called more than once
                    __tcfapi(
                        "removeEventListener",
                        2,
                        (success) => {
                            if (success) {
                                // oh good...
                            }
                        },
                        tcData.listenerId
                    );
                } else {
                    // do something else
                }
            };

            try {
                __tcfapi("addEventListener", 2, callback);
            } catch (e) {
                console.error(e);
            }

            function handleResizeForSmartAd() {
                $(".smartAdSliderImage").each(function () {
                    var $element = $(this);
                    $element.css("height", $element.width() * (440 / 1170));
                });
                $(".smartAdFeaturedImage").each(function () {
                    var $element = $(this);
                    $element.css("height", $element.width() * (240 / 414));
                });
            }

            options.scope.attr("catposts", []);
            var previousWindowWidth = window.innerWidth,
                self = this,
                setActualSliderImagesToShow = function () {
                    var category = options.scope.attr("cat");
                    var catSlug = options.scope.attr("cat.slug");
                    var isMobile = utils.isMobile();
                    options.scope.attr("isMobile", isMobile);
                    options.scope.attr("notIsMobile", !isMobile);

                    var assignSliderItems = function (user) {
                        var additionalSlider = [];
                        var slidersAtPosition = [];
                        var _additionalSliderImage =
                            config.frontpage.additionalSliderImages(
                                category,
                                user && user.isPlus
                            );

                        _additionalSliderImage.forEach(function (item, i) {
                            if (item.isActive === false) {
                                return;
                            }
                            const addRandom = (url) => {
                                return (
                                    url +
                                    (url.includes("?") ? "&" : "?") +
                                    "random=" +
                                    randomAdTrack
                                );
                            };

                            var sliderItem = {
                                actualSliderLink: item.actualSliderLink,
                                actualSliderImage: item.actualSliderImageLarge,
                            };
                            if (item.specialType == "FeaturedSlot") {
                                var tracker =
                                    (typeof item.actualSliderTracker ===
                                    "string"
                                        ? [item.actualSliderTracker]
                                        : item.actualSliderTracker) || [];
                                sliderItem.specialType = item.specialType;
                                sliderItem.actualSliderLink = addRandom(
                                    item.actualSliderLink
                                );
                                sliderItem.actualSliderTracker =
                                    tracker.map(addRandom);

                                sliderItem.campaignName = item.campaignName;
                                sliderItem.actualSliderScriptSrc =
                                    item.actualSliderScriptSrc;

                                if (item.actualSliderVideoLink) {
                                    sliderItem.hasVideoPreview = true;
                                    sliderItem.actualSliderVideoLink =
                                        item.actualSliderVideoLink;
                                } else {
                                    sliderItem.hasVideoPreview = false;
                                }
                            } else if (item.specialType === "SmartAdSlot") {
                                sliderItem.specialType = item.specialType;
                                sliderItem.pageId = item.pageId;
                                sliderItem.identifier = item.identifier;

                                options.scope.handleResizeForSmartAd =
                                    handleResizeForSmartAd;
                                // $(window).on('resize', handleResizeForSmartAd);
                                can.defer(function () {
                                    handleResizeForSmartAd();
                                    can.defer(handleResizeForSmartAd);
                                });
                            }
                            if (isMobile) {
                                sliderItem.actualSliderImage =
                                    item.actualSliderImageSmall;
                            }
                            sliderItem.actualSliderImage = addRandom(
                                sliderItem.actualSliderImage
                            );
                            if (item.position !== undefined) {
                                // this should be at a fixed position
                                slidersAtPosition.push({
                                    position: item.position,
                                    sliderItem: sliderItem,
                                });
                            } else {
                                // just add somewhere in the slider
                                additionalSlider.push(sliderItem);
                            }
                        });

                        var posts = [];
                        options.scope
                            .attr("cat.posts")
                            .each(function (item, i) {
                                var actualSliderLink =
                                    window.platform === "netzkino"
                                        ? item._internalMovieLink
                                        : "#!/" + catSlug + "/" + item.slug;
                                if (
                                    window.platform === "bronco" ||
                                    window.platform === "grjngo"
                                )
                                    actualSliderLink = "#!/filme/" + item.slug;

                                posts.push({
                                    actualSliderImagePath: isMobile
                                        ? "custom_fields.featured_img_all.0"
                                        : "custom_fields.featured_img_slider.0",
                                    actualSliderLink: actualSliderLink,
                                    movie: item,
                                });
                            });

                        if (!options.scope.attr("hideextraslider")) {
                            [].unshift.apply(posts, additionalSlider);
                        }
                        shuffle(posts);

                        slidersAtPosition.forEach(function (item, i) {
                            posts.splice(item.position, 0, item.sliderItem);
                        });

                        options.scope.attr("catposts", posts);
                    };

                    state.currentUserPromise.then(
                        assignSliderItems,
                        assignSliderItems
                    );
                };

            setActualSliderImagesToShow();

            setTimeout(function () {
                can.$("div.item:first", el).addClass("active");

                var carousel = document.getElementById(
                    "carousel-example-generic"
                );
                if (carousel) {
                    var hammertime = new Hammer(carousel);
                    hammertime.on("swipe", function (ev) {
                        if (ev.deltaX > 0) {
                            can.$(".carousel").carousel("prev");
                        } else {
                            can.$(".carousel").carousel("next");
                        }
                        return false;
                    });
                }
                var firstItem = carousel.getElementsByClassName("item")[0];
                var $firstItem = $(firstItem);
                var $html5VideoPreview = $firstItem.find(
                    "html5-video-preview:first"
                );

                if (
                    !$firstItem.hasClass("youtube") &&
                    $html5VideoPreview.length === 0
                ) {
                    self.slideWithTimeout();
                } else if ($html5VideoPreview.length > 0) {
                    // start the first movie automatically
                    $html5VideoPreview.trigger("forceVisible");
                }

                if ($firstItem.hasClass("featuredSlot")) {
                    // impression is tracked once on pageload *only if* the first item is a featuredAd
                    // future iterations of the slider (e.g. coming in view again) will not trigger an impression
                    ga.sendEvent(
                        "Ad",
                        $firstItem.data("campaign-name") + " Impression",
                        "Impression",
                        1
                    );
                }

                window.onresize = function () {
                    if (
                        (window.innerWidth <= utils.getMobileWidth() &&
                            previousWindowWidth > utils.getMobileWidth()) ||
                        (window.innerWidth > utils.getMobileWidth() &&
                            previousWindowWidth <= utils.getMobileWidth())
                    ) {
                        previousWindowWidth = window.innerWidth;
                        setActualSliderImagesToShow();
                    }
                };

                var $video = can.$(el).find("video");
                if (platform === "netzkino" && $video.length > 0) {
                    // will only be executed if a FeaturedSlot with video exists
                    // as of now, limited to *one* FeaturedSlot with video element in slider
                    // and tracking events fixed and special to Netflix
                    var $videoContainer = can.$(".videoContainer:first");
                    var isMobile = utils.isMobile();
                    $video.on("ended", function () {
                        $video.css("opacity", 0);
                        $video.css("pointer-events", "none");
                        ga.sendEvent("Ad", "Netflix Video Ended", "Ended", 1);
                        if (isMobile) {
                            $videoContainer.css("width", "47%");
                            $videoContainer.css("top", "15%");
                            $videoContainer.css("height", 124);
                            $videoContainer.css("right", "1%");
                        }
                        setTimeout(function () {
                            if (self.element) {
                                self.element.find(".carousel").carousel("next");
                            }
                        }, SLIDER_INTERVAL);
                        can.$(".carousel-control").show();
                    });
                    $video.on("pause", function () {
                        ga.sendEvent("Ad", "Netflix Video Pause", "Pause", 1);
                        clearTimeout(this.currentSlideTimer);
                    });
                }
            }, 0);
        },
        removed: function () {
            var handler = this.options.scope.handleResizeForSmartAd;
            if (handler) {
                $(window).off("resize", handler);
            }
        },
        " no-smartad-ad": function ($el, ev) {
            config.log("no smart ad found for slider, hiding container");

            var item = $(ev.target).closest(".item");
            var $carousel = $el.find(".carousel");
            if (item.hasClass("active")) {
                // $carousel.carousel('next');
                $carousel.carousel("next");
            }
            // removing items from the bootstrap carousel does not seem to work.
            item.addClass("hiddenSmartAd");
        },
        slideWithTimeout: function () {
            var self = this;
            clearTimeout(self.currentSlideTimer);
            self.currentSlideTimer = setTimeout(function () {
                if (self.element) {
                    self.element.find(".carousel").carousel("next");
                }
            }, SLIDER_INTERVAL);
        },
        slidePrevWithTimeout: function () {
            var self = this;
            clearTimeout(self.currentSlideTimer);
            self.currentSlideTimer = setTimeout(function () {
                self.element.find(".carousel").carousel("prev");
            }, SLIDER_INTERVAL);
        },
        " slide.bs.carousel": function ($slider, ev) {
            // sliding starting, load next youtube player
            clearTimeout(this._youtubeFadoutTimeout); // allow users to manually skip the youtube preview
            var $items = $slider.find(".item"),
                $currentItem = $items.filter(".active:first"),
                $nextItem = can.$(ev.relatedTarget);
            if (!$nextItem.length) {
                $nextItem = $items.filter(
                    ev.direction === "left" ? ":first" : ":last"
                );
            }

            // if the next item is a hidden smart ad, we need to skip it. Removing items from the bootstrap carousel is not supported...
            if ($nextItem.is(".hiddenSmartAd")) {
                ev.preventDefault();
                var currentItemIndex = $items.index($currentItem);
                var nextItemIndex = $items.index($nextItem);
                // avoid stack overflow
                setTimeout(function () {
                    var lastIndex = $items.length - 1;
                    var indexLeftOfNext = nextItemIndex - 1;
                    if (indexLeftOfNext < 0) {
                        indexLeftOfNext = lastIndex;
                    }
                    var indexRightOfNext = nextItemIndex + 1;
                    if (indexRightOfNext > lastIndex) {
                        indexRightOfNext = 0;
                    }
                    // TODO: setting IndexRightOfNext does nothing...
                    $slider.carousel(
                        "to",
                        ev.direction === "left"
                            ? indexRightOfNext
                            : indexLeftOfNext
                    ); // slide direction is the other way than you expect
                }, 300);
                return;
            }

            if (
                !$nextItem.hasClass("youtube") &&
                $nextItem.find("html5-video-preview").length === 0
            ) {
                if (ev.direction === "left") {
                    this.slideWithTimeout();
                } else {
                    this.slidePrevWithTimeout();
                }
            } else {
                clearTimeout(this.currentSlideTimer);
            }

            // $currentItem.find('youtube-cover').trigger('forceInvisible');
            $nextItem.find("youtube-cover").trigger("forceVisible");
            $nextItem.find("html5-video-preview").trigger("forceVisible");
        },
        " slid.bs.carousel": function ($slider, ev) {
            // sliding done, destroy old youtube player
            var $items = $slider.find(".item"),
                $currentItem = $items.filter(".active:first"),
                $previousItem =
                    ev.direction === "right"
                        ? $currentItem.next(".item")
                        : $currentItem.prev(".item");
            if (!$previousItem.length) {
                $previousItem = $items.filter(
                    ev.direction === "right" ? ":first" : ":last"
                );
            }

            $previousItem.find("youtube-cover").trigger("forceInvisible");
            $previousItem.find("html5-video-preview").trigger("forceInvisible");
        },
        // youtube cover may fire next on finished playing
        "youtube-cover requestNext": function () {
            this.element.find(".item youtube-cover").trigger("forceInvisible");
            config.log(
                "youtube element in slider finished current iteration, skipping this slider item!"
            );
            var self = this;
            this._youtubeFadoutTimeout = setTimeout(function () {
                self.element.find(".carousel").carousel("next");
            }, SLIDE_DELAY_AFTER_VIDEO);
        },
        ".videoContainer click": function ($el, ev) {
            var $video = $el.find(".adVideo:first");
            var isMobile = utils.isMobile();
            $video.css("opacity", 1);
            $video.css("pointer-events", "auto");
            $video.css("background-color", "#000");
            if (isMobile) {
                $el.css("width", "100%");
                $el.css("top", "0%");
                $el.css("height", 174);
                $el.css("right", "0%");
            }
            $video[0].play();
            ga.sendEvent("Ad", "Netflix Button Click", "Click", 1);
            clearTimeout(this.currentSlideTimer);
            can.$(".carousel-control").hide();
        },
        ".adURL click": function ($el, ev) {
            ga.sendEvent(
                "Ad",
                $el.data("campaign-name") + " Click",
                "Click",
                1
            );
        },
    },
    helpers: {
        showRegisterButton: function showRegisterButton(movieCompute, options) {
            if (window.platform === "kixi") {
                var movie = utils.resolveCompute(movieCompute);
                if (movie.isPlusMovie()) {
                    return options.fn();
                }
            }
            return options.inverse();
        },
        // eslint-disable-next-line no-unused-vars
        applyGDPR(urlCompute, gdprStateCompute, _options) {
            let url = utils.resolveCompute(urlCompute);
            const gdprState = utils.resolveCompute(gdprStateCompute);
            console.log("applying gpdr ", url, gdprState);
            url = url?.replace(/\[Timestamp\]/gi, String(date));
            return url
                ?.replace("${GDPR}", gdprState?.gdprApplies ? "1" : "0")
                .replace(/\$\{GDPR_CONSENT_?(\d+)\}/, gdprState?.tcString);
        },
    },
});
