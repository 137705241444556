import can from "can";
import externalAdvertisers from "../../lib/externalAdvertisers";
import loader from "../../lib/loader";
import API from "../api";
import config from "../config";
import Category from "../models/category";
import "../models/modelFactory";

var loadIndex = function ($def) {
    API.getAllCategories().then(function (index) {
        var models = {
            categories: Category.models(index.categories),
            homepage_categories: Category.models(index.homepage_categories),
        };
        models.all_categories = [];
        [].push.apply(models.all_categories, models.categories);
        [].push.apply(models.all_categories, models.homepage_categories);

        models.category_tree = {};
        var category_tree_list = [];
        index.category_index =
            index.category_index ||
            index.categories.map(function (category) {
                return category;
            });
        var enabled = config.categoryTree.enabled;

        // add pseudo "all"-categories with negative id (based on actual id)
        index.categories.forEach(function (item) {
            if (
                item.id !== 11803 &&
                (!config.plus.enabled || !config.plus.isPlusCategory(item))
            ) {
                var fakeId = -item.id;
                var fakeTitle = enabled ? "Alle Filme" : item.title;
                if (enabled && window.platform === "kixi") {
                    switch (item.slug) {
                        case "serien":
                            fakeTitle = "Alle Serien";
                            break;
                        case "audio":
                            fakeTitle = "Alle Audioinhalte";
                            break;
                    }
                }
                index.category_index[fakeId] = {
                    id: fakeId,
                    parent: item.id,
                    slug: item.slug + (enabled ? "-all" : ""),
                    title: fakeTitle,
                    post_count: item.post_count,
                    subcategories: [],
                };
            }
        });

        Object.keys(index.category_index).forEach(function (key) {
            // prepare tree and tree list
            models.category_tree[key] = index.category_index[key];
            category_tree_list.push(index.category_index[key]);
            models.category_tree[key].subcategories = [];

            // write back subcategories to category tree
        });
        var isRoot = config.categoryTree.isRoot;

        Object.keys(index.category_index).forEach(function (key) {
            var cat = models.category_tree[key];
            if (!isRoot(cat)) {
                var parent = models.category_tree[cat.parent];
                if (!parent) return; // kixi and others have a different category structure, leading to other roots/parents

                var parentSubcategories = parent.subcategories;
                if (key < 0) {
                    // fake id
                    parentSubcategories.unshift(key);
                } else {
                    parentSubcategories.push(key);
                }
            }
        });
        // if the fakeCat is the only subcategory of its original category, remove it
        // => this category never had a subcategoy
        Object.keys(models.category_tree).forEach(function (key) {
            var cat = models.category_tree[key];
            if (
                cat.subcategories.length < 2 &&
                cat.subcategories[0] === "-" + cat.id
            ) {
                cat.subcategories = [];
            }
        });
        [].push.apply(
            models.all_categories,
            Category.models(category_tree_list)
        );
        $def.resolve(models);
    });

    return $def;
};

export default can.Construct.extend(
    {},
    {
        init: function (state) {
            this.options = new can.Map({});
            this.options.state = state;

            var data = new can.Map();
            state.data.attr("index", data);

            config.log("Loading Index...");

            var $indexLoaderDef = loadIndex(new can.Deferred())
                .then(function (models) {
                    var $def = can.Deferred();
                    // setTimeout(function() {
                    can.batch.start();

                    var categoriesById = state.data.categoriesById;
                    var categoriesBySlug = state.data.categoriesBySlug;
                    var moviesBySlug = state.data.moviesBySlug;
                    var moviesById = state.data.moviesById;

                    models.all_categories.forEach(function (cat) {
                        if (!cat.attr("posts")) {
                            cat.attr("posts", []);
                        }

                        // map slider defaults on category basis:
                        cat.sliderType =
                            config.frontpage.sliderTypeForCategory(cat);

                        if (models.category_tree[cat.id]) {
                            cat.subcategories =
                                models.category_tree[cat.id].subcategories ||
                                [];
                        } else console.warn("Category", cat.slug, "is not part of index_category response!");
                        // if(models.category_tree[cat.id] && cat.subcategories.length > 1)
                        //     console.log("Category", cat.slug, "has subcategories");

                        categoriesById[cat.id] = cat;
                        categoriesBySlug[cat.slug] = cat;
                        if (cat.posts) {
                            cat.posts.forEach(function (movie) {
                                moviesBySlug[movie.slug] = movie;
                                moviesById[movie.id] = movie;
                            });
                        }
                    });

                    for (var key in models.category_tree) {
                        if (!categoriesById[key]) {
                            categoriesById[key] = models.category_tree[key];
                            categoriesBySlug[models.category_tree[key].slug] =
                                models.category_tree[key].slug;
                        }
                    }

                    models.featured_homepage_category =
                        models.homepage_categories.filter(
                            config.frontpage.isFeaturedSliderCategory
                        )[0];
                    models.homepage_categories =
                        models.homepage_categories.filter(function (item) {
                            return !config.frontpage.isFeaturedSliderCategory(
                                item
                            );
                        });
                    models.homepage_categories.comparator = "sort_id";
                    models.homepage_categories.sort();
                    // Swap Array Elements
                    function swapElements(arr, from, to) {
                        var b = arr[from];
                        arr[from] = arr[to];
                        arr[to] = b;
                        // arr.splice(
                        //     from,
                        //     1,
                        //     arr.splice(to, 1, arr[from])[0]
                        // );
                    }
                    // NP-739. Set 'Neu bei Netzkino' as first category to be shown.
                    if (
                        models.homepage_categories &&
                        models.homepage_categories.length > 0
                    ) {
                        for (
                            var i = 0;
                            i < models.homepage_categories.length;
                            i++
                        ) {
                            if (
                                models.homepage_categories[i].title.includes(
                                    "Neu bei Netzkino"
                                )
                            ) {
                                // 'Neu bei Netzkino' is already first category
                                if (i === 0) {
                                    break;
                                } else {
                                    // 'Neu bei Netzkino' will be placed as first category (index 0)
                                    swapElements(
                                        models.homepage_categories,
                                        i,
                                        0
                                    );
                                    break;
                                }
                            }
                        }
                    }

                    if (
                        config.ads.frontpagebanner.show ||
                        window.platform === "netzkino"
                    ) {
                        models.homepage_categories.forEach(function (cat, i) {
                            if (
                                (i - 1) %
                                    config.ads.frontpagebanner
                                        .everyNthSlider ===
                                0
                            ) {
                                cat.attr("shouldDisplayAd", true);
                            } else {
                                cat.attr("shouldDisplayAd", false);
                            }
                        });
                    }

                    data.attr(models);
                    can.batch.stop();

                    config.log("Loaded Index...");
                    $def.resolve(models);
                    // }, 1000);
                    return $def;
                })
                .then(
                    function () {
                        state.defs.indexLoader.resolve.apply(this, arguments);
                    },
                    function () {
                        state.defs.indexLoader.reject();
                    }
                );
            $indexLoaderDef.always(function () {
                can.defer(function () {
                    try {
                        externalAdvertisers.init("indexload");
                    } catch (e) {
                        console.error(
                            "could load initialize external advertisers indexload"
                        );
                    }
                });
            });
            loader($indexLoaderDef, true, "Lade Index-Daten...");
        },
    }
);
