import can from "can";
import API from "../../api";
import config from "../../config";
import ga from "../../modules/googleanalytics";
import viewHeader from "./view.stache";
can.Component.extend({
    tag: "my-user-login",
    template: viewHeader,
    scope: {
        config: config,
    },
    events: {
        "#register click": function ($el, ev) {
            var val = $el.html();
            this.element.trigger("switchLoginComponent", "register");
            ev.preventDefault();
            return false;
        },
        "form submit": function (el, ev) {
            // just for tracking. Does not prevent standard form submit
            ev.preventDefault();
            API.addToStorage("loggedIn", true);
            ga.sendEvent("Account Action", "Login", "local");
            API.login($("#loginEmail").val(), $("#loginPassword").val());
        },
    },
});
